import { IJob, SESSION_STORAGE_KEY } from "./types";

const queueJob = (job: IJob) => {
  let queuedJobs = [];
  try {
    queuedJobs = JSON.parse(
      sessionStorage.getItem(SESSION_STORAGE_KEY.JOBS) || "[]"
    );
  } catch {
    console.warn("Error while reading event queue");
  }

  const updatedJobQueue = [...queuedJobs, job];
  sessionStorage.setItem(
    SESSION_STORAGE_KEY.JOBS,
    JSON.stringify(updatedJobQueue)
  );
};

export default queueJob;
