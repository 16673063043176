import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import "./App.css";
import App from "./AppAlpha";
import * as serviceWorker from "./serviceWorker";
import AppStore from "./user/AppStore";
import { useProvider as provider } from "mobx-store-provider";
import { SnackbarProvider } from "notistack";
import { DURATION_TOSTA } from "./utils/constant";

const appStore = AppStore.create();
const Provider = provider();

ReactDOM.render(
  <Provider value={appStore}>
    <React.StrictMode>
      <SnackbarProvider
        maxSnack={3}
        anchorOrigin={{
          horizontal: "right",
          vertical: "top",
        }}
        autoHideDuration={DURATION_TOSTA}
      >
        <App />
      </SnackbarProvider>
    </React.StrictMode>
  </Provider>,
  document.getElementById("root")
);

serviceWorker.unregister();
