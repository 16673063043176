import React from "react";
import { useParams } from "react-router-dom";
import ViewDetails from "./ViewDetails";
import { useStyles } from "./ViewDetails.styles";

/* This version will render things flat without a Dialog box.
This is used for deeplink. Check routes for usage info*/
const ViewDetailsStandAlone = () => {
  const { device_id }: any = useParams();
  const classes = useStyles();

  return (
    <div
      className={classes.dialogRoot}
      style={{
        minWidth: "auto",
        maxWidth: "none",
        margin: "0 1em 1em 6em",
      }}
    >
      <ViewDetails
        endPoint={`/devices/${device_id}`}
        onDone={() => true}
        readOnly
      />
    </div>
  );
};

export default ViewDetailsStandAlone;
