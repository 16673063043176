import React, { useEffect } from "react";
import { useSnackbar } from "notistack";

import SubmitWithConfirmation from "../SubmitWithConfirmation";
import useApi from "../../../hooks/useApi/useApi";

const COMMENT_REGEX = /^[0-9a-zA-Z_\\s-]/;
interface IUpdateCommentCTA {
  updatedComment: string | null;
  currentComment: string;
  endPointURL: string;
  requestPage: string;
  onDone?: () => void;
}

const UpdateCommentCTA: React.FC<IUpdateCommentCTA> = ({
  updatedComment,
  currentComment,
  endPointURL,
  requestPage,
  onDone = () => true,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const {
    data: commentUpdateResult,
    status: commentUpdateStatus,
    trigger: updateComment,
  } = useApi(endPointURL, {
    method: "POST",
    deferred: true,
    includePMSCredentialsInRequestBody: true,
    responseDataFormatter: (data: any) => {
      return data.message;
    },
  });

  useEffect(() => {
    if ((commentUpdateResult || "").toLowerCase() === "success") {
      enqueueSnackbar("Comment updated successfully", { variant: "success" });
      onDone();
      return;
    }

    if (!commentUpdateStatus.pending && commentUpdateStatus.error) {
      enqueueSnackbar("You are not authorized to perform this operation", {
        variant: "error",
      });
    }
  }, [commentUpdateResult, commentUpdateStatus.error]);

  return (
    <SubmitWithConfirmation
      title="Are you sure?"
      subTitle="Are you sure that you want to save these changes?"
      preValidator={() => {
        if (!COMMENT_REGEX.test(updatedComment || "")) {
          enqueueSnackbar("Comment should not start with special characters", {
            variant: "error",
          });
          return false;
        }
        return true;
      }}
      onYes={() => {
        updateComment({ comment: updatedComment, request_page: requestPage });
      }}
      buttonLabel="Save"
      disabled={
        updatedComment === null ||
        updatedComment === "" ||
        currentComment === updatedComment
      }
    />
  );
};

export default UpdateCommentCTA;
