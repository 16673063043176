import React from "react";
import { Grid } from "@material-ui/core";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    header: {
      width: "auto",
      margin: "1.5em 1.5em 1.5em 3em",
      padding: "1.5em",
      background: "#2b2b36",
    },
    wrapper: {
      display: "flex",
      color: "white",
      alignItems: "center",
    },
    link: {
      fontFamily: '"Avenir Next"',
      fontSize: "14px",
      color: "rgb(196, 196, 196)",
      marginRight: "10px",
      cursor: "pointer",
      textDecoration: "underline",
    },
    path: {
      fontFamily: '"Avenir Next"',
      fontSize: "16px",
      lineHeight: "19px",
      color: "rgb(196, 196, 196)",
      fontWeight: "bold",
      marginLeft: ".5em",
    },
  })
);

const HeaderNavigation: React.FC<any> = ({ onClick }) => {
  const classes = useStyles();
  return (
    <Grid container direction="column" className={classes.header}>
      <div className={classes.wrapper}>
        <div className={classes.link} onClick={onClick}>
          Device Management
        </div>{" "}
        {" > "}
        <div className={classes.path}>Replace Device</div>
      </div>
    </Grid>
  );
};

export default HeaderNavigation;
