import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { DataTable } from "..";

//Convert tagData to a Data grid compatible columnSchema and values
const formatForDataTable = (tagData: any) => {
  const columns = tagData.value.split("|").map((columnName: string) => {
    return {
      id: columnName,
      label: columnName,
    };
  });

  const values = tagData.table_values.map((tableRecord: any[]) => {
    let record = {};
    tableRecord.forEach((value: any, index: number) => {
      record = { ...record, [columns[index].id]: value };
    });
    return record;
  });

  return {
    columns,
    values: values,
  };
};

const ReadOnlyTagRenderer: React.FC<any> = ({ mappedTags }) => {
  return (
    <Grid container spacing={0} className="dcOuter dcSep">
      <Grid item xs={12}>
        <Typography style={{ fontSize: "19px", fontWeight: "bolder" }}>
          Tags
        </Typography>
      </Grid>
      {mappedTags
        .filter(({ type }: any) => type !== "Tabular")
        .map((tagData: any) => {
          return (
            <Grid item xs={4}>
              <Typography className="dcTitle">{tagData.name}</Typography>
              <Typography className="dcFieldValue">{tagData.value}</Typography>
            </Grid>
          );
        })}
      {mappedTags
        .filter(({ type }: any) => type === "Tabular")
        .map((tagData: any) => {
          return (
            <Grid item xs={12}>
              <Typography className="dcTitle" style={{ marginBottom: "1em" }}>
                {tagData.name}
              </Typography>
              <DataTable
                {...formatForDataTable(tagData)}
                refreshGrid={() => true}
                noWrapper
              />
            </Grid>
          );
        })}
    </Grid>
  );
};

export default ReadOnlyTagRenderer;
