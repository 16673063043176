import React from "react";

import useStyles from "./Comments.styles";

interface ICommentInfoBoxProps {
  updatedBy?: string;
  updatedOn?: string;
  commentLength: number;
  maximumCommentLength: number;
}

const CommentInfoBox: React.FC<ICommentInfoBoxProps> = ({
  updatedBy,
  updatedOn,
  commentLength,
  maximumCommentLength,
}) => {
  const classes = useStyles();
  return (
    <div className={classes.metaInfo}>
      {updatedBy ? (
        <div>
          <div>
            <strong>Updated By: </strong> {updatedBy}
          </div>
          <div>
            <strong>Updated On: </strong> {updatedOn}
          </div>
        </div>
      ) : (
        <span>No Comments Added Yet</span>
      )}

      <div>{`${commentLength}/${maximumCommentLength}`}</div>
    </div>
  );
};

export default CommentInfoBox;
