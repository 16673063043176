import React from "react";
import { Grid, IconButton } from "@material-ui/core";
import { Delete as DeleteIcon } from "@material-ui/icons";
import { CustomTextField, FormSection } from "../../../UiComponents";
import useStyles from "../EditConfiguration/EditConfiguration.styles";

const Columns: React.FC<any> = ({
  tableName,
  isNew,
  dropdowns,
  onDropdownUpdate,
  onDropdownRemove,
}) => {
  const classes = useStyles();
  return (
    <>
      {Object.keys(dropdowns).map((columnName: any, index) => {
        return (
          <Grid item xs={6} key={`${columnName}${index}`}>
            <div className={classes.tagWrapper}>
              <div className={classes.tagHeaderWrapper}>
                <div
                  style={{
                    fontSize: "14px",
                    fontWeight: "bold",
                    position: "relative",
                  }}
                >
                  {`${tableName}: ${columnName} (Enter possible field values separated by | )`}
                </div>
                <IconButton
                  style={{ margin: "-0.6em 0em" }}
                  onClick={() => onDropdownRemove(tableName, columnName)}
                  disabled={!isNew}
                >
                  <DeleteIcon color="secondary" />
                </IconButton>
              </div>

              <CustomTextField
                key={columnName}
                style={{
                  marginTop: "0",
                  paddingTop: "0",
                }}
                value={dropdowns[columnName]}
                onChange={(value: string) =>
                  onDropdownUpdate(tableName, columnName, value)
                }
              />
            </div>
          </Grid>
        );
      })}
    </>
  );
};

const TabularDropdowns: React.FC<any> = ({
  dropdowns,
  newlyAddedTabularTags,
  onDropdownUpdate,
  onDropdownRemove,
}) => {
  return (
    <FormSection header="Dropdowns for Tabular Tags">
      {Object.keys(dropdowns).map((tableName: any) => {
        return (
          <Columns
            key={tableName}
            tableName={tableName}
            isNew={newlyAddedTabularTags.includes(tableName)}
            dropdowns={dropdowns[tableName]}
            onDropdownUpdate={onDropdownUpdate}
            onDropdownRemove={onDropdownRemove}
          />
        );
      })}
    </FormSection>
  );
};

export default TabularDropdowns;
