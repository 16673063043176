import React from "react";
import { useMultiFilterContext } from "./MultiFilterContextProvider";
import {
  Chip,
  withStyles,
  createStyles,
  makeStyles,
  Theme,
} from "@material-ui/core";
import { IFilter, IFilterSchema } from "./types";

//TODO: Add proper types

const StyledChip = withStyles({
  root: {
    background: "#0097bb33",
    color: "white",
    margin: "5px",
    border: "2px solid #0097BB",
    maxWidth: "13em",
    "& .MuiChip-deleteIcon": {
      color: "white",
    },
  },

  label: {
    textTransform: "none",
  },
})(Chip);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    clearButton: {
      position: "absolute",
      right: "5px",
      top: "5px",
      border: "1px solid #3e3e3e",
      padding: "5px 10px",
      borderRadius: "5px",
      cursor: "pointer",
    },
  })
);

const getFilterSchema = (
  availableFilters: IFilterSchema[],
  filterType: string
) => {
  const filterSchema = availableFilters.find(
    ({ id }: any) => id === filterType
  );
  return filterSchema;
};

const getFilterLabelFromFilterSchema = (
  availableFilters: IFilterSchema[],
  filterType: string
) =>
  availableFilters.find(({ id }: any) => id === filterType)?.label ||
  filterType;

const translateFilterValueByFilterSchema = (
  availableFilters: IFilterSchema[],
  filterType: string,
  filterValue: string
) => {
  const filterSchema = availableFilters.find(
    ({ id }: any) => id === filterType
  );

  if (filterSchema?.input && filterSchema?.input.type === "dropDown") {
    return (filterSchema?.input.dropDownOptions || []).find(
      ({ value }) => value === filterValue
    )?.label;
  }
  return filterValue;
};

const MultiFilterChipRenderer: React.FC<any> = ({}) => {
  const { availableFilters, activeFilters, setActiveFilters, setOpenedFilter } =
    useMultiFilterContext();

  const classes = useStyles();

  const removableFilters = activeFilters.filter(({ filterType }) => {
    const columnSchema = availableFilters.find(({ id }) => id === filterType);
    return !columnSchema?.nonRemovable;
  });

  return (
    <div style={{ color: "white", position: "relative" }}>
      <div style={{ maxWidth: "70%" }}>
        {activeFilters.map(({ filterValue, filterType }: any) => {
          const label = `${getFilterLabelFromFilterSchema(
            availableFilters,
            filterType
          )}: ${translateFilterValueByFilterSchema(
            availableFilters,
            filterType,
            filterValue
          )}`;

          const filterSchema = getFilterSchema(availableFilters, filterType);

          const deleteHandler = filterSchema?.nonRemovable
            ? {}
            : {
                onDelete: () => {
                  const filterSchame = availableFilters.find(
                    ({ id }) => id === filterType
                  );
                  if (!filterSchame?.nonRemovable) {
                    setActiveFilters(
                      activeFilters.filter(
                        ({ filterType: ft }: IFilter) => filterType !== ft
                      )
                    );
                  }
                },
              };

          return (
            <StyledChip
              key={filterType}
              style={
                filterSchema?.nonRemovable
                  ? { border: "2px dotted #0097BB" }
                  : {}
              }
              label={label}
              title={label}
              variant="outlined"
              onClick={() => setOpenedFilter(filterType)}
              {...deleteHandler}
            />
          );
        })}
      </div>

      {activeFilters.length > 0 && removableFilters.length > 0 && (
        <div
          className={classes.clearButton}
          role="button"
          onClick={() => {
            const nonRemovableFilters = activeFilters.filter(
              ({ filterType }) => {
                const columnSchema = availableFilters.find(
                  ({ id }) => id === filterType
                );
                return columnSchema?.nonRemovable;
              }
            );
            setActiveFilters(nonRemovableFilters);
          }}
        >
          <strong style={{ fontSize: "1em" }}>✕</strong>
          {"  "}Clear Filters
        </div>
      )}
    </div>
  );
};

export { MultiFilterChipRenderer };
