import React, { useState, useEffect } from "react";
import Typography from "@material-ui/core/Typography";
import { useHistory } from "react-router-dom";

const device_config = "Device Configurations";
const connection_config = "Connection Configurations";
const font_family = "Avenir Next";

const BreadcurmbComponent = (props: any) => {
  const history = useHistory();
  const [result, setResult] = useState<any>({
    addDevice: [
      { name: "Devices", url: "devices" },
      { name: "Manage Devices" },
    ],
    editDevice: [{ name: "Devices", url: "devices" }, { name: "Edit Device" }],
    deviceConfig: [{ name: "Configurations" }, { name: device_config }],
    connectionConfig: [{ name: "Configurations" }, { name: connection_config }],
    editConnectionConfig: [
      { name: "Configurations" },
      { name: connection_config, url: "conn-config" },
      { name: "Edit Connection Configuration" },
    ],
    addConnectionConfig: [
      { name: "Configurations" },
      { name: connection_config, url: "conn-config" },
      { name: "Add Connection Configuration" },
    ],
    addDeviceConfig: [
      { name: "Configurations" },
      { name: device_config, url: "device-config" },
      { name: "Add New Configuration" },
    ],
    editDeviceConfig: [
      { name: "Configurations" },
      { name: device_config, url: "device-config" },
      { name: "Edit Device Configuration" },
    ],
    resources: [{ name: "Resources" }, { name: "IoT Hubs" }],
    addResources: [
      { name: "Resources" },
      { name: "IoT Hubs", url: "resources" },
      { name: "Add Existing IoT Hub" },
    ],
    createAzureResources: [
      { name: "Resources" },
      { name: "IoT Hubs", url: "resources" },
      { name: "Add New Resource" },
    ],
    editResources: [
      { name: "Resources" },
      { name: "IoT Hubs", url: "resources" },
      { name: "Edit Existing IoT Hub" },
    ],
    application: [{ name: "Portal" }, { name: "Applications" }],
    addApplication: [
      { name: "Portal" },
      { name: "Applications", url: "applications" },
      { name: "Add New Application" },
    ],
    editApplication: [
      { name: "Portal" },
      { name: "Applications", url: "applications" },
      { name: "Edit Application" },
    ],
    roles: [{ name: "Portal" }, { name: "Roles" }],
    addRole: [
      { name: "Portal" },
      { name: "Roles", url: "roles" },
      { name: "Add New Role" },
    ],
    editRole: [
      { name: "Portal" },
      { name: "Roles", url: "roles" },
      { name: "Edit Role" },
    ],
    users: [{ name: "Portal" }, { name: "Users" }],
    auditLog:[{ name: "Audit Log" }],
    addUser: [
      { name: "Portal" },
      { name: "Users", url: "users" },
      { name: "Add New User" },
    ],
    editUser: [
      { name: "Portal" },
      { name: "Users", url: "users" },
      { name: "Edit User" },
    ],
    // manageApp: [
    //   { name: "Portal" },
    //   { name: "Users", url: "users" },
    //   { name: "Manage Application" },
    // ],
    deviceInstallation: [{ name: "Devices" }, { name: "Device Installation" }],
  });
  const SmallHeader = (text: string, link: string) => {
    return (
      <React.Fragment>
        {props.func && link ? (
          <Typography
            style={{
              fontFamily: font_family,
              fontSize: "14px",
              lineHeight: "14px",
              color: "#C4C4C4",
              marginRight: "10px",
              textDecoration: "underline",
              cursor: "pointer",
            }}
            onClick={props.func}
          >
            {text}
          </Typography>
        ) : (
          <Typography
            style={{
              fontFamily: font_family,
              fontSize: "14px",
              lineHeight: "14px",
              color: "#C4C4C4",
              marginRight: "10px",
            }}
          >
            {text}
          </Typography>
        )}
        <Typography
          style={{
            color: "#C4C4C4",
            fontSize: "14px",
            marginRight: "10px",
          }}
        >
          {" "}
          {" > "}{" "}
        </Typography>
      </React.Fragment>
    );
  };

  const BigHeader = (text: string) => {
    return (
      <Typography
        style={{
          fontFamily: font_family,
          fontSize: "16px",
          lineHeight: "19px",
          color: "#C4C4C4",
          fontWeight: "bold",
        }}
      >
        {text}
      </Typography>
    );
  };

  return result[props.route]
    ? result[props.route].map((x: any, index: number) =>
        index < result[props.route].length - 1
          ? SmallHeader(x.name, x.url)
          : BigHeader(x.name)
      )
    : BigHeader(props.route);
};

export default BreadcurmbComponent;
