import React, { useState } from "react";
import { ITemplateColumnProps } from "../../../../UiComponents/DataTable/types";
import UpdateInstallationStatusModal from "./UpdateInstallationStatusModal";

const DeviceInstallationStatusColumn: React.FC<ITemplateColumnProps> = (
  props
) => {
  const [modalOpen, setModalOpen] = useState(false);

  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <div
        onClick={() => setModalOpen(true)}
        style={{
          color: "rgb(0, 137, 255)",
          cursor: "pointer",
          fontWeight: "bolder",
        }}
      >
        {props.record.device_install_status || ""}
      </div>
      {modalOpen && (
        <UpdateInstallationStatusModal
          deviceName={props.record.device_name}
          deviceDlmId={props.record.device_dlm_id}
          installStatus={props.record.device_install_status}
          installationStatusId={props.record.device_install_status_id}
          open={true}
          onSuccess={() => {
            props.refreshGrid();
            setModalOpen(false);
          }}
          onClose={() => setModalOpen(false)}
        />
      )}
    </div>
  );
};

export default DeviceInstallationStatusColumn;
