import React, { useState, useEffect } from "react";
import {
  makeStyles,
  createStyles,
  Theme,
} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { useSnackbar } from "notistack";
import { API } from "../../api/property";
import { Backdrop, CircularProgress } from "@material-ui/core";
import callXhrRequest from "../../utils/xhrRequestHandler";
import { arrayOfErrorsRefresh, arrayOfErrorsLogout } from '../../utils/helper';
import { useStore } from "mobx-store-provider";
import BreadcumbComponent from '../../utils/breadcurmb';
import CustomTextField from '../UiComponents/TextField';

const textFieldBorder = "1px solid #464659"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      margin: "30px",
    },
    noSpace: {
      padding: "0px",
      margin: "0px",
    },
    spaceTop: {
      marginTop: "35px",
    },
    space2Top: {
      marginTop: "20px",
    },
    spaceRight: {
      paddingRight: "10px",
    },
    spaceLeft: {
      paddingLeft: "10px",
    },
    formTitle: {
      fontWeight: "bold",
      fontSize: "21px",
      lineHeight: "25px",
      color: "#FFFFFF",
    },
    form: {
      width: "100%", // Fix IE 11 issue.
    },
    fieldWrapper: {
      marginTop: "20px",
    },
    labels: {
      color: "#fcfcfc",
      lineHeight: "19px",
      fontSize: "16px",
      transform: "scale(1)",
    },
    accordionRoot: {
      color: "#FFFFFF",
      margin: "0px",
      padding: "0px",
      boxShadow: "none",
      "& .MuiAccordionSummary-content": {
        margin: "0px",
      },
      minHeight: "auto !important",
    },
    noSpaceNoMin: {
      margin: "0px !important",
      padding: "0px !important",
      minHeight: "auto !important",
    },
    typoRoot: {
      paddingLeft: "24px",
    },
    field: {
      color: "#FCFCFC",
      fontSize: "12px",
      padding: "10px",
    },
    textfield: {
      color: "#ffffff",
      backgroundColor: "#1D1D25",
      border: `${textFieldBorder}`,
      borderRadius: "2px",
      boxSizing: "border-box",
    },
    icon: {
      color: "#FFFFFF",
    },
    options: {
      color: "#FCFCFC",
      fontSize: "12px",
      padding: "10px",
      cursor: "pointer",
    },
    gridRoot: {
      flexGrow: 1,
      padding: "0.5em 24px",
      backgroundColor: "#2B2B36",
    },
    fieldRoot: {
      marginTop: "16px",
    },
    selectRoot: {
      padding: "24px",
    },
    datePickerRoot: {
      color: "#FFFFFF",
      border: `${textFieldBorder}`,
      borderRadius: "2px",
      "& .MuiIconButton-root, .MuiPickersDay-day, .MuiPickersCalendarHeader-dayLabel": {
        color: "#FFFFFF",
      },
      "& .MuiPickersDay-daySelected": {
        border: `${textFieldBorder}`,
      },
    },
    bottomSection: {
      justifyContent: "space-between",
      display: "flex",
      borderTop: "1px solid #33333F",
      paddingTop: "20px",
    },
    btnCancel: {
      borderRadius: "2px",
      border: "1px solid rgba(235,235,245,0.35)",
      color: "#0089FF",
      background: "none",
      fontSize: "16px",
      lineHeight: "19px",
      textTransform: "none",
      padding: "9px 20px",
    },
    btnAddNUser: {
      backgroundColor: "#0089ff",
      borderRadius: "4px",
      border: "none",
      color: "#FFFFFF",
      "&:hover": {
        backgroundColor: "#0089ff",
      },
      fontSize: "16px",
      lineHeight: "19px",
      textTransform: "none",
      padding: "9px 20px",
    },
    vp: {
      backgroundColor: "#2B2B36",
      border: `${textFieldBorder}`,
      borderRadius: "2px",
      color: "#FCFCFC",
      "&:hover": {
        backgroundColor: "#2B2B36",
      },
      fontSize: "14px",
      lineHeight: "16px",
      textTransform: "none",
      padding: "12px 0px",
      marginTop: "34px",
      width: "100%",
    },
    helperText: {
      color: "#d32f2f !important",
      position: "absolute",
      right: 0,
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: "#fff",
    },
  })
);

interface EditResourceInterface {
  editResources: any;
  onCancelAction: any;
}

export default function EditResource({
  editResources, onCancelAction
}: EditResourceInterface) {
  const classes = useStyles();
  const { user } = useStore();

  const API_KEY = window.SERVER_DATA.REACT_APP_PM_API_KEY;
  const { enqueueSnackbar } = useSnackbar();
  const tokenKey = "sso_token";
  const accessToken = JSON.parse(sessionStorage.getItem(tokenKey) as string);
  interface sObj {
    pm_s_id: string;
  }
  interface tObj {
    pm_s_token: string;
  }
  let pmSId: sObj = {
    pm_s_id: "",
  };
  let pmST: tObj = {
    pm_s_token: "",
  };
  pmSId =
    sessionStorage.getItem("pm_s_id") || JSON.parse(JSON.stringify(pmSId));
  pmST =
    sessionStorage.getItem("pm_s_token") || JSON.parse(JSON.stringify(pmST));
  const [backDrop, setbackDrop] = React.useState(false);
  const backDropClose = () => {
    setbackDrop(false);
  };
  const editApplication = editResources.application_name;
  const editApplicationId = editResources.application_id;
  const editIotHubId = editResources.iot_hub_dlm_id;
  const editSubscriptionId = editResources.subscription_id;
  const editSubscriptionName = editResources.subscription_name;
  const editResourceGroup = editResources.resource_group_name;
  const editRegion = editResources.region;
  const editiotHub = editResources.iot_hub_name;
  const editSku = editResources.sku;
  const editDeviceString = editResources.device_conn_string;
  const editServiceString = editResources.service_conn_string;


  const [editSubscriptionIdValue, setEditSubscriptionIdValue] = React.useState(editSubscriptionId);
  const [editSubscriptionNameValue, setEditSubscriptionNameValue] = React.useState(editSubscriptionName);
  const [editResourceGroupValue, setEditResourceGroupValue] = useState(editResourceGroup);
  const [region, setRegion] = React.useState(editRegion);
  const [sku, setSKU] = React.useState(editSku);
  const [reload, setReload] = React.useState(false);
  const [editIotHubName, setEditIotHubName] = useState(editiotHub);
  const [editDeviceConnString, setEditDeviceConnString] = useState(editDeviceString);
  const [editServiceConnString, setEditServiceConnString] = useState(editServiceString);

  // const onChangeRegion = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   setRegion(event.target.value);
  // };

  // const onChangeSKU = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   setSKU(event.target.value);
  // };

  const saveResourceAction = () => {
    const accessToken = JSON.parse(sessionStorage.getItem(tokenKey) as string);
    const EDIT_RESOURCE = `${API["GETALLAPPLS"]}/${editApplicationId}/az-iot-hubs/${editIotHubId}`
    const requestOptions = {
      method: 'PUT',
      headers: {
        'Content-Type': "application/json",
        'Authorization': `Bearer ${accessToken.access_token}`,
        'Ocp-Apim-Subscription-Key': `${API_KEY}`,
        'Ocp-Apim-Trace': `true`
      },
      body: JSON.stringify(
        {
          az_iot_hub: {
            subscription_id: editSubscriptionIdValue,
            subscription_name: editSubscriptionNameValue,
            resource_group_name: editResourceGroupValue,
            region: region,
            sku: sku,
            iot_hub_name: editIotHubName,
            device_conn_string: editDeviceConnString,
            service_conn_string: editServiceConnString,
            iot_hub_type: "EXISTING"
          },
          pm_s_token: pmST,
          pm_s_id: pmSId,
        }
      )
    };
    fetch(EDIT_RESOURCE, requestOptions)
      .then(response => response.json()).then(function (response: any) {
        if ((response && response.status === 401) && (response && arrayOfErrorsRefresh.includes(response.message.toLowerCase()))) {
          try {
            const tokenKey = "sso_token";
            callXhrRequest().then(function (data) {
              sessionStorage.setItem(tokenKey, data);
              saveResourceAction();
            }).catch(function (error) {
              user.triggerLogout();
              console.log('error', error)
            })
          } catch (error) {
            console.log(error);
          }
          return;
        }
        else if ((response && response.status === 401) && (response && arrayOfErrorsLogout.includes(response.message.toLowerCase()))) {
          try {
            user.triggerLogout();
          } catch (error) {
            console.log(error);
          }
          return;
        }
        else if (response.status === 200 || response.status === 201) {
          enqueueSnackbar('Resource modification success', { variant: "success" });       
          setbackDrop(false);
          onCancelAction()
        }
        else {
          enqueueSnackbar('Resource modification failed', { variant: "error" });
          setbackDrop(false);
        }
      })
      .catch(function (error) {
        console.log(error);
      });

  }
  const [editResourceInput, setEditResourceInput] = useState(
    [] as any
  );
  const Regions = [] as any;
  const Skus = [] as any;
  editResourceInput.forEach((r: any) => {
    if (r.regions) {
      r.regions.forEach((region: any) => {
        Regions.push({ label: region, value: region })
      })
    }
    if (r.skus) {
      r.skus.forEach((s: any) => {
        Skus.push({ label: s, value: s })
      })
    }
  })

  const validateField = () => {
    const regexCheck = /^[0-9a-zA-Z_-]+$/;
    const regexString = /^HostName/;

    if (!editSubscriptionIdValue) {
      enqueueSnackbar("Please enter subscrption id.", { variant: "error" });
    } else if (!(regexCheck.test(editSubscriptionIdValue) && editSubscriptionIdValue.length < 100)) {
      enqueueSnackbar('Invalid subscription id (only 0-9,A-Z,a-z,_,- allowed upto 100 characters).', { variant: "error" });
    } else if (!editSubscriptionNameValue) {
      enqueueSnackbar("Please enter subscrption name.", { variant: "error" });
    } else if (!(regexCheck.test(editSubscriptionNameValue) && editSubscriptionNameValue.length < 100)) {
      enqueueSnackbar('Invalid subscription name (only 0-9,A-Z,a-z,_,- allowed upto 100 characters).', { variant: "error" });
    } else if (!editResourceGroupValue) {
      enqueueSnackbar("Please enter resource group.", { variant: "error" });
    } else if (!(regexCheck.test(editResourceGroupValue) && editResourceGroupValue.length < 100)) {
      enqueueSnackbar('Invalid resource group (only 0-9,A-Z,a-z,_,- allowed upto 100 characters).', { variant: "error" });
    } else if (region.length === 0) {
      enqueueSnackbar("Please select region.", { variant: "error" });
    } else if (sku.length === 0) {
      enqueueSnackbar("Please select sku.", { variant: "error" });
    } else if (!editIotHubName) {
      enqueueSnackbar("Please enter iotHubName.", { variant: "error" });
    } else if (!(regexCheck.test(editIotHubName) && editIotHubName.length < 100)) {
      enqueueSnackbar('Invalid iot Hub Name (only 0-9,A-Z,a-z,_,- allowed upto 100 characters).', { variant: "error" });
    } else if (!editDeviceConnString) {
      enqueueSnackbar("Please enter device connection string.", { variant: "error" });
    } else if (!(regexString.test(editDeviceConnString) && (editDeviceConnString.length < 250))) {
      enqueueSnackbar('Device connection string is not started either with HostName or greater than 250 character.', { variant: "error" });
    } else if (!editServiceConnString) {
      enqueueSnackbar("Please enter service connection string.", { variant: "error" });
    } else if (!(regexString.test(editServiceConnString) && (editServiceConnString.length < 250))) {
      enqueueSnackbar('Service connection string is not started either with HostName or greater than 250 characters.', { variant: "error" });
    } else {
      saveResourceAction();
    }
  }

  useEffect(() => {
    const tokenKey = "sso_token";
    const accessToken = JSON.parse(sessionStorage.getItem(tokenKey) as string);
    interface sessionObj {
      pm_s_id: string;
    }
    interface tokenObj {
      pm_s_token: string;
    }
    let pmSessionId: sessionObj = {
      pm_s_id: "",
    };
    let pmSessionToken: tokenObj = {
      pm_s_token: "",
    };
    pmSessionId =
      sessionStorage.getItem("pm_s_id") ||
      JSON.parse(JSON.stringify(pmSessionId));
    pmSessionToken =
      sessionStorage.getItem("pm_s_token") ||
      JSON.parse(JSON.stringify(pmSessionToken));
    setbackDrop(true);
    const RESOURCE_INPUT = API["RESOURCE_INPUT"] + `?pm_s_token=${pmSessionToken}&pm_s_id=${pmSessionId}`;
    fetch(RESOURCE_INPUT, {
      headers: {
        'Authorization': `Bearer ${accessToken.access_token}`,
        'Ocp-Apim-Subscription-Key': `${API_KEY}`,
        'Ocp-Apim-Trace': `true`
      },
    })
      .then((resp) => resp.json())
      .then(function (response) {
        setbackDrop(false);
        if ((response && response.status === 401) && (response && arrayOfErrorsRefresh.includes(response.message.toLowerCase()))) {
          try {
            const tokenKey = "sso_token";
            setbackDrop(true);
            callXhrRequest().then(function (data) {
              sessionStorage.setItem(tokenKey, data);
              setReload(!reload)
              setbackDrop(false);
            }).catch(function (error) {
              user.triggerLogout();
              console.log('error', error)
            })
          } catch (error) {
            console.log(error);
          }
          return;
        }
        else if ((response && response.status === 401) && (response && arrayOfErrorsLogout.includes(response.message.toLowerCase()))) {
          try {
            user.triggerLogout();
          } catch (error) {
            console.log(error);
          }
          return;
        }

        setEditResourceInput(response && response.az_iot_hubs_input);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [accessToken.access_token, API_KEY, reload]);


  return (
    <div className={classes.root} style={{ marginTop: "0px" }}>
      <div
        className={classes.gridRoot}
        style={{ marginRight: "1.5em", marginBottom: "1.5em", width: "99%" }}
      >
        <Grid
          container
          direction="row"
          xs={12}
          sm={6}
          style={{ marginTop: "0.1em", marginBottom: "0.1em" }}
          alignItems="center"
        >
          <BreadcumbComponent route='editResources' func={onCancelAction}/>
        </Grid>
        <Backdrop
          className={classes.backdrop}
          open={backDrop}
          onClick={backDropClose}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>

      <form className={classes.form} noValidate method="post">
        <div className={classes.space2Top}>
          <Grid container>
            <Grid item xs={6} className={classes.spaceRight}>
            <CustomTextField
                name="selectApplication"
                label="Application *"
                readOnly
                value={editApplication}
              />
            </Grid>

            <Grid item xs={6} className={classes.spaceRight}>
              <CustomTextField
                name="subscription_id"
                label="Subscription ID *"
                readOnly
                value={editSubscriptionIdValue}
              />
            </Grid>
            <Grid item xs={6} className={`${classes.spaceRight}`}>
              <CustomTextField
                readOnly
                name="subscription_name"
                label="Subscription Name *"
                value={editSubscriptionNameValue}
              />
            </Grid>
            <Grid item xs={6} className={classes.spaceRight}>
              <CustomTextField
                readOnly
                name="resource_group"
                label="Resource Group *"
                value={editResourceGroupValue}
              />
            </Grid>
            <Grid item xs={6} className={`${classes.spaceRight}`}>
              <CustomTextField
                readOnly
                name="selectRole"
                label="Region *"
                value={region}
              />
              {/* <div className={classes.fieldWrapper}>
                <InputLabel
                  classes={{ root: classes.labels }}
                  htmlFor="selectRole"
                >
                  Region *
                </InputLabel>
                <TextField
                  className={classes.textfield}
                  fullWidth
                  select
                  variant="outlined"
                  disabled
                  classes={{ root: classes.fieldRoot }}
                  inputProps={{ className: classes.field }}
                  value={region}
                  id="selectRole"
                  SelectProps={{
                    classes: {
                      iconOutlined: classes.icon,
                    },
                  }}
                  // onChange={onChangeRegion}
                >
                  {Array.isArray(Regions) && Regions.map((option: any) => (
                    <option
                      className={classes.options}
                      key={option.value}
                      value={option.value}
                    >
                      {option.label}
                    </option>
                  ))}
                </TextField>
              </div> */}
            </Grid>
            <Grid item xs={6} className={`${classes.spaceRight}`}>
              <CustomTextField
                readOnly
                name="sku"
                label="SKU *"
                value={sku}
              />
              {/* <div className={classes.fieldWrapper}>
                <InputLabel classes={{ root: classes.labels }} htmlFor="sku">
                  SKU *
                </InputLabel>
                <TextField
                  className={classes.textfield}
                  defaultValue={editRegion}
                  fullWidth
                  select
                  variant="filled"
                  disabled
                  classes={{ root: classes.fieldRoot }}
                  inputProps={{ className: classes.field }}
                  value={sku}
                  id="sku"
                  SelectProps={{
                    classes: {
                      iconOutlined: classes.icon,
                    },
                  }}
                  // onChange={onChangeSKU}
                >
                  {Array.isArray(Skus) && Skus.map((option) => (
                    <option
                      className={classes.options}
                      value={option.label}
                    >
                      {option.label}
                    </option>
                  ))}
                </TextField>
              </div> */}
            </Grid>
            <Grid item xs={6} className={classes.spaceRight}>
              <CustomTextField
                readOnly
                name="iot_name"
                label="IoT Hub Name *"
                value={editIotHubName}
              />
              {/* <CssTextField
                fullWidth
                name="iot_name"
                label="IoT Hub Name *"
                type="text"
                id="iot_name"
                disabled
                className={classes.fieldWrapper}
                InputProps={{
                  disableUnderline: true,
                  // onChange: (e) => setEditIotHubName(e.target.value),
                }}
                InputLabelProps={{
                  shrink: true,
                  className: classes.labels,
                }}
                value={editIotHubName}
              /> */}
            </Grid>
            {/* <Grid item xs={6} className={classes.spaceRight}>
              <CustomTextField
                name="device_conn_string"
                label="Device Connection String *"
                value={editDeviceConnString}
                onChange={ (value) => setEditDeviceConnString(value)}
              />
              {/* <CssTextField
                fullWidth
                name="device_conn_string"
                label="Device Connection String *"
                type="text"
                id="device_conn_string"
                className={classes.fieldWrapper}
                InputProps={{
                  disableUnderline: true,
                  onChange: (e) => setEditDeviceConnString(e.target.value),
                }}
                InputLabelProps={{
                  shrink: true,
                  className: classes.labels,
                }}
                value={editDeviceConnString}
              /> 
            </Grid> */}
            {/* <Grid item xs={6} className={classes.spaceRight}>
              <CustomTextField
                name="service_conn_string"
                label="Service Connection String *"
                value={editServiceConnString}
                onChange={ (value) => setEditServiceConnString(value)}
              />
              {/* <CssTextField
                fullWidth
                name="service_conn_string"
                label="Service Connection String *"
                type="text"
                id="service_conn_string"
                className={classes.fieldWrapper}
                InputProps={{
                  disableUnderline: true,
                  onChange: (e) => setEditServiceConnString(e.target.value),
                }}
                InputLabelProps={{
                  shrink: true,
                  className: classes.labels,
                }}
                value={editServiceConnString}
              /> 
            </Grid> */}
          </Grid>
        </div>
        <div className={`${classes.space2Top} ${classes.bottomSection}`}>
          <Button
            variant="outlined"
            className={classes.btnCancel}
            onClick={() => setTimeout(function () {
              onCancelAction()
            }, 500)}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            className={classes.btnAddNUser}
            onClick={validateField}
            style={{ marginRight: "10px" }}
            disabled
          >
            Save Changes
          </Button>
        </div>
      </form >
    </div >
  );
}
