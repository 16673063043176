import React from "react";

import { Modal, CustomButton } from "../";

import { IConfirmationDialogProps } from "./ConfirmationDialog.types";
import useStyles from "./ConfirmationDialog.styles";

const ConfirmationDialog: React.FC<IConfirmationDialogProps> = ({
  title = "",
  subTitle = "",
  onYes,
  onNo,
  onClose,
}) => {
  const classes = useStyles();

  return (
    <Modal open={true} onClose={onClose} title={title}>
      <div className={classes.wrapper}>{subTitle && <div>{subTitle}</div>}</div>
      <div className={classes.buttonHolder}>
        <CustomButton
          variant="outlined-white"
          onClick={() => {
            onYes();
            onClose();
          }}
          style={{ marginRight: "10px" }}
        >
          Yes
        </CustomButton>
        <CustomButton
          variant="outlined-white"
          onClick={() => {
            onNo && onNo();
            onClose();
          }}
        >
          No
        </CustomButton>
      </div>
    </Modal>
  );
};

export default ConfirmationDialog;
