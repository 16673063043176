import React from "react";
import { Grid } from "@material-ui/core";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import { CONFIG_PAGE } from "../types";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    header: {
      width: "auto",
      margin: "1.5em 1.5em 1.5em 3em",
      padding: "1.5em",
      background: "#2b2b36",
    },
    wrapper: {
      display: "flex",
      color: "white",
      alignItems: "center",
    },
    link: {
      fontFamily: '"Avenir Next"',
      fontSize: "14px",
      color: "rgb(196, 196, 196)",
      marginRight: "10px",
      cursor: "pointer",
      textDecoration: "underline",
    },
    path: {
      fontFamily: '"Avenir Next"',
      fontSize: "16px",
      lineHeight: "19px",
      color: "rgb(196, 196, 196)",
      fontWeight: "bold",
      marginLeft: ".5em",
    },
  })
);

interface IHeaderNavigationProps {
  onClick: () => void;
  page: CONFIG_PAGE;
}

const PAGE_DESCRIPTION: any = {
  [CONFIG_PAGE.ADD_CONFIG]: "Add New Configuration",
  [CONFIG_PAGE.EDIT_CONFIG]: "Edit Device Configuration",
};

const HeaderNavigation: React.FC<IHeaderNavigationProps> = ({
  onClick,
  page,
}) => {
  const classes = useStyles();
  return (
    <Grid container direction="column" className={classes.header}>
      <div className={classes.wrapper}>
        <span>Configurations</span>
        <span style={{ margin: "0 .5em" }}>{" > "}</span>
        <div className={classes.link} onClick={onClick}>
          Device Configurations
        </div>{" "}
        {" > "}
        <div className={classes.path}>{PAGE_DESCRIPTION[page]}</div>
      </div>
    </Grid>
  );
};

export default HeaderNavigation;
