import React, { useEffect } from "react";
import { useSnackbar } from "notistack";
import { LoadingIndicator, Modal } from "../../../../UiComponents";
import useApi from "../../../../../hooks/useApi";
import DeviceHistoryCard from "./DeviceHistoryCard";
import useStyles from "./DeviceHistory.styles";
import { Typography } from "@material-ui/core";

const DeviceHistory: React.FC<any> = ({
  applicationId,
  deviceDlmId,
  onDone,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();

  const { data, status } = useApi(
    `/applications/${applicationId}/devices/${deviceDlmId}/history`,
    {
      method: "GET",
    }
  );

  useEffect(() => {
    if (status.error) {
      enqueueSnackbar("An error occurred while reading history", {
        variant: "error",
      });
      onDone();
    }
  }, [status.error]);

  if (status.pending || !data) {
    return <LoadingIndicator />;
  }

  return (
    <Modal
      open={true}
      onClose={onDone}
      title={`Device History: ${data.device_id}`}
    >
      <div className={classes.deviceHistory}>
        {(data.device_history || []).length === 0 && (
          <Typography style={{ marginTop: "1em" }}>
            No Device Replacement History Found!
          </Typography>
        )}
        {data.device_history.map((deviceHistory: any) => (
          <DeviceHistoryCard
            key={deviceHistory.device_id}
            deviceHistoryEntry={deviceHistory}
            applicationId={applicationId}
          />
        ))}
      </div>
    </Modal>
  );
};

export default DeviceHistory;
