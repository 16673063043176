import React from "react";
import { DropDown } from "../DropDown";
import TextField from "../TextField";
import { IFilterSchema, IInputSchema } from "./types";

interface ICustomFieldRendererProps {
  filterSchema: IFilterSchema;
  filterValue: string;
  onFilterValueChange: (value: string) => void;
}

const defaultInputSchema: IInputSchema = {
  type: "text",
  defaultValue: "",
  dropDownOptions: [],
};

const fieldRenderers = {
  text: (filterValue: string, onFilterValueChange: any) => {
    return (
      <TextField
        label="Filter Value *"
        style={{ margin: "15px 0" }}
        value={filterValue}
        onChange={(value: any) => onFilterValueChange(value)}
      />
    );
  },
  readOnlyText: (filterValue: string) => {
    return (
      <TextField readOnly value={filterValue} style={{ margin: "15px 0" }} />
    );
  },
  dropDown: (
    filterValue: string,
    onFilterValueChange: any,
    dropDownOptions: any[] = []
  ) => {
    return (
      <DropDown
        value={filterValue}
        onChange={(value: string) => onFilterValueChange(value)}
        options={dropDownOptions}
        style={{ margin: "15px 0" }}
      />
    );
  },
};

const CustomFieldRenderer: React.FC<ICustomFieldRendererProps> = ({
  filterSchema,
  filterValue,
  onFilterValueChange,
}) => {
  const { input: inputSchema = defaultInputSchema } = filterSchema;

  return fieldRenderers[inputSchema.type](
    filterValue,
    onFilterValueChange,
    inputSchema.dropDownOptions
  );
};

export default CustomFieldRenderer;
