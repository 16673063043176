import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      width: "600px",
      display: "flex",
      flexFlow: "column",
      alignItems: "center",
      padding: "1em 0em",
      minHeight: "5em",
    },
    dateInputStyle: {
      "& .MuiInputBase-input": {
        color: "white",
        border: "1px solid #33333f",
      },
      "& .MuiIconButton-label": {
        color: "white",
      },
    },

    dateButtonStyle: {
      "& .MuiButton-label": {
        color: "white",
        border: "1px solid #33333f",
      },
      "& .MuiPickersCalendarHeader-dayLabel": {
        color: "white",
      },
      "& .MuiSvgIcon-root": {
        color: "white",
      },
      "& .MuiPickersDay-day": {
        color: "grey",
      },
      "& .MuiPickersDay-daySelected": {
        color: "white",
      },
      "& .MuiPickersYear-root": {
        color: "white",
      },
      "& .MuiTypography-alignCenter": {
        color: "white",
      },
      "& .MuiPickersClockPointer-thumb": {
        color: "white",
      },
      "& .MuiPickersClock-pin": {
        backgroundColor: "grey",
      },
      "& .MuiPickersClockPointer-pointer": {
        background: "grey",
      },
      "& .MuiPickersClockNumber-clockNumber": {
        color: "grey",
        "&.MuiPickersClockNumber-clockNumberSelected": {
          color: "white",
        },
      },
    },

    buttonHolder: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      borderTop: "1px solid #6b6868",
      paddingTop: "0.5em",
      margin: "15px 0px",
      width: "100%",
    },
  })
);

export default useStyles;
