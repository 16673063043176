import React from "react";
import { FormSection, GriddedTextField } from "../../../../UiComponents";

const DeviceProperties: React.FC<any> = ({ properties, onPropertyChange }) => {
  return (
    <FormSection header="Properties">
      {Object.keys(properties).map((property: any) => (
        <GriddedTextField
          key={property}
          label={property}
          value={properties[property]}
          onChange={(v: string) => onPropertyChange(property, v)}
        />
      ))}
    </FormSection>
  );
};

export default DeviceProperties;
