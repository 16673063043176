import { useEffect, useRef } from "react";

const useInterval = (callback: () => void, delay: number | undefined) => {
  const savedCallback = useRef<() => void>();

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback])

  useEffect(() => {
    const tick = () => {
      const cb = savedCallback.current || (() => true);//just to trick typescript.
      cb();
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay])
}

export default useInterval;