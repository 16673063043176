import React from "react";
import { CustomTextField } from "../../../../UiComponents";
import { IconButton } from "@material-ui/core";
import { Delete as DeleteIcon } from "@material-ui/icons";

const TagForm: React.FC<any> = ({
  name,
  onChange,
  onRemove,
  disableDelete,
}) => {
  return (
    <div style={{ display: "flex" }}>
      <CustomTextField
        label="Property Name*"
        value={name}
        onChange={(v) => onChange(v)}
      />

      <IconButton
        disabled={disableDelete}
        onClick={onRemove}
        style={{ margin: "1.2em 0 0 1em" }}
      >
        <DeleteIcon color="secondary" />
      </IconButton>
    </div>
  );
};

export default TagForm;
