import React from "react";
import { GriddedTextField } from "../../../../UiComponents";

const CustomDeviceFields: React.FC<any> = ({ fields, onFieldChange }) => {
  return (
    <>
      {Object.keys(fields).map((field) => (
        <GriddedTextField
          key={field}
          label={field}
          value={fields[field]}
          onChange={(value: string) => onFieldChange(field, value)}
        />
      ))}
    </>
  );
};

export default CustomDeviceFields;
