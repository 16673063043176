const removeEmptyRecords = (records: any[][]) =>
  (records || []).filter(
    (record) => record.length > 1 && !record.every((r) => !r)
  );

const trimWhiteSpaces = (records: any[][]) =>
  (records || []).map((record) => record.map((r) => (r || "").trim()));

const sanitizeFileData = (fileData: any) => {
  const sanitizedData = trimWhiteSpaces(removeEmptyRecords(fileData));
  return sanitizedData;
};

export default sanitizeFileData;
