import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    CustomizeColumn: {
      minWidth: "500px",
      minHeight: "400px",
      overflow: "auto",
      paddingBottom: "200px",
      maxHeight: "500px",
    },
    listButton: {
      padding: "0px",
      textTransform: "none",
      fontSize: "14px",
      whiteSpace: "nowrap",
    },
    addColumnHolder: {
      border: "1px dashed #383737",
      display: "flex",
      padding: "0.5em",
      alignItems: "center",
      justifyContent: "space-evenly",
      position: "relative",
    },
    columnInfoHolder: {
      display: "flex",
      border: "1px dashed #2f2d2d",
      alignItems: "center",
      padding: "10px",
      margin: "5px 0",
    },
    sequenceNumber: {
      flex: 1,
    },
    columnName: {
      flex: 5,
    },
    removeButtonHolder: {
      flex: 2,
      display: "flex",
      justifyContent: "flex-end",
    },
    buttonHolder: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      borderTop: "1px solid #6b6868",
      paddingTop: "0.5em",
      margin: "15px 0px",
      width: "100%",
    },
  })
);

export default useStyles;
