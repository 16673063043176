export const getManageAppGridCol = (templateHandler:any) => {
    return [
        {
            id: "application_name",
            label: "Application",
            sortable: true
        },
        {
            id: "role_name",
            label: "Role",
            sortable: true
        },
        {
            id: "valid_to",
            label: "Access Validity",
            template: (row: any) => templateHandler(row, 'valid_to')
        },
        {
            id: "edit",
            label:"",
            template:(row: any) => templateHandler(row, 'edit')
        }
    ]
}