import React, { useEffect, useState } from "react";
import { withRouter, useLocation, RouteComponentProps } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Collapse from "@material-ui/core/Collapse";
import ArrowRightIcon from "@material-ui/icons/KeyboardArrowRightOutlined";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import DevicesIcon from "../../assets/LeftPanelIcons/Devices.png";

const border_bottom_style = "2px solid #FBCE07";

//TODO: Make entire routing logic declarative with <Switch> and <Link>
const useStyles = makeStyles((theme) => ({
  nested: {
    paddingLeft: "4em",
  },
  leftpanel: {
    marginLeft: "-1em",
    width: "3em",
    height: "2.7em",
  },
}));

interface IDeviceMenuProps extends RouteComponentProps {
  drawerOpen: boolean;
  active: string;
  onClick: () => void;
}

const DEVICE_PAGE = "devices";

const PATHS = {
  DEVICE_INSTALLTION: "/device-installation",
  DEVICE_MANAGEMENT: "/device-management",
};

const DeviceMenu: React.FC<IDeviceMenuProps> = ({
  active,
  onClick,
  history,
  drawerOpen,
}) => {
  const classes = useStyles();
  const [subRouteVisible, setSubRouteVisible] = useState(false);
  const { pathname } = useLocation();

  //ShowDeviceInstallation flag is kept always true. In future we will show this based on service flag.
  const showDeviceInstallation = true;

  useEffect(() => {
    if ((active !== DEVICE_PAGE || !drawerOpen) && subRouteVisible) {
      setSubRouteVisible(false);
    }
  }, [active, drawerOpen]);

  return (
    <>
      <ListItem
        onClick={() => {
          if (showDeviceInstallation) {
            setSubRouteVisible(!subRouteVisible);
          } else {
            active === DEVICE_PAGE
              ? window.location.reload()
              : history.push("/devices");
            onClick();
          }
        }}
        style={{
          marginTop: "0.1em",
          marginLeft: "0.5em",
          background: active === DEVICE_PAGE ? "#1D1D25" : "#2B2B36",
          borderBottom: active === DEVICE_PAGE ? border_bottom_style : "",
        }}
        button
        key="Devices"
      >
        <ListItemIcon>
          <img
            className={classes.leftpanel}
            alt="Device Logo"
            src={DevicesIcon}
          />
        </ListItemIcon>
        <ListItemText primary={"Devices"} />
      </ListItem>

      <Collapse in={subRouteVisible} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItem
            style={{
              marginLeft: "0.5em",
              background: active === "devices" ? "#1D1D25" : "#2B2B36",
            }}
            onClick={() => {
              pathname === PATHS.DEVICE_MANAGEMENT
                ? window.location.reload()
                : history.push(PATHS.DEVICE_MANAGEMENT);
              onClick && onClick();
              setSubRouteVisible(false);
            }}
            button
            className={classes.nested}
          >
            <ArrowRightIcon
              style={{ color: "#FCFCFC", marginRight: "0.5em" }}
            />
            <ListItemText
              primary="Device Management"
              style={{
                color:
                  pathname === PATHS.DEVICE_MANAGEMENT ? "#0089FF" : "#FCFCFC",
              }}
            />
          </ListItem>
          <ListItem
            style={{
              marginLeft: "0.5em",
              background: active === DEVICE_PAGE ? "#1D1D25" : "#2B2B36",
            }}
            onClick={() => {
              pathname === PATHS.DEVICE_INSTALLTION
                ? window.location.reload()
                : history.push(PATHS.DEVICE_INSTALLTION);

              onClick && onClick();
              setSubRouteVisible(false);
            }}
            button
            className={classes.nested}
          >
            <ArrowRightIcon
              style={{ color: "#FCFCFC", marginRight: "0.5em" }}
            />
            <ListItemText
              primary="Device Installation"
              style={{
                color:
                  pathname === PATHS.DEVICE_INSTALLTION ? "#0089FF" : "#FCFCFC",
              }}
            />
          </ListItem>
        </List>
      </Collapse>
    </>
  );
};

export default withRouter(DeviceMenu);
