import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      paddingLeft: "20px",
    },
    draganddrop: {
      border: "1px solid #464659",
      boxSizing: "border-box",
      borderRadius: "2px",
      margin: "15px",
      padding: "20px 20px 20px 20px",
      maxWidth: "550px",
    },
    uploadText: {
      fontSize: "16px",
      lineHeight: "19px",
      display: "flex",
      alignItems: "center",
      textTransform: "uppercase",
      color: "#FCFCFC",
    },
    dropElement: {
      marginTop: "10px",
      marginBottom: "10px",
      marginLeft: "10px",
      marginRight: "10px",
    },
    buttonHolder: {
      padding: "20px",
    },
  })
);

export default useStyles;
