import React, { useState } from "react";
import { useSnackbar } from "notistack";
import { Modal, CustomButton, SubmitButton } from "../../../../../../UiComponents";
import { IMenuItemProps } from "../../../../../../UiComponents/DataTable/types";
import GpsCard from "./GpsCard";
import CaptureGPS from "./CaptureGps";
import useStyles from "./AddGps.styles";

const AddGps: React.FC<IMenuItemProps> = ({ record, onDone, refreshGrid }) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [showModal, setShowModal] = useState(false);
  const { latitude, longitude, accuracy, location_updated_on } = record;
  const existingGpsData = latitude
    ? { latitude, longitude, accuracy, location_updated_on }
    : null;
  return (
    <Modal
      open={true}
      onClose={onDone}
      title={`Device Id: ${record.device_id}`}
    >
      <div className={classes.wrapper}>
        {existingGpsData && (
          <GpsCard gpsCrd={existingGpsData} title="Last location captured" />
        )}
        {!existingGpsData && <div>Last Location Unavailable</div>}
      </div>
      {showModal && (
        <CaptureGPS
          onDone={() => setShowModal(false)}
          onSuccess={() => {
            enqueueSnackbar(
              `GPS location ${
                existingGpsData ? "updated" : "added"
              } successfully`,
              {
                variant: "success",
              }
            );
            refreshGrid && refreshGrid();
            onDone && onDone();
          }}
          deviceId={record.device_dlm_id}
        />
      )}
      <div className={classes.buttonHolder}>
        <CustomButton
          variant="outlined-white"
          onClick={() => onDone && onDone()}
        >
          Cancel
        </CustomButton>
        <SubmitButton
          onClick={() => setShowModal(true)}
          style={{ marginLeft: "1em" }}
        >
          {existingGpsData ? "Update" : "Add"} Coordinates
        </SubmitButton>
      </div>
    </Modal>
  );
};

export default AddGps;
