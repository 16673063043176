import React from "react";
import { Dialog, Grid, Typography, Divider, Button } from "@material-ui/core";
import { DialogContent } from './applicationStyles';

interface DeleteApplicationProps{
    openDialogDelete: any;
    classes: { [key: string]: string };
    dialogHandleCloseDelete: () => void;
    deleteLogo: any;
    DialogTitle: any;
    DialogActions: any;
    handleDelete: () => void;
}
export default function DeleteApplication({
    openDialogDelete,
    classes,
    dialogHandleCloseDelete,
    deleteLogo,
    DialogTitle,
    DialogActions,
    handleDelete
}: DeleteApplicationProps) {
    return (
        <Dialog
            classes={{ paper: classes.dialogRootSmall }}
            onClose={dialogHandleCloseDelete}
            aria-labelledby="customized-dialog-title"
            open={openDialogDelete}
        >
            <Grid container direction="row" alignItems="center">
                <img
                    style={{ marginLeft: "1em" }}
                    alt="Delete Logo"
                    src={deleteLogo}
                ></img>
                <DialogTitle
                    id="customized-dialog-title"
                    onClose={dialogHandleCloseDelete}
                >
                    Delete Application?
            </DialogTitle>
            </Grid>
            <DialogContent>
                <Typography
                    style={{
                        fontFamily: "Avenir Next",
                        fontSize: "14px",
                        lineHeight: "16px",
                        display: "flex",
                        alignItems: "center",
                        margin: "1em",
                        color: "#FFFFFF",
                    }}
                >
                    Are you sure you want to delete this Application? This Application
                    cannot be recovered.
            </Typography>
            </DialogContent>
            <Divider
                variant="middle"
                classes={{ middle: classes.dividerColor }}
            />

            <DialogActions>
                <Button
                    onClick={dialogHandleCloseDelete}
                    variant="outlined"
                    className="dcBtDelete"
                >
                    Cancel
            </Button>
                <Button
                    onClick={handleDelete}
                    variant="contained"
                    className="dcBtEdit"
                >
                    Delete
            </Button>
            </DialogActions>
        </Dialog>
    )
}
