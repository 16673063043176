import { addMinutes, format, isValid } from "date-fns";

const formatDateTime = (dateTime: Date) => {
  //We need to send this in UTC
  const utcDatTime = new Date(
    dateTime.valueOf() + dateTime.getTimezoneOffset() * 60 * 1000
  );
  // return format(utcDatTime, "y-M-d H:m:s");
  return format(utcDatTime, "yyyy-MM-dd HH:mm:ss");
};

//converting UTC date to local
export const convertUTCDateToLocal = (dateTime: Date) => {
  const utcTime = new Date(dateTime);
  //[todo] need to change the below line in future
  const date = addMinutes(utcTime, utcTime.getTimezoneOffset() * -1)
  return format(date, "yyyy-MM-dd HH:mm:ss")
}

export default formatDateTime;
