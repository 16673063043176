import React from "react";
import {
  createStyles,
  makeStyles,
  Theme,
  withStyles,
  WithStyles,
} from "@material-ui/core/styles";

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import CloseIcon from "@material-ui/icons/Close";

import { Card, CardContent } from "@material-ui/core";
import Divider from "@material-ui/core/Divider";

import useAcknowledgeEvent from "./useAcknowledgeEvent";
import useApi from "../../../hooks/useApi";
import { useApplicationContext } from "../../../contexts/ApplicationContext";
import { LoadingIndicator } from "../../UiComponents";
import { useSnackbar } from "notistack";

/*
TODO: This component is copied from old Event page as is. There will be redundant code 
and unused styles.
*/

const font = "Avenir Next";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    hidden: {
      display: "none",
    },
    gridRoot: {
      flexGrow: 1,
      padding: "0px 24px",
      backgroundColor: "#2B2B36",
    },
    root: {
      width: "100%",
      maxWidth: 360,
      backgroundColor: theme.palette.background.paper,
    },
    flexContainer: {
      display: "flex",
      flexDirection: "row",
    },
    listButtonWrap: {
      marginLeft: "20px",
    },
    listItem: {
      width: "auto",
      padding: "0px",
      marginRight: "20px",
      fontSize: "16px",
      lineHeight: "19px",
      color: "#FFFFFF",
    },
    listItemText: {
      fontSize: "16px",
      lineHeight: "19px",
    },
    tableWrapper: {
      padding: "0 20px",
      marginTop: "15px",
      boxShadow: "none",
    },
    dividerColor: {
      background: "#464659",
      margin: "1em",
    },
    table: {
      minWidth: "100%",
      maxWidth: "100%",
    },
    listButton: {
      padding: "0px",
      textTransform: "none",
      fontSize: "14px",
      whiteSpace: "nowrap",
    },
    rightSec: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-end",
      whiteSpace: "nowrap",
      padding: "5px 0px",
      fontSize: "12px",
      lineHeight: "14px",
      "& p": {
        fontSize: "12px",
        padding: "11px 0px",
        marginRight: "30px",
      },
      "& button": {
        background: "#0089FF",
        borderRadius: "2px",
        fontSize: "16px",
        color: "#FCFCFC",
        padding: "6px 20px",
        marginRight: "10px",
        "&:hover": {
          background: "#0089FF",
        },
      },
    },
    menuButtonRoot: {
      padding: "0px",
    },
    viewDetails: {
      color: "#0089FF !important",
    },
    menuRoot: {
      backgroundColor: "#1D1D25",
      border: "1px solid #33333F",
      boxShadow: "0px 4px 4px rgba(0,0,0,0.25)",
      borderRadius: "2px",
    },
    cardTitle: {
      fontSize: "18px",
      fontWeight: "bold",
      lineHeight: "21px",
      color: "#FFFFFF",
      marginBottom: "16px",
    },
    cardText: {
      fontSize: "16px",
      lineHeight: "19px",
      color: "#FFFFFF",
      marginBottom: "10px",
    },
    CardStyle: {
      background: "#2B2B36",
      borderRadius: "3px",
    },
    cardGrid: {
      marginBottom: "1em",
      marginTop: "1em",
    },
    menuitem: {
      color: "white",
      fontSize: "14px",
      paddingRight: "40px",
      "&:hover": {
        color: "#0089FF !important",
      },
      "& svg": {
        marginRight: "8px",
      },
    },
    dialogRoot: {
      boxShadow: "0px 30px 100px #000000",
      borderRadius: "3px",
      maxWidth: "850px",
      minWidth: "850px",
      "& .dcTitle.dcHeader": {
        marginTop: "0px",
      },
      "& .dcTitle": {
        fontSize: "16px",
        lineHeight: "19px",
        color: "#FFFFFF",
        fontWeight: "bold",
        marginTop: "25px",
      },
      "& .dcOuter": {
        background: "#2B2B36",
        borderRadius: "4px",
        padding: "15px",
      },
      "& .dcFieldValue": {
        fontSize: "14px",
        lineHeight: "16px",
        marginTop: "10px",
        color: "#FFFFFF",
        marginBottom: "25px",
      },
      "& .dcSep": {
        marginTop: "20px",
      },
      "& .dcBtDelete": {
        borderRadius: "2px",
        border: "1px solid rgba(235,235,245,0.35)",
        color: "#0089FF",
        background: "none",
      },
      "& .dcBtEdit": {
        backgroundColor: "#0089ff",
        borderRadius: "4px",
        border: "none",
        color: "#FFFFFF",
      },
    },
    dialogRootSmall: {
      boxShadow: "0px 30px 100px #000000",
      borderRadius: "3px",
      maxWidth: "800px",
      position: "absolute",
      top: "10%",
      minWidth: "416px",
      "& .dcTitle.dcHeader": {
        marginTop: "0px",
      },
      "& .dcTitle": {
        fontSize: "16px",
        lineHeight: "19px",
        color: "#FFFFFF",
        fontWeight: "bold",
        marginTop: "25px",
      },
      "& .dcOuter": {
        background: "#2B2B36",
        borderRadius: "4px",
        padding: "15px",
      },
      "& .dcFieldValue": {
        fontSize: "14px",
        lineHeight: "16px",
        marginTop: "10px",
        color: "#FFFFFF",
      },
      "& .dcSep": {
        marginTop: "20px",
      },
      "& .dcBtDelete": {
        borderRadius: "2px",
        border: "1px solid rgba(235,235,245,0.35)",
        color: "#0089FF",
        background: "none",
      },
      "& .dcBtEdit": {
        backgroundColor: "#0089ff",
        borderRadius: "4px",
        border: "none",
        color: "#FFFFFF",
      },
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: "#fff",
    },
    label: {
      fontSize: "18px",
      lineHeight: "21px",
      marginRight: "18px",
      color: "#FFF",
    },
    dropdown: {
      width: "207px",
      height: "40px",
      background: "transparent",
      padding: "10px",
      color: "#FFFFFF",
      border: "1px solid #4CA5FF",
      font: "400 13.3333px 'Avenir Next'",
      borderRadius: "2px",
      marginRight: "30px",
      "& option": {
        background: "#1D1D25",
      },
    },
    alignCentre: {
      alignItems: "center",
      marginTop: "0.5rem",
    },
    labels: {
      color: "#fcfcfc",
      lineHeight: "16px",
      fontSize: "14px",
      transform: "scale(1)",
    },
    tblpaginate: {
      color: "#fff",
    },
    tblfooter: {
      position: "absolute",
    },
    tblfooter1: {
      position: "relative",
      padding: "10px 0",
      display: "flex",
      alignItems: "center",
      justify: "space-between",
    },
    tblpaginatenumber: {
      color: "#fff",
    },
    paginateUser: {
      color: "#fff",
    },
    paginateView: {
      color: "#fff",
    },
    formControl: {
      margin: "0 25px",
    },
  })
);

const DialogTitleStyles = (theme: Theme) =>
  createStyles({
    root: {
      margin: "20px",
      padding: 0,
      color: "#FFFFFF",
    },
    title: {
      fontSize: "21px",
      lineHeight: "25px",
      fontWeight: "bold",
    },
    closeButton: {
      position: "absolute",
      right: "2px",
      top: "15px",
      color: "#FFFFFF",
      fontSize: "14px",
    },
  });
const DialogContent = withStyles((theme: Theme) => ({
  root: {
    margin: "0px 15px",
    padding: 0,
    color: "#FFFFFF",
  },
}))(MuiDialogContent);
const DialogActions = withStyles((theme: Theme) => ({
  root: {
    margin: "20px 15px",
    justifyContent: "space-between",
    padding: 0,
    "& button": {
      fontSize: "16px",
      lineHeight: "19px",
      textTransform: "none",
      padding: "9px 20px",
    },
  },
}))(MuiDialogActions);

export interface DialogTitleProps extends WithStyles<typeof DialogTitleStyles> {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
}

const DialogTitle = withStyles(DialogTitleStyles)((props: DialogTitleProps) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6" className={classes.title}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon fontSize="inherit" />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const ViewDetailsDialog: React.FC<any> = ({
  onDone = () => true,
  refreshGrid,
  record,
}) => {
  const [expanded, setExpanded] = React.useState(false);
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { selectedApplication } = useApplicationContext();
  // const eventDetails: any = {};
  const { data: eventDetails, status } = useApi(
    `/applications/${selectedApplication.application_id}/dlm-msgs/${record.dlm_msg_id}`,
    { method: "GET", responseDataFormatter: (data: any) => data?.dlm_msg }
  );
  const { acknowledgeEvent } = useAcknowledgeEvent({
    dlm_msg_id: record.dlm_msg_id,
    onSuccess: () => {
      enqueueSnackbar("Event Acknowledged", { variant: "success" });
      refreshGrid();
      onDone();
    },
  });

  if (!eventDetails || status.pending) {
    return <LoadingIndicator />;
  }
  return (
    <Dialog
      classes={{ paper: classes.dialogRoot }}
      onClose={onDone}
      aria-labelledby="customized-dialog-title"
      open={true}
    >
      <DialogTitle id="customized-dialog-title" onClose={onDone}>
        Event Details
      </DialogTitle>
      <DialogContent>
        <Grid container direction="column">
          <Grid item>
            <Grid container direction="row" style={{ marginTop: "1em" }}>
              <Typography
                style={{
                  fontFamily: font,
                  fontSize: "14px",
                  lineHeight: "16px",
                  display: "flex",
                  fontWeight: "bold",
                  alignItems: "center",
                  color: "#FCFCFC",
                  marginRight: "5px",
                }}
              >
                Event ID:
              </Typography>
              <Typography
                style={{
                  fontFamily: font,
                  fontSize: "14px",
                  lineHeight: "16px",
                  display: "flex",
                  alignItems: "center",
                  color: "#FCFCFC",
                  marginRight: "5px",
                }}
              >
                {record.dlm_msg_id}
              </Typography>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container direction="row" style={{ marginTop: "1em" }}>
              <Typography
                style={{
                  fontFamily: font,
                  fontSize: "14px",
                  lineHeight: "16px",
                  display: "flex",
                  fontWeight: "bold",
                  alignItems: "center",
                  color: "#FCFCFC",
                  marginRight: "5px",
                }}
              >
                Event Type:
              </Typography>
              <Typography
                style={{
                  fontFamily: font,
                  fontSize: "14px",
                  lineHeight: "16px",
                  display: "flex",
                  alignItems: "center",
                  color: "#FCFCFC",
                  marginRight: "5px",
                }}
              >
                {record.msg_type}
              </Typography>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container direction="row" style={{ marginTop: "1em" }}>
              <Typography
                style={{
                  fontFamily: font,
                  fontSize: "14px",
                  lineHeight: "16px",
                  display: "flex",
                  fontWeight: "bold",
                  alignItems: "center",
                  color: "#FCFCFC",
                  marginRight: "5px",
                }}
              >
                Event Time:
              </Typography>
              <Typography
                style={{
                  fontFamily: font,
                  fontSize: "14px",
                  lineHeight: "16px",
                  display: "flex",
                  alignItems: "center",
                  color: "#FCFCFC",
                  marginRight: "5px",
                }}
              >
                {record.created_on}
              </Typography>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container direction="row" style={{ marginTop: "1em" }}>
              <Typography
                style={{
                  fontFamily: font,
                  fontSize: "14px",
                  lineHeight: "16px",
                  display: "flex",
                  fontWeight: "bold",
                  alignItems: "center",
                  color: "#FCFCFC",
                  marginRight: "5px",
                }}
              >
                Acknowledgement:
              </Typography>
              <Typography
                style={{
                  fontFamily: font,
                  fontSize: "14px",
                  lineHeight: "16px",
                  display: "flex",
                  alignItems: "center",
                  color: "#FCFCFC",
                  marginRight: "5px",
                }}
              >
                {" "}
                {record.ack_status}
              </Typography>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container direction="row" style={{ marginTop: "1em" }}>
              <Typography
                style={{
                  fontFamily: font,
                  fontSize: "14px",
                  lineHeight: "16px",
                  display: "flex",
                  fontWeight: "bold",
                  alignItems: "center",
                  color: "#FCFCFC",
                  marginRight: "5px",
                }}
              >
                Acknowledged By:
              </Typography>
              <Typography
                style={{
                  fontFamily: font,
                  fontSize: "14px",
                  lineHeight: "16px",
                  display: "flex",
                  alignItems: "center",
                  color: "#FCFCFC",
                  marginRight: "5px",
                }}
              >
                {" "}
                {record.ack_by}
              </Typography>
            </Grid>
          </Grid>

          <Typography
            style={{
              fontFamily: font,
              fontSize: "14px",
              lineHeight: "16px",
              display: "flex",
              alignItems: "center",
              color: "#FCFCFC",
              marginRight: "5px",
              marginTop: "3em",

              cursor: "pointer",
            }}
          >
            Short Message
          </Typography>

          <Grid item className={classes.cardGrid}>
            <Card
              variant="outlined"
              elevation={3}
              classes={{
                root: classes.CardStyle,
              }}
            >
              <CardContent>
                <Typography
                  style={{
                    fontFamily: font,
                    fontSize: "16px",
                    lineHeight: "19px",
                    display: "flex",
                    alignItems: "center",
                    color: "#FCFCFC",
                    marginRight: "5px",
                  }}
                >
                  <pre>{JSON.stringify(eventDetails.short_msg, null, 2)}</pre>
                </Typography>
              </CardContent>
            </Card>
          </Grid>

          <Typography
            onClick={() => setExpanded(!expanded)}
            style={{
              fontFamily: font,
              fontSize: "14px",
              lineHeight: "16px",
              display: "flex",
              alignItems: "center",
              color: "#0089FF",
              marginRight: "5px",
              marginTop: "3em",

              cursor: "pointer",
            }}
          >
            {expanded ? "Show Less Details" : "Show More Details"}
          </Typography>
          {expanded ? (
            <Grid item className={classes.cardGrid}>
              <Card
                variant="outlined"
                elevation={3}
                classes={{
                  root: classes.CardStyle,
                }}
              >
                <CardContent>
                  <Typography
                    style={{
                      fontFamily: font,
                      fontSize: "16px",
                      lineHeight: "19px",
                      display: "flex",
                      alignItems: "center",
                      color: "#FCFCFC",
                      marginRight: "5px",
                    }}
                  >
                    <pre>{JSON.stringify(eventDetails.long_msg, null, 2)}</pre>
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ) : (
            <div></div>
          )}
          <Divider
            variant="middle"
            classes={{ middle: classes.dividerColor }}
          />
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            onDone();
          }}
          variant="outlined"
          className="dcBtDelete"
        >
          Cancel
        </Button>
        <Button
          onClick={() => {
            acknowledgeEvent();
          }}
          variant="contained"
          className="dcBtEdit"
        >
          Acknowledge
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ViewDetailsDialog;
