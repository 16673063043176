import React from "react";

import {
  Header,
  MultifilteredDataTable,
  ServiceUnavailable,
} from "../../UiComponents";

import { columns } from "./columnConfigs";
import ApplicationSelector from "./ApplicationSelector";
import mockApi from "./mockApi";
import { useApplicationContext } from "../../../contexts/ApplicationContext";
import FormatNumber from "../../UiComponents/FormatNumber";

const GridController: React.FC<any> = ({ data, gridControls }) => (
  <Header
    route="deviceInstallation"
    headerText={
      <>
        Total Devices:{" "}
        <strong>
          <FormatNumber number={data ? data.summary.total_devices : 0} />
        </strong>
      </>
    }
  >
    {gridControls}
  </Header>
);

const DeviceInstallation = () => {
  const { selectedApplication } = useApplicationContext();
  if (!(selectedApplication.services || {}).device_installation) {
    return (
      <>
        <ApplicationSelector />
        <ServiceUnavailable>
          Installation service is not available for this application
        </ServiceUnavailable>
      </>
    );
  }

  return (
    <>
      <ApplicationSelector style={{ marginLeft: "1.8em" }} />
      <MultifilteredDataTable
        endPoint="device-installation"
        gridController={GridController}
        columns={columns}
        selectedApplication={selectedApplication}
        dataLookupKey="devices"
        totalRecordsLookupKey="total_devices"
      />
    </>
  );
};

export default DeviceInstallation;
