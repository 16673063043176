import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import { Doughnut } from "react-chartjs-2";
import LegendLine from "./LegendLine/LegendLine";

const useStyles = makeStyles({
  root: {
    height: "400px",
    width: "577px",
    color: "#FCFCFC",
    background: "#2B2B36",
  },
  content: {
    textAlign: "center",
  },
  title: {
    fontSize: "18px",
    lineHeight: "21px",
  },
  donut: {
    padding: "32px 0px",
    position: "relative",
    height: "230px",
    zIndex: 1,
  },
  statuses: {
    textAlign: "left",
  },
  legend: {
    display: "block",
  },
  arrow: {
    color: "#0089FF",
    float: "right",
    padding: "0px",
  },
  icon: {
    position: "relative",
    top: "-100px"
  },
  divwidth: {
    width: '577px',
  }
});



export default function StatusCard({ statusData, toggleFunction, image, width }: any) {
  const classes = useStyles();
  const dataSet = [] as any;
  const backgroundColorSet = [] as any;
  const labelSet = [] as any;

  const data = statusData.data
  const legendLines = [] as any;
  data.forEach((dataObj: any, index: number) => {
    let color = ""
    dataSet.push(dataObj.count)
    labelSet.push(dataObj.desc)
    switch (dataObj.color) {
      case "green":
        color = "#13BC86"
        break;
      case "blue":
        color = "#0089FF"
        break;
      case "red":
        color = "#DB4052"
        break;
      case "orange":
        color = "#FF9400"
        break;
      default:
        color = "#13BC86"
    }
    backgroundColorSet.push(color)
    if (dataObj.range) {
      legendLines.push(<LegendLine key={index} color={color} value={dataObj.count} text={dataObj.desc} range={dataObj.range} />)
    }
    else {
      legendLines.push(<LegendLine key={index} color={color} value={dataObj.count} text={dataObj.desc} />)
    }
  })

  const graphData = {
    datasets: [
      {
        data: dataSet,
        backgroundColor: backgroundColorSet,
        borderWidth: 0,
      },
    ],
    labels: labelSet,
  };
  const option = {
    cutoutPercentage: 70,
    maintainAspectRatio: false,
    segmentShowStroke: false,
    legend: false,
    tooltips: {
      bodyFontFamily: "Avenir Next",
    },
  };
  const cardProps: any = {
    className: classes.root,
    variant: "outlined",
  };
  if (width) {
    cardProps.style = { width }
  }

  return (
    <Card {...cardProps}>
      <CardContent className={classes.content}>
        <Typography className={classes.title}>
          {statusData.title}
        </Typography>
        <div className={classes.donut} >
          <div><Doughnut data={graphData} width={154} height={154} options={option} /></div>
          <div><img className={classes.icon} src={image} alt={statusData.title} width="40px" height="40px" /></div>
        </div>

        <Typography className={classes.legend} variant="body2">
          <span className={classes.legend}>
            {legendLines}
          </span>
        </Typography>
      </CardContent>
    </Card>
  );
}
