import readError from "../../../../../utils/readError";
import validateTags from "../../utils/validateTags";

const hasSubsetOfKeys = (importedTags: any, configTags: any) => {
  const configTagKeys = Object.keys(configTags);
  const importedTagKeys = Object.keys(importedTags);
  const totalKeys = configTagKeys.length;

  if (importedTagKeys.length === 0) {
    return true;
  }

  return (
    importedTagKeys.length > 0 &&
    importedTagKeys.length <= totalKeys &&
    importedTagKeys.every((it) => configTagKeys.indexOf(it) !== -1)
  );
};

const hasInvalidTagType = (importedTags: any, configTags: any) => {
  for (const tagName in importedTags) {
    const importedTagType = importedTags[tagName]?.type || "Empty";
    const storedTagType = configTags[tagName]?.type;
    if (storedTagType !== importedTagType) {
      return `for '${tagName}'. Expected '${storedTagType}' but found '${importedTagType}'`;
    }
  }
  return "";
};

const hasInvalidTagValue = (importedTags: any, configTags: any) => {
  if (importedTags.length === 0) {
    return "";
  }
  const formattedTagData = Object.keys(importedTags).map((tagName) => {
    const { type, data_type, options = "" } = configTags[tagName];
    return {
      tag_name: tagName,
      tag_type: type,
      tag_data_type: data_type,
      tag_value: importedTags[tagName]?.value,
      options,
    };
  });
  const validationResult = validateTags(formattedTagData);
  return validationResult;
};

const getMissingTagsDetails = (importedTags: any, configTags: any) => {
  try {
    const configTagKeys = Object.keys(configTags);
    const importedTagKeys = Object.keys(importedTags);

    const invalidTagKeys = importedTagKeys.filter(
      (itg) => configTagKeys.indexOf(itg) === -1
    );

    const [invalidTagName, ...otherInvalidTagNames] = invalidTagKeys;
    const numberOfOtherInvalidTags = otherInvalidTagNames.length;

    return `Tag '${invalidTagName}'${
      numberOfOtherInvalidTags > 0
        ? ` (and ${numberOfOtherInvalidTags} more)`
        : ""
    } not found in selected configuration.`;
  } catch {
    return "";
  }
};

const findIndex = (columnName: string, pipeSeperatedColumnList: string) => {
  return pipeSeperatedColumnList
    .split("|")
    .findIndex((it) => it === columnName);
};

const validateTabularTags = (csvData: any[], selectedConfig: any) => {
  csvData.forEach((csvRecord, index) => {
    const csvRowIndex = index + 2;
    const { tags: importedTags } = csvRecord;

    const importedTabularTagsNames = Object.keys(importedTags).filter(
      (tag) => selectedConfig.tags[tag]?.type === "Tabular"
    );

    if (importedTabularTagsNames.length === 0) {
      return;
    }

    importedTabularTagsNames.forEach((tagName) => {
      const { value: configuredTabularColumns } = selectedConfig.tags[tagName];
      if (!importedTags[tagName].value) {
        throw new Error(
          `Column names missing for tabular tag '${tagName}' (at row ${csvRowIndex}). Expected '${configuredTabularColumns}'`
        );
      }

      if (configuredTabularColumns !== importedTags[tagName].value) {
        throw new Error(
          `Column mismatch for tabular tag '${tagName}' (at row ${csvRowIndex}). Expected '${configuredTabularColumns}' but found '${importedTags[tagName].value}'`
        );
      }

      const numberOfColumns = configuredTabularColumns.split("|").length;
      const tableValues = importedTags[tagName].table_values || [];
      if (tableValues.length === 0) {
        throw new Error(
          `Please enter 'table_values' for tag '${tagName}' (at row ${csvRowIndex})`
        );
      }

      tableValues.forEach((row: any) => {
        if (row.length !== numberOfColumns) {
          throw new Error(
            `Column mismatch for tabular tag '${tagName}' (at row ${csvRowIndex}). Expected '${numberOfColumns}' columns but found '${row.length}'`
          );
        }
      });

      const dropdownColumns = selectedConfig.dropdown[tagName];
      if (!dropdownColumns) {
        return; //there is no dropdown spec for this tabular tag
      }
      Object.keys(dropdownColumns).map((ddc) => {
        //we cannot trust Object.keys order, so we need to find the index of the column
        const columnIndex = findIndex(ddc, configuredTabularColumns);
        const tableValues = importedTags[tagName].table_values;
        if (!tableValues || tableValues.length === 0) {
          throw new Error(`Please enter 'table_values' for '${tagName}'`);
        }

        tableValues.forEach((row: any) => {
          const allowedOptions = dropdownColumns[ddc].split("|");
          const enteredColumnValue = row[columnIndex];

          if (!allowedOptions.includes(enteredColumnValue)) {
            throw new Error(
              `Invalid dropdown value given for dropdown column '${tagName}:${ddc}' (at row ${csvRowIndex})`
            );
          }
        });
      });
    });
  });
  /*

   "PI_tags": {"type": "Tabular", "value": "Tag_number|Sensor_value|Sensor_eng_unit", "table_values": [["key1", "key2", "key3"], ["4", "5", "6"]]}*/
};

const validateSchema = (importedTags: any) => {
  const PERMITTED_TAG_KEYS = ["type", "value", "table_values", "options"];
  for (const key in importedTags) {
    const invalidKeys = Object.keys(importedTags[key]).filter(
      (tagKey: string) => !PERMITTED_TAG_KEYS.includes(tagKey)
    );
    if (invalidKeys.length > 0) {
      throw new Error(
        `Invalid attribute(s) '${invalidKeys.join(", ")}' found in tag '${key}'`
      );
    }
  }
};

const validateImportedTags = (csvData: any, selectedConfig: any) => {
  for (let i = 0; i < csvData.length; i++) {
    const csvRowIndex = i + 2;
    const record = csvData[i];
    const importedTags = record.tags || {};
    const configTags = selectedConfig.tags;
    if (!hasSubsetOfKeys(importedTags, configTags)) {
      throw new Error(
        `Tag details mismatch at row ${csvRowIndex}. ${getMissingTagsDetails(
          importedTags,
          configTags
        )}`
      );
    }

    try {
      validateSchema(importedTags);
    } catch (error) {
      const errorMessage = readError(error);
      throw new Error(`${errorMessage} at row ${csvRowIndex}`);
    }

    //TODO: Rather than returning error, let the function throw it
    const invalidTagTypeError = hasInvalidTagType(
      importedTags,
      selectedConfig.tags
    );
    if (invalidTagTypeError) {
      throw new Error(
        `Tag details mismatch at row ${csvRowIndex} ${invalidTagTypeError}`
      );
    }

    const invalidTagValueError = hasInvalidTagValue(
      importedTags,
      selectedConfig.tags
    );
    if (invalidTagValueError) {
      throw new Error(
        `Tag value mismatch at row ${csvRowIndex}. ${invalidTagValueError}`
      );
    }
  }

  validateTabularTags(csvData, selectedConfig);
};

export default validateImportedTags;
