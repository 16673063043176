import { IFilter } from "./types";

const getFilterValueByType = (
  activeFilters: any[],
  openedFilterType: string
) => {
  const { filterValue: openedFilterValue } = activeFilters.find(
    ({ filterType }: IFilter) => filterType === openedFilterType
  ) || { filterValue: "" };
  return openedFilterValue;
};

const addOrUpdate = (array: any[], item: any, key: string) => {
  const itemIndex = array.findIndex((i) => i[key] === item[key]);
  if (itemIndex === -1) {
    return [...array, item];
  }
  return array.map((i, index) => (index === itemIndex ? item : i));
};

export { getFilterValueByType, addOrUpdate };
