import { useApplicationContext } from "../../../contexts/ApplicationContext";
import useApi from "../../../hooks/useApi";

interface IUseAcknowledgeEvent {
  dlm_msg_id: string;
  onSuccess?: () => void;
}

const useAcknowledgeEvent = ({
  dlm_msg_id,
  onSuccess = () => true,
}: IUseAcknowledgeEvent) => {
  const { selectedApplication } = useApplicationContext();
  const { trigger, status, data } = useApi(
    `/applications/${selectedApplication.application_id}/dlm-msgs/${dlm_msg_id}`,
    {
      method: "PUT",
      deferred: true,
      responseDataFormatter: () => {
        onSuccess();
      },
    }
  );
  return { acknowledgeEvent: trigger };
};

export default useAcknowledgeEvent;
