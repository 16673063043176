import React, { useEffect } from "react";
import { Grid } from "@material-ui/core";
import { DropDown } from "../..";

const DropDownFieldRenderer: React.FC<any> = ({
  onChange,
  name,
  tagData: { value, options },
}: any) => {
  useEffect(() => {
    //if the value is not present in the option, reset the value to ''
    //TODO: We need to give a warning to user. For that we need a way to memorize previous invalid selection
    const isSelectionInvalid = value !== "" && !options.includes(value);
    if (isSelectionInvalid) {
      onChange(name, { value: "" });
    }
  }, [value, options, name, onChange]);

  return (
    <Grid item xs={6}>
      <DropDown
        label={name}
        options={options
          .split("|")
          .map((opt: string) => ({ value: opt, label: opt }))}
        onChange={(v: string) => onChange(name, { value: v })}
        value={value}
      />
    </Grid>
  );
};

export default DropDownFieldRenderer;
