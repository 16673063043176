import React from "react";

import Typography from "@material-ui/core/Typography";
import ErrorIcon from "@material-ui/icons/ErrorOutline";
import SuccessIcon from "@material-ui/icons/CheckCircle";
import { format } from "date-fns";

import { JOB_STATUSES } from "./types";
import useStyles from "./NotificationManager.styles";

const NotificationStatusIndicator: React.FC<any> = ({ notification }) => {
  const classes = useStyles();
  return (
    <div className={classes.notificationIndicator}>
      {notification.status === JOB_STATUSES.SUCCESS ? (
        <SuccessIcon style={{ color: "#027f02" }}></SuccessIcon>
      ) : (
        <ErrorIcon style={{ color: "#DB4052" }}></ErrorIcon>
      )}
    </div>
  );
};

const NotificationCard: React.FC<any> = ({ notification }) => {
  const classes = useStyles();
  return (
    <div key={notification.id} className={classes.notificationWrapper}>
      <NotificationStatusIndicator notification={notification} />
      <div className={classes.notificationMessageWrapper}>
        <Typography style={{ fontWeight: "bold" }}>
          {notification.subject}
        </Typography>
        <div>
          <Typography style={{ fontSize: ".9em" }}>
            {notification.message}
          </Typography>
        </div>
        <div className={classes.notificationTimeStamp}>
          <Typography style={{ fontSize: ".8em" }}>
            {format(new Date(notification.timeStamp), "y-M-d H:mm:ss")}
          </Typography>
        </div>
      </div>
    </div>
  );
};

export default NotificationCard;
