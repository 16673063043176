import React from "react";
import { Button } from "@material-ui/core";

interface ICustomButtonProps {
  onClick: (event: React.MouseEvent) => void;
  variant:
    | "outlined-green"
    | "outlined-red"
    | "outlined-white"
    | "outlined-blue"
    | "link-blue";

  style?: React.CSSProperties;
  disabled?: boolean;
}

//TODO: Merge similar styles. Create a map if required
//TODO: Add submit button as a variant
const CustomButton: React.FC<ICustomButtonProps> = ({
  variant,
  onClick,
  children,
  disabled,
  style = {},
}) => {
  if (variant === "outlined-green") {
    return (
      <Button
        onClick={onClick}
        size="small"
        style={{
          color: "green",
          textTransform: "none",
          border: "1px solid green",
          ...style,
        }}
      >
        {children}
      </Button>
    );
  }

  if (variant === "outlined-red") {
    return (
      <Button
        onClick={onClick}
        size="small"
        style={{
          color: "#CD5C5C",
          textTransform: "none",
          border: "1px solid #CD5C5C",
          ...style,
        }}
      >
        {children}
      </Button>
    );
  }

  if (variant === "outlined-blue") {
    return (
      <Button
        onClick={onClick}
        size="small"
        style={{
          color: "#0089FF",
          textTransform: "none",
          fontWeight: "bolder",
          border: "1px solid #6E6E79",
          padding: "8px 16px",
          ...style,
        }}
      >
        {children}
      </Button>
    );
  }
  if (variant === "link-blue") {
    return (
      <div
        role="button"
        style={{
          color: disabled ? "#5f5f66" : "#0089FF",
          fontSize: "16px",
          margin: "1em 0 1em 0.5em",
          cursor: "pointer",
          ...style,
        }}
        onClick={onClick}
      >
        {children}
      </div>
    );
  }
  return (
    <Button
      style={{
        color: "#FCFCFC",
        borderColor: "#FCFCFC",
        textTransform: "none",
        marginTop: "5px",
        ...style,
      }}
      variant="outlined"
      component="span"
      onClick={onClick}
    >
      {children}
    </Button>
  );
};

export default CustomButton;
