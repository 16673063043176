import React, { useState } from "react";
import { compareAsc, format } from "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import { Modal, CustomButton, SubmitButton } from "../../UiComponents";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
  KeyboardDatePickerProps,
  KeyboardTimePicker,
} from "@material-ui/pickers";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";

import useStyles from "./CustomDateRangeSelector.styles";
import { Typography } from "@material-ui/core";
import { useSnackbar } from "notistack";

interface ICustomDateRange {
  from: MaterialUiPickersDate;
  to: MaterialUiPickersDate;
}

interface ICustomDatePicker extends KeyboardDatePickerProps {}

const CustomDatePicker: React.FC<ICustomDatePicker> = ({ label, ...props }) => {
  const classes = useStyles();
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <div style={{ display: "flex", alignItems: "center" }}>
        <Typography style={{ width: "3em" }}>{label}</Typography>
        <KeyboardDatePicker
          {...props}
          InputProps={{ className: classes.dateInputStyle }}
          DialogProps={{ className: classes.dateButtonStyle }}
          format="MM-dd-yyyy"
        />
        <KeyboardTimePicker
          margin="normal"
          value={props.value}
          onChange={(dateTime) => props.onChange(dateTime)}
          InputProps={{ className: classes.dateInputStyle }}
          DialogProps={{ className: classes.dateButtonStyle }}
          KeyboardButtonProps={{
            "aria-label": "change time",
          }}
        />
      </div>
    </MuiPickersUtilsProvider>
  );
};

const isValidDate = (date: any) => {
  return (
    date instanceof Date &&
    isFinite(date.getTime()) &&
    compareAsc(date, new Date()) === -1
  );
};

const CustomDateRangeSelector: React.FC<any> = ({ onClose, onDone }) => {
  const MAX_DATE = new Date();
  const [dateRange, setDateRange] = useState<ICustomDateRange>({
    from: MAX_DATE,
    to: MAX_DATE,
  });

  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  return (
    <Modal open={true} onClose={onClose} title={`Select custom date range`}>
      <div className={classes.wrapper}>
        <CustomDatePicker
          label="From"
          maxDate={MAX_DATE}
          onChange={(date) => setDateRange({ ...dateRange, from: date })}
          value={dateRange.from}
        />
        <br />
        <CustomDatePicker
          label="To"
          maxDate={MAX_DATE}
          onChange={(date) => setDateRange({ ...dateRange, to: date })}
          value={dateRange.to}
        />

        <div className={classes.buttonHolder}>
          <CustomButton variant="outlined-white" onClick={() => onClose()}>
            Cancel
          </CustomButton>
          <SubmitButton
            onClick={() => {
              if (dateRange.from !== null && dateRange.to !== null) {
                if (compareAsc(dateRange.from, dateRange.to) === 1) {
                  enqueueSnackbar("Please enter valid date time range", {
                    variant: "error",
                  });
                  return;
                }
                if (
                  !isValidDate(dateRange.from) ||
                  !isValidDate(dateRange.to)
                ) {
                  enqueueSnackbar("Please enter valid date & time", {
                    variant: "error",
                  });
                  return;
                }

                onDone(dateRange);
              }
            }}
            style={{ marginLeft: "1em" }}
          >
            Apply
          </SubmitButton>
        </div>
      </div>
    </Modal>
  );
};

export default CustomDateRangeSelector;
