import React, { useContext } from "react";
import useApi from "../hooks/useApi";
import LoadingIndicator from "../components/UiComponents/LoadingIndicator";

const InputContext = React.createContext<any>({});

type Entity = "devices" | "configs";
interface IInputContextProviderProps {
  entity?: Entity;
  children: React.ReactNode;
  applicationId: number;
  lookup?: any;
}

const END_POINTS = {
  devices: "/devices/inputs",
  configs: "/device-configs/inputs",
};

const extractData = (entity: Entity, data: any, applicationId: number) => {
  if (entity === "devices") {
    return data.devices_input[0].applications.find(
      ({ application_id }: any) => application_id === applicationId
    );
  } else if (entity === "configs") {
    return data.devices_config_input[0].applications.find(
      ({ application_id }: any) => application_id === applicationId
    );
  }
  return null;
};

//When refactoring is complete, InputContextProvider can internally use useApplicationContext to get selected application
const InputContextProvider: React.FC<IInputContextProviderProps> = ({
  entity = "devices",
  children,
  applicationId,
  lookup = {},
}) => {
  const { data: inputsData, status } = useApi(END_POINTS[entity], {
    method: "GET",
  });

  if (status.pending || !inputsData) {
    return <LoadingIndicator />;
  }

  // const applicationInputs = inputsData.devices_input[0].applications.find(
  //   ({ application_id }: any) => application_id === applicationId
  // );

  const applicationInputs = extractData(entity, inputsData, applicationId);

  return (
    <InputContext.Provider value={{ ...applicationInputs, lookup }}>
      {children}
    </InputContext.Provider>
  );
};

const useInputContext = () => useContext<any>(InputContext);

export { InputContextProvider, useInputContext };
