import { DateRange } from '@material-ui/icons';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { createStyles, makeStyles, withStyles } from '@material-ui/styles';
import React from 'react'

export const DateTextField = withStyles({
  root: {
    "& input": {
      padding: "10px",
      color: "#ffffff",
    },
    "& .MuiInput-root": {
      border: "1px solid #464659",
      marginTop: "32px",
      backgroundColor: "#1D1D25",
      borderRadius: "2px",
      boxSizing: "border-box",
      padding: "2px",
    },
    "& label.Mui-focused": {
      color: "#ffffff",
    },
    "& .MuiIconButton-root": {
      color: "#ffffff",
    },
  },
})(KeyboardDatePicker);

const useStyles = makeStyles(() =>
  createStyles({
    datePickerRoot: {
      color: "#FFFFFF",
      border: "1px solid #464659",
      borderRadius: "2px",
      "& .MuiIconButton-root, .MuiPickersDay-day": {
        color: "#FFFFFF",
      },
      "& .MuiPickersDay-daySelected": {
        border: "1px solid #464659",
      },
      "& .MuiPickersCalendarHeader-dayLabel": {
        color:"#fff"
      },
      '& .MuiPickersYear-yearDisabled': {
        color:'#8f8f8f !important'
      },
      '& .MuiPickersYear-yearSelected': {
        color:'#1976d2 !important'
      },
      "& .MuiPickersDay-dayDisabled": {
        color:'#6f6f6f'
      },
      
    },
    wrapper: {
      marginTop:"20px",
      "& label":{
        color:'#fff !important'
      }
    },
    labels: {
      color: "#fcfcfc",
      lineHeight: "16px",
      fontSize: "14px",
      transform: "scale(1)",
    },
    field: {
      color: "#FCFCFC",
      fontSize: "14px",
      lineHeight: "16px",
      padding: "10px",
      "& .MuiFormLabel-root.Mui-disabled": {
        color:"#fff"
      }
    },
    disabled: {
      marginTop: '20px',
      cursor: 'not-allowed',
      '& input': {
        cursor: 'not-allowed',
      },
      '& button': {
        cursor: 'not-allowed',
      },
      '& .Mui-disabled': {
        cursor: 'not-allowed',
      }
    }
  })
);

interface CustomDatePickerProp {
  value: Date|null|undefined;
  change: (val:Date|null, field:string)=>void;
  field:string;
  label?:string;
  disabled?:boolean;
  disablePast?:boolean
}

function CustomDatePicker({value,disabled,change,field,label,disablePast}:CustomDatePickerProp) {
  const classes = useStyles();
  const classNames = disabled? classes.disabled+" "+classes.wrapper: classes.wrapper;
  return (
    <DateTextField
    fullWidth
    variant="inline"
    placeholder="YYYY-MM-DD"
    format="yyyy-MM-dd"
    disabled={disabled}
    margin="normal"
    id={"custome-date-picker"+field}
    label={label || "custom date picker"}
    disablePast={disablePast}
    value={value}
    inputProps={{ className: classes.field }}
    className={classNames}
    InputLabelProps={{
      className: classes.labels,
    }}
    PopoverProps={{
      classes: {
        paper: classes.datePickerRoot,
      },
    }}
    onChange={date => change(date, field)}
    keyboardIcon={<DateRange />}
    KeyboardButtonProps={{ "aria-label": "change date" }}
  />
  )
}

export default CustomDatePicker