import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";

const border_style = "1px solid rgba(235,235,245,0.35)";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    listButton: {
      padding: "0px",
      textTransform: "none",
      fontSize: "14px",
      whiteSpace: "nowrap",
      color: "white",
      margin: "0 15px 0 0",
    },
    formControl: {
      margin: "0 25px",
    },
    dividerColor: {
      background: "#464659",
      margin: "1em",
    },
    dcBtDelete: {
      borderRadius: "2px",
      border: border_style,
      color: "#0089FF",
      background: "none",
      textTransform: "none",
    },
    dcBtEdit: {
      backgroundColor: "#0089ff",
      borderRadius: "4px",
      border: "none",
      color: "#FFFFFF",
      textTransform: "none",
    },
  })
);

export default useStyles;
