const isValidColumnFormat = (delimiterSeparatedColumnString: string) => {
  const columnsArray: string[] = delimiterSeparatedColumnString.split("|");
  return !columnsArray.some((cl) => !cl);
};

const isValidDropdownOptionsFormat = (
  delimiterSeparatedOptionString: string
) => {
  const dropdownOptions: string[] = delimiterSeparatedOptionString.split("|");
  return !dropdownOptions.some((cl) => !cl);
};

const hasDuplicateColumnEntries = (delimiterSeparatedColumnString: string) => {
  const formattedColumnNames: string[] = delimiterSeparatedColumnString
    .split("|")
    .map((s) => s.trim().toLowerCase());

  return new Set(formattedColumnNames).size !== formattedColumnNames.length;
};

export {
  isValidColumnFormat,
  hasDuplicateColumnEntries,
  isValidDropdownOptionsFormat,
};
