import React, { useState, useRef, useEffect } from "react";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import Typography from "@material-ui/core/Typography";
import callXhrRequest from "../../../../../utils/xhrRequestHandler";
import FormControl from "@material-ui/core/FormControl";
import { useSnackbar } from "notistack";
import { useStore } from "mobx-store-provider";
import { API } from "../../../../../api/property";
import axios from "axios";
import { arrayOfErrorsRefresh, arrayOfErrorsLogout } from "../../../../../utils/helper";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import {
  createStyles,
  Theme,
  makeStyles,
  withStyles,
  WithStyles,
} from "@material-ui/core/styles";
import { TOKEN_KEY, API_KEY, DEFAULT_ERROR_MESSAGE, DEFAULT_CERT_ERROR_MESSAGES, CERT_RENEW_QUESTION, CERT_DOWNLOAD_QUESTION } from "../../../../../utils/constant";
import { LoadingIndicator } from "../../../../UiComponents";
const shadow_style = "0px 30px 100px #000000";
const textFieldBorder = "1px solid #464659";
const border_style = "1px solid rgba(235,235,245,0.35)";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialogRoot: {
      boxShadow: shadow_style,
      borderRadius: "3px",
      maxWidth: "850px",
      minWidth: "850px",
      "& .dcTitle.dcHeader": {
        marginTop: "0px",
      },
      "& .dcTitle": {
        fontSize: "16px",
        lineHeight: "19px",
        color: "#FFFFFF",
        fontWeight: "bold",
        marginTop: "25px",
      },
      "& .dcOuter": {
        background: "#2B2B36",
        borderRadius: "4px",
        padding: "15px",
      },
      "& .dcFieldValue": {
        fontSize: "14px",
        lineHeight: "16px",
        marginTop: "10px",
        color: "#FFFFFF",
        marginBottom: "25px",
      },
      "& .dcSep": {
        marginTop: "20px",
      },
      "& .dcBtDelete": {
        borderRadius: "2px",
        border: border_style,
        color: "#0089FF",
        background: "none",
      },
      "& .dcBtEdit": {
        backgroundColor: "#0089ff",
        borderRadius: "4px",
        border: "none",
        color: "#FFFFFF",
      },
    },
    dialogRoot1: {
      backgroundColor: "#2B2B36",
      boxShadow: shadow_style,
      borderRadius: "3px",
      maxWidth: "1200px",
      "& .dcTitle.dcHeader": {
        marginTop: "0px",
      },
      "& .dcTitle": {
        fontSize: "16px",
        lineHeight: "19px",
        color: "#FFFFFF",
        fontWeight: "bold",
        marginTop: "25px",
      },
      "& .dcOuter": {
        background: "#2B2B36",
        borderRadius: "4px",
        padding: "15px",
      },
      "& .dcFieldValue": {
        fontSize: "14px",
        lineHeight: "16px",
        marginTop: "10px",
        color: "#FFFFFF",
        marginBottom: "25px",
      },
      "& .dcSep": {
        marginTop: "20px",
      },
      "& .dcBtDelete": {
        borderRadius: "2px",
        border: border_style,
        color: "#0089FF",
        background: "none",
      },
      "& .dcBtEdit": {
        backgroundColor: "#0089ff",
        borderRadius: "4px",
        border: "none",
        color: "#FFFFFF",
      },
    },
    dialogRootSmall: {
      boxShadow: shadow_style,
      borderRadius: "3px",
      maxWidth: "800px",
      position: "absolute",
      top: "10%",
      minWidth: "416px",
      "& .dcTitle.dcHeader": {
        marginTop: "0px",
      },
      "& .dcTitle": {
        fontSize: "16px",
        lineHeight: "19px",
        color: "#FFFFFF",
        fontWeight: "bold",
        marginTop: "25px",
      },
      "& .dcOuter": {
        background: "#2B2B36",
        borderRadius: "4px",
        padding: "15px",
      },
      "& .dcFieldValue": {
        fontSize: "14px",
        lineHeight: "16px",
        marginTop: "10px",
        color: "#FFFFFF",
      },
      "& .dcSep": {
        marginTop: "20px",
      },
      "& .dcBtDelete": {
        borderRadius: "2px",
        border: border_style,
        color: "#0089FF",
        background: "none",
      },
      "& .dcBtEdit": {
        backgroundColor: "#0089ff",
        borderRadius: "4px",
        border: "none",
        color: "#FFFFFF",
      },
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: "#fff",
    },
    spaceRight: {
      paddingRight: "10px",
    },
    spaceMoreRight: {
      paddingRight: "18px",
      display: "flex",
      alignItems: "center",
      marginTop: "36px",
      marginBottom: "10px",
    },
    spaceMoreLeft: {
      paddingLeft: "18px",
      display: "flex",
      alignItems: "center",
      marginTop: "36px",
      marginBottom: "10px",
    },
    spaceLeft: {
      paddingLeft: "10px",
    },
    fieldWrapper: {
      marginTop: "20px",
    },
    labels: {
      color: "#fcfcfc",
      lineHeight: "16px",
      fontSize: "14px",
      transform: "scale(1)",
    },
  })
);

const DialogTitleStyles = (theme: Theme) =>
  createStyles({
    root: {
      margin: "27px 37px",
      padding: 0,
      color: "#FFFFFF",
    },
    title: {
      fontSize: "21px",
      lineHeight: "25px",
      fontWeight: "bold",
    },
    closeButton: {
      position: "absolute",
      right: "2px",
      top: "15px",
      color: "#FFFFFF",
      fontSize: "14px",
    },
  });

export interface DialogTitleProps extends WithStyles<typeof DialogTitleStyles> {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
}

const CssTextField = withStyles({
  root: {
    "&.tagInput svg": {
      position: "absolute",
      left: "8px",
      color: "#ebebf599",
    },
    "&.tagInput .MuiInput-underline": {
      "&:before": {
        borderBottom: "none",
      },
      "&:after": {
        borderBottom: "none",
      },
    },
    "&.tagInput input": {
      paddingLeft: "40px",
      marginBottom: "16px",
    },
    "&.tagInput label": {
      fontWeight: 600,
    },
    "&.inline": {
      display: "flex",
      alignItems: "center",
    },
    "&.inline label": {
      display: "none",
    },
    "&.inline label + .MuiInput-formControl": {
      marginTop: 0,
    },
    "&.inline input": {
      marginTop: 0,
    },
    "& input": {
      padding: "10px",
      color: "#ffffff",
      backgroundColor: "#1D1D25",
      border: `${textFieldBorder}`,
      borderRadius: "2px",
      marginTop: "16px",
    },
    "& .MuiInput-underline": {
      "&&:before": {
        borderBottom: "none",
      },
      "&&:after": {
        borderBottom: "none",
      },
    },
    "& label.Mui-focused": {
      color: "#ffffff",
    },
    "& .MuiFormLabel-root.Mui-disabled": {
      color: "#FCFCFC",
    },
  },
})(TextField);

const CssFormControl = withStyles({
  root: {
    width: "100%",
    height: "180px",
    overflow: "hidden",
    overflowY: "scroll",
    scrollbarWidth: "none",
    "& .MuiFormControlLabel-root": {
      marginRight: 0,
    },
    "&::-webkit-scrollbar": {
      width: "10px",
    },
    "&::-webkit-scrollbar-track": {
      borderRadius: "4px",
    },
    "&::-webkit-scrollbar-thumb": {
      background: "#464659",
      borderRadius: "4px",
    },
    "& .MuiFormControlLabel-label": {
      width: "100%",
      "& p": {
        display: "flex",
        justifyContent: "space-between",
      },
    },
    "& .MuiButtonBase-root": {
      color: "#7C7C94",
    },
    "& .MuiButtonBase-root.Mui-checked": {
      color: "#0089FF",
      "& svg:nth-child(2)": {
        width: "40px",
        height: "40px",
        left: "-8px",
        top: "-8px",
      },
    },
    "& .MuiButtonBase-root.Mui-checked span:first-child": {
      "&::before": {
        height: "7px",
        width: "7px",
        content: "''",
        display: "block",
        top: "50.5%",
        position: "absolute",
        left: "50%",
        transform: "translate(-50%, -50%)",
        backgroundColor: "white",
        zIndex: 9,
        borderRadius: "50%",
      },
    },
  },
})(FormControl);

const DialogTitle = withStyles(DialogTitleStyles)((prop: DialogTitleProps) => {
  const { children, classes, ...other } = prop;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography
        variant="h6"
        className={classes.title}
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        {children}
      </Typography>
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme: Theme) => ({
  root: {
    margin: "37px",
    marginTop: 0,
    backgroundColor: "#2B2B36",
    color: "#FFFFFF",
  },
  button: {
    textTransform: "inherit",
  },
}))(MuiDialogContent);

const SingleDialogContent = withStyles((theme: Theme) => ({
  root: {
    margin: "37px",
    color: "#FFFFFF",
    marginTop: "20px",
  },
  button: {
    textTransform: "inherit",
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme: Theme) => ({
  root: {
    margin: "0px 62px 33px 62px",
    justifyContent: "space-between",
    paddingTop: "20px",
    borderTop: "1px solid #33333F",
    "& a": {
      textDecoration: "none",
    },
    "& button": {
      fontSize: "16px",
      lineHeight: "19px",
      textTransform: "none",
      padding: "9px 20px",
    },
  },
}))(MuiDialogActions);

const CertificateHandler = (props: any) => {
  const {popupType, device, open, appId, close} = props;
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { user } = useStore();
  const [tags, setTags] = useState([] as any);
  const [dataUrl, setDataUrl] = useState("");
  const [loader, setLoader] = useState(false);
  const aElm = useRef(null as any);
  const aElm1 = useRef(null as any);

  interface sessionObj {
    pm_s_id: string;
  }
  interface tokenObj {
    pm_s_token: string;
  }
  interface DialogActionProps {
    label:string;
    click:()=>void;
  }
  let pmSessionId: sessionObj = {
    pm_s_id: "",
  };
  let pmSessionToken: tokenObj = {
    pm_s_token: "",
  };

  const errorHandler = async (err:any, type:string, callbackFn: ()=>void) =>{
    if (!err || !err.response || !err.response.data) {
      enqueueSnackbar(DEFAULT_ERROR_MESSAGE, { variant: "error" });
      setLoader(false);
      return;
    }
    const {status,message} = err.response.data;
    if (status === 401 && message) {
      if (arrayOfErrorsRefresh.includes(message.toLowerCase())) {
        try {
          const data = await callXhrRequest();
          sessionStorage.setItem(TOKEN_KEY, data);
          callbackFn();
        } catch (error) {
          console.log("error", error);
          user.triggerLogout();
        }
        setLoader(false);
        return;
      }
      else if (arrayOfErrorsLogout.includes(message.toLowerCase())) {
        try {
          user.triggerLogout();
        } catch (error) {
          console.log(error);
        }
        setLoader(false);
        return;
      }
      else {
        enqueueSnackbar(message, { variant: "error" });
      }
    }
    else {
      enqueueSnackbar(DEFAULT_CERT_ERROR_MESSAGES[type], { variant: "error" });
    }
    setLoader(false);
  }

  const getAxiosOptions = () => {
    const accessToken = JSON.parse(sessionStorage.getItem(TOKEN_KEY) as string);
    const options = {
      headers:{
          Authorization:`Bearer ${accessToken?.access_token}`,
          "Ocp-Apim-Subscription-Key": `${API_KEY}`,
          "Ocp-Apim-Trace": `true`,
      }
    }
    return options;
  }

  const getSessionIdAndToken = () => {
    pmSessionToken =
      sessionStorage.getItem("pm_s_token") ||
      JSON.parse(JSON.stringify(pmSessionToken));
    pmSessionId =
      sessionStorage.getItem("pm_s_id") ||
      JSON.parse(JSON.stringify(pmSessionId));
    return [pmSessionToken,pmSessionId];
  }

  function base64ToArrayBuffer(base64:string) {
    const binaryString = atob(base64);
    let bytes = new Uint8Array(binaryString.length);
    for (let i = 0; i < binaryString.length; i++) {
        bytes[i] = binaryString.charCodeAt(i);
    }
    return bytes.buffer;
}

  const deviceDownloadFunc = async() => {
      const [pmSessionToken, pmSessionId] = getSessionIdAndToken();
      const CERT_DOWNLOAD_API = `${API["GETALLAPPLS"]}/${appId}/devices/${device.device_dlm_id}/device-cert?pm_s_token=${pmSessionToken}&pm_s_id=${pmSessionId}&request_action=generate_device_cert`;
      try{
        setLoader(true);
        const { data } = await axios.get(CERT_DOWNLOAD_API, getAxiosOptions());
        const {device_name,last_updated_on, certificate} = data?.data;
        const buffer = base64ToArrayBuffer(certificate)
        const url = window.URL.createObjectURL(new File([buffer],`${device.device_id}.pfx`))
        const tagList = [{...deviceName,value:device_name}, {...lastUpdate,value:last_updated_on}];
        setTags(tagList);
        setDataUrl(url);
        setLoader(false);
      }
      catch(err) {
        errorHandler(err,'download', deviceDownloadFunc);
      }
  };

  const getDeviceName = async() => {
    const [pmSessionToken, pmSessionId] = getSessionIdAndToken();
    const CERT_DOWNLOAD_API = `${API["GETALLAPPLS"]}/${appId}/devices/${device.device_dlm_id}/device-cert?pm_s_token=${pmSessionToken}&pm_s_id=${pmSessionId}&request_action=revoke_device_cert`;
    try{
      setLoader(true);
      const { data } = await axios.get(CERT_DOWNLOAD_API, getAxiosOptions());
      const {device_name} = data?.data;
      const tagList = [{...deviceName,value:device_name}, deviceId];
      setTags(tagList);
      setLoader(false);
    }
    catch(err) {
      const tagList = [deviceName, deviceId];
      setTags(tagList);
      errorHandler(err,'default', getDeviceName);
    }
};

  const revokeFunc = async() => {
    setLoader(true);
    const [pmSessionToken, pmSessionId] = getSessionIdAndToken();
    const CERT_REVOKE_API = `${API["GETALLAPPLS"]}/${appId}/devices/${device.device_dlm_id}/revoke-device-cert?pm_s_token=${pmSessionToken}&pm_s_id=${pmSessionId}`;
    try {
      const { data } = await axios.put(CERT_REVOKE_API, {}, getAxiosOptions())
      enqueueSnackbar(data.message, { variant: "success" });
      setLoader(false);
      close();
    }
    catch(err) {
      errorHandler(err,'revoke', revokeFunc);
    }
  };

  const renewFunc = async () => {
    setLoader(true);
    const [pmSessionToken, pmSessionId] = getSessionIdAndToken();
    const CERT_RENEW_API = `${API["GETALLAPPLS"]}/${appId}/devices/${device.device_dlm_id}/renew-leaf-cert?pm_s_token=${pmSessionToken}&pm_s_id=${pmSessionId}`;
    try {
      const { data } = await axios.put(CERT_RENEW_API, {}, getAxiosOptions())
      enqueueSnackbar(data.message, { variant: "success" });
      setLoader(false);
      close();
    }
    catch(err) {
      errorHandler(err,'renew', renewFunc);
    }
  };

  useEffect(() => {
    setDataUrl("");
    if ( device.device_dlm_id && open && popupType === "single_download" ) {
      const tagList = [deviceName, lastUpdate];
      setTags(tagList);
      deviceDownloadFunc();
    } else if ( device.device_dlm_id && open && popupType === "revoke" ) {
      getDeviceName();
    }
  }, [device, open, popupType]);

  const downloadFunc = () => {
    if (aElm?.current && dataUrl !== "") {
      aElm.current.href = dataUrl;
      aElm.current.setAttribute("download", `${device.device_id}.pfx`);
      // aElm.current.click();
    }
  };

  const DialogActionComponent = ({label, click}:DialogActionProps) => {
    let buttonDisable = label === "Download" ? dataUrl === "" : loader;
    return (
      <DialogActions>
        <Button onClick={close} variant="outlined" className="dcBtDelete">
          Cancel
        </Button>
        <a
          onClick={click}
          ref={aElm}
          href="#"
          style={{
            pointerEvents: buttonDisable ? "none" : "visible",
            cursor: buttonDisable ? "default" : "pointer",
            opacity: buttonDisable ? ".5" : "1",
          }}
        >
          <Button variant="contained" className="dcBtEdit">
            {label}
          </Button>
        </a>
      </DialogActions>
    )
  }

  const singleDownloadUi = (
    <>
      <SingleDialogContent>
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="h3">
              {CERT_DOWNLOAD_QUESTION}
            </Typography>
          </Grid>
        </Grid>
        <Grid container>
          {tags.map((tag: any, index: any) => (
            <Grid
              item
              key={index}
              xs={6}
              className={
                index % 2 === 1 ? classes.spaceMoreLeft : classes.spaceMoreRight
              }
            >
              <Typography style={{ width: "50%" }}>{tag.fieldName}</Typography>
              <CssTextField
                fullWidth
                name={tag.fieldName}
                label={tag.fieldName}
                type={tag.fieldType}
                id={tag.fieldName}
                className={"inline"}
                InputProps={{
                  disableUnderline: true,
                }}
                InputLabelProps={{
                  shrink: true,
                  className: classes.labels,
                }}
                onChange={(event) => console.log(event)}
                value={tag.value}
                disabled={tag.disabled}
              />
            </Grid>
          ))}
        </Grid>
      </SingleDialogContent>
      <DialogActionComponent label="Download" click={downloadFunc} />
    </>
  );

  const revokeUi = (
    <>
      <DialogTitle id="customized-dialog-title" onClose={close}>
        <span>Revoke Certificate</span>
        <span
          style={{
            fontSize: "14px",
            fontWeight: 400,
          }}
        >
          Expired Date: {device?.cert_exp_date}
        </span>
      </DialogTitle>
      <DialogContent>
        <Grid container style={{ marginBottom: "30px" }}>
          {tags.map((tag: any, index: any) => (
            <Grid
              item
              key={index}
              xs={6}
              className={
                index % 2 === 1 ? classes.spaceLeft : classes.spaceRight
              }
            >
              <CssTextField
                fullWidth
                name={tag.fieldName}
                label={tag.fieldName}
                type={tag.fieldType}
                id={tag.fieldName}
                className={classes.fieldWrapper}
                InputProps={{
                  disableUnderline: true,
                }}
                InputLabelProps={{
                  shrink: true,
                  className: classes.labels,
                }}
                onChange={(event) => console.log(event)}
                value={tag.value}
                disabled={tag.disabled}
              />
            </Grid>
          ))}
        </Grid>
      </DialogContent>
      <DialogActionComponent label="Revoke" click={revokeFunc} />
    </>
  );

  const renewUi = (
    <>
      <DialogTitle id="customized-dialog-title" onClose={close}>
        <span>{CERT_RENEW_QUESTION}</span>
        <span
          style={{
            fontSize: "14px",
            fontWeight: 400,
          }}
        >
          Expiry Date: {device?.cert_exp_date}
        </span>
      </DialogTitle>
      <DialogActionComponent label="Renew" click={renewFunc} />
    </>
  );

  const lastUpdate = {
    fieldName: "Last  Updated ",
    fieldType: "Text",
    value: device?.last_updated_on,
    options: [],
    disabled: true,
  };
  const deviceName = {
    fieldName: "Device Name",
    fieldType: "Text",
    value: device?.device_name,
    options: [],
    disabled: true,
  };
  const deviceId = {
    fieldName: "Device Id",
    fieldType: "Text",
    value: device?.device_id,
    options: [],
    disabled: true,
  };

  const renderUi = {
    single_download:singleDownloadUi,
    revoke:revokeUi,
    renew:renewUi
  };

  return (
    <Dialog
      classes={{ paper: classes.dialogRoot }}
      aria-labelledby="customized-dialog-title"
      open={open}
    >
      {loader && <LoadingIndicator />}
      {renderUi[popupType]}
    </Dialog>
  );
};

export default CertificateHandler;
