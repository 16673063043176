import React from "react";
import { createStyles, Theme, withStyles, WithStyles, makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import deleteLogo from "../../assets/Delete.png";
import { useSnackbar } from "notistack";
import { Backdrop, CircularProgress } from '@material-ui/core';
import { API } from "../../api/property";
import callXhrRequest from "../../utils/xhrRequestHandler";
import { arrayOfErrorsRefresh, arrayOfErrorsLogout } from '../../utils/helper';
import { useStore } from "mobx-store-provider";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
    hidden: {
      display: "none",
    },
    gridRoot: {
      flexGrow: 1,
      padding: "0px 24px",
      backgroundColor: "#2B2B36",
    },
    root: {
      width: "100%",
      maxWidth: 360,
      backgroundColor: theme.palette.background.paper,
    },
    flexContainer: {
      display: "flex",
      flexDirection: "row",
    },
    listButtonWrap: {
      marginLeft: "20px",
    },
    listItem: {
      width: "auto",
      padding: "0px",
      marginRight: "20px",
      fontSize: "16px",
      lineHeight: "19px",
      color: "#FFFFFF",
    },
    listItemText: {
      fontSize: "16px",
      lineHeight: "19px",
    },
    tableWrapper: {
      padding: "0 20px",
      marginTop: "15px",
      boxShadow: "none",
    },
    dividerColor: {
      background: "#464659",
      margin: "1em",
    },
    table: {
      minWidth: "100%",
      maxWidth: "100%",
    },
    listButton: {
      padding: "0px",
      textTransform: "none",
      fontSize: "14px",
      whiteSpace: "nowrap",
    },
    rightSec: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-end",
      whiteSpace: "nowrap",
      padding: "5px 0px",
      fontSize: "12px",
      lineHeight: "14px",
      "& p": {
        fontSize: "12px",
        padding: "11px 0px",
        marginRight: "30px",
      },
      "& button": {
        background: "#0089FF",
        borderRadius: "2px",
        fontSize: "16px",
        color: "#FCFCFC",
        padding: "6px 20px",
        marginRight: "10px",
        "&:hover": {
          background: "#0089FF",
        },
      },
    },
    menuButtonRoot: {
      padding: "0px",
    },
    viewDetails: {
      color: "#0089FF !important",
    },
    menuRoot: {
      backgroundColor: "#1D1D25",
      border: "1px solid #33333F",
      boxShadow: "0px 4px 4px rgba(0,0,0,0.25)",
      borderRadius: "2px",
    },
    cardTitle: {
      fontSize: "18px",
      fontWeight: "bold",
      lineHeight: "21px",
      color: "#FFFFFF",
      marginBottom: "16px",
    },
    cardText: {
      fontSize: "16px",
      lineHeight: "19px",
      color: "#FFFFFF",
      marginBottom: "10px",
    },
    CardStyle: {
      background: "#2B2B36",
      borderRadius: "3px",
    },
    cardGrid: {
      marginBottom: "20px",
    },
    menuitem: {
      color: "white",
      fontSize: "14px",
      paddingRight: "40px",
      "&:hover": {
        color: "#0089FF !important",
      },
      "& svg": {
        marginRight: "8px",
      },
    },
    dialogRoot: {
      boxShadow: "0px 30px 100px #000000",
      borderRadius: "3px",
      maxWidth: "850px",
      minWidth: "850px",
      "& .dcTitle.dcHeader": {
        marginTop: "0px",
      },
      "& .dcTitle": {
        fontSize: "16px",
        lineHeight: "19px",
        color: "#FFFFFF",
        fontWeight: "bold",
        marginTop: "25px",
      },
      "& .dcOuter": {
        background: "#2B2B36",
        borderRadius: "4px",
        padding: "15px",
      },
      "& .dcFieldValue": {
        fontSize: "14px",
        lineHeight: "16px",
        marginTop: "10px",
        color: "#FFFFFF",
      },
      "& .dcSep": {
        marginTop: "20px",
      },
      "& .dcBtDelete": {
        borderRadius: "2px",
        border: "1px solid rgba(235,235,245,0.35)",
        color: "#0089FF",
        background: "none",
      },
      "& .dcBtEdit": {
        backgroundColor: "#0089ff",
        borderRadius: "4px",
        border: "none",
        color: "#FFFFFF",
      },
    },
    dialogRootSmall: {
      boxShadow: "0px 30px 100px #000000",
      borderRadius: "3px",
      maxWidth: "416px",
      position: "absolute",
      top: "10%",
      minWidth: "416px",
      "& .dcTitle.dcHeader": {
        marginTop: "0px",
      },
      "& .dcTitle": {
        fontSize: "16px",
        lineHeight: "19px",
        color: "#FFFFFF",
        fontWeight: "bold",
        marginTop: "25px",
      },
      "& .dcOuter": {
        background: "#2B2B36",
        borderRadius: "4px",
        padding: "15px",
      },
      "& .dcFieldValue": {
        fontSize: "14px",
        lineHeight: "16px",
        marginTop: "10px",
        color: "#FFFFFF",
      },
      "& .dcSep": {
        marginTop: "20px",
      },
      "& .dcBtDelete": {
        borderRadius: "2px",
        border: "1px solid rgba(235,235,245,0.35)",
        color: "#0089FF",
        background: "none",
      },
      "& .dcBtEdit": {
        backgroundColor: "#0089ff",
        borderRadius: "4px",
        border: "none",
        color: "#FFFFFF",
      },
    },
  })
);
const DialogActions = withStyles((theme: Theme) => ({
  root: {
    padding: "20px 20px",
    justifyContent: "space-between",
    borderTop: "1px solid #33333F",
    "& button": {
      fontSize: "16px",
      lineHeight: "19px",
      textTransform: "none",
      padding: "9px 20px",
    },
  },
}))(MuiDialogActions);
const DialogTitleStyles = (theme: Theme) =>
  createStyles({
    root: {
      margin: "20px",
      padding: 0,
      color: "#FFFFFF",
    },
    title: {
      fontSize: "21px",
      lineHeight: "25px",
      fontWeight: "bold",
    },
    closeButton: {
      position: "absolute",
      right: "2px",
      top: "15px",
      color: "#FFFFFF",
      fontSize: "14px",
    },
  });
export interface DialogTitleProps extends WithStyles<typeof DialogTitleStyles> {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
}

const DialogTitle = withStyles(DialogTitleStyles)((props: DialogTitleProps) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6" className={classes.title}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon fontSize="inherit" />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});
const DialogContent = withStyles((theme: Theme) => ({
  root: {
    margin: "10px 24px 20px 24px",
    padding: 0,
    color: "#FFFFFF",
    overflow: "hidden",
  },
}))(MuiDialogContent);
interface DeleteResourcePromptInterface {
  displayDeleteResource: boolean;
  selectDeleteResources: string;
  selectDeleteApplication: string;
  toggleDisplayDeleteResource: () => void;
}

export default function DeleteResourcePrompt({
  displayDeleteResource,
  toggleDisplayDeleteResource,
  selectDeleteResources,
  selectDeleteApplication
}: DeleteResourcePromptInterface) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [backDrop, setbackDrop] = React.useState(false);
  const backDropClose = () => {
    setbackDrop(false);
  };
  const { user } = useStore();
  const tokenKey = "sso_token";
  const accessToken = JSON.parse(sessionStorage.getItem(tokenKey) as string);

  const deleteResourceHandle = () => {


    const accessToken = JSON.parse(sessionStorage.getItem(tokenKey) as string);
    const API_KEY = window.SERVER_DATA.REACT_APP_PM_API_KEY;
    interface sessionObj {
      pm_s_id: string;
    }
    interface tokenObj {
      pm_s_token: string;
    }
    let pmSessionId: sessionObj = {
      pm_s_id: "",
    };
    let pmSessionToken: tokenObj = {
      pm_s_token: "",
    };
    pmSessionId =
      sessionStorage.getItem("pm_s_id") ||
      JSON.parse(JSON.stringify(pmSessionId));
    pmSessionToken =
      sessionStorage.getItem("pm_s_token") ||
      JSON.parse(JSON.stringify(pmSessionToken));

    setbackDrop(true);
    const DELETE_RESOURCE = `${API["GETALLAPPLS"]}/${selectDeleteApplication}/az-iot-hubs/${selectDeleteResources}?pm_s_token=${pmSessionToken}&pm_s_id=${pmSessionId}`;
    const requestOptions = {
      method: 'DELETE',
      headers: {
        'Authorization': `Bearer ${accessToken.access_token}`,
        'Ocp-Apim-Subscription-Key': `${API_KEY}`,
        'Ocp-Apim-Trace': `true`
      },
    };
    fetch(DELETE_RESOURCE, requestOptions)
      .then(function (response: any) {
        response.json().then((response: any) => {
          setbackDrop(false)
          if ((response && response.status === 401) && (response && arrayOfErrorsRefresh.includes(response.message.toLowerCase()))) {
            try {
              setbackDrop(true)
              const tokenKey = "sso_token";
              callXhrRequest().then(function (data) {
                sessionStorage.setItem(tokenKey, data);
                setbackDrop(false)
                deleteResourceHandle();
                toggleDisplayDeleteResource();
              }).catch(function (error) {
                user.triggerLogout();
                console.log('error', error);
                toggleDisplayDeleteResource();
              })
            } catch (error) {
              console.log(error);
            }
            return;
          }
          else if ((response && response.status === 401) && (response && arrayOfErrorsLogout.includes(response.message.toLowerCase()))) {
            try {
              user.triggerLogout();
            } catch (error) {
              console.log(error);
            }
            return;
          }
          else if (response.status === 200 || response.status === 201) {
            enqueueSnackbar('Resource Deleted', { variant: "success" });
            setbackDrop(false)
            toggleDisplayDeleteResource();
          }
          else {
            enqueueSnackbar("Resource Deletion Failed", {
              variant: "error",
            });
            setbackDrop(false)
            toggleDisplayDeleteResource();
          }
        })
      })
      .catch(function (error) {
        console.log(error);
        setbackDrop(false)
      });
  };

  return (
    <Dialog
      classes={{ paper: classes.dialogRootSmall }}
      onClose={toggleDisplayDeleteResource}
      aria-labelledby="customized-dialog-title"
      open={displayDeleteResource}
    >
      <Grid container direction="row" alignItems="center">
        <img
          style={{ marginLeft: "24px" }}
          alt="Delete Logo"
          src={deleteLogo}
        />
        <DialogTitle
          id="customized-dialog-title"
          onClose={toggleDisplayDeleteResource}

        >
          Delete Resource?
        </DialogTitle>
      </Grid>
      <Backdrop className={classes.backdrop} open={backDrop} onClick={backDropClose}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <DialogContent>
        <Typography
          style={{
            fontFamily: "Avenir Next",
            fontSize: "14px",
            lineHeight: "16px",
            display: "flex",
            alignItems: "center",
            color: "#FFFFFF",
          }}
        >
          Are you sure you want to delete this Resource? All devices associated
          with this resource will be affected.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={toggleDisplayDeleteResource}
          variant="outlined"
          className="dcBtDelete"
        >
          Cancel
        </Button>
        <Button
          onClick={deleteResourceHandle}
          variant="contained"
          className="dcBtEdit"
        >
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
}
