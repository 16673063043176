import React, { useState } from "react";

import { MoreVert as MoreVertIcon } from "@material-ui/icons";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import { IconButton, Menu, MenuItem } from "@material-ui/core";
import { ITemplateColumnProps } from "../../../../UiComponents/DataTable/types";
import menuItems from "./menuItems";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    menuitem: {
      color: "white",
      fontSize: "14px",
      paddingRight: "40px",
      "&:hover": {
        color: "#0089FF !important",
      },
      "& svg": {
        marginRight: "8px",
      },
    },
    menuRoot: {
      border: "1px solid #33333F",
      boxShadow: "0px 4px 4px rgba(0,0,0,0.25)",
      borderRadius: "2px",
    },
  })
);

const getMenuItemComponent = (activeMenuId: string) =>
  menuItems.find(({ id }) => id === activeMenuId)?.component || <></>;

const DeviceInstallationContextMenu: React.FC<ITemplateColumnProps> = (
  props
) => {
  const [anchorEl, setAnchorEl] = useState<any>();
  const classes = useStyles();
  const [activeItem, setActiveItem] = useState("");

  const toggleMenu = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <>
      <IconButton onClick={(event) => toggleMenu(event)}>
        <MoreVertIcon color="secondary" />
      </IconButton>
      <Menu
        id="user-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        className={classes.menuRoot}
      >
        {menuItems.map(({ id, icon: Icon, label }) => {
          return (
            <MenuItem
              key={label}
              onClick={() => {
                setAnchorEl(null);
                setActiveItem(id);
              }}
              className={classes.menuitem}
            >
              <Icon fontSize="small"></Icon>
              {label}
            </MenuItem>
          );
        })}
      </Menu>

      {activeItem &&
        React.cloneElement(getMenuItemComponent(activeItem), {
          record: props.record,
          refreshGrid: props.refreshGrid,
          onDone: () => setActiveItem(""),
        })}
    </>
  );
};

export default DeviceInstallationContextMenu;
