import addSyntheticColumns from "./addSyntheticColumns";
import getColumnTemplate from "./getColumnTemplate";
import getControlFlags from "./getControlFlags";
import getFilterInputSchema from "./getFilterInputSchema";
import getHeaderTemplate from "./getHeaderTemplate";
import getResolver from "./getResolver";
import getTranslator from "./getTranslator";

const buildColumnSchema = (userPreference: any) => {
  const preferredColumns = userPreference.filter(
    ({ is_selected, is_default }: any) => is_selected || is_default
  );

  const selectedColumns = preferredColumns.map((column: any) => {
    return {
      id: column.column_name,
      label: column.column_label,
      device_metadata_options_id: column.device_metadata_id,
    };
  });

  const selectedColumnsWithSyntheticColumns =
    addSyntheticColumns(selectedColumns);

  const columnsWithOverrides = selectedColumnsWithSyntheticColumns.map(
    (rawColumn: any) => {
      const columnId = rawColumn.id;
      return {
        ...rawColumn,
        ...getControlFlags(columnId),
        template: getColumnTemplate(columnId),
        input: getFilterInputSchema(columnId),
        translator: getTranslator(columnId),
        resolver: getResolver(columnId),
        headerTemplate: getHeaderTemplate(columnId),
      };
    }
  );

  return columnsWithOverrides;
};

export default buildColumnSchema;
