import React from "react";
import {
  IColumn,
  ITemplateColumnProps,
} from "../../UiComponents/DataTable/types";
import ApplicationLevels from "./ApplicationLevels";
import UserActionColumn from "./UserActionColumn";

const columns: IColumn[] = [
  {
    id: "email_id",
    label: "Email Id",
    searchable: true,
    sortable: true,
    exportable: true,
  },
  {
    id: "application_name",
    label: "Application",
    searchable: false,
    sortable: false,
    isHidden: true,
    exportable: true,
  },
  {
    id: "application_level",
    label: "Application Level",
    searchable: true,
    sortable: true,
    exportable: true,
    template: ({ record }: ITemplateColumnProps) => (
      <ApplicationLevels record={record} />
    ),
    resolver: (record: any) => {
      if (!record.application_level) {
        return "";
      }

      const commaSeperatedApplicationLevelNames = record.application_level
        .map(({ level_name }: any) => level_name)
        .join(", ");
      return `'${commaSeperatedApplicationLevelNames}'`;
    },
  },
  {
    id: "role_name",
    label: "Role name",
    searchable: false,
    sortable: false,
    exportable: true,
    isHidden: true,
  },
  {
    id: "valid_to",
    label: "Valid To",
    searchable: true,
    sortable: true,
    exportable: true,
  },
  {
    id: "last_login",
    label: "Last login",
    searchable: true,
    sortable: true,
    exportable: true,
  },
  {
    id: "status",
    label: "Status",
    searchable: true,
    sortable: true,
    exportable: true,
    template: ({ record }: any) =>
      record.status === "ACTIVE" ? (
        <div style={{ color: "#2AC392" }}>{record.status}</div>
      ) : (
        <div style={{ color: "#d72b3f" }}>{record.status}</div>
      ),
    input: {
      type: "dropDown",
      dropDownOptions: ["INACTIVE", "ACTIVE"].map((v) => ({
        label: v,
        value: v,
      })),
    },
  },
  {
    id: "action",
    label: " ",
    searchable: false,
    sortable: false,
    exportable: false,
    template: (props: ITemplateColumnProps) => {
      return <UserActionColumn {...props} />;
    },
  },
];

export { columns };
