import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  hoveredStyle: {
    borderRadius: "0px",
    "&:hover": {
      backgroundColor: "#2B2B36",
    },
  },

  wrapper: {
    width: "344px",
    background: "#464659",
  },

  noNotificationText: {
    color: "#949494",
    padding: "1em",
  },

  notificationCardHolder: {
    maxHeight: "400px",
    overflow: "auto",
  },

  notificationWrapper: {
    color: "white",
    display: "flex",
    flexDirection: "row",
    margin: "0.2em 0",
    background: "#373746",
    padding: "0.2em 0em",
    cursor: "default",
  },
  notificationMessageWrapper: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
  },
  notificationIndicator: {
    width: "3.5em",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "& svg": {
      fontSize: "2em",
    },
  },

  notificationHeaderText: {
    color: "#FFFFFF",
    fontWeight: "bold",
    fontSize: "18px",
    lineHeight: "8px",
    padding: "1em",
    borderBottom: "1px solid #FCFCFC",
  },

  notificationTimeStamp: {
    display: "flex",
    alignItems: "flex-end",
    flex: 1,
    justifyContent: "end",
    paddingRight: "0.5em",
  },

  menuitem: {
    color: "#373746",
  },
}));

export default useStyles;
