enum JOB_TYPE {
  SINGLE_DEVICE_CREATION = "single",
}

enum SESSION_STORAGE_KEY {
  JOBS = "jobQueue",
  NOTIFICATIONS = "notificationQueue",
}

enum JOB_STATUSES {
  IN_PROGRESS = "INPROGRESS",
  SUCCESS = "SUCCESS",
  FAILED = "FAILED",
}
export interface IJob {
  id: string | number;
  type: JOB_TYPE;
  completed: boolean;
  timeStamp: number;
  payload: any;
}

export { JOB_TYPE, JOB_STATUSES, SESSION_STORAGE_KEY };
