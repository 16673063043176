import FileSaver from "file-saver";
import * as XLSX from "xlsx";

export async function exportToExcel(ExclData, SheetName, ColsCount, applicationName) {
  const today = getCurrentDate();
  const appSheetName = applicationName && typeof applicationName === "string"? `${applicationName}-${SheetName}`: SheetName
  const fileName = `Export-${appSheetName}-${today}`;
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".csv";
  const wscols = [];
  for (let i = 1; i <= ColsCount; i++) {
    wscols.push({ wch: 15 });
  }
  const reportExcl = XLSX.utils.json_to_sheet(ExclData);
  reportExcl["!cols"] = wscols;
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, reportExcl, SheetName);
  const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
  const data = new Blob([excelBuffer], { type: fileType });
  FileSaver.saveAs(data, fileName + fileExtension);
}

function getCurrentDate() {
  const date = new Date();
  const day = date.getDate();
  const month = new Date().getMonth() + 1;
  const year = new Date().getFullYear();
  return `${month}-${day}-${year}`;
}

export function getTodayDate() {
  var d = new Date(),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) {
    month = "0" + month;
  }
  if (day.length < 2) {
    day = "0" + day;
  }
  const hrs = d.getHours() < 10 ? "0" + d.getHours() : d.getHours();
  const mins = d.getMinutes() < 10 ? "0" + d.getMinutes() : d.getMinutes();
  const secs = d.getSeconds() < 10 ? "0" + d.getSeconds() : d.getSeconds();
  return `${[year, month, day].join("-")} ${hrs}:${mins}:${secs}`;
}

export const isValidImageExtension = (file) => {
  const extension = file.name.split(".").pop().toLowerCase();
  return ["jpeg", "jpg", "png"].includes(extension);
};

export const arrayOfErrorsRefresh = ["token expired"];
export const arrayOfErrorsLogout = ["authentication error"];
export const arrayOfImageTypes = ["image/jpeg","image/jpg","image/png"];
export const maxImages = 5;
export const maxImageSize = 10;
export const AuthType = {
  x509: 'X509',
  sas: 'SAS'
};
