enum CSV_COLUMNS {
  device_id,
  //   device_name,
  //   properties,
  //   serial_num,
  tags,
  installation_status,
  //   fields,
  //   conn_request_payload,
}
const BULK_UPDATE_RECORD_LIMIT = 500;
export { CSV_COLUMNS, BULK_UPDATE_RECORD_LIMIT };
