/*
When applying filters, certain values should be translated to some fixed
string/number. For example here when user keys in 'invalid' or 'invalid data'
we will send the value "-1"
*/

import { COLUMN_IDS } from "./columns";

const translateInvalidData = (value: string = "") => {
  const loweredString = value.toLowerCase();
  if (["invalid", "invalid data"].includes(loweredString)) {
    return "-1";
  }
  return value;
};

const translatePercentage = (value: string = "") => {
  if (value.trim() === "%") {
    return "per";
  }
  return value;
};

const getTranslator = (columnId: string) => {
  return (
    {
      [COLUMN_IDS.pi_timestamp]: translateInvalidData,
      [COLUMN_IDS.pi_measurement]: translateInvalidData,
      [COLUMN_IDS.battery_level]: translateInvalidData,
      [COLUMN_IDS.battery_unit]: translatePercentage,
    }[columnId] || ((v: string) => v)
  );
};

export default getTranslator;
