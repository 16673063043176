import React from "react";
import ReportProblemOutlinedIcon from "@material-ui/icons/ReportProblemOutlined";
import { withStyles } from "@material-ui/core/styles";
import { Tooltip } from "@material-ui/core";

const ToolTipRed = withStyles({
  tooltip: {
    color: "#D93638",
    background: "#32323e",
    fontSize: ".8em",
    fontWeight: "bolder",
    "& .MuiTooltip-arrow": {
      color: "#32323e",
    },
  },
})(Tooltip);

interface IDeviceHealthReportProps {}

const DeviceHealthReport: React.FC<IDeviceHealthReportProps> = () => {
  return (
    <ToolTipRed arrow title="This device is unhealthy" placement="top-start">
      <ReportProblemOutlinedIcon
        style={{ color: "#D93638" }}
        fontSize="small"
      ></ReportProblemOutlinedIcon>
    </ToolTipRed>
  );
};

export default DeviceHealthReport;
