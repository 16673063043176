import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { Tooltip } from "@material-ui/core";
import AlertRed from "../../../../assets/AlertRed.png";
import AlertYellow from "../../../../assets/AlertYellow.png";

//TODO: This component is taken as is from old ShowDevices file

const AlertTooltipYellow = withStyles({
  tooltip: {
    color: "#fff",
    background: "#2B2B36",
    border: "1px solid #FDB840"
  },
})(Tooltip);

const AlertTooltipRed = withStyles({
  tooltip: {
    color: "#fff",
    background: "#2B2B36",
    border: "1px solid #D72B3F"
  },
})(Tooltip);

const CertificateStatusIndicator: React.FC<any> = ({ record }) => {
  const MID_LIFE_EXPIRE_DAYS = 60;
  
  if (record.auth_type === 'SAS' || record.status === 'DEVICE_REVOKED' || record.cert_exp_date === null) return null;
  if (record.is_mid_life_passed) {
    if (record.days_to_expiry > 0) {
      const MID_LIFE_EXPIRE_MESSAGE = `This device certificate will expire in ${record.days_to_expiry} days. Please renew.`
      return (
        <AlertTooltipYellow title={MID_LIFE_EXPIRE_MESSAGE}>
          <img src={AlertYellow} />
        </AlertTooltipYellow>
      );
    }
    else {
      return (
        <AlertTooltipRed title={`This device certificate is expired.`}>
          <img src={AlertRed} />
        </AlertTooltipRed>
      );
    }
  }
  else if (record.mid_life_expiry_in_days <= MID_LIFE_EXPIRE_DAYS) {
    const MID_LIFE_REACH_MESSAGE = `This device certificate needs renewal in ${record.mid_life_expiry_in_days} days.`
    return (
      <AlertTooltipYellow title={MID_LIFE_REACH_MESSAGE}>
        <img src={AlertYellow} />
      </AlertTooltipYellow>
    );
  }
  else return null
};

export default CertificateStatusIndicator;
