import React, { useEffect } from "react";
import { useSnackbar } from "notistack";

import SubmitWithConfirmation from "../../../UiComponents/SubmitWithConfirmation";
import useApi from "../../../../hooks/useApi/useApi";

interface ISendToDeviceCTA {
  endPointURL: string;
  formData: any;
  onDone?: () => void;
}

const payloadRegX = /^[a-fA-F0-9]+$/;
const MIN_PORT_NUMBER = 1;
const MAX_PORT_NUMBER = 223;

const SendToDeviceCTA: React.FC<ISendToDeviceCTA> = ({
  endPointURL,
  formData,
  onDone = () => true,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const {
    data: responseData,
    status: requestStatus,
    trigger: sendToDevice,
  } = useApi(endPointURL, {
    method: "POST",
    deferred: true,
    includePMSCredentialsInRequestBody: true,
  });

  const validatePayload = (payload: string = "") => {
    if (!payload) {
      enqueueSnackbar("Please enter payload", { variant: "error" });
      return false;
    }

    if (payload.length % 2 !== 0 || !payloadRegX.test(payload)) {
      enqueueSnackbar("Invalid payload", { variant: "error" });
      return false;
    }
    return true;
  };

  const validatePortNumber = (portNumber: string) => {
    if (!portNumber) {
      enqueueSnackbar("Please enter port number", { variant: "error" });
      return false;
    }

    const portNumberInteger = +portNumber;
    if (
      isNaN(portNumberInteger) ||
      portNumberInteger % 1 !== 0 || //check for float
      portNumberInteger > MAX_PORT_NUMBER ||
      portNumberInteger < MIN_PORT_NUMBER
    ) {
      enqueueSnackbar("Invalid Port Number", { variant: "error" });
      return false;
    }
    return true;
  };

  useEffect(() => {
    if (responseData?.status == 200) {
      enqueueSnackbar(responseData?.message || "Success", {
        variant: "success",
      });
      onDone();
      return;
    }

    if (!requestStatus.pending && requestStatus.error) {
      enqueueSnackbar(requestStatus.error, {
        variant: "error",
      });
    }
  }, [responseData, requestStatus.error]);

  return (
    <SubmitWithConfirmation
      title="Are you sure?"
      subTitle={`Are you sure that you want to send this payload to device?`}
      preValidator={() => {
        return (
          validatePortNumber(formData.port) && validatePayload(formData.payload)
        );
      }}
      onYes={() => {
        sendToDevice({ message: { ...formData, port: +formData.port } });
      }}
      buttonLabel="Send To Device"
    />
  );
};

export default SendToDeviceCTA;
