import React, { useState } from "react";
import { Modal } from "../../../UiComponents";

const ApplicationLevelsModal = ({ applicationLevels }: any) => {
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <div
        style={{
          fontSize: ".8em",
          color: "#4da5ff",
          textDecoration: "underline",
          cursor: "pointer",
        }}
        onClick={() => setShowModal(true)}
      >
        {`${applicationLevels.length - 1} more`}
      </div>

      {showModal && (
        <Modal
          title="Application Levels"
          open={true}
          onClose={() => setShowModal(false)}
        >
          <div style={{ width: "30em" }}>
            {applicationLevels.map((applicationLevel: any) => {
              return (
                <p key={applicationLevel.level_id}>
                  {applicationLevel.level_name}
                </p>
              );
            })}
          </div>
        </Modal>
      )}
    </>
  );
};

const ApplicationLevels = ({ record }: any) => {
  const { application_level } = record;
  if (!application_level) {
    return null;
  }
  return (
    <div>
      {application_level[0]?.level_name}{" "}
      {application_level.length > 1 ? (
        <ApplicationLevelsModal applicationLevels={application_level} />
      ) : (
        ``
      )}
    </div>
  );
};

export default ApplicationLevels;
