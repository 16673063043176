import React, { useState } from "react";
import { Grid } from "@material-ui/core";
import { useSnackbar } from "notistack";
import { TAG_TYPE } from "../../../../utils/constant";
import readError from "../../../../utils/readError";
import {
  HeaderNavigation,
  BasicConfigFields,
  Tags,
  AddNewTag,
  TabularDropdowns,
  AddNewDropdownSpecifications,
  Properties,
  Submit,
} from "../sharedFormParts";
import ButtonText from "../../../UiComponents/ButtonText";
import { validateAddedTags } from "../sharedFormParts/AddNewTag/utils";
import { CONFIG_PAGE } from "../types";
import useStyles from "./EditConfiguration.styles";

interface IEditConfigurationProps {
  onEditConfig: any;
  editItem: any;
}

const prepareFields = (configuration: any) => {
  const {
    application_id,
    config_name,
    device_type,
    edge_type,
    auth_type,
    config_desc,
  } = configuration;
  return {
    application_id,
    config_name,
    device_type,
    edge_type,
    auth_type,
    config_desc,
  };
};

const EditConfiguration: React.FC<IEditConfigurationProps> = ({
  onEditConfig,
  editItem,
}) => {
  const [basicFields, setBasicFields] = useState(prepareFields(editItem));
  const [tags, setTags] = useState(editItem.tags);
  const [properties, setProperties] = useState(editItem.properties);
  const [dropdown, setDropdown] = useState(editItem.dropdown);
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const safelyRemoveTag = (removedTagName: string) => {
    const { [removedTagName]: removedTagDetails, ...persistingTags } = tags;
    if (
      removedTagDetails.type === TAG_TYPE.Tabular &&
      dropdown[removedTagName] //user needs to remove the dropdown spec first
    ) {
      throw new Error(
        `Please remove all dropdown specification for the tag '${removedTagName}'`
      );
    }
    setTags(persistingTags);
  };

  const tagValidation = (
    tagName: string,
    tagData: any,
    type: string,
    existingOptions: string
  ) => {
    //TODO: these checks needs to be extracted to a function
    if (type === "Dropdown") {
      // Check if there are no existing options
      if (!existingOptions || existingOptions.trim() === "") {
        // Directly set the new options provided by the user
        return { [tagName]: { ...tagData } };
      }
      // If there are existing options
      // Check if tagData.options starts with existing options and appends data after "|"
      if (tagData.options.startsWith(existingOptions + "|")) {
        return { [tagName]: { ...tagData } };
      }
      if (tagData.options === existingOptions) {
        //removes appended value along with "|"
        if (existingOptions.endsWith("|")) {
          const newOptions = existingOptions.slice(0, -1); // Remove the "|" symbol
          return { [tagName]: { ...tagData, options: newOptions } };
        }
        // removes appended value but there's no "|" symbol to remove
        return { [tagName]: tagData };
      }
      return {
        error: true,
        msg: "Cannot edit previous values. Append data only after adding | symbol.",
      };
    }
    if (type === "Text" || type === "Tabular") {
      return { [tagName]: tagData };
    }
    return { error: true, msg: "Cannot edit previous values" };
  };

  const handleTagChange = (tagName: string, tagData: any) => {
    const { type } = tagData;
    const existingOptions = editItem.tags[tagName]
      ? editItem.tags[tagName].options
      : ""; //newly added tag
    const validatedTagData = tagValidation(
      tagName,
      tagData,
      type,
      existingOptions
    );
    if (validatedTagData?.error) {
      enqueueSnackbar(validatedTagData.msg, { variant: "error" });
      setTags({ ...editItem.tags });
    } else {
      setTags({ ...tags, ...validatedTagData });
    }
  };

  return (
    <>
      <HeaderNavigation page={CONFIG_PAGE.EDIT_CONFIG} onClick={onEditConfig} />

      <div className={classes.wrapper}>
        <Grid container>
          <BasicConfigFields
            basicFields={basicFields}
            page={CONFIG_PAGE.EDIT_CONFIG}
            onFieldChange={(field, value) => {
              setBasicFields({ ...basicFields, [field]: value });
            }}
          />

          <Tags
            tags={tags}
            page={CONFIG_PAGE.EDIT_CONFIG}
            onTagChange={handleTagChange}
            onTagDelete={(removedTagName: string) => {
              try {
                safelyRemoveTag(removedTagName);
              } catch (error) {
                const errorMessage = readError(error);
                enqueueSnackbar(errorMessage, { variant: "error" });
              }
            }}
          />
          <AddNewTag
            onTagAdd={(newTags: any) => {
              //Note: Errors are handled inside component
              validateAddedTags(tags, newTags);
              setTags({ ...tags, ...newTags });
            }}
          />
          <TabularDropdowns
            dropdowns={dropdown}
            newlyAddedTabularTags={Object.keys(tags).filter(
              (t) => tags[t]._isNew
            )}
            onDropdownUpdate={(
              tableName: string,
              columnName: string,
              value: string
            ) => {
              const updatedTableOptions = {
                ...dropdown[tableName],
                [columnName]: value,
              };
              setDropdown({ ...dropdown, [tableName]: updatedTableOptions });
            }}
            onDropdownRemove={(
              tableName: string,
              removedColumnName: string
            ) => {
              const { [removedColumnName]: _, ...persistingTableOptions } =
                dropdown[tableName];

              //if all column specs are removed for the tag, then remove the tag from dropdown
              if (Object.keys(persistingTableOptions).length > 0) {
                setDropdown({
                  ...dropdown,
                  [tableName]: persistingTableOptions,
                });
              } else {
                const { [tableName]: _, ...persitingTableMappings } = dropdown;
                setDropdown(persitingTableMappings);
              }
            }}
          />
          <AddNewDropdownSpecifications
            tags={tags}
            page={CONFIG_PAGE.EDIT_CONFIG}
            onDropdownSpecificationAdd={(
              tabularTagName: string,
              selectedColumn: string
            ) => {
              if (dropdown[tabularTagName]?.[selectedColumn] !== undefined) {
                throw new Error(`Dropdown specification already exists!`);
              }
              const updatedTableOptions = {
                ...dropdown[tabularTagName],
                [selectedColumn]: "",
              };
              setDropdown({
                ...dropdown,
                [tabularTagName]: updatedTableOptions,
              });
            }}
          />

          <Properties
            properties={properties}
            onPropertyChange={(propertyName: string, propertyValue: string) => {
              setProperties({ ...properties, [propertyName]: propertyValue });
            }}
          />
          <ButtonText
            style={{ margin: "-1.2em 0 0 1em" }}
            label="Add New Property"
            disabled
          />
          <Submit
            configData={{
              ...editItem,
              ...basicFields,
              tags,
              properties,
              dropdown,
            }}
            page={CONFIG_PAGE.EDIT_CONFIG}
            onDone={onEditConfig}
          />
        </Grid>
      </div>
    </>
  );
};

export default EditConfiguration;
