import React, { useState } from "react";
import { IconButton } from "@material-ui/core";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import EditIcon from "@material-ui/icons/Edit";
import CustomButton from "../../../CustomButton";
import Modal from "../../../Modal";
import SubmitButton from "../../../SubmitButton";
import ModalInput from "./ModalInput";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    editModal: {
      flex: 1,
      display: "flex",
    },
    buttonHolder: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      borderTop: "1px solid #454343",
      padding: "1em 0",
    },
  })
);

const EditModal: React.FC<any> = ({ label, record, onClose, onRecordEdit }) => {
  const classes = useStyles();
  const [editedRecord, setEditedRecord] = useState(record);

  return (
    <Modal title="Edit Row" open={true} onClose={onClose}>
      <div className={classes.editModal}>
        {Object.keys(editedRecord)
          .filter((field) => field !== "_meta")
          .map((field) => {
            return (
              <ModalInput
                key={field}
                label={label}
                field={field}
                value={editedRecord[field]}
                onChange={(value: string) =>
                  setEditedRecord({ ...editedRecord, [field]: value })
                }
              />
            );
          })}
      </div>
      <div className={classes.buttonHolder}>
        <CustomButton variant="outlined-white" onClick={onClose}>
          Cancel
        </CustomButton>
        <SubmitButton
          onClick={() => {
            onRecordEdit(editedRecord);
            onClose();
          }}
        >
          Save
        </SubmitButton>
      </div>
    </Modal>
  );
};

const RecordEditor: React.FC<any> = ({ label, record, onRecordEdit }) => {
  const [showModal, setShowModal] = useState(false);
  return (
    <>
      {showModal && (
        <EditModal
          label={label}
          record={record}
          onClose={() => setShowModal(false)}
          onRecordEdit={onRecordEdit}
        />
      )}
      <IconButton
        onClick={() => {
          setShowModal(true);
        }}
      >
        <EditIcon color="secondary" />
      </IconButton>
    </>
  );
};

export default RecordEditor;
