import React, { useEffect } from "react";
import {
  createStyles,
  Theme,
  makeStyles,
  withStyles,
  WithStyles,
} from "@material-ui/core/styles";

import IconButton from "@material-ui/core/IconButton";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";

import CloseIcon from "@material-ui/icons/Close";

import MuiDialogTitle from "@material-ui/core/DialogTitle";

import Divider from "@material-ui/core/Divider";
import deleteLogo from "../../../../assets/Delete.png";
import useApi from "../../../../hooks/useApi";
import { useApplicationContext } from "../../../../contexts/ApplicationContext";
import { useSnackbar } from "notistack";

const shadow_style = "0px 30px 100px #000000";
const border_style = "1px solid rgba(235,235,245,0.35)";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialogRootSmall: {
      boxShadow: shadow_style,
      borderRadius: "3px",
      maxWidth: "800px",
      position: "absolute",
      top: "10%",
      minWidth: "416px",
      "& .dcTitle.dcHeader": {
        marginTop: "0px",
      },
      "& .dcTitle": {
        fontSize: "16px",
        lineHeight: "19px",
        color: "#FFFFFF",
        fontWeight: "bold",
        marginTop: "25px",
      },
      "& .dcOuter": {
        background: "#2B2B36",
        borderRadius: "4px",
        padding: "15px",
      },
      "& .dcFieldValue": {
        fontSize: "14px",
        lineHeight: "16px",
        marginTop: "10px",
        color: "#FFFFFF",
        overflowWrap: "anywhere",
        paddingRight: ".5em",
      },
      "& .dcSep": {
        marginTop: "20px",
      },
      "& .dcBtDelete": {
        borderRadius: "2px",
        border: border_style,
        color: "#0089FF",
        background: "none",
      },
      "& .dcBtEdit": {
        backgroundColor: "#0089ff",
        borderRadius: "4px",
        border: "none",
        color: "#FFFFFF",
      },
    },
    root: {
      width: "100%",
      maxWidth: 360,
      backgroundColor: theme.palette.background.paper,
    },
    title: {
      marginLeft: theme.spacing(2),
      flex: 1,
    },
    dividerColor: {
      background: "#464659",
      margin: "1em",
    },
  })
);

const DialogTitleStyles = (theme: Theme) =>
  createStyles({
    root: {
      margin: "20px",
      padding: 0,
      color: "#FFFFFF",
    },
    title: {
      fontSize: "21px",
      lineHeight: "25px",
      fontWeight: "bold",
    },
    closeButton: {
      position: "absolute",
      right: "2px",
      top: "15px",
      color: "#FFFFFF",
      fontSize: "14px",
    },
  });

const DialogContent = withStyles((theme: Theme) => ({
  root: {
    margin: "0px 15px",
    padding: 0,
    color: "#FFFFFF",
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme: Theme) => ({
  root: {
    margin: "20px 15px",
    justifyContent: "space-between",
    padding: 0,
    "& button": {
      fontSize: "16px",
      lineHeight: "19px",
      textTransform: "none",
      padding: "9px 20px",
    },
  },
}))(MuiDialogActions);

export interface DialogTitleProps extends WithStyles<typeof DialogTitleStyles> {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
}
const DialogTitle = withStyles(DialogTitleStyles)((props: DialogTitleProps) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6" className={classes.title}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon fontSize="inherit" />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DeleteDevice: React.FC<any> = ({ record, onDone }) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { selectedApplication } = useApplicationContext();
  const {
    trigger: deleteDevice,
    data: deleteSuccessful,
    status,
  } = useApi(
    `/applications/${selectedApplication.application_id}/devices/${record.device_dlm_id}`,
    {
      method: "DELETE",
      deferred: true,
      responseDataFormatter: (responseData: any) => {
        return (responseData.message || "").toLowerCase() === "success";
      },
    }
  );

  useEffect(() => {
    if (deleteSuccessful) {
      enqueueSnackbar("Device deletion initiated", { variant: "success" });
      onDone();
    }
    if (!status.pending && status.error) {
      console.log(status);
      enqueueSnackbar("Device deletion failed", {
        variant: "error",
      });
    }
  }, [deleteSuccessful, status.pending, status.error]);

  return (
    <Dialog
      classes={{ paper: classes.dialogRootSmall }}
      onClose={onDone}
      aria-labelledby="customized-dialog-title"
      open={true}
    >
      <Grid container direction="row" alignItems="center">
        <img
          style={{ marginLeft: "1em" }}
          alt="Delete Logo"
          src={deleteLogo}
        ></img>
        <DialogTitle id="customized-dialog-title" onClose={onDone}>
          Delete Device
        </DialogTitle>
      </Grid>
      <DialogContent>
        <Typography
          style={{
            fontFamily: "Avenir Next",
            fontSize: "14px",
            lineHeight: "16px",
            display: "flex",
            alignItems: "center",
            margin: "1em",
            color: "#FFFFFF",
          }}
        >
          Are you sure you want to delete this Device? This Device cannot be
          recovered.
        </Typography>
      </DialogContent>
      <Divider variant="middle" classes={{ middle: classes.dividerColor }} />
      <DialogActions>
        <Button onClick={onDone} variant="outlined" className="dcBtDelete">
          Cancel
        </Button>
        <Button
          onClick={() => deleteDevice()}
          variant="contained"
          className="dcBtEdit"
        >
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteDevice;
