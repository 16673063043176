import React, { useState } from 'react';
import { Table, TableBody, TableRow, TableCell, Paper, TableContainer, TableHead, createStyles, withStyles, Checkbox } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles';
import { StyledTableCell, StyledTableRow } from '../StyledComponents';

const useStyles = makeStyles(() =>
  createStyles({
    tableWrapper: {
      padding: "0 20px",
      marginTop: "15px",
      boxShadow: "none",
      overflow: "hidden",
      width: "50em",
      minHeight: "250px"
    },
    table: {
      minWidth: "100%",
      maxWidth: "100%",
    },
    levelSearch: {
      height: "50px",
      backgroundColor: "#2B2B36",
      border: "transparent",
      color: "white",
      marginTop: "2em",
      paddingLeft: "23px"
    },
    bgColorBlue: {
      color: "#1976d2 !important",
    },
  })
);

interface Selected {
  level_id: number[];
  level_name: string[];
}
interface ApplicationLevelProp {
  selected: Selected;
  levels: any;
  setSelected: (val: Selected) => void;
}

function ApplicationLevel({ levels, selected, setSelected }: ApplicationLevelProp) {
  const [search, setSearch] = useState("");

  const classes = useStyles();

  const filterSearch = (item: any) => item.level_name.toLowerCase().includes(search.toLowerCase())
  const handleChangeSearch = (event: any) => {
    const target = event.target.value;
    setSearch(target);
  };
  const highlightHandler = (event: React.ChangeEvent<HTMLInputElement>, level_id: any, level_name: any) => {
    const checked = event.target.checked;
    const levelIDs = [...selected.level_id];
    const levelNames = [...selected.level_name];
    if (checked) {
      levelIDs.push(level_id);
      levelNames.push(level_name)
    }
    else {
      levelIDs.splice(levelIDs.indexOf(level_id), 1);
      levelNames.splice(levelNames.indexOf(level_name), 1);
    }
    const selectedLevel = { level_id: levelIDs, level_name: levelNames }
    setSelected(selectedLevel);
  };

  return (
    <TableContainer
      component={Paper}
      className={classes.tableWrapper}
    >
      <Table
        className={classes.table}
        aria-label="customized table"
      >
        <TableHead>
        </TableHead>
        <TableHead style={{ color: "#fff" }} >
          <input type="search"
            className={classes.levelSearch}
            placeholder="Search..."
            onChange={(event) => handleChangeSearch(event)} />
        </TableHead>
        <TableBody>
          {levels.filter(filterSearch).map((row: any, index: number) => (
            <StyledTableRow key={index}>
              <StyledTableCell>
                <Checkbox
                  className={classes.bgColorBlue}
                  aria-label="level"
                  value={row.level_name}
                  onChange={(event) => { highlightHandler(event, row.level_id, row.level_name) }}
                  checked={selected?.level_id.includes(row.level_id)}
                />
                {row.level_name}
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default ApplicationLevel;
