import React from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";

import Table from "@material-ui/core/Table";
import TableContainer from "@material-ui/core/TableContainer";
import Paper from "@material-ui/core/Paper";
import { TableFooter } from "@material-ui/core";

import { IColumn, ITemplateColumnProps, SortingOrder } from "./types";
import DataTableBody from "./DataTableBody";
import DataTableHeader from "./DataTableHeader";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tableWrapper: {
      padding: "0 20px",
      marginTop: "15px",
      boxShadow: "none",
    },
    table: {
      minWidth: "100%",
      maxWidth: "100%",
    },
    tblfooter: {
      position: "absolute",
    },
  })
);

interface SortingInfo {
  column: string;
  order: SortingOrder;
}
export interface IDataTableProps {
  columns: Array<IColumn>;
  values: Array<any>;
  sortingInfo?: SortingInfo;
  onSort?: (columnId: string, sortingOrder: SortingOrder) => void;
  refreshGrid: () => void;
  noWrapper?: boolean;
}

const DataTable: React.FunctionComponent<IDataTableProps> = ({
  columns,
  values,
  sortingInfo,
  onSort,
  refreshGrid,
  noWrapper,
}) => {
  const classes = useStyles();
  const visibleColumns = columns.filter(({ isHidden }) => !isHidden);
  return (
    <TableContainer
      component={Paper}
      className={noWrapper ? "" : classes.tableWrapper}
    >
      <Table className={classes.table} aria-label="customized table">
        <DataTableHeader
          columns={visibleColumns}
          sortedColumn={sortingInfo?.column}
          sortingOrder={sortingInfo?.order}
          onSort={onSort}
        />
        <DataTableBody
          columns={visibleColumns}
          values={values}
          refreshGrid={refreshGrid}
        />
        <TableFooter className={classes.tblfooter}></TableFooter>
      </Table>
    </TableContainer>
  );
};

export default DataTable;
