import React from "react";
import { ThemeProvider } from "@material-ui/styles";
import {
  Typography,
  makeStyles,
  Button,
  Grid,
  createMuiTheme,
} from "@material-ui/core";

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import CardContent from "@material-ui/core/CardContent";
import Card from "@material-ui/core/Card";
import CloseSharpIcon from "@material-ui/icons/CloseSharp";
import AddIcon from "@material-ui/icons/Add";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  cardTitle: {
    fontSize: "14px",
    fontWeight: "bold",
    lineHeight: "19px",
    color: "#FFFFFF",
    marginBottom: "16px",
  },
  cardText: {
    fontSize: "12px",
    lineHeight: "16px",
    color: "#FFFFFF",
    marginBottom: "10px",
  },
  CardStyle: {
    background: "#2B2B36",
    borderRadius: "3px",
  },
  cardGrid: {
    marginBottom: "20px",
  },
  dialogueTitle: {
    fontSize: "16px",
    fontWeight: "bold",
    display: "flex",
    marginTop: "5px",
    color: "#FFFFFF",
  },
  closeIcon: {
    color: "#FFFFFF",
    marginBottom: "20px",
    padding: "0px",
  },
}));

const theme = createMuiTheme({
  palette: {
    primary: {
      main: `#0089FF`,
    },
    secondary: {
      main: `#FFFFFF`,
    },
  },
});

interface AddResourcesPromptInterface {
  displayResourcePrompt: boolean;
  toggleDisplayResourcePrompt: () => void;
  toggleDisplayAddNewResource: () => void;
  toggleDisplayAddExistingResource: () => void;
}

export default function AddResourcesPrompt({
  displayResourcePrompt,
  toggleDisplayResourcePrompt,
  toggleDisplayAddNewResource,
  toggleDisplayAddExistingResource,
}: AddResourcesPromptInterface) {
  const classes = useStyles();
  return (
    <Dialog
      open={displayResourcePrompt}
      onClose={toggleDisplayResourcePrompt}
      maxWidth="xl"
      PaperProps={{
        style: {
          backgroundColor: "#1D1D25",
          boxShadow: "0px 30px 100px #000000",
          borderRadius: "3px",
          width: "800px",
        },
      }}
    >
      <DialogContent>
        <Grid container direction="column">
          <Grid container direction="row" justify="space-between">
            <Grid item>
              <Typography className={classes.dialogueTitle}>
                Create a New Resource
              </Typography>
            </Grid>
            <Grid item>
              <Button onClick={toggleDisplayResourcePrompt}>
                <CloseSharpIcon className={classes.closeIcon}></CloseSharpIcon>
              </Button>
            </Grid>
          </Grid>
          <Grid item className={classes.cardGrid}>
            <Card
              variant="outlined"
              elevation={3}
              classes={{
                root: classes.CardStyle,
              }}
            >
              <CardContent>
                <Grid container direction="column">
                  <Grid item>
                    <Typography className={classes.cardTitle}>
                      Create Azure IoT Hub 
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography className={classes.cardText} style = {{color :'#C4C4C4'}}>
                    IoT Hub is the cloud gateway service that enables devices
                      to connect. Azure IoT Hub is integrated with DPS for
                      automatic provisioning.
                    </Typography>
                  </Grid>
                  <Grid container direction="row" justify="flex-end">
                    <Grid item>
                      <ThemeProvider theme={theme}>
                        <Button
                          onClick={() => {
                            toggleDisplayAddNewResource();
                          }}
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            padding: "8px 16px",
                            background: "#0089FF",
                            borderRadius: "2px",
                            marginLeft: "20px",
                            marginRight: "20px",
                          }}
                        >
                          <Typography
                            style={{
                              fontFamily: "Avenir Next",
                              fontSize: "16px",
                              lineHeight: "19px",
                              display: "flex",
                              alignItems: "center",
                              textAlign: "center",
                              color: "#FCFCFC",
                              textTransform: "none",
                              marginRight: "5px",
                            }}
                          >
                            Create Azure IoT Hub
                          </Typography>
                          <AddIcon color="secondary"></AddIcon>
                        </Button>
                      </ThemeProvider>
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          <Grid item className={classes.cardGrid}>
            <Card
              variant="outlined"
              elevation={3}
              classes={{
                root: classes.CardStyle,
              }}
            >
              <CardContent>
                <Grid container direction="column">
                  <Grid item>
                    <Typography className={classes.cardTitle}>
                      Add Existing IoT Hub
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography className={classes.cardText} style = {{color :'#C4C4C4'}}>
                      Add your existing IOTHUB from MAHS, External
                      Subscriptions or Azure@Shell.
                    </Typography>
                  </Grid>
                  <Grid container direction="row" justify="flex-end">
                    <Grid item>
                      <ThemeProvider theme={theme}>
                        <Button
                          onClick={() => {
                            toggleDisplayAddExistingResource();
                          }}
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            padding: "8px 16px",
                            background: "#0089FF",
                            borderRadius: "2px",
                            marginLeft: "20px",
                            marginRight: "20px",
                          }}
                        >
                          <Typography
                            style={{
                              fontFamily: "Avenir Next",
                              fontSize: "16px",
                              lineHeight: "19px",
                              display: "flex",
                              alignItems: "center",
                              textAlign: "center",
                              color: "#FCFCFC",
                              textTransform: "none",
                              marginRight: "5px",
                            }}
                          >
                            Add Existing IoT Hub
                          </Typography>
                          <AddIcon color="secondary"></AddIcon>
                        </Button>
                      </ThemeProvider>
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}
