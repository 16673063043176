import React from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    nativeDropdown: {
      width: "207px",
      height: "40px",
      background: "transparent",
      padding: "10px",
      color: "#FFFFFF",
      border: "1px solid #4CA5FF",
      font: "400 13.3333px 'Avenir Next'",
      borderRadius: "2px",
      "& option": {
        background: "#1D1D25",
      },
    },
  })
);

interface IDropDownOption {
  value: string;
  label: string;
}

interface INativeDropDownProps {
  options: Array<IDropDownOption>;
  value: string;
  onChange: (value: string) => void;
}

const NativeDropDown: React.FC<INativeDropDownProps> = ({
  options,
  value,
  onChange,
}) => {
  const classes = useStyles();
  return (
    <select
      className={classes.nativeDropdown}
      onChange={(event: any) => onChange(event.target.value)}
      value={value}
    >
      {options.map((option: any) => (
        <option key={option.value} value={option.value}>
          {option.label}
        </option>
      ))}
    </select>
  );
};

export default NativeDropDown;
