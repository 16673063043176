import React, { useEffect, useState } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import HighlightCards from "./HighlightCards/HighlightCards";
import StatusCards from "./StatusCards/StatusCards";
import ErrorStatsCard from "../DashboardCards/ErrorStatsCard/ErrorStatsCard";
import DashboardHeader from "./DashboardHeader/DashboardHeader";
import { API } from "../../api/property";
import { Backdrop, CircularProgress } from "@material-ui/core";
import callXhrRequest from "../../utils/xhrRequestHandler";
import { arrayOfErrorsRefresh, arrayOfErrorsLogout } from "../../utils/helper";
import { useStore } from "mobx-store-provider";
import { useApplicationContext } from "../../contexts/ApplicationContext";
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginLeft: "20px",
    },
    dashboard: {
      display: "grid",
      gridTemplateRows: "auto",
      gridTemplateAreas: `
        "header"
        "first-row"
        "second-row"
        "third-row"
      `,
      gridRowGap: "30px",
      padding: "10px 30px",
      justifyContent: "center",
    },
    thirdrow: {
      gridArea: "third-row",
      display: "grid",
      gridTemplateColumns: "min-content",
      gridColumnGap: "32px",
      gridTemplateAreas: `
      "error"
    `,
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: "#fff",
    },
  })
);

const getSelectedApplicationData = (
  dashboardData: any[],
  selectedApplication: any
) => {
  const selectedApplicationData = dashboardData.find(
    (db: any) => db.application_id === selectedApplication.application_id
  );

  return selectedApplicationData;
};

export default function Dashboards(props: any) {
  const API_KEY = window.SERVER_DATA.REACT_APP_PM_API_KEY;
  const { user } = useStore();
  const { selectedApplication } = useApplicationContext();

  const classes = useStyles();
  const [backDrop, setBackDrop] = React.useState(true);
  const backDropClose = () => {
    setBackDrop(false);
  };

  const [reload, setReload] = useState(false);
  const [dashboardData, setDashboardData] = useState([] as any);
  // const [selectedIndex, setSelectedIndex] = useState(0);
  let applicationList = {} as any;

  // const GetApplicationList = (data: any) => {
  //   if (data.length) {
  //     applicationList = data.map((obj: any) => {
  //       return {
  //         application_id: obj.application_id,
  //         application_name: obj.application_name,
  //       };
  //     });
  //   }
  // };

  // const selectApplication = (event: any) => {
  //   const selectedValue: number = +event.target.value;
  //   const index = dashboardData.findIndex(
  //     (obj: any) => obj.application_id === selectedValue
  //   );
  //   setSelectedIndex(index);
  // };

  useEffect(() => {
    const PMSId = sessionStorage.getItem("pm_s_id");
    const PMStoken = sessionStorage.getItem("pm_s_token");

    GetDashboard(PMStoken, PMSId);
  }, [user, API_KEY]);

  //TODO: We are pulling entire applications data here all at once. Scope for optimization
  const GetDashboard = async (pmstoken: any, pmsid: any) => {
    if (!pmstoken || !pmsid) {
      return;
    }
    const tokenKey = "sso_token";
    const accessToken = JSON.parse(sessionStorage.getItem(tokenKey) as string);
    setBackDrop(true);
    const DASHBOARDS_API_URL =
      API["DASHBOARDS_END_POINT"] + `?pm_s_token=${pmstoken}&pm_s_id=${pmsid}`;
    try {
      fetch(DASHBOARDS_API_URL, {
        headers: {
          Authorization: `Bearer ${accessToken.access_token}`,
          "Ocp-Apim-Subscription-Key": `${API_KEY}`,
          "Ocp-Apim-Trace": `true`,
        },
      }).then(async (response: any) => {
        response.json().then((response: any) => {
          if (
            response &&
            response.status === 401 &&
            response &&
            arrayOfErrorsRefresh.includes(response.message.toLowerCase())
          ) {
            try {
              const tokenKey = "sso_token";
              setBackDrop(true);
              callXhrRequest()
                .then(function (data) {
                  sessionStorage.setItem(tokenKey, data);
                  GetDashboard(pmstoken, pmsid);
                  setReload(!reload);
                  setBackDrop(false);
                })
                .catch(function (error) {
                  user.triggerLogout();
                  console.log("error", error);
                });
            } catch (error) {
              console.log(error);
            }
            return;
          } else if (
            response &&
            response.status === 401 &&
            response &&
            arrayOfErrorsLogout.includes(response.message.toLowerCase())
          ) {
            try {
              user.triggerLogout();
            } catch (error) {
              console.log(error);
            }
            return;
          } else {
            setDashboardData(response.dashboards);
            setBackDrop(false);
          }
        });
      });
    } catch (error) {
      console.log(error);
      setBackDrop(false);
    }
  };

  let pageView1 = <Backdrop className={classes.backdrop} open={backDrop} />;
  let pageView2 = <Backdrop className={classes.backdrop} open={backDrop} />;

  if (!backDrop && dashboardData !== undefined && dashboardData.length > 0) {
    const selectedData = getSelectedApplicationData(
      dashboardData,
      selectedApplication
    );
    // const selectedData = dashboardData[selectedIndex];
    pageView1 = (
      <div className={classes.dashboard}>
        <DashboardHeader
          data={selectedData.summary}
          applications={applicationList}
          select={() => true} //remove once stable (multi app)
        />
        <HighlightCards data={selectedData.highlight} />
        <StatusCards data={selectedData.status} />
        {/* <div className={classes.thirdrow}>
          <ErrorStatsCard data={selectedData.error_stats.data} />
        </div> */}
      </div>
    );
  } else {
    // GetApplicationList(dashboardData);
    const headerData = [
      {
        application_id: 0,
        application_name: "No Application Found",
        summary: {
          Certificates: 0,
          Gateways: 0,
          Standalones: 0,
          Things: 0,
          "Total Devices": 0,
        },
      },
    ];
    pageView2 = (
      <div className={classes.dashboard}>
        <DashboardHeader
          data={headerData[0].summary}
          applications={headerData}
          select={() => true} //remove once stable (multi app)
        />
        <p style={{ color: "white" }}>Data unavailable/loading.</p>
      </div>
    );
  }
  return (
    <div className={classes.root}>
      {pageView1}
      {pageView2}
      <Backdrop
        className={classes.backdrop}
        open={backDrop}
        onClick={backDropClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
}
