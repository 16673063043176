import React, { useEffect, useState } from "react";
import { Button } from "@material-ui/core";
import { useSnackbar } from "notistack";

import exportLogo from "../../../assets/Export.png";
import { exportToExcel } from "../../../utils/helper";
import { useApplicationContext } from "../../../contexts/ApplicationContext";
import ExportColumnSelectionDialog from "./ExportColumnSelectionDialog";
import useStyles from "./ExportEventsToCSV.styles";
import useApi from "../../../hooks/useApi";
import { LoadingIndicator } from "../";
import { IColumn } from "../DataTable/types";
import { IFilter } from "../MultiFilter/types";

const MAXIMUM_EXPORTABLE_RECORD_COUNT = 20000;
interface ExportToCSVProps {
  columns: IColumn[];
  filter: IFilter[],
  sortingInfo: any;
  endpoint: string;
  dataLookUp: string;
  pageName: string;
  expectedRecordCount: number
}
const ExportToCSV: React.FC<any> = ({
  columns,
  filter,
  sortingInfo,
  endpoint,
  dataLookUp,
  pageName,
  expectedRecordCount,
}: ExportToCSVProps) => {
  const [selectedCols, setSelectedCols]: any[] = useState([]);
  const [showExportDialog, setShowExportDialog] = useState(false);

  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { selectedApplication } = useApplicationContext();
  const url = `/applications/${selectedApplication.application_id}/${endpoint}`;

  const { data, status, trigger } = useApi(url, {
    deferred: true,
    method: "GET",
  })

  function exportDataFunction(rows: any) {
    const csvData = (rows || []).map((dlmMessageData: any) => {
      const csvRow = selectedCols?.reduce(
        (acc: any, { id, label, resolver }: any) => {
          return {
            ...acc,
            [label]: resolver ? resolver(dlmMessageData) : dlmMessageData[id],
          };
        },
        {}
      );
      return csvRow;
    });
    const { application_name } = selectedApplication;
    exportToExcel(csvData, pageName, 9, application_name); //TODO: Why 9?
  }

  useEffect(() => {
    if (data && data[dataLookUp]) {
      exportDataFunction(data[dataLookUp])
    }
  }, [data])

  return (
    <>
      {status.pending && <LoadingIndicator />}
      <Button
        variant="text"
        color="inherit"
        className={classes.listButton}
        startIcon={<img alt="Export Logo" src={exportLogo}></img>}
        onClick={() => {
          if (expectedRecordCount > MAXIMUM_EXPORTABLE_RECORD_COUNT) {
            enqueueSnackbar(
              `Export limit is set to ${MAXIMUM_EXPORTABLE_RECORD_COUNT} records. Please narrow your search window to reduce record count.`,
              { variant: "info" }
            );
            return;
          }
          setShowExportDialog(true);
        }}
      >
        Export
      </Button>
      {showExportDialog && (
        <ExportColumnSelectionDialog
          columns={columns}
          onClose={() => setShowExportDialog(false)}
          onSelect={(selectedColumns: any[]) => {
            setSelectedCols(selectedColumns)
            trigger({
              filter,
              ...sortingInfo
            });
            setShowExportDialog(false);
            enqueueSnackbar("Export Initiated", { variant: "success" });
          }}
        />
      )}
    </>
  );
};

export default ExportToCSV;
