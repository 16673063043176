import React from "react";
import { useHistory } from "react-router-dom";
import AddDevicePage from "./AddDevicesPage";

/**
 TODO: We have copied the entire AddDevice code from old ShowDevice as is.
 */

const AddDevice: React.FC<any> = () => {
  const history = useHistory();
  return (
    <AddDevicePage
      returnHome={() => {
        history.push("/");
      }}
    />
  );
};

export default AddDevice;
