import React from "react";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import InputLabel from "@material-ui/core/InputLabel";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import Grid from "@material-ui/core/Grid";
import DateRangeIcon from "@material-ui/icons/DateRange";

const DateTextField = withStyles({
  root: {
    "& input": {
      padding: "10px",
      color: "#ffffff",
    },
    "& .MuiInput-root": {
      border: "1px solid #464659",
      marginTop: "32px",
      backgroundColor: "#1D1D25",
      borderRadius: "2px",
      boxSizing: "border-box",
      padding: "2px",
    },
    "& label.Mui-focused": {
      color: "#ffffff",
    },
    "& .MuiIconButton-root": {
      color: "#ffffff",
    },
  },
})(KeyboardDatePicker);

type TypeAddNewUserStepTwoProp = {
  classes: { [key: string]: string };
  dialogHandleDelete: () => void;
  onChangeRoles: (e: any) => void;
  showDialog: () => void;
  onValidToDateChange: (date: Date | null) => void;
  onValidFromDateChange: (date: Date | null) => void;
  currentApp: any;
  dateValidTo: any;
  dateValidFrom: any;
  userChoices: any;
  roles: any;
  applicationLevels: any
};

export default function AddNewUserStepTwo({
  classes,
  currentApp,
  onChangeRoles,
  showDialog,
  dateValidTo,
  userChoices,
  roles,
  onValidToDateChange,
  onValidFromDateChange,
  dateValidFrom,
  applicationLevels
}: TypeAddNewUserStepTwoProp) {
  const today = new Date();
  return (
    <div className={classes.space2Top}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Grid container>
          <Grid item xs={6} className={classes.spaceLeftSite}>
            <div
              className={classes.rightSec}
              style={{ justifyContent: "left" }}
            >
              <InputLabel
                classes={{ root: classes.labels }}
                htmlFor="selectRoleValue"
                style={{ marginTop: "-27px" }}
              >
                Levels
              </InputLabel>
              <Grid container direction='column'>
                {applicationLevels.map((element: any) => {
                  return (<Typography
                    className="dcFieldValue"
                    style={{ marginLeft: "-3em" }}
                  >
                    {element}
                  </Typography>)
                })}
              </Grid>


            </div>
          </Grid>
          <Grid item xs={4} className={classes.spaceLeft}>
            <div className={classes.fieldWrapper}>
              <InputLabel
                classes={{ root: classes.labels }}
                htmlFor="selectRoleValue"
              >
                Role *
              </InputLabel>
              <TextField
                className={classes.textfield}
                fullWidth
                select
                variant="outlined"
                classes={{ root: classes.fieldRoot }}
                inputProps={{ className: classes.field }}
                value={roles}
                id="selectRole"
                SelectProps={{
                  classes: {
                    iconOutlined: classes.icon,
                  },
                }}
                onChange={onChangeRoles}
                required
              >
                {Array.isArray(currentApp.roles) &&
                  currentApp.roles.map((option: any) => (
                    <option
                      className={classes.options}
                      key={option.role_name}
                      value={option.role_id}
                    >
                      {option.role_name}
                    </option>
                  ))}
              </TextField>
            </div>
          </Grid>
          <Grid item xs={2} className={classes.spaceLeft}>
            <div className={classes.fieldWrapper}>
              <Button
                variant="outlined"
                className={classes.vp}
                onClick={showDialog}
                disabled={!Array.isArray(currentApp.roles)}
              >
                View Permissions
              </Button>
            </div>
          </Grid>
          <Grid item xs={6} className={classes.spaceLeft}>
            <DateTextField
              autoOk={true}
              fullWidth
              placeholder="YYYY-MM-DD"
              variant="inline"
              format="yyyy-MM-dd"
              margin="normal"
              id="valid-from"
              label="Valid From"
              value={dateValidFrom}
              minDate={today}
              inputProps={{ className: classes.field }}
              className={classes.fieldWrapper}
              InputLabelProps={{
                className: classes.labels,
              }}
              PopoverProps={{
                classes: {
                  paper: classes.datePickerRoot,
                },
              }}
              onChange={onValidFromDateChange}
              keyboardIcon={<DateRangeIcon />}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
              required
            />
          </Grid>
          <Grid item xs={6} className={classes.spaceLeft}>
            <DateTextField
              autoOk
              fullWidth
              variant="inline"
              placeholder="YYYY-MM-DD"
              minDate={today}
              format="yyyy-MM-dd"
              margin="normal"
              id="valid-to"
              label="Valid To"
              value={dateValidTo}
              inputProps={{ className: classes.field }}
              className={classes.fieldWrapper}
              InputLabelProps={{
                className: classes.labels,
              }}
              PopoverProps={{
                classes: {
                  paper: classes.datePickerRoot,
                },
              }}
              onChange={onValidToDateChange}
              keyboardIcon={<DateRangeIcon />}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
              required
            />
          </Grid>
        </Grid>
      </MuiPickersUtilsProvider>
    </div>
  );
}
