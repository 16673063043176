import React from 'react';

interface FormatNumberProp {
    number: number;
    classes?: string;
    type?: string;
}

function FormatNumber({ number, type, classes }: FormatNumberProp) {
    if (Number.isNaN(number)) return(<span>{number}</span>)
    const defaultType = "us";
    const formatTypes = {
        indian:"en-IN",
        us:'en-US'
    }
    const formatType = type? formatTypes[type]: formatTypes[defaultType];
    //style can be decimal, percent, unit, currency etc...
    //[todo] can be modify this component to accomodate the above style too.
    return (
        <span className={classes}>
            {new Intl.NumberFormat(formatType, {style:'decimal'}).format(number)}
        </span>
    )
}

export default FormatNumber