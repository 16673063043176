import { CSV_COLUMNS } from "../constants";
import getKeysFromEnum from "./getKeysFromEnums";

const extractors = {
  [CSV_COLUMNS.tags]: (record: any) => {
    const stringifiedTag = (record[CSV_COLUMNS.tags] || "").trim();
    if (!stringifiedTag) {
      return {};
    }
    return JSON.parse(stringifiedTag);
  },
};

const getDefaultExtractor = (columnName: string) => {
  return (record: any) => record[CSV_COLUMNS[columnName]] || "";
};

const extractColumnData = (columnName: string, record: any) => {
  const extractor =
    extractors[CSV_COLUMNS[columnName]] || getDefaultExtractor(columnName);
  return extractor(record);
};

const extractData = (fileData: any) => {
  const [_, ...records] = fileData; //first row is header
  const deviceRecords = records.map((record: any) => {
    const columns = getKeysFromEnum(CSV_COLUMNS);
    let deviceRecord = {};
    columns.forEach((columnName) => {
      deviceRecord = {
        ...deviceRecord,
        [columnName]: extractColumnData(columnName, record),
      };
    });
    return deviceRecord;
  });
  return deviceRecords;
};

export default extractData;
