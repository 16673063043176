import React, { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import { Button, IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import DragIndicatorIcon from "@material-ui/icons/DragIndicator";
import { Container, Draggable } from "react-smooth-dnd";

import {
  CustomButton,
  LoadingIndicator,
  Modal,
  SubmitButton,
  AutoComplete,
} from "../../../../UiComponents";
import useApi from "../../../../../hooks/useApi";
import { useApplicationContext } from "../../../../../contexts/ApplicationContext";

import useStyles from "./CustomizeColumnsModal.styles";
import { sortFunction } from "../../../../../utils/arrayFunctions";

const DragIndicator: React.FC<any> = () => {
  return (
    <IconButton
      edge="start"
      color="inherit"
      aria-label="drag"
      className="customize-column-drag-handle"
    >
      <DragIndicatorIcon />
    </IconButton>
  );
};

const CancelButton: React.FC<any> = ({ onClick }) => {
  return (
    <IconButton
      style={{ marginLeft: "2px" }}
      edge="start"
      color="inherit"
      onClick={onClick}
      aria-label="close"
    >
      <CloseIcon />
    </IconButton>
  );
};

const Columns: React.FC<any> = ({ columns, onRemove, onReplace, onSwap }) => {
  const classes = useStyles();
  const selectedColumns = columns.filter(
    ({ is_selected, is_default }: any) => is_selected || is_default
  );

  return (
    <div>
      <Container
        dragHandleSelector=".customize-column-drag-handle"
        lockAxis="y"
        onDrop={({ removedIndex, addedIndex }: any) => {
          const addedColumn = selectedColumns[addedIndex];
          const removedColumn = selectedColumns[removedIndex];
          onSwap(addedColumn, removedColumn);
        }}
        style={{ overflow: "visible" }}
      >
        {selectedColumns.map((column: any, index: number) => {
          return (
            <Draggable key={column.column_name}>
              <div className={classes.columnInfoHolder}>
                <DragIndicator />
                <div className={classes.sequenceNumber}>{index + 1}</div>
                <ColumnSelector
                  columns={columns}
                  selectedValue={column}
                  onReplace={onReplace}
                  onSelect={() => true}
                />
                {column.is_default ? (
                  <div style={{ width: "50px" }} />
                ) : (
                  <CancelButton onClick={() => onRemove(column)} />
                )}
              </div>
            </Draggable>
          );
        })}
      </Container>
    </div>
  );
};

const ColumnSelector: React.FC<any> = ({
  columns,
  selectedValue,
  onSelect,
  onReplace,
}) => {
  const options = [
    ...columns.filter(
      ({ is_selected, is_default }: any) => !is_selected && !is_default
    ),
    ...(selectedValue ? [selectedValue] : []),
  ];

  const sortedOptions = sortFunction(options,'strAsc','column_label');

  return (
    <AutoComplete
      label=""
      getOptionLabel={(option: any) => option.column_label}
      options={sortedOptions}
      onSelect={(value: any) =>
        selectedValue ? onReplace(value, selectedValue) : onSelect(value)
      }
      value={selectedValue}
      disabled={selectedValue?.is_default}
    />
  );
};

const AddNewColumn: React.FC<any> = ({ columns, onAdd }) => {
  const [showColumnSelector, setShowColumnSelector] = useState(false);
  const classes = useStyles();
  return (
    <div className={classes.addColumnHolder}>
      {showColumnSelector ? (
        <>
          <ColumnSelector
            columns={columns}
            onSelect={(selectedColumn: any) => {
              onAdd(selectedColumn);
              setShowColumnSelector(false);
            }}
            onCancel={() => setShowColumnSelector(false)}
          />
          <CancelButton onClick={() => setShowColumnSelector(false)} />
        </>
      ) : (
        <div
          className={classes.addColumnButton}
          role="button"
          onClick={() => setShowColumnSelector(true)}
        >
          + Add Column
        </div>
      )}
    </div>
  );
};

const compileUserPreferenceData = (columns: any[]) => {
  const selectedColumns = columns.filter(({ is_selected }: any) => is_selected);
  const preferenceData = selectedColumns.map((column: any, index) => {
    return {
      device_metadata_options_id: column.device_metadata_id,
      sequence: index + 1,
      user_preference_id: column.user_preferences_device_metadata_id,
    };
  });
  return preferenceData;
};

const SavePreferenceCTA: React.FC<any> = ({ onDone, columns }) => {
  const { selectedApplication } = useApplicationContext();
  const { enqueueSnackbar } = useSnackbar();
  const {
    data: result,
    trigger: saveUserPreferences,
    status,
  } = useApi(
    `/applications/${selectedApplication.application_id}/user_preferred_column`,
    { deferred: true, method: "PUT" }
  );

  const userPreferenceData = compileUserPreferenceData(columns);

  useEffect(() => {
    if (result?.message === "Success") {
      enqueueSnackbar("Column preference updated successfully", {
        variant: "success",
      });
      onDone();
    }
    if (!status.pending && status.error) {
      enqueueSnackbar("An error occurred while updating the column preference", {
        variant: "error",
      });
    }
  }, [result?.message, status.error, status.pending]);

  return (
    <SubmitButton
      disabled={false}
      onClick={() => {
        saveUserPreferences({
          user_preferences: userPreferenceData,
          page_name: "DEV_MGMT",
        });
      }}
    >
      Save
    </SubmitButton>
  );
};

const CustomizeColumnsModal: React.FC<any> = ({ onDone, onCancel }) => {
  const [columns, setColumns] = useState<any[]>([]);
  const { selectedApplication } = useApplicationContext();
  const classes = useStyles();
  /*
https://qa-pm.northeurope.cloudapp.azure.com/api/v1d/applications/1002/user_preferred_column?pm_s_id=33703&pm_s_token=952292b7eeec274afc4166c92d3fe9ff362024fcc6f2aaec21b140223c06ed8f&page_name=DEV_MGMT
  */
  useApi(
    `/applications/${selectedApplication.application_id}/user_preferred_column`,
    {
      method: "GET",
      data: {
        page_name: "DEV_MGMT",
      },
      //mock: { fetcher: () => mockApi.getPreferences() },
      responseDataFormatter: (data: any) => {
        let newSequenceNumber = 1;
        const columnList = data.column_list.map((column: any) => ({
          ...column,
          newSequenceNumber:
            column.is_default || column.is_selected
              ? newSequenceNumber++
              : 1000,
          is_selected: column.is_default || column.is_selected,
        }));
        setColumns(columnList);
      },
    }
  );

  if (columns.length === 0) {
    return <LoadingIndicator />;
  }

  return (
    <Modal
      title="Customise Columns"
      open={true}
      onClose={() => onDone()}
      contentWrapperStyle={{ margin: "0px" }}
    >
      <div className={classes.CustomizeColumn}>
        <Columns
          columns={columns}
          onRemove={(removedColumn: any) => {
            const updatedColumns = columns.map((column: any) => {
              return column.column_name === removedColumn.column_name
                ? { ...column, is_selected: false, newSequenceNumber: 1000 }
                : column;
            });
            setColumns(updatedColumns);
          }}
          onReplace={(addedColumn: any, removedColumn: any) => {
            if (!addedColumn) {
              return true;
            }

            //swap two columns by altering selected status
            const updatedColumns = columns.map((column: any) => {
              if (column.column_name === removedColumn.column_name) {
                return {
                  ...addedColumn,
                  is_selected: true,
                  newSequenceNumber: removedColumn.newSequenceNumber,
                };
              }
              if (column.column_name === addedColumn.column_name) {
                return {
                  ...removedColumn,
                  is_selected: false,
                  newSequenceNumber: 1000,
                };
              }
              return column;
            });
            setColumns(updatedColumns);
          }}
          onSwap={(addedColumn: any, removedColumn: any) => {
            const updatedColumns = columns.map((column: any) => {
              if (column.column_name === addedColumn.column_name) {
                return {
                  ...removedColumn,
                  newSequenceNumber: addedColumn.newSequenceNumber,
                };
              }
              if (column.column_name === removedColumn.column_name) {
                return {
                  ...addedColumn,
                  newSequenceNumber: removedColumn.newSequenceNumber,
                };
              }
              return column;
            });

            setColumns(updatedColumns);
          }}
        />

        <AddNewColumn
          columns={columns}
          onAdd={(addedColumn: any) => {
            const numberOfSelectedColumns = columns.filter(
              ({ is_selected }: any) => is_selected
            ).length;

            const updatedColumns = columns.map((column: any) =>
              column.column_name === addedColumn.column_name
                ? {
                    ...column,
                    is_selected: true,
                    newSequenceNumber: numberOfSelectedColumns + 1,
                  }
                : column
            );

            // @ts-ignore
            const sortedColumns = updatedColumns.toSorted(
              (c1: any, c2: any) => {
                return c1.newSequenceNumber - c2.newSequenceNumber;
              }
            );

            setColumns(sortedColumns);
          }}
        />
      </div>
      <div className={classes.buttonHolder}>
        <CustomButton variant="outlined-white" onClick={onCancel}>
          Cancel
        </CustomButton>
        <SavePreferenceCTA onDone={onDone} columns={columns} />
      </div>
    </Modal>
  );
};

export default CustomizeColumnsModal;
