import { useState } from "react";

const useGps = () => {
  const [gpsCrd, setGpsCrd] = useState<any>(null);
  const [status, setStatus] = useState({ pending: false, error: "" });

  const success = (pos: any) => {
    setGpsCrd(pos.coords);
    setStatus({ pending: false, error: "" });
  };

  const error = () => {
    setStatus({
      pending: false,
      error: "Unable to track location",
    });
  };

  const capturePosition = () => {
    setStatus({ pending: true, error: "" });
    setGpsCrd(null);
    navigator.geolocation.getCurrentPosition(success, error, {
      enableHighAccuracy: true,
      maximumAge: 0,
    });
  };

  return { gpsCrd, status, capturePosition };
};

export default useGps;
