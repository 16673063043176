import React, { useState } from "react";
import {
  CustomButton,
  Modal,
  ButtonText,
  SubmitButton,
} from "../../../../UiComponents";

import PropertyForm from "./PropertyForm";
import useStyles from "./AddNewProperties.styles";
import { useSnackbar } from "notistack";
import readError from "../../../../../utils/readError";
import {
  convertToObject,
  hasDuplicatePropertyEntries,
  remove,
  replace,
} from "./utils";

const AddNewPropertyDialog: React.FC<any> = ({ onPropertyAdd, onClose }) => {
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const [properties, setProperties] = useState([{ name: "", value: "" }]);

  return (
    <Modal
      open={true}
      title="Add New Property"
      onClose={onClose}
      disableBackdropClick
    >
      {properties.map((property: any, index: number) => {
        return (
          <PropertyForm
            key={index}
            {...property}
            onChange={(propName: string) => {
              const updatedProperty = { name: propName, value: "" };
              setProperties(replace(properties, index, updatedProperty));
            }}
            onRemove={() => setProperties(remove(properties, index))}
            disableDelete={index === 0 && properties.length === 1}
          />
        );
      })}
      <ButtonText
        style={{ margin: "-1.2em 0 0 1em" }}
        label="Add New"
        onClick={() => setProperties([...properties, { name: "", value: "" }])}
      />

      <div className={classes.buttonHolder}>
        <CustomButton variant="outlined-white" onClick={onClose}>
          Cancel
        </CustomButton>
        <SubmitButton
          onClick={() => {
            try {
              hasDuplicatePropertyEntries(properties);
              onPropertyAdd(convertToObject(properties));
              onClose();
            } catch (error) {
              const errorMessage = readError(error);
              enqueueSnackbar(errorMessage, { variant: "error" });
            }
          }}
        >
          Save
        </SubmitButton>
      </div>
    </Modal>
  );
};

const AddNewProperty: React.FC<any> = ({ onPropertyAdd }) => {
  const [showAddModal, setShowAddModal] = useState(false);
  return (
    <>
      <ButtonText
        style={{ margin: "-1.2em 0 0 1em" }}
        label="Add New Property"
        onClick={() => setShowAddModal(true)}
      />
      {showAddModal && (
        <AddNewPropertyDialog
          onPropertyAdd={onPropertyAdd}
          onClose={() => setShowAddModal(false)}
        />
      )}
    </>
  );
};

export default AddNewProperty;
