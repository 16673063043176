import React, { useState } from "react";
import {
  CustomButton,
  DropDown,
  SubmitButton,
  Modal,
  ButtonText,
} from "../../../../UiComponents";
import useStyles from "./AddNewDropdownSpecifications.styles";
import { Typography } from "@material-ui/core";
import readError from "../../../../../utils/readError";
import { useSnackbar } from "notistack";
import { hasDuplicateColumnEntries, isValidColumnFormat } from "../utils";
import { CONFIG_PAGE } from "../../types";

const extractTabularTags = (tags: any) => {
  const tagArray = Object.keys(tags).map((tagName: string) => ({
    name: tagName,
    ...tags[tagName],
  }));
  return tagArray.filter((tag) => tag.type === "Tabular" && tag._isNew);
};

interface IAddNewDropdownSpecificationProps {
  tags: any;
  onDropdownSpecificationAdd: (
    selectedTabularTag: any,
    selectedColumns: any
  ) => void;
  page: CONFIG_PAGE;
}

const AddNewDropdownSpecifications: React.FC<
  IAddNewDropdownSpecificationProps
> = ({ tags, page, onDropdownSpecificationAdd }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [showModal, setShowModal] = useState(false);
  const [selectedTabularTag, setSelectedTabularTag] = useState("");
  const [selectedColumn, setSelectedColumn] = useState("");
  const classes = useStyles();
  const tabularTags = extractTabularTags(tags);
  const hasTabularTags = tabularTags.length > 0;

  const closeAndResetForm = () => {
    setSelectedTabularTag("");
    setSelectedColumn("");
    setShowModal(false);
  };

  const columns = tags[selectedTabularTag]?.value || "";
  const hasDuplicateColumns = hasDuplicateColumnEntries(columns);
  const columnsSelectable = isValidColumnFormat(columns) || hasDuplicateColumns;

  const isEdit = page === CONFIG_PAGE.EDIT_CONFIG;

  return (
    <>
      <ButtonText
        style={{ margin: "-1.2em 0 0 1em" }}
        label="Add New Dropdown Specifications"
        onClick={() => setShowModal(true)}
      />
      <Modal
        open={showModal}
        title="Add New Dropdown Specifications"
        onClose={closeAndResetForm}
      >
        <div className={classes.wrapper}>
          {!hasTabularTags && (
            <div style={{ margin: "1em 1em 2em 1em" }}>
              <Typography style={{ margin: "1em 0" }}>
                No Tabular tags found!
              </Typography>
              {isEdit && (
                <Typography style={{ fontSize: "14px" }}>
                  You can add dropdown specifications to newly added tags only{" "}
                </Typography>
              )}
            </div>
          )}
          {hasTabularTags && (
            <>
              <DropDown
                options={tabularTags.map(({ name }) => ({
                  label: name,
                  value: name,
                }))}
                label="Tabular Tag"
                style={{ marginLeft: "0px" }}
                value={selectedTabularTag}
                onChange={(v) => setSelectedTabularTag(v)}
              />

              <DropDown
                disabled={!columnsSelectable}
                options={columns
                  .split("|")
                  .map((c: string) => ({ label: c, value: c }))}
                label="Select Column"
                value={selectedColumn}
                onChange={(v) => setSelectedColumn(v)}
              />
            </>
          )}
        </div>
        {selectedTabularTag && !columns && (
          <div>Columns not entered. Please correct and try again</div>
        )}
        {selectedTabularTag && columns && !columnsSelectable && (
          <div>Invalid column format. Please correct and try again</div>
        )}
        {selectedTabularTag && hasDuplicateColumns && (
          <div>Duplicate column names found. Please correct and try again</div>
        )}
        <div className={classes.buttonHolder}>
          <CustomButton variant="outlined-white" onClick={closeAndResetForm}>
            Cancel
          </CustomButton>

          <SubmitButton
            disabled={!(selectedTabularTag && selectedColumn)}
            onClick={() => {
              try {
                onDropdownSpecificationAdd(selectedTabularTag, selectedColumn);
                closeAndResetForm();
              } catch (er) {
                const errorMessage = readError(er);
                enqueueSnackbar(errorMessage, { variant: "error" });
              }
            }}
          >
            Save
          </SubmitButton>
        </div>
      </Modal>
    </>
  );
};

export default AddNewDropdownSpecifications;
