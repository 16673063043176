import { Button } from "@material-ui/core";
import React, { useState } from "react";
import {
  FormGroup,
  FormControl,
  FormControlLabel,
  Checkbox,
  Divider,
  DialogActions,
} from "@material-ui/core";

import { Modal } from "../../../UiComponents";

import { addOrRemove } from "../../../../utils/arrayFunctions";

import useStyles from "./ExportToCSV.styles";

const ExportColumnSelectionDialog: React.FC<any> = ({
  onClose,
  onSelect,
  columns,
}) => {
  const classes = useStyles();
  const [selectedColumns, setSelectedColumns] = useState<any[]>(
    columns.map(({ id }: any) => id)
  );
  return (
    <Modal open={true} title="Select columns to export" onClose={onClose}>
      <FormControl
        style={{ width: "400px" }}
        component="fieldset"
        className={classes.formControl}
      >
        <FormGroup>
          {columns.map(({ label, id }: any) => {
            const checked = selectedColumns.includes(id);
            return (
              <FormControlLabel
                key={id}
                control={
                  <Checkbox
                    style={{ color: "#0089ff" }}
                    color="primary"
                    checked={checked}
                    onChange={() =>
                      setSelectedColumns(addOrRemove(selectedColumns, id))
                    }
                    name="application_level"
                  />
                }
                style={{ color: "white" }}
                label={label}
              />
            );
          })}
        </FormGroup>
      </FormControl>
      <Divider variant="middle" classes={{ middle: classes.dividerColor }} />
      <DialogActions
        style={{ justifyContent: "space-between", marginBottom: "5px" }}
      >
        <Button
          onClick={onClose}
          variant="outlined"
          className={classes.dcBtDelete}
        >
          Cancel
        </Button>
        <Button
          onClick={() =>
            onSelect(
              columns.filter(({ id }: any) => selectedColumns.includes(id))
            )
          }
          variant="contained"
          className={classes.dcBtEdit}
          disabled={selectedColumns.length < 1}
        >
          Export
        </Button>
      </DialogActions>
    </Modal>
  );
};

export default ExportColumnSelectionDialog;
