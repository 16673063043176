import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";

const shadow_style = "0px 30px 100px #000000";
const border_style = "1px solid rgba(235,235,245,0.35)";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      position: "relative",
    },
    title: {
      marginLeft: theme.spacing(2),
      flex: 1,
    },
    headerStyle: {
      fontStyle: "normal",
      fontFamily: "Avenir Next",
      fontWeight: "bold",
      fontSize: "20px",
      display: "flex",
      lineHeight: "27px",
      color: "#FFFFFF",
    },
    preview: {
      width: "210px",
      border: "1px dashed rgba(255, 255, 255, 0.2)",
      height: "118px",
      display: "flex",
      background: "rgba(0, 0, 0, 0.11)",
      alignItems: "center",
      justifyContent: "center",
    },

    hidden: {
      display: "none",
    },
    gridRoot: {
      flexGrow: 1,
      padding: "0px 24px",
      backgroundColor: "#2B2B36",
    },
    root: {
      width: "100%",
      maxWidth: 360,
      backgroundColor: theme.palette.background.paper,
    },
    flexContainer: {
      display: "flex",
      flexDirection: "row",
    },
    listButtonWrap: {
      marginLeft: "20px",
    },
    listItem: {
      width: "auto",
      padding: "0px",
      marginRight: "20px",
      fontSize: "16px",
      lineHeight: "19px",
      color: "#FFFFFF",
    },
    listItemText: {
      fontSize: "16px",
      lineHeight: "19px",
    },
    tableWrapper: {
      padding: "0 20px",
      marginTop: "15px",
      boxShadow: "none",
    },
    tableWrapper2: {
      marginTop: "15px",
      boxShadow: "none",
    },
    formControl: {
      margin: "0 25px",
    },
    tableWrapper1: {
      padding: "0 20px",
      marginTop: "15px",
      boxShadow: "none",
      overflow: "hidden",
    },
    table1: {
      minWidth: "100%",
      maxWidth: "100%",
    },
    dividerColor: {
      background: "#464659",
      margin: "1em",
    },
    table: {
      minWidth: "100%",
      maxWidth: "100%",
    },
    listButton: {
      padding: "0px",
      textTransform: "none",
      fontSize: "14px",
      whiteSpace: "nowrap",
    },
    rightSec: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-end",
      whiteSpace: "nowrap",
      padding: "5px 0px",
      fontSize: "12px",
      lineHeight: "14px",
      "& p": {
        fontSize: "12px",
        padding: "11px 0px",
        marginRight: "30px",
      },
      "& button": {
        background: "#0089FF",
        borderRadius: "2px",
        fontSize: "16px",
        color: "#FCFCFC",
        padding: "6px 20px",
        marginRight: "10px",
        "&:hover": {
          background: "#0089FF",
        },
      },
    },
    menuButtonRoot: {
      padding: "0px",
    },
    viewDetails: {
      color: "#0089FF !important",
    },
    menuRoot: {
      backgroundColor: "#1D1D25",
      border: "1px solid #33333F",
      boxShadow: "0px 4px 4px rgba(0,0,0,0.25)",
      borderRadius: "2px",
    },
    cardTitle: {
      fontSize: "18px",
      fontWeight: "bold",
      lineHeight: "21px",
      color: "#FFFFFF",
      marginBottom: "16px",
    },
    cardText: {
      fontSize: "16px",
      lineHeight: "19px",
      color: "#FFFFFF",
      marginBottom: "10px",
    },
    CardStyle: {
      background: "#2B2B36",
      borderRadius: "3px",
    },
    cardGrid: {
      marginBottom: "20px",
    },
    menuitem: {
      color: "white",
      fontSize: "14px",
      paddingRight: "40px",
      "&:hover": {
        color: "#0089FF !important",
      },
      "& svg": {
        marginRight: "8px",
      },
    },
    dialogRoot: {
      boxShadow: shadow_style,
      borderRadius: "3px",
      maxWidth: "850px",
      minWidth: "850px",
      "& .dcTitle.dcHeader": {
        marginTop: "0px",
      },
      "& .dcTitle": {
        fontSize: "16px",
        lineHeight: "19px",
        color: "#FFFFFF",
        fontWeight: "bold",
        marginTop: "25px",
      },
      "& .dcOuter": {
        background: "#2B2B36",
        borderRadius: "4px",
        padding: "15px",
      },
      "& .dcFieldValue": {
        fontSize: "14px",
        lineHeight: "16px",
        marginTop: "10px",
        color: "#FFFFFF",
        marginBottom: "25px",
        overflowWrap: "anywhere",
        paddingRight: ".5em",
      },
      "& .dcSep": {
        marginTop: "20px",
      },
      "& .dcBtDelete": {
        borderRadius: "2px",
        border: border_style,
        color: "#0089FF",
        background: "none",
      },
      "& .dcBtEdit": {
        backgroundColor: "#0089ff",
        borderRadius: "4px",
        border: "none",
        color: "#FFFFFF",
      },
    },
    dialogRoot1: {
      backgroundColor: "#2B2B36",
      boxShadow: shadow_style,
      borderRadius: "3px",
      maxWidth: "1200px",
      "& .dcTitle.dcHeader": {
        marginTop: "0px",
      },
      "& .dcTitle": {
        fontSize: "16px",
        lineHeight: "19px",
        color: "#FFFFFF",
        fontWeight: "bold",
        marginTop: "25px",
      },
      "& .dcOuter": {
        background: "#2B2B36",
        borderRadius: "4px",
        padding: "15px",
      },
      "& .dcFieldValue": {
        fontSize: "14px",
        lineHeight: "16px",
        marginTop: "10px",
        color: "#FFFFFF",
        marginBottom: "25px",
        overflowWrap: "anywhere",
        paddingRight: ".5em",
      },
      "& .dcSep": {
        marginTop: "20px",
      },
      "& .dcBtDelete": {
        borderRadius: "2px",
        border: border_style,
        color: "#0089FF",
        background: "none",
      },
      "& .dcBtEdit": {
        backgroundColor: "#0089ff",
        borderRadius: "4px",
        border: "none",
        color: "#FFFFFF",
      },
    },
    dialogRootSmall: {
      boxShadow: shadow_style,
      borderRadius: "3px",
      maxWidth: "800px",
      position: "absolute",
      top: "10%",
      minWidth: "416px",
      "& .dcTitle.dcHeader": {
        marginTop: "0px",
      },
      "& .dcTitle": {
        fontSize: "16px",
        lineHeight: "19px",
        color: "#FFFFFF",
        fontWeight: "bold",
        marginTop: "25px",
      },
      "& .dcOuter": {
        background: "#2B2B36",
        borderRadius: "4px",
        padding: "15px",
      },
      "& .dcFieldValue": {
        fontSize: "14px",
        lineHeight: "16px",
        marginTop: "10px",
        color: "#FFFFFF",
        overflowWrap: "anywhere",
        paddingRight: ".5em",
      },
      "& .dcSep": {
        marginTop: "20px",
      },
      "& .dcBtDelete": {
        borderRadius: "2px",
        border: border_style,
        color: "#0089FF",
        background: "none",
      },
      "& .dcBtEdit": {
        backgroundColor: "#0089ff",
        borderRadius: "4px",
        border: "none",
        color: "#FFFFFF",
      },
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: "#fff",
    },
    bgColorBlue: {
      color: "#1976d2 !important",
    },
    spaceRight: {
      marginRight: "1em",
      paddingRight: "2em",
    },
    margin: {
      margin: theme.spacing(1),
    },
    textField: {
      width: "16ch",
      color: "#fff",
      padding: "3px 10px",
      border: "1px solid gray",
    },
    label: {
      fontSize: "18px",
      lineHeight: "21px",
      marginRight: "18px",
      color: "#FFF",
    },
    dropdown: {
      width: "207px",
      height: "40px",
      background: "transparent",
      padding: "10px",
      color: "#FFFFFF",
      border: "1px solid #4CA5FF",
      font: "400 13.3333px 'Avenir Next'",
      borderRadius: "2px",
      marginRight: "30px",
      "& option": {
        background: "#1D1D25",
      },
    },
    alignCentre: {
      alignItems: "center",
      marginTop: "0.5rem",
    },
    labels: {
      color: "#fcfcfc",
      lineHeight: "16px",
      fontSize: "14px",
      transform: "scale(1)",
    },
    tblpaginate: {
      color: "#fff",
    },
    tblfooter: {
      position: "absolute",
    },
    tblfooter1: {
      position: "relative",
      padding: "10px 0",
      display: "flex",
      alignItems: "center",
      justify: "space-between",
    },
    tblpaginatenumber: {
      color: "#fff",
    },
    paginateUser: {
      color: "#fff",
    },
    paginateView: {
      color: "#fff",
    },
    noSpace: {
      padding: "0px",
      margin: "0px",
    },
    spaceTop: {
      marginTop: "20px",
    },
    expireBlock: {
      display: "flex",
      zIndex: 99,
      flexDirection: "row",
      position: "fixed",
      right: "20px",
      padding: "0px 10px",
      background: "#464659",
      borderRadius: "2px",
      color: "#fff",
      borderLeft: "4px solid #FDB840",
      boxShadow: " 0px 0px 2px rgb(0 0 0 / 10%), 0px 2px 5px rgb(0 0 0 / 10%)",
      alignItems: "center",
    },
    expireBtn: {
      width: "63px",
      height: "32px",
      margin: "0px 20px",
      background: "#0089FF",
      border: "1px solid rgba(0, 137, 255, 0.15)",
      borderRadius: "2px",
    },
    errorDisplay: {
      display: "flex",
      height: "100vh",
      alignItems: "center",
      justifyContent: "center",
      fontSize: "1.5em",
      color: "white",
    },
  })
);
