import { types, Instance, SnapshotIn, SnapshotOut } from "mobx-state-tree";

const SSOConfig = types.model("SSOConfig", {
  host: types.string,
  client_id: types.string,
  scope: types.string,
  login_redirect: types.string,
});

export default SSOConfig;
export interface ISSOConfig extends Instance<typeof SSOConfig> {}
export interface ISSOConfigSnapshotIn extends SnapshotIn<typeof SSOConfig> {}
export interface ISSOConfigSnapshotOut extends SnapshotOut<typeof SSOConfig> {}
