import { CSV_COLUMNS, BULK_UPDATE_RECORD_LIMIT } from "../constants";
import getKeysFromEnum from "./getKeysFromEnums";
import { installationStatusDropDownOptions } from "../../../../../../utils/constant";

//TODO: Pull from consts
const letterNumberID = /^[0-9a-zA-Z_-]*$/;

const checkHeaders = (headerRow: any[] = []) => {
  const columns = getKeysFromEnum(CSV_COLUMNS);
  if (
    columns.some((key) => !(headerRow[CSV_COLUMNS[key]] || "").includes(key))
  ) {
    throw new Error("Headers should be in correct order");
  }
};

const checkForDuplicateRecords = (records: any) => {
  const [_, ...deviceRecords] = records;
  const deviceIds = deviceRecords.map(
    (record: any) => record[CSV_COLUMNS.device_id]
  );

  if (new Set(deviceIds).size !== deviceIds.length) {
    throw new Error("Duplicate records found in CSV");
  }
};

const parseTagsFromRecord = (record: any, index: number) => {
  const stringifiedTag = (record[CSV_COLUMNS.tags] || "").trim();
  if (!stringifiedTag) {
    return "";
  }
  try {
    return JSON.parse(stringifiedTag);
  } catch (error) {
    throw new Error(`Invalid tag at row ${index}. Value is not a JSON.`);
  }
};

const validators = {
  [CSV_COLUMNS.device_id]: (record: any, index: number) => {
    const deviceId = record[CSV_COLUMNS.device_id];
    if (!letterNumberID.test(deviceId) || deviceId.length > 64) {
      throw new Error(
        `Invalid Device ID at row ${index}. (only 0-9,A-Z,a-z,_,- allowed upto 64 characters)`
      );
    }
  },
  [CSV_COLUMNS.tags]: (record: any, index: number) => {
    parseTagsFromRecord(record, index); //this will throw error if JSON is wrong
    return "";
  },
  [CSV_COLUMNS.installation_status]: (record: any, index: number) => {
    const validInstallationStatuses = installationStatusDropDownOptions.map(
      ({ label }: any) => label.toLowerCase()
    );
    const uploadedInstallationStatus = (
      record[CSV_COLUMNS.installation_status] || ""
    )
      .trim()
      .toLowerCase();

    if (!uploadedInstallationStatus) {
      return "";
    }

    if (!validInstallationStatuses.includes(uploadedInstallationStatus)) {
      throw new Error(`Invalid Installation Status at row ${index}`);
    }
  },
};

const getValidator = (columnName: string) => {
  const columnId = CSV_COLUMNS[columnName];
  return validators[columnId] || (() => true);
};

const hasUtleastOneColumnToUpdate = (record: any[]) => {
  //skip first column as it will be always device id
  return !record.slice(1).every((v) => !(v + "").trim());
};

const validateColumns = (sanitizedData: any) => {
  const columns = getKeysFromEnum(CSV_COLUMNS);
  //will start from index 1 to skip header
  for (let i = 1; i < sanitizedData.length; i++) {
    const record = sanitizedData[i];
    if (!hasUtleastOneColumnToUpdate(record)) {
      throw new Error(
        `There is nothing to update at row ${i + 1}. Please remove the row.`
      );
    }
    columns.forEach((column: any) => {
      const validator = getValidator(column);
      validator(record, i + 1);
    });
  }
};

const validateData = (sanitizedData: any) => {
  const totalReacords = sanitizedData.length - 1;
  if (totalReacords > BULK_UPDATE_RECORD_LIMIT) {
    throw new Error(
      `Maximum Record Count (${BULK_UPDATE_RECORD_LIMIT}) Exceeded`
    );
  }
  checkHeaders(sanitizedData[0]);
  checkForDuplicateRecords(sanitizedData);
  validateColumns(sanitizedData);
};

export default validateData;
