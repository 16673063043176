import React, { useState } from "react";
import { Button } from "@material-ui/core";
import { MemoryRouter, Switch, Route } from "react-router";
import { NavLink } from "react-router-dom";

import {
  Header,
  LoadingIndicator,
  MultifilteredDataTable,
  ServiceUnavailable,
} from "../../UiComponents";

import DevicesIcon from "../../../assets/LeftPanelIcons/Devices.png";

import ApplicationSelector from "../DeviceInstallation/ApplicationSelector";
// import mockApi from "./mockApi";
import {
  ApplicationContextProvider,
  useApplicationContext,
} from "../../../contexts/ApplicationContext";
import buildColumnSchema from "./columnConfigs";
import ExportToCSV from "./ExportToCSV";
import EditDevice from "./EditDevice";
import ReplaceDevice from "./ReplaceDevice";
import AddDevice from "./AddDevice";
import ExpiringDeviceNotification from "./ExpiringDeviceNotification";
import useStyles from "./DeviceManagement.styles";
import CustomizeColumns from "./CustomizeColumns";
import useApi from "../../../hooks/useApi";
import FormatNumber from "../../UiComponents/FormatNumber";

const ManageDeviceButton: React.FC<any> = () => {
  const classes = useStyles();

  return (
    <div className={classes.rightSec}>
      <NavLink to="/add-device" style={{ textDecoration: "none" }}>
        <Button
          variant="contained"
          color="inherit"
          className={classes.listButton}
          endIcon={<img style={{ margin: "-1em -0.5em" }} src={DevicesIcon} />}
          size="large"
        >
          Manage Devices
        </Button>
      </NavLink>
    </div>
  );
};

const GridController: React.FC<any> = ({
  columns,
  onPreferenceSave,
  data,
  activeFilters,
  sortingSequence,
  setActiveFilters,
  gridControls,
}) => {
  const classes = useStyles();
  if (!data?.summary) {
    return null;
  }
  return (
    <>
      <ExpiringDeviceNotification
        devicesExpireCount={data.summary.expiring_devices}
        onClick={() => {
          setActiveFilters([
            {
              filterType: "certificate_expiring", //TODO: Make column names enum
              filterValue: "true",
              filterId: columns.find(
                ({ id }: any) => id === "certificate_expiring"
              )?.device_metadata_options_id,
            },
          ]);
        }}
      />

      <Header
        route="Devices"
        headerText={
          <>
            Total Devices:{" "}
            <strong>
              <FormatNumber number={data ? data.summary.total_devices : 0} />
            </strong>
          </>
        }
      >
        {/* TODO: need to use re-usable exportToCSV component from uiComponents */}
        <ExportToCSV
          columns={columns}
          isIRMS={true} //TODO: Check for IRMS application
          activeFilters={activeFilters}
          sortingSequence={sortingSequence}
        />
        <CustomizeColumns onSave={onPreferenceSave} />
        {gridControls}
        <div className={classes.headerRHS}>
          <ManageDeviceButton />
        </div>
      </Header>
    </>
  );
};

const DeviceManagement = () => {
  const { selectedApplication } = useApplicationContext();

  const [preferedColumns, setPreferedColumn] = useState<any[]>([]);
  const { status, trigger: refetchUserPreference } = useApi(
    `/applications/${selectedApplication.application_id}/user_preferred_column`,
    {
      method: "GET",
      data: {
        page_name: "DEV_MGMT",
      },
      responseDataFormatter: (data) => {
        const userPreferredColumns = data.column_list;
        setPreferedColumn(userPreferredColumns);
      },
    }
  );

  if (status.pending) {
    return <LoadingIndicator />;
  }
  if (preferedColumns.length === 0) {
    return (
      <>
        <ApplicationSelector style={{marginLeft:"1.8em"}} />
        <ServiceUnavailable>
          Unable to load user preference. Please check permission.
        </ServiceUnavailable>
      </>
    );
  }
  //we will build columnSchema on the fly. This makes the state light weight
  const preferredColumnSchema = buildColumnSchema(preferedColumns);

  return (
    <MemoryRouter>
      <Switch>
        <Route path="/" exact>
          <ApplicationSelector style={{ marginLeft: "1.8em" }} />
          <MultifilteredDataTable
            endPoint="devices"
            filterPreserveKey={`deviceManagement${selectedApplication.application_id}`}
            gridController={(props: any) => (
              <GridController
                onPreferenceSave={() => refetchUserPreference()}
                columns={preferredColumnSchema}
                {...props}
              />
            )}
            columns={preferredColumnSchema}
            selectedApplication={selectedApplication}
            dataLookupKey="devices"
            totalRecordsLookupKey="total_devices"
          />
        </Route>
        <Route path="/add-device">
          <AddDevice />
        </Route>
        <Route path="/edit-device/:deviceDlmId">
          <EditDevice />
        </Route>
        <Route path="/replace-device/:deviceDlmId">
          <ReplaceDevice />
        </Route>
      </Switch>
    </MemoryRouter>
  );
};

const DeviceManagementWrapper = () => {
  const { selectedApplication } = useApplicationContext();
  return <DeviceManagement key={selectedApplication.application_id} />;
};

//TODO: ApplicationContext should be elevated to Application Root. Currently this is duplicated in all pages.
const Wrapper = () => {
  return <DeviceManagementWrapper />;
};
export default Wrapper;
