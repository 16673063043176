import { types, Instance } from "mobx-state-tree";
import User from "./User";

const AppStore = types
  .model("AppStore", {
    shouldShowHomeFeedback: false,
    user: types.optional(User, {}),
  })
  .actions(self => ({
    pageReset() {
      self.shouldShowHomeFeedback = false;
    },

    showHomeFeedback() {
      self.shouldShowHomeFeedback = true;
    },
  }));

export default AppStore;
export interface IAppStore extends Instance<typeof AppStore> {}
