import React from "react";
import { InputLabel } from "@material-ui/core";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    labels: {
      color: "#fcfcfc",
      lineHeight: "16px",
      fontSize: "14px",
      transform: "scale(1)",
    },
  })
);

const CustomInputLabel: React.FC<any> = ({ children, style = {} }) => {
  const classes = useStyles();
  return (
    <InputLabel classes={{ root: classes.labels }} style={style}>
      {children}
    </InputLabel>
  );
};

export default CustomInputLabel;
