import React, { useEffect, useState } from "react";

import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import { ClickAwayListener, Grow, Paper, Popper } from "@material-ui/core";

import Badge from "@material-ui/core/Badge";
import NotificationsNoneIcon from "@material-ui/icons/NotificationsNone";
import { useSnackbar } from "notistack";

// import CreateDeviceSimulationButton from "./CreateDeviceSimulationButtonÏ";
import { callApi } from "../../../hooks/useApi/useApi";
import { JOB_STATUSES, JOB_TYPE } from "./types";
import { fetchDeviceStatusMock, tracker } from "./developerUtils";
import useStyles from "./NotificationManager.styles";
import useInterval from "../../../hooks/useInterval";
import NotificationCard from "./NotificationCard";
import {
  createNotification,
  getSingleDeviceCreationJobsInBatches,
  markAllNotificationsAsSeen,
  markAllNotificationsAsShown,
  markJobComplete,
  readNotifications,
} from "./utils";

const POLLING_INTERVAL = 5 * 1000;

const NotificationManager: React.FC<{}> = () => {
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [notifications, setNotifications] = useState(readNotifications());
  const anchorRef = React.useRef<HTMLButtonElement>(null);

  useInterval(async () => {
    tracker("\n\n------------------------------------------\n\n");
    tracker(1, "Polling Started");
    const singleDeviceJobBatches = getSingleDeviceCreationJobsInBatches();

    tracker(
      2,
      `Reading Single Device Job Queue - ${singleDeviceJobBatches.length} batch(es) found`,
      singleDeviceJobBatches
    );

    for (const jobBatch of singleDeviceJobBatches) {
      const { jobs, applicationId } = jobBatch;
      tracker(2.1, "Processing Job batch for application", applicationId);

      const data = await callApi(`/applications/${applicationId}/poll`, {
        method: "GET",
        data: {
          job: jobs.map(({ payload }) => ({
            job_id: payload.deviceDlmId,
            job: JOB_TYPE.SINGLE_DEVICE_CREATION,
          })),
        },
        // mock: fetchDeviceStatusMock,
        responseDataFormatter: (dataIn: any) => {
          return dataIn.job_statuses;
        },
      });

      tracker(2.3, `Recieved Job Details From Api`, data);

      for (const job of jobs) {
        const jobUpdates = data.find(
          ({ device_dlm_id }: any) => device_dlm_id === job.payload.deviceDlmId
        );
        tracker(2.4, `Filtered Job Update`, jobUpdates);
        if (jobUpdates.job_status !== JOB_STATUSES.IN_PROGRESS) {
          tracker(2.5, `Creating Notification`);

          createNotification(job, jobUpdates);
          tracker(4, `Marking the job complete`);
          markJobComplete(job);
        }
      }
    }

    const notificationQueue = readNotifications();

    const unshownNotifications = notificationQueue.filter(
      ({ shown }: any) => !shown
    );

    if (unshownNotifications.length === 0) {
      return;
    }

    for (const notification of unshownNotifications) {
      enqueueSnackbar(notification.message, {
        variant:
          notification.status === JOB_STATUSES.SUCCESS ? "success" : "error",
      });
    }

    tracker(4, `Marking ${unshownNotifications.length} Notification as shown`);
    markAllNotificationsAsShown();

    const updatedNotificationQueue = readNotifications();
    setNotifications(updatedNotificationQueue);
  }, POLLING_INTERVAL);

  useEffect(() => {
    if (!open) {
      markAllNotificationsAsSeen();
      setNotifications(readNotifications());
    }
  }, [open]);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: React.MouseEvent<EventTarget>) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  };

  const numberOfUnseenNotifications = notifications
    ? notifications.filter(({ seen }: any) => !seen).length
    : 0;

  return (
    <>
      {/* <CreateDeviceSimulationButton /> */}
      <IconButton
        ref={anchorRef}
        aria-controls={open ? "menu-list-grow" : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
        className={classes.hoveredStyle}
        color="inherit"
      >
        <Badge
          color="error"
          badgeContent={
            numberOfUnseenNotifications > 0 ? numberOfUnseenNotifications : null
          }
        >
          <NotificationsNoneIcon />
        </Badge>
      </IconButton>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <div className={classes.wrapper} onClick={handleClose}>
                  <Typography className={classes.notificationHeaderText}>
                    Notifications
                  </Typography>
                  {!notifications ||
                    (notifications.length === 0 && (
                      <Typography className={classes.noNotificationText}>
                        No notifications
                      </Typography>
                    ))}
                  <div className={classes.notificationCardHolder}>
                    {notifications.map((notification: any) => {
                      return (
                        <NotificationCard
                          key={notification.id}
                          notification={notification}
                        />
                      );
                    })}
                  </div>
                </div>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};

export default NotificationManager;
