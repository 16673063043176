import React from "react";
import { Button, ButtonProps } from "@material-ui/core";
import { styled } from "@material-ui/styles";

const SubmitButton = styled(Button)<ButtonProps>(() => ({
  backgroundColor: "#0089FF",
  color: "#FFFFFF",
  textTransform: "none",
  fontSize: "16px",
  fontWeight: "bold",
  padding: "0.25em 1em",
  "&:hover": {
    backgroundColor: "#0089FF",
  },
  "&.Mui-disabled": {
    color: "#ebebf57d !important",
    backgroundColor: "#42424A",
  },

  boxShadow: "none",
  borderRadius: "3px",
}));

export default SubmitButton;
