const API_URL = window.SERVER_DATA.REACT_APP_PM_API_HOST;

const API = {
  GETALLUSERS: API_URL + "/api/v1d/users",
  GETALLAPPLS: API_URL + "/api/v1d/applications",
  GETALLROLES: API_URL + "/api/v1d/roles",
  GETRESOURCE: API_URL + "/api/v1d/az-iot-hubs",
  RESOURCE_INPUT: API_URL + "/api/v1d/az-iot-hubs/inputs",
  POSTLOGIN: API_URL + "/api/v1d/sessions",
  GETHEALTH: API_URL + "/api/v1d/app-healths",
  GETDEVICECONNECTIVITY: API_URL + "/api/v1d/device-connectivity",
  GETDEVICES: API_URL + "/api/v1d/devices",
  GETDEVICESINPUTS: API_URL + "/api/v1d/devices/inputs",
  GETREQUESTSTATUS: API_URL + "/api/v1d/request-status",
  GETCONFIG: API_URL + "/api/v1d/device-configs",
  GETCONNCONFIG: API_URL + "/api/v1d/conn-configs",
  GETUSERSAPPLS: API_URL + "/api/v1d/users/inputs",
  ROLES_APPLS_INPUT: API_URL + "/api/v1d/roles/inputs",
  DASHBOARDS_END_POINT: API_URL + "/api/v1d/dashboards",
  EVENTS_END_POINT: API_URL + "/api/v1d/dlm-msgs",
  DEVICE_CONFIG_INPUT: API_URL + "/api/v1d/device-configs/inputs",
  DEVICES_EXPORT: API_URL + "/api/v1d/devices-export"

};

export { API, API_URL };