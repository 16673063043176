import validateTags from "./validateTags";

//Tag validator expects tags as array. So we will convert the tags in expected format
//NativeTags - unformatted tags as in tag json
const validateNativeTags = (deviceTags: any) => {
  const formattedTagData = Object.keys(deviceTags).map((tagName) => {
    const {
      type,
      value,
      data_type = "text",
      options = "",
    } = deviceTags[tagName];
    return {
      tag_name: tagName,
      tag_type: type,
      tag_data_type: data_type,
      tag_value: value,
      options,
    };
  });

  return validateTags(formattedTagData);
};

export default validateNativeTags;
