import React from "react";
import { TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";

import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    autoComplete: {
      flex: 1,
      border: "1px solid #353541",
      padding: "2px 5px",
      "& .MuiInputBase-input, & .MuiInputLabel-root": {
        color: "white",
      },

      "& .MuiSvgIcon-root": {
        fill: "white",
      },
      "& .MuiAutocomplete-popper": {
        color: "white",

        "& .MuiAutocomplete-option": {
          color: "white",
        },
        "& .MuiAutocomplete-noOptions": {
          color: "grey",
        },
        "& .MuiAutocomplete-paper": {
          border: "1px solid #1D1D25",
        },
      },
    },
  })
);

const AutocompleteWrapper: React.FC<any> = ({
  label,
  options,
  getOptionLabel,
  onSelect,
  value,
  disabled,
  style = {},
}) => {
  const classes = useStyles();
  return (
    <div className={classes.autoComplete} style={style}>
      <Autocomplete
        disablePortal
        color="white"
        id="combo-box-demo"
        style={{ color: "white" }}
        getOptionLabel={getOptionLabel}
        options={options}
        renderInput={(params) => <TextField {...params} label={label} />}
        onChange={(_, newValue: any) => onSelect(newValue)}
        value={value}
        disabled={disabled}
      />
    </div>
  );
};

export default AutocompleteWrapper;
