import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      width: "600px",
      display: "flex",
      flexFlow: "column",
      alignItems: "flex-start",
      padding: "1em 0.3em",
    },

    buttonHolder: {
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center",
      borderTop: "1px solid #33333F",
      paddingTop: "0.5em",
      margin: "15px 0px",
      width: "100%",
    },
  })
);

export default useStyles;
