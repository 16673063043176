import { installationStatusDropDownOptions } from "../../../../../utils/constant";
import { COLUMN_IDS } from "./columns";

const COLUMN_INPUT_SCHEMA = {
  [COLUMN_IDS.certificate_expiring]: {
    type: "readOnlyText",
    defaultValue: "true",
  },

  [COLUMN_IDS.is_healthy]: {
    type: "dropDown",
    dropDownOptions: [
      { label: "Healthy", value: "true" },
      { label: "Unhealthy", value: "false" },
      { label: "Unknown", value: "unknown" },
    ],
  },

  [COLUMN_IDS.installation_status]: {
    type: "dropDown",
    dropDownOptions: installationStatusDropDownOptions,
  },
};

const DEFAULT_INPUT_SCHEMA = { type: "text" };

const getFilterInputSchema = (columnId: string) => {
  return COLUMN_INPUT_SCHEMA[columnId] || DEFAULT_INPUT_SCHEMA;
};

export default getFilterInputSchema;
