import React, { useState } from "react";

import {
  Modal,
  CustomButton,
  CustomCheckbox,
  CustomInputLabel,
} from "../../../UiComponents";
import CustomTextField from "../../../UiComponents/TextField/TextField";
import { IMenuItemProps } from "../../../UiComponents/DataTable/types";
import { useApplicationContext } from "../../../../contexts/ApplicationContext";
import SendToDeviceCTA from "./SendToDeviceCTA";
import useStyles from "./DownlinkMessaging.styles";

interface IDownlinkMessagingProps extends IMenuItemProps {}

const DownlinkMessaging: React.FC<IDownlinkMessagingProps> = ({
  record,
  onDone,
}) => {
  const classes = useStyles();
  const { selectedApplication } = useApplicationContext();

  const [formData, setFormData] = useState({
    port: "",
    acknowledged: true,
    payload: "",
    config_type: record.config_type,
  });
  return (
    <Modal open={true} onClose={onDone} title={`Downlink Messaging`}>
      <div className={classes.wrapper}>
        <div className={classes.deviceId}>
          <strong>Device Id: </strong> {record.device_id}
        </div>
        <div className={classes.portDetails}>
          <CustomTextField
            label="Port Number (1 to 223)"
            style={{ width: "20rem", margin: "0 10px 0 0" }}
            value={formData.port}
            onChange={(value: string) =>
              setFormData({ ...formData, port: value })
            }
          />
          <CustomCheckbox
            label="Request Confirmation"
            checked={formData.acknowledged}
            onChange={(value: boolean) =>
              setFormData({ ...formData, acknowledged: value })
            }
          />
        </div>
        <CustomInputLabel style={{ margin: "0 0 15px 0" }}>
          Payload (Hex String)
        </CustomInputLabel>
        <textarea
          placeholder="Payload"
          onChange={(ev: any) =>
            setFormData({ ...formData, payload: ev.target.value })
          }
          className={classes.textArea}
        />
        <div className={classes.buttonHolder}>
          <CustomButton
            variant="outlined-white"
            onClick={() => onDone && onDone()}
          >
            Cancel
          </CustomButton>

          <SendToDeviceCTA
            onDone={onDone}
            formData={formData}
            endPointURL={`/applications/${selectedApplication.application_id}/device-downlink/${record.device_dlm_id}`}
          />
        </div>
      </div>
    </Modal>
  );
};

export default DownlinkMessaging;
