const callXhrRequest = function () {
  const tokenKey = "sso_token";
  const REACT_APP_SSO_HOST = window.SERVER_DATA.REACT_APP_SSO_HOST;
  const accessToken = JSON.parse(sessionStorage.getItem(tokenKey));
  return new Promise(function (myResolve, myReject) {
    // "Producing Code" (May take some time)
    console.log("Calling Refresh token request");
    var data = `refresh_token=${accessToken.refresh_token}&grant_type=refresh_token`;

    var xhr = new XMLHttpRequest();
    xhr.withCredentials = true;

    xhr.addEventListener("readystatechange", function () {
      if (this.readyState === 4) {
        if (this.responseText.indexOf("error") === -1) {
          myResolve(this.responseText);
        } else {
          myReject("could not refresh token, logging out");
        }
        // when successful
      }
    });

    xhr.open("POST", `${REACT_APP_SSO_HOST}/as/token.oauth2`);
    xhr.setRequestHeader("content-type", "application/x-www-form-urlencoded");
    xhr.setRequestHeader("authorization", `Bearer ${accessToken.access_token}`);

    xhr.send(data);
  });
};

export default callXhrRequest;
