import React from "react";
import {
  IMenuItemProps,
  ITemplateColumnProps,
} from "../../../UiComponents/DataTable/types";
import { GridContextMenuColumn } from "../../../UiComponents";
import VisibilityIcon from "@material-ui/icons/Visibility";
import EditIcon from "@material-ui/icons/Edit";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import UserDetails from "./UserDetails";
import { Redirect } from "react-router";
import DeleteUser from "./DeleteUser";

//TODO: This can be moved to components
interface IRedirectToProps extends IMenuItemProps {
  path: string;
}

const RedirectTo: React.FC<IRedirectToProps> = ({ path, record }) => {
  return <Redirect to={{ pathname: `/${path}/`, state: record }} />;
};

const UserActionColumn = (props: ITemplateColumnProps) => {
  return (
    <GridContextMenuColumn
      menuItems={[
        {
          id: "viewDetails",
          icon: VisibilityIcon,
          label: "View Details",
          component: <UserDetails />,
        },
        {
          id: "editUser",
          icon: EditIcon,
          label: "Edit",
          component: <RedirectTo path="edit-user" />,
        },
        {
          id: "deleteUser",
          icon: DeleteOutlinedIcon,
          label: "Delete",
          component: <DeleteUser />,
        },
      ]}
      {...props}
    />
  );
};

export default UserActionColumn;
