import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    deviceHistoryCard: {
      backgroundColor: "#33333f",
      marginBottom: "16px",
    },
    replacedOn: {
      textAlign: "right",
      marginRight: "1em",
    },
    deviceHistory: {
      overflowY: "auto",
      maxHeight: "70vh",
      paddingBottom: "2em",
      width: "750px",
      maxWidth: "70vw",
    },
    imageHolder: {
      display: "flex",
      flexWrap: "wrap",
    },
  })
);

export default useStyles;
