import React, { useEffect } from "react";
import useAcknowledgeEvent from "./useAcknowledgeEvent";
import { useSnackbar } from "notistack";

const AcknowledgeEvent: React.FC<any> = ({ record, refreshGrid, onDone }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { acknowledgeEvent } = useAcknowledgeEvent({
    dlm_msg_id: record.dlm_msg_id,
    onSuccess: () => {
      enqueueSnackbar("Event Acknowledged", { variant: "success" });
      refreshGrid();
      onDone();
    },
  });

  useEffect(() => {
    acknowledgeEvent();
  }, []);

  return null;
};

export default AcknowledgeEvent;
