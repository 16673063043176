import React from "react";
import { Divider, Grid } from "@material-ui/core";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import BreadcurmbComponent from "../../utils/breadcurmb";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    header: {
      background: "#2b2b36",
      margin: "1.5em",
      padding: "1.5em",
      paddingBottom: "5px",
      width: "auto",
      position: "relative",
    },
    searchBar: {
      display: "flex",
      alignItems: "center",
      height: "3em",
    },
    text: {
      color: "white",
      marginRight: "3em",
    },
    breadCrumHolder: {
      display: "flex",
      alignItems: "center",
      marginBottom: "1em",
    },
  })
);

interface IHeaderProps {
  headerText: React.ReactNode;
  route: string;
}

const Header: React.FC<IHeaderProps> = ({ headerText, route, children }) => {
  const classes = useStyles();
  return (
    <Grid container direction="column" className={classes.header}>
      <div className={classes.breadCrumHolder}>
        <BreadcurmbComponent route={route} />
      </div>
      <Divider variant="fullWidth" style={{ color: "#33333F" }} />
      <div className={classes.searchBar}>
        <div className={classes.text}>{headerText}</div>
        {children}
      </div>
    </Grid>
  );
};

export default Header;
