import React from "react";
import { Button, Typography } from "@material-ui/core";

import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    buttonText: {
      color: "#0089FF",
      marginTop: "1em",
      marginBottom: "1em",
      textTransform: "none",
      fontSize: "16px",
      lineHeight: "19px",
    },
    disabled: {
      color: "rgb(95, 95, 101)",
    },
  })
);

const ButtonText: React.FC<any> = ({
  onClick,
  label,
  disabled = false,
  style = {},
}) => {
  const classes = useStyles();
  return (
    <Button disabled={disabled} onClick={onClick} style={style}>
      <Typography
        className={`${classes.buttonText} ${disabled ? classes.disabled : ""}`}
      >
        {label}
      </Typography>
    </Button>
  );
};

export default ButtonText;
