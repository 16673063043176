import { IJob, JOB_STATUSES, JOB_TYPE, SESSION_STORAGE_KEY } from "./types";
import { tracker } from "./developerUtils";

const WAITING_TIME = 18 * 1000;

const readArrayFromSession = (key: string) => {
  let array = [];
  try {
    array = JSON.parse(window.sessionStorage.getItem(key) || "[]") || [];
  } catch {
    console.warn(`Error while reading session for ${key}`);
  }
  return array;
};

const readNotifications = () =>
  readArrayFromSession(SESSION_STORAGE_KEY.NOTIFICATIONS);

const readJobs = () => readArrayFromSession(SESSION_STORAGE_KEY.JOBS);

const writeNotifications = (notifications: any) => {
  window.sessionStorage.setItem(
    SESSION_STORAGE_KEY.NOTIFICATIONS,
    JSON.stringify(notifications)
  );
};

const writeJobs = (jobs: any) => {
  window.sessionStorage.setItem(SESSION_STORAGE_KEY.JOBS, JSON.stringify(jobs));
};

const getNotificationSubject = (jobUpdates: any) => {
  if (jobUpdates.job === JOB_TYPE.SINGLE_DEVICE_CREATION) {
    return `Device Creation ${
      jobUpdates.job_status === JOB_STATUSES.SUCCESS ? "Successful" : "Failed"
    } `;
  }
};

const createNotification = (job: any, jobUpdates: any) => {
  if (job.type === JOB_TYPE.SINGLE_DEVICE_CREATION) {
    tracker(3.0, `Creating notificaiton for single device`, job);

    const notification = {
      id: job.id,
      type: JOB_TYPE.SINGLE_DEVICE_CREATION,
      status: jobUpdates.job_status,
      subject: getNotificationSubject(jobUpdates),
      message: jobUpdates.message,
      snackBarShown: false,
      seen: false,
      shown: false,
      timeStamp: Date.now(),
    };
    const notificationQueue = readNotifications();
    const updatedNotificationQueue = [notification, ...notificationQueue];
    writeNotifications(updatedNotificationQueue);
  }
};

const markAllNotificationsAsSeen = () => {
  const notificationQueue = readNotifications();
  const updatedNotificationQueue = notificationQueue.map(
    (notification: any) => {
      return { ...notification, seen: true };
    }
  );
  writeNotifications(updatedNotificationQueue);
};

const markAllNotificationsAsShown = () => {
  const notificationQueue = readNotifications();
  const updatedNotificationQueue = notificationQueue.map(
    (notification: any) => {
      return { ...notification, shown: true };
    }
  );
  writeNotifications(updatedNotificationQueue);
};

const markJobComplete = (completedJob: any) => {
  const currentJobs = readJobs();
  const updatedJobs = currentJobs.map((job: any) => {
    if (completedJob.id === job.id) {
      return { ...job, completed: true };
    } else {
      return job;
    }
  });
  tracker(4.1, `Marking job ${completedJob.id} complete`);
  writeJobs(updatedJobs);
};

const hasCompletedWaitingTime = (job: IJob) =>
  Date.now() - job.timeStamp > WAITING_TIME;

/*
Why in batches? 
DLM Admin may switch application while creating devices. We can only query multiple devices for one application
at a time. So we will read and batch jobs according to applicationIds.
*/
const getSingleDeviceCreationJobsInBatches = () => {
  try {
    const jobQueue: IJob[] = readJobs().filter(
      (job: IJob) => !job.completed && hasCompletedWaitingTime(job)
    );

    //extract unique applicationIds by using Set
    const applicationIds = Array.from(
      new Set(jobQueue.map(({ payload }: IJob) => payload.applicationId))
    );

    const jobsBatchedByApplicationId = applicationIds.map((applicationId) => {
      return {
        applicationId,
        jobs: jobQueue.filter(
          ({ payload }) => payload.applicationId === applicationId
        ),
      };
    });
    return jobsBatchedByApplicationId;
  } catch {
    console.warn("Error reading event queue");
    return [];
  }
};

export {
  getSingleDeviceCreationJobsInBatches,
  markJobComplete,
  readNotifications,
  createNotification,
  markAllNotificationsAsSeen,
  markAllNotificationsAsShown,
};
