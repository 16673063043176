import { COLUMN_IDS } from "./columns";

const UN_SORTABLE_COLUMN_IDS = [
  COLUMN_IDS.certificate_expiring,
  COLUMN_IDS.action,
  COLUMN_IDS.cloud_gateway,
  COLUMN_IDS.device_twin,
  COLUMN_IDS.comment,
  COLUMN_IDS.gps_location,
];

const UN_SEARCHABLE_COLUMN_IDS = [
  COLUMN_IDS.action,
  COLUMN_IDS.cloud_gateway,
  COLUMN_IDS.device_twin,
  COLUMN_IDS.gps_location,
  COLUMN_IDS.comment,
];

const NON_EXPORTABLE_COLUMN_IDS = [
  COLUMN_IDS.certificate_expiring,
  COLUMN_IDS.action,
];

const HIDDEN_COLUMNS = [
  COLUMN_IDS.device_twin,
  COLUMN_IDS.cloud_gateway,
  COLUMN_IDS.gps_location,
  COLUMN_IDS.comment,
];

const getControlFlags = (columnId: COLUMN_IDS) => {
  return {
    sortable: !UN_SORTABLE_COLUMN_IDS.includes(columnId),
    searchable: !UN_SEARCHABLE_COLUMN_IDS.includes(columnId),
    exportable: !NON_EXPORTABLE_COLUMN_IDS.includes(columnId),
    isHidden: HIDDEN_COLUMNS.includes(columnId),
  };
};

export default getControlFlags;
