import React from "react";
import { Typography } from "@material-ui/core";

import useApi from "../../../../hooks/useApi";

interface IThumbnailViewProps {
  image: any;
  requestPage: string;
  imageAccessBaseUrl: string;
  onClick?: () => void;
}

const createDataUrl = (data: any) =>
  window.URL.createObjectURL(new Blob([data]));

const ThumbnailView: React.FC<IThumbnailViewProps> = ({
  imageAccessBaseUrl,
  image,
  requestPage,
  onClick,
}) => {
  const { status, data } = useApi(`${imageAccessBaseUrl}/${image.image_id}`, {
    method: "GET",
    data: { request_page: requestPage, view_type: "thumbnail" },
    responseDataFormatter: createDataUrl,
    responseType: "blob",
  });

  return (
    <>
      {status.pending && <span>Loading...</span>}
      {status.error && (
        <Typography style={{ margin: ".5em" }} color="error">
          Error occured while loading image
        </Typography>
      )}
      {data && (
        <img
          style={{
            width: "100%",
            height: "100%",
            cursor: onClick ? "pointer" : "default",
          }}
          src={data}
          width={640}
          onClick={() => onClick && onClick()}
        />
      )}
    </>
  );
};

export default ThumbnailView;
