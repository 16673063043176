import React, { useEffect } from "react";
import { FormSection } from "..";
import useFirstRenderDetector from "../../../hooks/useFirstRenderDetector";
import ReadOnlyTagRenderer from "./ReadOnlyTagRenderer";
import DynamicFieldRenderer from "./DynamicFieldRenderer";
import useTagPopulator from "./useTagPopulator";

const sortAndMapTags = (tags: any) => {
  const mappedTags = Object.keys(tags)
    .map((tagName: any) => {
      return { name: tagName, ...tags[tagName] };
    })
    .sort((t1, t2) => (t1.name < t2.name ? 1 : -1)) //sort alphabetically
    .sort(({ type }) => (type === "Tabular" ? 1 : -1)); //push tabular to last
  return mappedTags;
};

interface IDeviceTagManagerProps {
  tags: any[];
  onTagUpdate?: (updatedTags: any) => void;
  readOnly?: boolean;
  onTagRenderingError?: () => void;
}

const DeviceTagManager: React.FC<IDeviceTagManagerProps> = ({
  tags,
  onTagUpdate,
  readOnly,
  onTagRenderingError, //TODO: Think this is not needed
}) => {
  const isFirstRender = useFirstRenderDetector();
  const populatedTagData = useTagPopulator(tags);

  useEffect(() => {
    if (isFirstRender && !readOnly) {
      /*Updating meta info. Because device tags can have inconsistent meta info.
      Meta info is required for proper tag validation.*/
      onTagUpdate && onTagUpdate(populatedTagData);
    }
  }, [isFirstRender, readOnly, onTagUpdate, populatedTagData]);

  const onTagValueChange = (tagName: string, changedFields: any) => {
    const updatedTags = {
      ...tags,
      [tagName]: {
        ...populatedTagData[tagName],
        ...changedFields, //if its a tabular tag, 'value' field holds the column names. Actual values are stored in 'table_values' :( So let renderer decide what to change
      },
    };
    onTagUpdate && onTagUpdate(updatedTags);
  };

  const mappedTags = sortAndMapTags(populatedTagData);

  if (readOnly) {
    return <ReadOnlyTagRenderer mappedTags={mappedTags} />;
  }

  return (
    <FormSection header="Tags">
      {mappedTags.map((tagData: any) => {
        //TODO: Rename to TagRenderer
        return (
          <DynamicFieldRenderer
            key={tagData.name}
            name={tagData.name} //TODO: This can be extracted from tagData
            tagData={tagData}
            onChange={onTagValueChange}
          />
        );
      })}
    </FormSection>
  );
};

export default DeviceTagManager;
