import { COLUMN_IDS } from "./columns";
/*
NOTE: device_metadata_options_id will not be used for synthetic columns
as they are not searchable or sortable. Its just a place holder 
to make the grid  stable.
*/
const SYNTHETIC_COLUMNS = [
  {
    id: COLUMN_IDS.action,
    label: " ",
    device_metadata_option_id: -1, //not necessery
  },
  {
    id: COLUMN_IDS.cloud_gateway,
    label: "IoT Hub",
    device_metadata_options_id: -1,
  },
  {
    id: COLUMN_IDS.device_twin,
    label: "Device Twin",
    device_metadata_options_id: -1,
  },
  {
    id: COLUMN_IDS.comment,
    label: "Comments",
    device_metadata_options_id: -1,
  },
  {
    id: COLUMN_IDS.gps_location,
    label: "GPS Location",
    device_metadata_options_id: -1,
  },
];

const addSyntheticColumns = (columns: any[]) => {
  return [...columns, ...SYNTHETIC_COLUMNS];
};

export default addSyntheticColumns;
