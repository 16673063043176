import { letterNumberRegXWithOutSpace } from "../../../../../utils/constant";
import readError from "../../../../../utils/readError";

//TODO: Candidate for common array utility function
const replace = (array: any[], index: number, item: any) => {
  return Object.assign([], array, { [index]: item });
};

//TODO: Candidate for common array utility function
const remove = (array: any[], index: number) => {
  return [...array.slice(0, index), ...array.slice(index + 1)];
};

const convertToObject = (properties: any[]) => {
  let propertyObj = {};
  properties.forEach((property) => {
    const { name, value } = property;
    propertyObj = { ...propertyObj, [name]: value };
  });
  return propertyObj;
};

const hasDuplicatePropertyEntries = (tags: any[]) => {
  const loweredTagNames = tags
    .map(({ name }) => name.toLowerCase())
    .filter((s) => !!s);

  if (new Set(loweredTagNames).size !== loweredTagNames.length) {
    throw new Error(
      `Same property entered multiple times. Please remove duplicates`
    );
  }
};

const hasDuplicateProperties = (
  existingPropertyNames: string[],
  propertyNameToCompare: string
) => {
  return existingPropertyNames
    .map((s) => s.trim().toLocaleLowerCase())
    .includes(propertyNameToCompare.trim().toLowerCase());
};

const validateAddedProperties = (
  existingProperties: any,
  newProperties: any
) => {
  const existingPropertyNames = Object.keys(existingProperties);
  const newPropertyNames = Object.keys(newProperties);

  for (let i = 0; i < newPropertyNames.length; i++) {
    const name = newPropertyNames[i];

    try {
      if (!name) {
        throw new Error(`Please enter name`);
      }

      if (!letterNumberRegXWithOutSpace.test(name)) {
        throw new Error(
          `Invalid property name - '${name}' (only 0-9,A-Z,a-z,_,- allowed with no spaces)`
        );
      }
      if (hasDuplicateProperties(existingPropertyNames, name)) {
        throw new Error(`Property name '${name}' already exists`);
      }
    } catch (error) {
      const errorMessage = readError(error);
      throw new Error(
        `${errorMessage} ${newPropertyNames.length > 1 ? `(row ${i + 1})` : ""}`
      );
    }
  }
};

export {
  replace,
  remove,
  hasDuplicatePropertyEntries,
  convertToObject,
  validateAddedProperties,
};
