import React from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";

import { useApplicationContext } from "../../../../contexts/ApplicationContext";
import { NativeDropDown } from "../../../UiComponents/DropDown";

//TODO: Move Application Selector to components

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      display: "flex",
      alignItems: "center",
      padding: "0.5em 0em",
    },

    alignCentre: {
      color: "#fff",
      alignItems: "center",
      marginTop: "0.5rem",
    },
    label: {
      fontSize: "18px",
      lineHeight: "21px",
      marginRight: "18px",
      color: "#FFF",
    },
  })
);

const getOptions = (applications: []) => {
  return applications.map(({ application_id, application_name }: any) => ({
    value: application_id,
    label: application_name,
  }));
};

interface IApplicationSelectorProps {
  style?: React.CSSProperties;
}

const ApplicationSelector: React.FC<IApplicationSelectorProps> = ({
  style = {},
}) => {
  const classes = useStyles();
  const { applications, selectedApplication, setSelectedApplicationId } =
    useApplicationContext();

  return (
    <div className={classes.wrapper} style={style}>
      <label className={classes.label}>Application: </label>
      <NativeDropDown
        options={getOptions(applications || [])}
        value={selectedApplication?.application_id}
        onChange={(applicationId: string) =>
          setSelectedApplicationId(applicationId)
        }
      />
    </div>
  );
};

export default ApplicationSelector;
