import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import FormatNumber from "../../../UiComponents/FormatNumber";
const disp = "inline-block"

const useStyles = makeStyles({
  root: {
    display: "block",
    textAlign: "left",
    paddingBottom: "8px",
    paddingRight: "0px",
  },
  value: {
    float: "right",
  },
  label: {
    paddingLeft: "10px",
    fontSize: "16px",
    lineHeight: "19px",
    color: "white",
  },
  diamond: {
    display: disp,
    width: "10px",
    height: "10px",
    transform: "rotate(45deg)",
  },
  arrow: {
    marginLeft: "10px",
    color: "#FCFCFC",
    float: "right",
    padding: "0px",
  },
  percentage: {
    display: disp,
    width: "60px",
    height: "20px",
    borderRadius: "2px",
    textAlign: "center",
    color: "white",
    fontSize: "12px",
    lineHeight: "14px",
    paddingTop: "3px",
  },
  status: {
    display: disp,
    width: "65px",
  },
});

interface LegendLineInterface {
  color: string;
  text: string;
  value: number;
  range?: string;
}
export default function LegendLine({
  color,
  text,
  value,
  range
}: LegendLineInterface) {
  const classes = useStyles();
  return (
    <span className={classes.root}>
      { range ? 
        <span>
          <span className={classes.status}>{text}</span>
          <span className={classes.percentage} style={{ background: color }}>
            {range}
          </span>
        </span>
        : 
        <span>
          <span className={classes.diamond} style={{ background: color }} />
          <span className={classes.label}>{text}</span>
        </span>
      }
      <FormatNumber number={value} classes={classes.value} />
    </span>
  );
}

