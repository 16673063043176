const buildSummary = (data: any = []) => {
  const totalUsers = data.length;

  const activeUsers = data.filter(
    ({ status }: any) => status === "ACTIVE"
  ).length;

  const inactiveUsers = totalUsers - activeUsers;

  return {
    total_users: totalUsers,
    active_users: activeUsers,
    inactive_users: inactiveUsers,
  };
};

const isSubStringCaseInsensitive = (
  mainString: string = "",
  searchString: string = ""
) => {
  return mainString.toLowerCase().includes(searchString.toLowerCase());
};

const getFilterFunction = (filter: any) => {
  const { name, value } = filter;

  const filterMap: any = {
    status: (record: any) => record?.status === value,
    defaultFilter: (record: any) =>
      isSubStringCaseInsensitive(record?.[name], value),
    application_level: (record: any) => {
      const { application_level } = record;
      if (!application_level) {
        return false;
      }
      const combinedString = application_level
        .map(({ level_name }: any) => level_name)
        .join(",");
      return isSubStringCaseInsensitive(combinedString, value);
    },
  };

  return filterMap[name] || filterMap.defaultFilter;
};

const sortData = (data: any[], gridArgs: any) => {
  const { sort = [] } = gridArgs;
  if (sort.length === 0) {
    return data;
  }
  const { name, order } = sort[0];

  const sortAsc = (a: any, b: any) => {
    if ((a[name] || "") < (b[name] || "")) {
      return -1;
    }
    return 1;
  };

  const sortDesc = (a: any, b: any) => {
    if ((a[name] || "") > (b[name] || "")) {
      return -1;
    }
    return 1;
  };

  //we are not considering multisort here
  const sortedData = data.sort(order === "asc" ? sortAsc : sortDesc);
  return sortedData;
};

const paginateData = (data: any, gridArgs: any) => {
  const { page_num, limit } = gridArgs;
  const startIndex = (page_num - 1) * limit;
  const endIndex = startIndex + limit;
  const paginatedData = data.slice(startIndex, endIndex);
  return paginatedData;
};

const clientApi = (data: any, gridArgs: any) => {
  const { filter } = gridArgs;

  const filteredUsers = data.users.filter((user: any) => {
    if (filter.length === 0) {
      return true;
    }
    return filter.every((f: any) => {
      const customFilter = getFilterFunction(f);
      return customFilter(user);
    });
  });

  const sortedData = sortData(filteredUsers, gridArgs);
  const paginatedData = paginateData(sortedData, gridArgs);

  return {
    ...data,
    users: paginatedData,
    exportableUserData: sortedData, //For export we don't need pagination
    summary: { ...data.summary, ...buildSummary(filteredUsers) },
  };
};

export default clientApi;
