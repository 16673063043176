import { useInputContext } from "../../../contexts/InputContext";

const getSelectedConfigData = (inputData: any) => {
  const {
    device_configs,
    lookup: { selectedConfigId },
  } = inputData;

  const selectedDeviceConfigData = device_configs.find(
    ({ device_config_id }: any) => device_config_id === selectedConfigId
  );
  return selectedDeviceConfigData || {};
};

/*Read values from deviceTagData if exists. Else default to empty value*/
const getCurrentValue = (
  deviceTagData: any,
  tagName: string,
  tagMetaData: any
): object => {
  let currentTagData = deviceTagData[tagName];
  if (!currentTagData) {
    console.warn(`Tag Missing: Tag ${tagName} is missing from device data`);
    currentTagData = { table_values: [], value: "" }; //Fill it with default
  }

  if (tagMetaData.type === "Tabular") {
    return { table_values: currentTagData.table_values };
  }
  return { value: currentTagData.value };
};

/*Use `configuration` data to populate tags*/
const populateTagDataFromConfig = (
  configData: any,
  deviceTagData: any
): object => {
  const { tags } = configData;
  let populatedTags = {};

  for (const tagName in tags) {
    const tagMetaData = tags[tagName];
    populatedTags[tagName] = {
      ...tagMetaData,
      ...getCurrentValue(deviceTagData, tagName, tagMetaData),
    };
  }
  return populatedTags;
};

const useTagPopulator = (deviceTags: any[]) => {
  const inputData = useInputContext();
  if (!inputData) {
    throw new Error(
      "DeviceTagManager should be used only inside InputContextProvider"
    );
  }

  const selectedConfigData = getSelectedConfigData(inputData);
  const populatedTagData = populateTagDataFromConfig(
    selectedConfigData,
    deviceTags
  );

  return populatedTagData;
};

export default useTagPopulator;
