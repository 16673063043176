import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    CustomizeColumn: {
      padding: "0 15px 200px 15px",
      overflow: "auto",
      maxHeight: "500px",
    },
    listButton: {
      padding: "0px",
      textTransform: "none",
      fontSize: "14px",
      whiteSpace: "nowrap",
    },
    addColumnHolder: {
      margin: "10px 0",
      display: "flex",
      padding: "5px 0 0 4em",
      position: "relative",
      alignItems: "center",
      marginRight: "1em",
    },
    addColumnButton: {
      color: "#0089FF",
      fontSize: "16px",
      fontWeight: 600,
      cursor: "pointer",
    },
    columnInfoHolder: {
      margin: "5px 0",
      display: "flex",
      alignItems: "center",
      marginRight: "1em",
      minWidth: "23em",
      position: "relative",
    },
    sequenceNumber: {
      width: "2em",
      textAlign: "right",
      paddingRight: "1em",
      marginLeft: "-0.5em",
    },
    columnName: {
      flex: 5,
    },
    removeButton: {
      marginLeft: "1em",
      fontSize: "1.3em",
      cursor: "pointer",
    },
    buttonHolder: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      borderTop: "1px solid #6b6868",
      paddingTop: "0.5em",
      padding: "15px",
      width: "100%",
    },
  })
);

export default useStyles;
