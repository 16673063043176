import React, { useState } from "react";
import {
  createStyles,
  Theme,
  withStyles,
  WithStyles,
} from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";

import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import CloseIcon from "@material-ui/icons/Close";
import { NavLink } from "react-router-dom";

import { Backdrop, LinearProgress } from "@material-ui/core";

import { PAGES } from "../../../../../utils/constant";
import useApi from "../../../../../hooks/useApi";
import {
  CustomButton,
  DeviceTagManager,
  LoadingIndicator,
} from "../../../../UiComponents";
import { useApplicationContext } from "../../../../../contexts/ApplicationContext";
import FullScreenView from "../../../../UiComponents/AddRemoveImage/ImageViewer/FullScreenView";
import ThumbnailView from "../../../../UiComponents/AddRemoveImage/ImageViewer/ThumbnailView";
import { InputContextProvider } from "../../../../../contexts/InputContext";
import DeleteDevice from "../DeleteDevice";
import DeviceHistory from "../DeviceHistory";
import { useSnackbar } from "notistack";
import DeviceConnectionString from "./DeviceConnectionString";
import { useStyles } from "./ViewDetails.styles";

/*TODO: This code is not optimized and  taken as is from  'ShowDevices' page. Only api call was optimized.
There are lots of unused/repeated styles which can be refactored.  
*/

const DialogTitleStyles = (theme: Theme) =>
  createStyles({
    root: {
      margin: "20px",
      padding: 0,
      color: "#FFFFFF",
    },
    title: {
      fontSize: "21px",
      lineHeight: "25px",
      fontWeight: "bold",
    },
    closeButton: {
      position: "absolute",
      right: "2px",
      top: "15px",
      color: "#FFFFFF",
      fontSize: "14px",
    },
  });

const DialogContent = withStyles((theme: Theme) => ({
  root: {
    margin: "0px 15px",
    padding: 0,
    color: "#FFFFFF",
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme: Theme) => ({
  root: {
    margin: "20px 15px",
    justifyContent: "space-between",
    padding: 0,
    "& button": {
      fontSize: "16px",
      lineHeight: "19px",
      textTransform: "none",
      padding: "9px 20px",
    },
  },
}))(MuiDialogActions);

export interface DialogTitleProps extends WithStyles<typeof DialogTitleStyles> {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
}

const DialogTitle = withStyles(DialogTitleStyles)((props: DialogTitleProps) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6" className={classes.title}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon fontSize="inherit" />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const isOptedForInstallationService = (uApplication: any, app: any) => {
  return true;
};

const createListFields = (fields: any) => {
  const array = [];
  for (const key in fields) {
    array.push({
      name: key,
      value: fields[key],
    });
  }
  return array;
};

const getConfigData = (data: any) => {
  const array = [];
  for (const key in data) {
    array.push({
      key: key,
      value: data[key],
    });
  }
  return array;
};

const extractTags = (deviceDetails: any) => {
  const temp: any[] = [];
  for (const tag in deviceDetails.device_config.tags) {
    if (deviceDetails.device_config.tags[tag].type === "Tabular") {
      temp.push({
        tag_name: tag,
        tag_value: deviceDetails.device_config.tags[tag].value,
        tag_type: deviceDetails.device_config.tags[tag].type,
        table_values: deviceDetails.device_config.tags[tag].table_values,
      });
    } else if (
      deviceDetails.device_config.tags[tag].type === "Text" ||
      deviceDetails.device_config.tags[tag].type === "Dropdown"
    ) {
      temp.push({
        tag_name: tag,
        tag_value: deviceDetails.device_config.tags[tag].value,
        tag_type: deviceDetails.device_config.tags[tag].type,
      });
    }
  }
  return temp;
};

const ViewDetails: React.FC<any> = ({
  record,
  endPoint,
  onDone,
  readOnly = false,
}) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { selectedApplication } = useApplicationContext();
  const [selectedImageForFullScreenView, setSelectedImageForFullScreenView] =
    useState(null);

  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [showDeviceHistoryDialog, setShowDeviceHistoryDialog] = useState(false);

  const { data: deviceDetails, status } = useApi(
    endPoint ||
      `/applications/${selectedApplication.application_id}/devices/${record?.device_dlm_id}`,
    {
      method: "GET",
      responseDataFormatter: (data: any = [{}]) => {
        const deviceDetailsRaw = data.device;
        const formattedTags = extractTags(deviceDetailsRaw);
        return { ...deviceDetailsRaw, formattedTags };
      },
      onError: (error) => {
        enqueueSnackbar(error, { variant: "error" });
      },
    }
  );

  let deviceDlmId = deviceDetails?.device_dlm_id;
  let applicationId = deviceDetails?.application_id;

  if (status.pending) {
    return <LoadingIndicator />;
  }

  if (!deviceDetails) {
    return (
      <div className={classes.errorDisplay}>
        Unable to fetch device details!
      </div>
    );
  }

  if (selectedImageForFullScreenView) {
    return (
      <FullScreenView
        image={selectedImageForFullScreenView}
        requestPage={PAGES.DEVICES}
        imageAccessBaseUrl={`/applications/${applicationId}/device-installation/${deviceDlmId}/device-img`}
        onClose={() => setSelectedImageForFullScreenView(null)}
      />
    );
  }

  if (showDeleteDialog) {
    return <DeleteDevice record={record} onDone={onDone} />;
  }

  if (showDeviceHistoryDialog) {
    return (
      <DeviceHistory
        applicationId={applicationId}
        deviceDlmId={deviceDlmId}
        onDone={() => setShowDeviceHistoryDialog(false)}
      />
    );
  }

  return (
    <InputContextProvider
      applicationId={applicationId}
      lookup={{ selectedConfigId: deviceDetails.device_config_id }}
    >
      <DialogTitle id="customized-dialog-title" onClose={onDone}>
        View Device Details
      </DialogTitle>
      <Backdrop className={classes.backdrop} open={status.pending}>
        <Grid container direction="column" alignItems="center">
          <LinearProgress style={{ color: "#FCFCFC", width: "30%" }} />
          <Typography
            style={{
              fontFamily: "Avenir Next",
              fontSize: "14px",
              lineHeight: "16px",
              display: "flex",
              alignItems: "center",
              margin: "1em",
              color: "#FFFFFF",
            }}
          >
            Please wait. Request is in progress.
          </Typography>
        </Grid>
      </Backdrop>
      <DialogContent>
        <Grid container spacing={0} className="dcOuter">
          <Grid item xs={4}>
            <Typography className="dcTitle">Application Name</Typography>
            <Typography className="dcFieldValue">
              {deviceDetails.application_name}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography className="dcTitle">Device Configuration</Typography>
            <Typography className="dcFieldValue">
              {deviceDetails.device_config
                ? deviceDetails.device_config.config_name
                : ""}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography className="dcTitle">
              Connection Configuration
            </Typography>
            <Typography className="dcFieldValue">
              {deviceDetails.conn_config
                ? deviceDetails.conn_config.config_name
                : "Not applicable"}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography className="dcTitle">Device Type</Typography>
            <Typography className="dcFieldValue">
              {deviceDetails.device_config
                ? deviceDetails.device_config.device_type
                : ""}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography className="dcTitle">Authentication Type</Typography>
            <Typography className="dcFieldValue">
              {deviceDetails.device_config
                ? deviceDetails.device_config.auth_type
                : ""}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography className="dcTitle">Edge Type</Typography>
            <Typography className="dcFieldValue">
              {deviceDetails.device_config
                ? deviceDetails.device_config.edge_type
                : ""}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography className="dcTitle">Device Name</Typography>
            <Typography className="dcFieldValue">
              {deviceDetails.device_name ? deviceDetails.device_name : ""}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography className="dcTitle">Device Id</Typography>
            <Typography className="dcFieldValue">
              {deviceDetails.device_id ? deviceDetails.device_id : ""}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography className="dcTitle">Serial Number</Typography>
            <Typography className="dcFieldValue">
              {deviceDetails.serial_num ? deviceDetails.serial_num : ""}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography className="dcTitle">Cloud Gateway</Typography>
            <Typography className="dcFieldValue">
              {deviceDetails.cloud_gateway ? deviceDetails.cloud_gateway : ""}
            </Typography>
          </Grid>
          {deviceDetails.device_config.auth_type === "X509" && (
            <Grid item xs={4}>
              <Typography className="dcTitle">Certificate Expiry</Typography>
              <Typography className="dcFieldValue">
                {deviceDetails.cert_expiry ? deviceDetails.cert_expiry : ""}
              </Typography>
            </Grid>
          )}
          <Grid item xs={4}>
            <Typography className="dcTitle">Application Level</Typography>
            <Typography className="dcFieldValue">
              {deviceDetails.application_level_name
                ? deviceDetails.application_level_name
                : ""}
            </Typography>
          </Grid>
          {isOptedForInstallationService({}, {}) &&
            deviceDetails.device_install_status_name && (
              <Grid item xs={4}>
                <Typography className="dcTitle">Installation Status</Typography>
                <Typography className="dcFieldValue">
                  {deviceDetails.device_install_status_name}
                </Typography>
              </Grid>
            )}
          {createListFields(deviceDetails.fields).map((field: any) => {
            return (
              <Grid item xs={4}>
                <Typography className="dcTitle">{field.name}</Typography>
                <Typography className="dcFieldValue">{field.value}</Typography>
              </Grid>
            );
          })}
          {createListFields(deviceDetails.runtime_metadata).map(
            (field: any) => {
              return (
                <Grid item xs={4}>
                  <Typography className="dcTitle">{field.name}</Typography>
                  <Typography className="dcFieldValue">
                    {field.value === -1 || field.value === "-1" ? (
                      <Typography style={{ color: "#D72B3F" }}>
                        INVALID DATA
                      </Typography>
                    ) : (
                      field.value
                    )}
                  </Typography>
                </Grid>
              );
            }
          )}
        </Grid>
        <Grid container spacing={0} className="dcOuter dcSep">
          <Grid
            item
            xs={12}
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography style={{ fontSize: "19px", fontWeight: "bold" }}>
              Device Images
            </Typography>
            <CustomButton
              variant="outlined-white"
              onClick={() => setShowDeviceHistoryDialog(true)}
            >
              Device History
            </CustomButton>
          </Grid>

          {deviceDetails.device_img ? (
            deviceDetails.device_img.map((item: any) => {
              return (
                <div style={{ margin: "1em" }}>
                  <Grid container direction="column" alignItems="center">
                    <div style={{ color: "#FCFCFC", margin: "0.5em" }}>
                      <b>{item.name ? item.name : ""}</b>
                    </div>
                    <div className={classes.preview}>
                      <ThumbnailView
                        image={item}
                        imageAccessBaseUrl={`/applications/${applicationId}/device-installation/${deviceDlmId}/device-img`}
                        requestPage={PAGES.DEVICES}
                        onClick={() => setSelectedImageForFullScreenView(item)}
                      />
                    </div>
                  </Grid>
                </div>
              );
            })
          ) : (
            <div></div>
          )}
        </Grid>
        <DeviceTagManager tags={deviceDetails.device_config.tags} readOnly />

        <Grid container spacing={0} className="dcOuter dcSep">
          <Grid item xs={12}>
            <List
              dense={true}
              classes={{ root: classes.noSpace }}
              className={classes.spaceTop}
            >
              <ListItem classes={{ root: classes.noSpace }}>
                <ListItemText
                  classes={{ root: classes.noSpace }}
                  primary="Properties"
                  primaryTypographyProps={{
                    style: {
                      color: "#FFFFFF",
                      fontWeight: "bold",
                      fontSize: "19px",
                    },
                  }}
                  secondary=""
                  secondaryTypographyProps={{
                    style: {
                      color: "#FCFCFC",
                      fontSize: "12px",
                      marginTop: "10px",
                    },
                  }}
                />
              </ListItem>
            </List>
          </Grid>
          {deviceDetails.device_config ? (
            getConfigData(deviceDetails.device_config.properties).map(
              (item) => (
                <Grid item xs={4}>
                  <Typography className="dcTitle">{item.key}</Typography>
                  <Typography className="dcFieldValue">{item.value}</Typography>
                </Grid>
              )
            )
          ) : (
            <div></div>
          )}
        </Grid>
        <Grid container spacing={0} className="dcOuter dcSep">
          <Grid item xs={12}>
            <List
              dense={true}
              classes={{ root: classes.noSpace }}
              className={classes.spaceTop}
            >
              <ListItem classes={{ root: classes.noSpace }}>
                <ListItemText
                  classes={{ root: classes.noSpace }}
                  primary="Device Twin"
                  primaryTypographyProps={{
                    style: {
                      color: "#FFFFFF",
                      fontWeight: "bold",
                      fontSize: "19px",
                    },
                  }}
                  secondary=""
                  secondaryTypographyProps={{
                    style: {
                      color: "#FCFCFC",
                      fontSize: "12px",
                      marginTop: "10px",
                    },
                  }}
                />
              </ListItem>
            </List>
          </Grid>
          {deviceDetails.device_twin ? (
            <Typography className="dcFieldValue">
              <pre>{JSON.stringify(deviceDetails.device_twin, null, 2)}</pre>
            </Typography>
          ) : (
            <div></div>
          )}
        </Grid>
        {/* <DeviceConnectionString
          deviceDlmId={deviceDlmId}
          applicationId={applicationId}
          value={deviceDetails.device_conn_string}
        /> */}
      </DialogContent>
      {!readOnly && (
        <DialogActions>
          <Button
            onClick={() => {
              setShowDeleteDialog(true);
            }}
            variant="outlined"
            className="dcBtDelete"
          >
            Delete
          </Button>
          <NavLink
            to={`/edit-device/${deviceDlmId}`}
            style={{ textDecoration: "none" }}
          >
            <Button
              onClick={() => true}
              variant="contained"
              className="dcBtEdit"
            >
              Edit
            </Button>
          </NavLink>
        </DialogActions>
      )}
    </InputContextProvider>
  );
};

export default ViewDetails;
