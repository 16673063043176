import React, { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import useApi from "../../../../hooks/useApi";
import {
  CustomButton,
  DeviceTagManager,
  GriddedTextField,
  LoadingIndicator,
} from "../../../UiComponents";
import { InputContextProvider } from "../../../../contexts/InputContext";
import { useApplicationContext } from "../../../../contexts/ApplicationContext";
import {
  BasicDeviceFields,
  CustomDeviceFields,
  DeviceProperties,
  Submit,
} from "./formParts";
import HeaderNavigation from "./HeaderNavigation";
import useStyles from "./EditDevice.styles";
import { useHistory, useParams } from "react-router-dom";

const isEmpty = (obj: any) => Object.keys(obj).length === 0;

const EditDevice: React.FC<any> = () => {
  const { deviceDlmId }: any = useParams();
  const history = useHistory();
  const { selectedApplication } = useApplicationContext();
  const classes = useStyles();
  const [formValues, setFormValues] = useState<any>({});
  const [disableSubmitButton, setDisableSubmitButton] =
    useState<boolean>(false);

  const gotoDeviceManagement = () => history.push("/");

  const { data: deviceDetails } = useApi(
    `/applications/${selectedApplication.application_id}/devices/${deviceDlmId}`,
    {
      method: "GET",
      responseDataFormatter: (data: any) => {
        return data.device;
      },
      // mock: { fetcher: mockApi },
    }
  );

  useEffect(() => {
    deviceDetails && setFormValues(deviceDetails);
  }, [deviceDetails]);

  if (!deviceDetails || isEmpty(formValues)) {
    return <LoadingIndicator />;
  }

  return (
    <InputContextProvider
      applicationId={+selectedApplication.application_id}
      lookup={{ selectedConfigId: formValues.device_config_id }}
    >
      <HeaderNavigation onClick={gotoDeviceManagement} />
      <div className={classes.wrapper}>
        <Grid container>
          <BasicDeviceFields
            deviceDetails={formValues}
            onFieldChange={(field: string, value: string) => {
              setFormValues({ ...formValues, [field]: value });
            }}
          />
          {deviceDetails.fields && (
            <CustomDeviceFields
              fields={formValues.fields}
              onFieldChange={(field: string, value: string) =>
                setFormValues({
                  ...formValues,
                  fields: { ...formValues.fields, [field]: value },
                })
              }
            />
          )}
        </Grid>
        <Grid container>
          {/* This functionality is disabled. */}
          <CustomButton
            variant="link-blue"
            onClick={() => true}
            disabled
            style={{ marginLeft: "1em" }}
          >
            Add New Field
          </CustomButton>
        </Grid>

        {deviceDetails.device_config?.tags && (
          <DeviceTagManager
            tags={formValues.device_config.tags}
            onTagRenderingError={() => setDisableSubmitButton(true)}
            onTagUpdate={(updatedTags: any) => {
              setFormValues({
                ...formValues,
                device_config: {
                  ...formValues.device_config,
                  tags: updatedTags,
                },
              });
            }}
          />
        )}
        {deviceDetails.device_config?.properties && (
          <DeviceProperties
            properties={formValues.device_config.properties}
            onPropertyChange={(propertyName: string, value: any) => {
              setFormValues({
                ...formValues,
                device_config: {
                  ...formValues.device_config,
                  properties: {
                    ...formValues.device_config.properties,
                    [propertyName]: value,
                  },
                },
              });
            }}
          />
        )}
        {formValues.conn_config && (
          <Grid container>
            <GriddedTextField
              readOnly
              label="Connection Configuration"
              value={formValues.conn_config?.config_name}
            />
          </Grid>
        )}
        <Submit
          application={selectedApplication.application_id}
          deviceDlmId={deviceDlmId}
          onDone={gotoDeviceManagement}
          formValues={formValues}
          disabled={disableSubmitButton}
        />
      </div>
    </InputContextProvider>
  );
};

export default EditDevice;
