import React, { useEffect, useRef, useState } from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import { useSnackbar } from "notistack";
import Compressor from "compressorjs";
import {
  arrayOfImageTypes,
  maxImageSize,
  isValidImageExtension,
} from "../../../../utils/helper";

import useApi from "../../../../hooks/useApi";
import { LoadingIndicator, CustomButton } from "../../../UiComponents";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    imageUploader: {
      margin: "1em",
      display: "flex",
      flexFlow: "column",
      width: "15em",
      alignItems: "center",
    },
    preview: {
      width: "210px",
      height: "118px",
      background: "rgba(0, 0, 0, 0.11)",
      border: "1px dashed rgba(255, 255, 255, 0.2)",
    },
    uploader: {
      display: "flex",
      marginTop: "1em",
      width: "10em",
      justifyContent: "space-between",
    },
    fileName: {
      fontSize: "0.8em",
      maxWidth: "18em",
      textOverflow: "ellipsis",
      overflow: "hidden",
      whiteSpace: "nowrap",
      marginBottom: "0.5em",
      minHeight: "16px",
    },
  })
);

const MAX_IMAGE_SIZE_IN_MB = maxImageSize;
const SUPPORTED_IMAGE_FORMATS = arrayOfImageTypes;

const convertToMb = (size: number) => size / 1024 / 1024;

interface IUploaderProps {
  imageUploadEndPoint: string;
  fileData: any;
  requestPage: string;
  onRemove: () => void;
  onDone: () => void;
}
const Uploader: React.FC<IUploaderProps> = ({
  imageUploadEndPoint,
  fileData,
  requestPage,
  onRemove,
  onDone,
}) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const {
    data,
    status,
    trigger: uploadDeviceImage,
  } = useApi(imageUploadEndPoint, {
    method: "POST",
    deferred: true,
    requestFormat: "form-data",
  });

  useEffect(() => {
    if (data?.message === "Success") {
      onDone();
    }
    if (!status.pending && status.error) {
      enqueueSnackbar("You are not authorized to perform this operation", {
        variant: "error",
      });
    }
  }, [data?.message, status.error, status.pending]);

  return (
    <div className={classes.uploader}>
      {status.pending && <LoadingIndicator />}
      <CustomButton
        variant="outlined-green"
        style={{ fontWeight: "bolder" }}
        onClick={() =>
          uploadDeviceImage({
            input_file: fileData.file,
            thumbnail: fileData.thumbnail,
            img_name: fileData.file.name,
            request_page: requestPage,
          })
        }
      >
        Upload
      </CustomButton>
      <CustomButton
        variant="outlined-red"
        style={{ fontWeight: "bolder" }}
        onClick={onRemove}
      >
        Remove
      </CustomButton>
    </div>
  );
};

interface IImageUploaderProps {
  imageUploadEndPoint: string;
  requestPage: string;
  onRemove: () => void;
  onDone: () => void;
}

const ImageUploader: React.FC<IImageUploaderProps> = ({
  imageUploadEndPoint,
  requestPage,
  onRemove,
  onDone,
}) => {
  const classes = useStyles();
  const fileInputRef = useRef<any>(null);
  const [fileData, setFileData] = useState<any>(null);
  const { enqueueSnackbar } = useSnackbar();

  const handleFileChange = (event: any) => {
    if (!event.target.files.length) {
      return;
    }

    const file = event.target.files[0];

    if (convertToMb(file.size) > MAX_IMAGE_SIZE_IN_MB) {
      enqueueSnackbar(
        `Maximum allowed image size is ${MAX_IMAGE_SIZE_IN_MB} Megabytes.`,
        { variant: "error" }
      );
      return;
    }

    if (!SUPPORTED_IMAGE_FORMATS.includes(file.type.toLowerCase())) {
      enqueueSnackbar(
        `Only image files are allowed. Uploaded type: ${file.type}`,
        { variant: "error" }
      );
      return;
    }

    if (!isValidImageExtension(file)) {
      enqueueSnackbar(`Invalid file extension (only jpg/png allowed)`, {
        variant: "error",
      });
      return;
    }

    new Compressor(file, {
      quality: 0.5,
      width: 200,
      height: 118,
      success: (compressedResult: any) => {
        var reader = new FileReader();
        reader.readAsDataURL(compressedResult);
        reader.onload = function () {
          setFileData({
            thumbnail: reader.result,
            file,
          });
        };
        reader.onerror = function (error) {
          console.error("Error: ", error);
          enqueueSnackbar("Could not load image.", { variant: "error" });
        };
      },
    });
  };

  return (
    <div className={classes.imageUploader}>
      <span className={classes.fileName}>{fileData?.file.name}</span>
      <div className={classes.preview}>
        {fileData && (
          <img
            src={fileData.thumbnail}
            style={{
              width: "100%",
              height: "100%",
            }}
          />
        )}
      </div>
      <input
        style={{ display: "none" }}
        ref={fileInputRef}
        type="file"
        accept=".jpg,.jpeg,.png"
        onChange={handleFileChange}
      />

      <CustomButton
        variant="outlined-white"
        onClick={() => fileInputRef.current.click()}
      >
        Choose File
      </CustomButton>

      {fileData && (
        <Uploader
          fileData={fileData}
          imageUploadEndPoint={imageUploadEndPoint}
          onRemove={onRemove}
          onDone={onDone}
          requestPage={requestPage}
        />
      )}
    </div>
  );
};

export default ImageUploader;
