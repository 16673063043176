import React, { useEffect, useState } from "react";
import {
  createStyles,
  Theme,
  makeStyles,
  withStyles,
} from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import SearchIcon from "@material-ui/icons/Search";
import { ClickAwayListener } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

import { NativeDropDown } from "../DropDown";
import SubmitButton from "../SubmitButton";

const CssTextField = withStyles({
  root: {
    "& input": {
      padding: "10px",
      color: "#ffffff",
      backgroundColor: "#1D1D25 ",
      border: "1px solid #464659",
      borderRadius: "2px",
      margin: "0 10px",
    },
    "& .MuiInput-multiline": {
      padding: "10px",
      color: "#ffffff",
      backgroundColor: "#1D1D25 ",
      border: "1px solid #464659",
      borderRadius: "2px",
      marginTop: "32px",
    },
    "& .MuiInput-underline": {
      "&&:before": {
        borderBottom: "none",
      },
      "&&:after": {
        borderBottom: "none",
      },
    },
    "& label.Mui-focused": {
      color: "#ffffff",
    },
  },
})(TextField);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formWrap: {
      display: "flex",
      alignItems: "center",
    },
    listButtonWrap: {
      marginLeft: "20px",
    },
    listButton: {
      padding: "0px",
      textTransform: "none",
      fontSize: "14px",
      whiteSpace: "nowrap",
    },
    labels: {
      color: "#fcfcfc",
      lineHeight: "16px",
      fontSize: "14px",
      transform: "scale(1)",
    },
  })
);

interface ISearchProps {
  searchableFields: any[];
  onSearch: (field: string, value: string) => void;
  defaultSearch?: any;
}

const Search: React.FC<ISearchProps> = ({
  defaultSearch,
  searchableFields,
  onSearch,
}) => {
  const [searchActive, setSearchActive] = useState(false);
  const [searchField, setSearchField] = useState(
    defaultSearch?.field || searchableFields[0].value
  );
  const [searchValue, setSearchValue] = useState(defaultSearch?.value || "");

  const classes = useStyles();

  useEffect(() => {
    if (!searchActive && searchValue === "") {
      onSearch("", ""); //remove search filters
    }
  }, [searchActive, searchValue]);

  return (
    <ClickAwayListener
      onClickAway={() => !searchValue && setSearchActive(false)}
    >
      {searchActive || searchValue !== "" ? (
        <div className={classes.formWrap}>
          <form onSubmit={(e) => e.preventDefault()}>
            <NativeDropDown
              options={searchableFields}
              value={searchField}
              onChange={(field: string) => setSearchField(field)}
            />

            <CssTextField
              InputProps={{
                disableUnderline: true,
              }}
              InputLabelProps={{
                shrink: false,
                className: classes.labels,
              }}
              autoComplete="off"
              value={searchValue}
              onChange={(event) => setSearchValue(event.target.value)}
              id="filled-search"
              type="search"
              variant="filled"
            />
            <SubmitButton
              onClick={() => onSearch(searchField, searchValue)}
              type="submit"
              disabled={!searchValue}
            >
              Search
            </SubmitButton>
          </form>
          <CloseIcon
            style={{ marginLeft: "0.5em", cursor: "pointer", color: "white" }}
            onClick={() => {
              setSearchValue("");
              setSearchActive(false);
            }}
          ></CloseIcon>
        </div>
      ) : (
        <Button
          variant="text"
          color="inherit"
          className={classes.listButton}
          startIcon={<SearchIcon style={{ color: "#7C7C94" }} />}
          onClick={() => setSearchActive(true)}
        >
          <span style={{ color: "white" }}>Search</span>
        </Button>
      )}
    </ClickAwayListener>
  );
};

export default Search;
