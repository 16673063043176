import React from "react";

const ServiceUnavailable: React.FC = ({ children }) => {
  return (
    <div
      style={{
        color: "white",
        fontSize: "1.6em",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "80vh",
      }}
    >
      {children}
    </div>
  );
};

export default ServiceUnavailable;
