import React from "react";
import { Grid } from "@material-ui/core";

import {
  DropDown,
  FormSection,
  GriddedTextField,
} from "../../../../UiComponents";

const DeviceConnectionConfigurationFields: React.FC<any> = ({
  loriotData,
  onFieldChange,
}) => {
  return (
    <FormSection header="Connection Configuration">
      <GriddedTextField
        readOnly
        label="Connection Configuration"
        value={loriotData.conn_config.config_name}
      />
      <GriddedTextField
        label="Device Title"
        value={loriotData.conn_request_payload.title}
        onChange={(value: string) => onFieldChange("title", value)}
      />
      <GriddedTextField
        label="Device Description *"
        value={loriotData.conn_request_payload.description}
        onChange={(value: string) => onFieldChange("description", value)}
      />
      <Grid item xs={6} style={{ padding: "0 5px" }}>
        <DropDown
          options={["A", "B", "C", "D"].map((c) => ({ value: c, label: c }))}
          value={loriotData.conn_request_payload.devclass}
          label="Class *"
          onChange={(value: string) => onFieldChange("devclass", value)}
        />
      </Grid>
      <GriddedTextField
        label="App Key *"
        value={loriotData.conn_request_payload.appkey}
        onChange={(value: string) => onFieldChange("appkey", value)}
      />
      <GriddedTextField
        readOnly
        label="Device EUI *"
        value={loriotData.conn_request_payload.deveui}
        onChange={(value: string) => onFieldChange("deveui", value)}
      />
      <GriddedTextField
        label="App EUI *"
        value={loriotData.conn_request_payload.appeui}
        onChange={(value: string) => onFieldChange("appeui", value)}
      />
    </FormSection>
  );
};

export default DeviceConnectionConfigurationFields;
