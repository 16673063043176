import React, { useEffect, useState } from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import CustomButton from "../CustomButton";
import { DropDown } from "../DropDown";
import Modal from "../Modal";
import SubmitButton from "../SubmitButton";

import { useMultiFilterContext } from "./MultiFilterContextProvider";
import { getFilterValueByType } from "./utils";
import { IFilter, IFilterSchema } from "./types";
import CustomFieldRenderer from "./CustomFieldRenderer";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      padding: "0px",
      textTransform: "none",
      fontSize: "14px",
      whiteSpace: "nowrap",
    },
    buttonHolder: {
      display: "flex",
      justifyContent: "space-between",
      borderTop: "1px solid #6b6868",
      paddingTop: "0.5em",
      margin: "15px 0px",
    },
  })
);

const getFilterSchema = (
  availableFilters: IFilterSchema[],
  filterType: string
): IFilterSchema => {
  const filterSchema = availableFilters.find(({ id }) => id === filterType);
  if (!filterSchema) {
    throw `Given filterType ${filterType} is not found in FilterSchema`;
  }
  return filterSchema;
};

interface IMultiFilterModalProps {
  onClose: () => void;
  onSelect: (filter: IFilter) => void;
}

const MultiFilterModal: React.FC<IMultiFilterModalProps> = ({
  onClose,
  onSelect,
}) => {
  const classes = useStyles();
  const { availableFilters, activeFilters, openedFilter } =
    useMultiFilterContext();

  const [selectedFilterType, setSelectedFilterType] = useState(openedFilter);
  const [selectedFilterValue, setSelectedFilterValue] = useState(
    getFilterValueByType(activeFilters, openedFilter)
  );

  const selectableFilterOptions = availableFilters.map(
    ({ id: value, label }: any) => ({ value, label })
  );

  useEffect(() => {
    let currentValue = getFilterValueByType(activeFilters, selectedFilterType);
    if (!currentValue && selectedFilterType !== "-") {
      const filterSchema = getFilterSchema(
        availableFilters,
        selectedFilterType
      );
      currentValue = filterSchema.input?.defaultValue || "";
    }
    setSelectedFilterValue(currentValue);
  }, [selectedFilterType]);

  return (
    <Modal open={true} onClose={onClose} title="">
      <div style={{ width: "600px" }}>
        <form onSubmit={(e) => e.preventDefault()}>
          <DropDown
            label="Filter On *"
            options={selectableFilterOptions}
            onChange={(v) => setSelectedFilterType(v)}
            value={selectedFilterType}
            style={{ margin: "15px 0" }}
          />
          {selectedFilterType !== "-" && (
            <CustomFieldRenderer
              filterSchema={getFilterSchema(
                availableFilters,
                selectedFilterType
              )}
              filterValue={selectedFilterValue}
              onFilterValueChange={(value: any) =>
                setSelectedFilterValue(value)
              }
            />
          )}

          <div className={classes.buttonHolder}>
            <CustomButton variant="outlined-white" onClick={onClose}>
              Cancel
            </CustomButton>

            <SubmitButton
              onClick={() => {
                const filterId = availableFilters.find(
                  ({ id }) => id === selectedFilterType
                )?.device_metadata_options_id;
                onSelect({
                  filterType: selectedFilterType,
                  filterValue: (selectedFilterValue + "").trim(),
                  filterId,
                });
                onClose();
              }}
              disabled={
                !selectedFilterType || !(selectedFilterValue + "").trim()
              }
              type="submit"
            >
              Apply
            </SubmitButton>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default MultiFilterModal;
