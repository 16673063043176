import React from "react";
import {
  Typography,
  makeStyles,
  Button,
  Grid,

} from "@material-ui/core";

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import CardContent from "@material-ui/core/CardContent";
import Card from "@material-ui/core/Card";
import CloseSharpIcon from "@material-ui/icons/CloseSharp";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  cardTitle: {
    fontSize: "14px",
    fontWeight: "bold",
    lineHeight: "19px",
    color: "#FFFFFF",
    marginBottom: "16px",
  },
  cardText: {
    fontSize: "12px",
    lineHeight: "16px",
    color: "#FFFFFF",
    marginBottom: "10px",
  },
  CardStyle: {
    background: "#2B2B36",
    borderRadius: "3px",
  },
  cardGrid: {
    marginBottom: "20px",
  },
  dialogueTitle: {
    fontSize: "21px",
    fontWeight: "bold",
    display: "flex",
    marginTop: "5px",
    color: "#FFFFFF",
  },
  closeIcon: {
    color: "#FFFFFF",
    marginBottom: "20px",
    padding: "0px",
  },
  detailLabel: {
    fontSize: "16px",
    fontWeight: "bold",
  },
  detailData: {
    fontSize: "14px",
  },
  details: {
    color: "#FFFFFF",
  },
  detailsItems: {
    padding: "10px 0",
  },
  deleteButton: {
    textTransform: "none",
    font: "400 13.3333px 'Avenir Next'",
    padding: "10px 20px",
    color: "#0089FF",
    border: "1px solid rgba(235, 235, 245, 0.35)",
  },
  editButton: {
    background: "#0089FF",
    font: "400 13.3333px 'Avenir Next'",
    padding: "10px 20px",
    textTransform: "none",
    "&:hover": {
      background: "#0089FF",
    },
  },
}));
interface AddResourceDetailsInterface {
  displayResourceDetails: boolean;
  toggleDisplayResourceDetails: () => void;
  toggleDisplayEditResource: () => void;
  toggleDisplayDeleteResource: () => void;
  selectedResources: any;
}

export default function ResourceDetails({
  displayResourceDetails,
  toggleDisplayResourceDetails,
  toggleDisplayEditResource,
  selectedResources,
  toggleDisplayDeleteResource
}: AddResourceDetailsInterface) {
  const classes = useStyles();
  return (
    <Dialog
      open={displayResourceDetails}
      onClose={toggleDisplayResourceDetails}
      maxWidth="xl"
      PaperProps={{
        style: {
          backgroundColor: "#1D1D25",
          boxShadow: "0px 30px 100px #000000",
          borderRadius: "3px",
          width: "800px",
        },
      }}
    >
      <DialogContent>
        <Grid container direction="column">
          <Grid container direction="row" justify="space-between">
            <Grid item>
              <Typography className={classes.dialogueTitle}>
                IoT Hub Details
              </Typography>
            </Grid>
            <Grid item>
              <Button onClick={toggleDisplayResourceDetails}>
                <CloseSharpIcon className={classes.closeIcon}></CloseSharpIcon>
              </Button>
            </Grid>
          </Grid>
          <Grid item className={classes.cardGrid}>
            <Card
              variant="outlined"
              elevation={3}
              classes={{
                root: classes.CardStyle,
              }}
            >
              <CardContent>
                <Grid container direction="column">
                  <Grid item>
                    <Grid container spacing={0} className={classes.details}>
                      <Grid item xs={4} className={classes.detailsItems}>
                        <Typography className={classes.detailLabel}>
                          IoT Hub Name
                        </Typography>
                        <Typography className={classes.detailData}>
                          {selectedResources.iot_hub_name}
                        </Typography>
                      </Grid>
                      <Grid item xs={4} className={classes.detailsItems}>
                        <Typography className={classes.detailLabel}>
                          Region
                        </Typography>
                        <Typography className={classes.detailData}>
                          {selectedResources.region}
                        </Typography>
                      </Grid>
                      <Grid item xs={4} className={classes.detailsItems}>
                        <Typography className={classes.detailLabel}>
                          SKU
                        </Typography>
                        <Typography className={classes.detailData}>
                          {selectedResources.sku}
                        </Typography>
                      </Grid>
                      <Grid item xs={4} className={classes.detailsItems}>
                        <Typography className={classes.detailLabel}>
                          Subscription Name
                        </Typography>
                        <Typography className={classes.detailData}>
                          {selectedResources.subscription_name}
                        </Typography>
                      </Grid>
                      <Grid item xs={4} className={classes.detailsItems}>
                        <Typography className={classes.detailLabel}>
                          Resource Group Name
                        </Typography>
                        <Typography className={classes.detailData}>
                          {selectedResources.resource_group_name}
                        </Typography>
                      </Grid>
                      {selectedResources.scope_id && <Grid item xs={4} className={classes.detailsItems}>
                        <Typography className={classes.detailLabel}>
                          Scope Id
                        </Typography>
                        <Typography className={classes.detailData}>
                          {selectedResources.scope_id}
                        </Typography>
                      </Grid>}
                      
                      {/* <Grid item xs={12} className={classes.detailsItems}>
                        <Typography className={classes.detailLabel}>
                          Device Connection String
                        </Typography>
                        <Typography className={classes.detailData}>
                          {selectedResources.device_conn_string}
                        </Typography>
                      </Grid> */}
                      {/* <Grid item xs={4} className={classes.detailsItems}>
                        <Typography className={classes.detailLabel}>
                          Service Connection String
                        </Typography>
                        <Typography className={classes.detailData}>
                          {selectedResources.service_conn_string}
                        </Typography>
                      </Grid> */}
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          <Grid item className={classes.cardGrid}>
            <Grid
              item
              container
              direction="row"
              justify="space-between"
              spacing={2}
            >
              <Grid item>
                <Button variant="outlined" className={classes.deleteButton} onClick={toggleDisplayDeleteResource}>
                  Delete
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  className={classes.editButton}
                  onClick={toggleDisplayEditResource}
                  style={{ color: "#FFFFFF" }}
                >
                  Edit
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}
