import React, { FC } from 'react';
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      marginLeft: 0,
      padding: theme.spacing(4),
      background: 'bottom',
      left: theme.spacing(4),

    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    }
  });

export interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6" style={{ color: "#ffffff" }} >{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(4),
    background: 'bottom',

  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme: Theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(4),
    background: 'bottom',
    paddingLeft: "31rem"
  },
}))(MuiDialogActions);

interface ChildComponentProps {
  open: any;
  message: any;
  updateError: any;
}

const CustomizedDialogs: FC<ChildComponentProps> = (props: ChildComponentProps) => {
  const handleClose = () => {
    props.updateError();
  };
  return (
    <div>
      <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={props.open} maxWidth="xl" style={{ paddingLeft: "12rem" }} >
        <DialogTitle id="customized-dialog-title" onClose={handleClose} >
          Error
        </DialogTitle>
        <DialogContent dividers>
          <Typography gutterBottom style={{ color: "#ffffff" }}>{props.message}</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" style={{ textTransform: "none", color: "#ffffff", backgroundColor: "#0089ff", left: "10px" }} >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default CustomizedDialogs;
