import React, { useState, useEffect } from "react";
import {
  makeStyles,
  createStyles,
  Theme,
  withStyles,
} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import InputLabel from "@material-ui/core/InputLabel";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { useSnackbar } from "notistack";
import { NavLink } from "react-router-dom";
import { API } from "../../api/property";
import callXhrRequest from '../../utils/xhrRequestHandler';
import { arrayOfErrorsRefresh, arrayOfErrorsLogout } from '../../utils/helper';
import { useStore } from "mobx-store-provider";
import { Backdrop, CircularProgress } from "@material-ui/core";
import BreadcumbComponent from '../../utils/breadcurmb';
const colorLiteral = "1px solid #464659"
const CssTextField = withStyles({
  root: {
    "& input": {
      padding: "10px",
      color: "#ffffff",
      backgroundColor: "#1D1D25 ",
      border: `${colorLiteral}`,
      borderRadius: "2px",
      marginTop: "16px",
    },
    "& .MuiInput-underline": {
      "&&:before": {
        borderBottom: "none",
      },
      "&&:after": {
        borderBottom: "none",
      },
    },
    "& label.Mui-focused": {
      color: "#ffffff",
    },
    "& label": {
      paddingLeft: "10px"
    },
    "&& label1": {
      paddingRight: "10px"
    },
  },
})(TextField);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      margin: "30px",
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: "#fff",
    },
    noSpace: {
      padding: "0px",
      margin: "0px",
    },
    spaceTop: {
      marginTop: "35px",
    },
    space2Top: {
      marginTop: "20px",
    },
    spaceRight: {
      paddingRight: "10px",
    },
    spaceLeft: {
      paddingLeft: "10px",
    },
    formTitle: {
      fontWeight: "bold",
      fontSize: "21px",
      lineHeight: "25px",
      color: "#FFFFFF",
    },
    gridRoot: {
      flexGrow: 1,
      padding: "0.5em 24px",
      backgroundColor: "#2B2B36",
    },
    form: {
      width: "100%",
    },
    fieldWrapper: {
      marginTop: "20px",
    },
    labels: {
      color: "#fcfcfc",
      fontSize: "16px",
      transform: "scale(1)",
      left: "-10px"
    },
    accordionRoot: {
      color: "#FFFFFF",
      margin: "0px",
      padding: "0px",
      boxShadow: "none",
      "& .MuiAccordionSummary-content": {
        margin: "0px",
      },
      minHeight: "auto !important",
    },
    noSpaceNoMin: {
      margin: "0px !important",
      padding: "0px !important",
      minHeight: "auto !important",
    },
    typoRoot: {
      paddingLeft: "24px",
    },
    field: {
      color: "#FCFCFC",
      fontSize: "12px",
      padding: "10px",
    },
    textfield: {
      color: "#ffffff",
      backgroundColor: "#1D1D25",
      border: `${colorLiteral}`,
      borderRadius: "2px",
      boxSizing: "border-box",
    },
    icon: {
      color: "#FFFFFF",
    },
    options: {
      color: "#FCFCFC",
      fontSize: "12px",
      padding: "10px",
      cursor: "pointer",
    },
    fieldRoot: {
      marginTop: "16px",
    },
    selectRoot: {
      padding: "24px",
    },
    datePickerRoot: {
      color: "#FFFFFF",
      border: `${colorLiteral}`,
      borderRadius: "2px",
      "& .MuiIconButton-root, .MuiPickersDay-day, .MuiPickersCalendarHeader-dayLabel": {
        color: "#FFFFFF",
      },
      "& .MuiPickersDay-daySelected": {
        border: `${colorLiteral}`,
      },
    },
    bottomSection: {
      justifyContent: "space-between",
      display: "flex",
      borderTop: "1px solid #33333F",
      paddingTop: "20px",
    },
    btnCancel: {
      borderRadius: "2px",
      border: "1px solid rgba(235,235,245,0.35)",
      color: "#0089FF",
      background: "none",
      fontSize: "16px",
      lineHeight: "19px",
      textTransform: "none",
      padding: "9px 20px",
    },
    btnAddNUser: {
      backgroundColor: "#0089ff",
      borderRadius: "4px",
      border: "none",
      color: "#FFFFFF",
      "&:hover": {
        backgroundColor: "#0089ff",
      },
      fontSize: "16px",
      lineHeight: "19px",
      textTransform: "none",
      padding: "9px 20px",
    },
    vp: {
      backgroundColor: "#2B2B36",
      border: `${colorLiteral}`,
      borderRadius: "2px",
      color: "#FCFCFC",
      "&:hover": {
        backgroundColor: "#2B2B36",
      },
      fontSize: "14px",
      lineHeight: "16px",
      textTransform: "none",
      padding: "12px 0px",
      marginTop: "34px",
      width: "100%",
    },
    helperText: {
      color: "#d32f2f !important",
      position: "absolute",
      right: 0,
    },
  })
);

interface CreateNewExistingResourceInterface {
  iotHubData: any;
  onCancelAction: any;
}

export default function CreateExistingResource({ iotHubData, onCancelAction }: CreateNewExistingResourceInterface) {
  const classes = useStyles();
  const { user } = useStore();

  const [backDrop, setbackDrop] = React.useState(false);
  const backDropClose = () => {
    setbackDrop(false);
  };


  const API_KEY = window.SERVER_DATA.REACT_APP_PM_API_KEY;
  interface sObj {
    pm_s_id: string;
  }
  interface tObj {
    pm_s_token: string;
  }
  let pmSId: sObj = {
    pm_s_id: "",
  };
  let pmST: tObj = {
    pm_s_token: "",
  };
  pmSId =
    sessionStorage.getItem("pm_s_id") || JSON.parse(JSON.stringify(pmSId));
  pmST =
    sessionStorage.getItem("pm_s_token") || JSON.parse(JSON.stringify(pmST));
  const { enqueueSnackbar } = useSnackbar();
  const [resourceInput, setResourceInput] = useState(
    [] as any
  );
  const [resourceInputAppl, setResourceInputAppl] = useState(
    [] as any
  );
  const [app, setApp] = useState(
    resourceInputAppl.length > 0 && resourceInputAppl[0].application_name
  );
  const [reload, setReload] = React.useState(false);
  const [subscriptioId, setSubscriptioId] = useState("");
  const [subscriptioName, setSubscriptioName] = useState("");
  const [resourceGroup, setResourceGroup] = useState("");
  const [iotHubName, setIotHubName] = useState("");
  const [ownerConnString, setOwnerConnString] = useState("");
  const [deviceConnString, setDeviceConnString] = useState("");
  const [serviceConnString, setServiceConnString] = useState("");
  const [loader, setLoader] = useState(false);

  const [region, setRegion] = useState([] as any);
  const [sku, setSKU] = useState([] as any);


  const onChangeApplication = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setApp(event.target.value);
  };

  const handleChangeRegion = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRegion(event.target.value);
  };

  const handleChangeSKU = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSKU(event.target.value);
  };

  const Regions = [] as any;
  const Skus = [] as any;
  Array.isArray(resourceInput) && resourceInput.forEach((r: any) => {
    if (r.regions) {
      r.regions.forEach((region: any) => {
        Regions.push({ label: region, value: region })
      })
    }
    if (r.skus) {
      r.skus.forEach((s: any) => {
        Skus.push({ label: s, value: s })
      })
    }
  })

  const onCancel = () => {
    setApp("")
    setResourceGroup("");
    setSKU([]);
    setSubscriptioId("")
    setSubscriptioName("");
    setRegion([]);
    setIotHubName("");
    setOwnerConnString("");
    setDeviceConnString("");
    setServiceConnString("")
    onCancelAction()

  }

  const saveResourceAction = async () => {
    setLoader(true);
    const tokenKey = "sso_token";
    const accessToken = JSON.parse(sessionStorage.getItem(tokenKey) as string);
    const CREATE_RESOURCE = `${API["GETALLAPPLS"]}/${app}/az-iot-hubs`;
    const requestOptions = {
      method: 'POST',
      headers: {
        "Content-Type": "application/json",
        'Authorization': `Bearer ${accessToken.access_token}`,
        'Ocp-Apim-Subscription-Key': `${API_KEY}`,
        'Ocp-Apim-Trace': `true`
      },
      body: JSON.stringify(
        {
          az_iot_hub: {
            subscription_id: subscriptioId,
            subscription_name: subscriptioName,
            resource_group_name: resourceGroup,
            region: region,
            sku: sku,
            iot_hub_name: iotHubName,
            owner_conn_string: ownerConnString,
            device_conn_string: deviceConnString,
            service_conn_string: serviceConnString,
            iot_hub_type: "existing"
          },
          pm_s_token: pmST,
          pm_s_id: pmSId,
        })
    };
    setbackDrop(true);
    await fetch(CREATE_RESOURCE, requestOptions)
      .then(response => response.json())
      .then((response: any) => {
        if ((response && response.status === 401) && (response && arrayOfErrorsRefresh.includes(response.message.toLowerCase()))) {
          try {
            const tokenKey = "sso_token";
            callXhrRequest()
              .then(function (data) {
                sessionStorage.setItem(tokenKey, data);
                saveResourceAction();
              })
              .catch(function (error) {
                user.triggerLogout();
                console.log("error", error);
              });
          } catch (error) {
            console.log(error);
          }
          return;
        }
        else if ((response && response.status === 401) && (response && arrayOfErrorsLogout.includes(response.message.toLowerCase()))) {
          try {
            user.triggerLogout();
          } catch (error) {
            console.log(error);
          }
          return;
        }
        else if (response.status === 200 || response.status === 201) {
          enqueueSnackbar('Resource Added', { variant: "success" });
          setbackDrop(false);
          setTimeout(function () {
            setLoader(false);
            onCancel();
          }, 500);
        }
        else {
          enqueueSnackbar('Resource addition failed', { variant: "error" });
          setbackDrop(false);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  const validateField = () => {
    const regexCheck = /^[0-9a-zA-Z_-]+$/;
    const regexString = /^HostName/;
    const subscriptionCheck = /^[{]?[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}[}]?$/;
    const uniqueIotHubName = iotHubData.some((e: any) => {
      if (e.iot_hub_name === iotHubName) {
        return true
      } else {
        return false
      }
    })
    if (!app) {
      enqueueSnackbar("Please select application name.", { variant: "error" });
    } else if (!subscriptioId) {
      enqueueSnackbar("Please enter subscrption id.", { variant: "error" });
    } else if (!(subscriptionCheck.test(subscriptioId))) {
      enqueueSnackbar('Invalid subscription id.', { variant: "error" });
    } else if (!subscriptioName) {
      enqueueSnackbar("Please enter subscrption name.", { variant: "error" });
    } else if (!(regexCheck.test(subscriptioName) && subscriptioName.length < 100)) {
      enqueueSnackbar('Invalid subscription name (only 0-9,A-Z,a-z,_,- allowed upto 100 characters).', { variant: "error" });
    } else if (!resourceGroup) {
      enqueueSnackbar("Please enter resource group.", { variant: "error" });
    } else if (!(regexCheck.test(resourceGroup) && resourceGroup.length < 100)) {
      enqueueSnackbar('Invalid resource group (only 0-9,A-Z,a-z,_,- allowed upto 100 characters).', { variant: "error" });
    } else if (region.length === 0) {
      enqueueSnackbar("Please select region.", { variant: "error" });
    } else if (sku.length === 0) {
      enqueueSnackbar("Please select sku.", { variant: "error" });
    } else if (!iotHubName) {
      enqueueSnackbar("Please enter iotHubName.", { variant: "error" });
    } else if (!(regexCheck.test(iotHubName) && iotHubName.length < 100)) {
      enqueueSnackbar('Invalid iot Hub Name (only 0-9,A-Z,a-z,_,- allowed upto 100 characters).', { variant: "error" });
    } else if (!ownerConnString) {
      enqueueSnackbar("Please enter owner connection string.", { variant: "error" });
    } else if (!(regexString.test(ownerConnString) && (ownerConnString.length < 250))) {
      enqueueSnackbar('Owner connection string is not started either with HostName or greater than 250 character.', { variant: "error" });
    } else if (!deviceConnString) {
      enqueueSnackbar("Please enter device connection string.", { variant: "error" });
    } else if (!(regexString.test(deviceConnString) && (deviceConnString.length < 250))) {
      enqueueSnackbar('Device connection string is not started either with HostName or greater than 250 character.', { variant: "error" });
    } else if (!serviceConnString) {
      enqueueSnackbar("Please enter service connection string.", { variant: "error" });
    } else if (!(regexString.test(serviceConnString) && (serviceConnString.length < 250))) {
      enqueueSnackbar('Service connection string is not started either with HostName or greater than 250 character.', { variant: "error" });
    } else if (uniqueIotHubName) {
      enqueueSnackbar("IotHubName is already there. Please enter another iotHubName.", { variant: "error" });
    } else {
      saveResourceAction();
    }
  }

  useEffect(() => {
    const tokenKey = "sso_token";
    const accessToken = JSON.parse(sessionStorage.getItem(tokenKey) as string);
    interface sessionObj {
      pm_s_id: string;
    }
    interface tokenObj {
      pm_s_token: string;
    }
    let pmSessionId: sessionObj = {
      pm_s_id: "",
    };
    let pmSessionToken: tokenObj = {
      pm_s_token: "",
    };
    pmSessionId =
      sessionStorage.getItem("pm_s_id") ||
      JSON.parse(JSON.stringify(pmSessionId));
    pmSessionToken =
      sessionStorage.getItem("pm_s_token") ||
      JSON.parse(JSON.stringify(pmSessionToken));

    const RESOURCE_INPUT = API["RESOURCE_INPUT"] + `?pm_s_token=${pmSessionToken}&pm_s_id=${pmSessionId}`;
    fetch(RESOURCE_INPUT, {
      headers: {
        'Authorization': `Bearer ${accessToken.access_token}`,
        'Ocp-Apim-Subscription-Key': `${API_KEY}`,
        'Ocp-Apim-Trace': `true`
      },
    })
      .then((resp) => resp.json())
      .then(function (response: any) {
        if ((response && response.status === 401) && (response && arrayOfErrorsRefresh.includes(response.message.toLowerCase()))) {
          try {
            const tokenKey = "sso_token";
            callXhrRequest()
              .then(function (data) {
                sessionStorage.setItem(tokenKey, data);
                setReload(!reload);
              })
              .catch(function (error) {
                user.triggerLogout();
                console.log("error", error);
              });
          } catch (error) {
            console.log(error);
          }
          return;
        }
        else if ((response && response.status === 401) && (response && arrayOfErrorsLogout.includes(response.message.toLowerCase()))) {
          try {
            user.triggerLogout();
          } catch (error) {
            console.log(error);
          }
          return;
        }
        setResourceInput(response && response.az_iot_hubs_input);
        setResourceInputAppl(response && response.az_iot_hubs_input[0].applications)
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [API_KEY, reload]);

  return (
    <div className={classes.root} style={{ marginTop: "0px" }}>
      <div
        className={classes.gridRoot}
        style={{ marginRight: "1.5em", marginBottom: "1.5em", width: "100%" }}
      >
        <Grid
          container
          direction="row"
          xs={12}
          sm={6}
          style={{ marginTop: "0.1em", marginBottom: "0.1em" }}
          alignItems="center"
        >
          <BreadcumbComponent route='addResources' func={onCancel}/>
        </Grid>
      </div>
      <p style={{ color: "#FFFFFF", fontSize: "12px" }}>All fields marked * are mandatory.</p>
      <form className={classes.form} noValidate method="post">
        <div className={classes.space2Top} >
          <Grid container>
            <Grid
              item
              xs={6}
              className={classes.spaceRight}
            >
              <div className={classes.fieldWrapper}>
                <InputLabel
                  classes={{ root: classes.labels }}
                  htmlFor="selectApplication"
                >
                  Application *
                </InputLabel>
                <TextField
                  className={classes.textfield}
                  fullWidth
                  select
                  variant="filled"
                  classes={{ root: classes.fieldRoot }}
                  inputProps={{ className: classes.field }}
                  value={app}
                  id="selectApplication"
                  SelectProps={{
                    classes: {
                      iconOutlined: classes.icon,
                    },
                  }}
                  onChange={onChangeApplication}
                >
                  {Array.isArray(resourceInputAppl) && resourceInputAppl.map((option: any) => (
                    <option className={classes.options} value={option.application_id}>
                      {option.application_name}
                    </option>
                  ))}
                </TextField>
              </div>
            </Grid>

            <Grid item xs={6} className={classes.spaceRight}>
              <CssTextField
                fullWidth
                name="subscription_id"
                label="Subscription ID *"
                type="text"
                id="s_id"
                className={classes.fieldWrapper}
                InputProps={{
                  disableUnderline: true,
                  onChange: (e) => setSubscriptioId(e.target.value),
                }}
                InputLabelProps={{
                  shrink: true,
                  className: classes.labels,
                }}
                FormHelperTextProps={{
                  className: classes.helperText,
                }}
              />
            </Grid>

            <Grid item xs={6} className={classes.spaceRight}>
              <CssTextField
                fullWidth
                name="subscription_name"
                label="Subscription Name *"
                type="text"
                id="sub_name"
                className={classes.fieldWrapper}
                InputProps={{
                  disableUnderline: true,
                  onChange: (e) => setSubscriptioName(e.target.value),
                }}
                InputLabelProps={{
                  shrink: true,
                  className: classes.labels,
                }}
                FormHelperTextProps={{
                  className: classes.helperText,
                }}
              />
            </Grid>

            <Grid item xs={6} className={classes.spaceRight}>
              <CssTextField
                fullWidth
                name="resource_group"
                label="Resource Group *"
                type="text"
                id="res_group"
                className={classes.fieldWrapper}
                InputProps={{
                  disableUnderline: true,
                  onChange: (e) => setResourceGroup(e.target.value),
                }}
                InputLabelProps={{
                  shrink: true,
                  className: classes.labels,
                }}
                FormHelperTextProps={{
                  className: classes.helperText,
                }}
              />
            </Grid>

            <Grid item xs={6} className={classes.spaceRight}>
              <div className={classes.fieldWrapper}>
                <InputLabel
                  classes={{ root: classes.labels }}
                  htmlFor="selectRole"
                >
                  Region *
                </InputLabel>
                <TextField
                  className={classes.textfield}
                  fullWidth
                  select
                  variant="filled"
                  classes={{ root: classes.fieldRoot }}
                  inputProps={{ className: classes.field }}
                  value={region}
                  id="selectRole"
                  SelectProps={{
                    classes: {
                      iconOutlined: classes.icon,
                    },
                  }}
                  onChange={handleChangeRegion}
                >
                  {Array.isArray(Regions) && Regions.map((option: any) => (
                    <option
                      className={classes.options}
                      value={option.label}
                    >
                      {option.label}
                    </option>
                  ))}
                </TextField>
              </div>
            </Grid>
            <Grid item xs={6} className={classes.spaceRight}>
              <div className={classes.fieldWrapper}>
                <InputLabel classes={{ root: classes.labels }} htmlFor="sku">
                  SKU *
                </InputLabel>
                <TextField
                  className={classes.textfield}
                  fullWidth
                  select
                  variant="filled"
                  classes={{ root: classes.fieldRoot }}
                  inputProps={{ className: classes.field }}
                  value={sku}
                  id="sku"
                  SelectProps={{
                    classes: {
                      iconOutlined: classes.icon,
                    },
                  }}
                  onChange={handleChangeSKU}
                >
                  {Array.isArray(Skus) && Skus.map((option) => (
                    <option
                      className={classes.options}
                      value={option.label}
                    >
                      {option.label}
                    </option>
                  ))}
                </TextField>
              </div>
            </Grid>

            <Grid item xs={6} className={classes.spaceRight} >
              <CssTextField
                fullWidth
                name="iot_name"
                label="IoT Hub Name *"
                type="text"
                id="iot_name"
                className={classes.fieldWrapper}
                InputProps={{
                  disableUnderline: true,
                  onChange: (e) => setIotHubName(e.target.value),
                }}
                InputLabelProps={{
                  shrink: true,
                  className: classes.labels,
                }}
                FormHelperTextProps={{
                  className: classes.helperText,
                }}
              />
            </Grid>
            <Grid item xs={6} className={classes.spaceRight} >
              <CssTextField
                fullWidth
                name="ownerConn_String"
                label="Owner Connection String *"
                type="text"
                id="ownerConn_String"
                className={classes.fieldWrapper}
                InputProps={{
                  disableUnderline: true,
                  onChange: (e) => setOwnerConnString(e.target.value),
                }}
                InputLabelProps={{
                  shrink: true,
                  className: classes.labels,

                }}
                FormHelperTextProps={{
                  className: classes.helperText,
                }}
              />
            </Grid>
            <Grid item xs={6} className={classes.spaceRight} >
              <CssTextField
                fullWidth
                name="deviceeConn_String"
                label="Device Connection String *"
                type="text"
                id="deviceConn_String"
                className={classes.fieldWrapper}
                InputProps={{
                  disableUnderline: true,
                  onChange: (e) => setDeviceConnString(e.target.value),
                }}
                InputLabelProps={{
                  shrink: true,
                  className: classes.labels,

                }}
                FormHelperTextProps={{
                  className: classes.helperText,
                }}
              />
            </Grid>
            <Grid item xs={6} className={classes.spaceRight} >
              <CssTextField
                fullWidth
                name="serviceConn_String"
                label="Service Connection String *"
                type="text"
                id="serviceConn_String"
                className={classes.fieldWrapper}
                InputProps={{
                  disableUnderline: true,
                  onChange: (e) => setServiceConnString(e.target.value),
                }}
                InputLabelProps={{
                  shrink: true,
                  className: classes.labels,

                }}
                FormHelperTextProps={{
                  className: classes.helperText,
                }}
              />
            </Grid>
          </Grid>
        </div>
        <div className={`${classes.space2Top} ${classes.bottomSection}`}>
          <Button
            variant="outlined"
            className={classes.btnCancel}
            onClick={onCancel}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            className={classes.btnAddNUser}
            onClick={validateField}
            // style={{ marginRight: "10px" }}
            style={{
              marginRight: '10px',
              pointerEvents: loader ? "none" : "visible",
              cursor: loader ? "default" : "pointer",
              opacity: loader ? ".5" : "1",
            }}
          >
            Save Existing IoT Hub
          </Button>
        </div>
        <Backdrop
          className={classes.backdrop}
          open={backDrop}
          onClick={backDropClose}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </form>
    </div>
  );
}
