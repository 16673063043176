import React from "react";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import { format } from "date-fns";
import { EVENT_TYPES } from "../../utils/constant";
import { IColumn, ITemplateColumnProps } from "../UiComponents/DataTable/types";
import { DateTimeDisplay, GridContextMenuColumn } from "../UiComponents";
import ViewDetailsDialog from "./actionMenuItems/ViewDetailsDialog";
import AcknowledgeEvent from "./actionMenuItems/AcknowledgeEvent";

const EventActionMenuColumn: React.FC<ITemplateColumnProps> = (props) => {
  return (
    <GridContextMenuColumn
      menuItems={[
        {
          id: "viewDetails",
          icon: ArrowForwardIcon,
          label: "View Details",
          component: <ViewDetailsDialog />,
        },
        {
          id: "acknowledgeEvent",
          icon: ArrowForwardIcon,
          label: "Acknowledge",
          component: <AcknowledgeEvent />,
        },
      ]}
      {...props}
    />
  );
};

const columns: IColumn[] = [
  {
    id: "device_id",
    label: "Device Id",
    searchable: true,
    sortable: true,
    exportable: true,
  },
  {
    id: "iot_hub_name",
    label: "IoT Hub Name",
    searchable: true,
    sortable: true,
    exportable: true,
  },
  {
    id: "msg_type",
    label: "Event Type",
    searchable: true,
    sortable: true,
    exportable: true,
    defaultFilterValue: "DEVICE_CREATED",
    input: {
      type: "dropDown",
      dropDownOptions: EVENT_TYPES.map((op) => ({ label: op, value: op })),
    },
    // nonRemovable: true,
  },

  {
    id: "created_on",
    label: "Event Time",
    sortable: true,
    exportable: true,
    template: ({ record }: ITemplateColumnProps) => {
      return <DateTimeDisplay dateTime={new Date(`${record.created_on}Z`)} />;
    },
    resolver: (record: any) => {
      try {
        return record.created_on
          ? format(new Date(record.created_on + "Z"), "M-d-y hh:mm aaa")
          : "";
      } catch {
        return "";
      }
    },
  },
  {
    id: "ack_status",
    label: "Acknowledgement Status",
    searchable: true,
    sortable: true,
    exportable: true,
  },
  {
    id: "ack_by",
    label: "Acknowledged By",
    exportable: true,
  },
  {
    id: "action",
    label: " ",
    searchable: false,
    sortable: false,
    exportable: false,

    template: (props: ITemplateColumnProps) => {
      return <EventActionMenuColumn {...props} />;
    },
  },
];
export default columns;
