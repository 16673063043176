import React, { useState } from "react";
import { Button } from "@material-ui/core";
import ColumnIcon from "@material-ui/icons/ViewColumnOutlined";
import useStyles from "./CustomizeColumns.styles";

import CustomizeColumnsModal from "./CustomizeColumnsModal/CustomizeColumnsModal";

const CustomizeColumns: React.FC<any> = ({ onSave }) => {
  const classes = useStyles();
  const [showDialog, setShowDialog] = useState(false);

  return (
    <>
      <Button
        variant="text"
        color="inherit"
        className={classes.listButton}
        startIcon={<ColumnIcon style={{ color: "#7C7C94" }} />}
        onClick={() => setShowDialog(true)}
        style={{ color: "white", margin: "0 15px" }}
      >
        Customise
      </Button>
      {showDialog && (
        <CustomizeColumnsModal
          onDone={() => {
            onSave();
            setShowDialog(false);
          }}
          onCancel={() => {
            setShowDialog(false);
          }}
        />
      )}
    </>
  );
};

export default CustomizeColumns;
