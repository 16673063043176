import { JOB_STATUSES, JOB_TYPE, SESSION_STORAGE_KEY } from "./types";
//@ts-ignore
window.setTemporaryNotification = function () {
  sessionStorage.setItem(
    SESSION_STORAGE_KEY.NOTIFICATIONS,
    JSON.stringify([
      {
        id: Date.now(),
        type: JOB_TYPE.SINGLE_DEVICE_CREATION,
        status: JOB_STATUSES.FAILED,
        subject: "Device Creation Failed",
        message: "Device creation failed for device id 12345678",
        snackBarShown: false,
        seen: false,
        shown: false,
        timeStamp: Date.now(),
      },
      {
        id: Date.now(),
        type: JOB_TYPE.SINGLE_DEVICE_CREATION,
        status: JOB_STATUSES.SUCCESS,
        subject: "Device Creation Success",
        message: "Device creation successful for device id 12345678",
        snackBarShown: false,
        seen: false,
        shown: false,
        timeStamp: Date.now(),
      },
    ])
  );
};

const tracker = (...args: any) => {
  //@ts-ignore
  if (window.enableTracking) {
    console.log(...args);
  }
};

const fetchDeviceStatusMock = {
  fetcher: (data: any) => {
    const { job_id } = data[0];
    tracker(2.3, `Fetching Job Details for ${job_id}`);
    return {
      job_statuses: [
        {
          job: "single",
          device_dlm_id: job_id,
          job_status: "SUCCESS",
          message:
            "Device test_device_creation_response has been created successfully.",
        },
      ],
    };
  },
};

export { tracker, fetchDeviceStatusMock };
