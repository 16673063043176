import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      display: "flex",
      minWidth: "28em",
      justifyContent: "space-between",
    },
    buttonHolder: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      borderTop: "1px solid #6b6868",
      paddingTop: "0.5em",
      margin: "15px 0px",
      width: "100%",
    },
  })
);

export default useStyles;
