import React, { useEffect, useState } from 'react'
import { LoadingIndicator, Modal } from '../../UiComponents';
import { createStyles, makeStyles } from '@material-ui/styles';
import { DialogActions, Grid, Typography } from '@material-ui/core';
import ReactDiffViewer from 'react-diff-viewer';
import { useApplicationContext } from '../../../contexts/ApplicationContext';
import useApi from '../../../hooks/useApi';
import { addMinutes, format } from 'date-fns';
import { OPERATION_STATUS_LABEL, OperationStatus } from '../../../utils/constant';
interface viewAuditDetailsProps {
  onDone?: () => void;
  record?: any;
}
const useStyles = makeStyles(() =>
  createStyles({
    infoWrapper: {
      backgroundColor: '#2B2B36',
      minHeight: 200,
      minWidth: 400,
      maxWidth: 1080,
      padding: "30px 20px",
      borderRadius: 4,
    },
    dialogWrapper: {
      boxShadow: "0px 30px 100px #000000",
      borderRadius: "3px",
      maxWidth: "1500px",
    },
    title: {
      fontSize: "19px",
      lineHeight: "19px",
      color: "#FFFFFF",
      fontWeight: "bold",
      // marginTop: "20px",
    },
    heading: {
      fontSize: "19px",
      lineHeight: "19px",
      color: "#FFFFFF",
      fontWeight: "bold",
      marginBottom: "10px",
    },
    fieldValue: {
      fontSize: "14px",
      lineHeight: "16px",
      marginTop: "10px",
      color: "#FFFFFF",
      overflowWrap: "anywhere",
      paddingRight: ".5em",
    }
  }))

const diffStyle = {
  variables: {
    dark: {
      diffViewerBackground:'#0d1117c9',
      removedBackground: '#f8514926',
      addedBackground: '#12261e',
      wordAddedBackground: '#1d562d',
      wordRemovedBackground:'#803030',
      addedGutterBackground: '#1c4428',
      removedGutterBackground:'#542426',
      codeFoldBackground:'#172030',
      codeFoldContentColor:'#9198a1',
      codeFoldGutterBackground:'#1e4273',
      gutterColor:'#fff',
      gutterBackground:'#0d1117',
      emptyLineBackground:'#13161d'
    },
  },
  line: {
    padding: '10px 2px',
  },
};


function ViewAuditLog({ onDone = () => true, record }: viewAuditDetailsProps) {
  const [appliactionNameMap, setApplicationNameMap]: any = useState({});
  const { operation: operationStatus, id } = record;
  const operationType = (operationStatus || '').split('_');
  const isUpdation = operationType[operationType.length - 1] === "updation";
  const classes = useStyles();
  const { selectedApplication, applications }: any = useApplicationContext();
  const viewDetailsEndpoint = `/applications/${selectedApplication.application_id}/audit-msgs/${id}`
  const { data, status } = useApi(viewDetailsEndpoint, {
    method: "GET"
  })

  useEffect(() => {
    if (applications && applications.length) {
      const appMap: any = {};
      applications.forEach(({ application_id, application_name }: any) => {
        appMap[application_id] = application_name;
      });
      setApplicationNameMap(appMap);
    }
  }, [applications])
  const logDetails = data?.audit_msg || {};
  let dateStr = "";
  if (!status.pending && logDetails?.activity_datetime) {
    const utcTime = new Date(logDetails?.activity_datetime);
    const date = addMinutes(utcTime, utcTime.getTimezoneOffset() * -1)
    dateStr = format(date, "yyyy-MM-dd HH:mm:ss")
  }
  const operation: OperationStatus = logDetails?.operation;
  const oldJson =  logDetails?.metadata?.old_data || "";
  const newJson = logDetails?.metadata?.new_data || ""
  return (
    <Modal title="Audit Log Details" paperStyle={classes.dialogWrapper} contentWrapperStyle={{ border: 'none' }}
      open={true} onClose={onDone} >
      {status.pending && <LoadingIndicator />}
      <div className={classes.infoWrapper}>
        <Grid container spacing={4}>
          <Grid item xs={6}>
            <Typography className={classes.title} >
              Application
            </Typography>
            <Typography className={classes.fieldValue} >
              {logDetails.application_id && appliactionNameMap[logDetails.application_id]}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography className={classes.title} >
              Activity Date Time
            </Typography>
            <Typography className={classes.fieldValue} >
              {dateStr}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography className={classes.title} >
              Requested Action
            </Typography>
            <Typography className={classes.fieldValue} >
              {logDetails?.operation && OPERATION_STATUS_LABEL[operation]}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography className={classes.title} >
              Request Status
            </Typography>
            <Typography className={classes.fieldValue} >
              {logDetails?.status}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography className={classes.title} >
              Requested By
            </Typography>
            <Typography className={classes.fieldValue} >
              {logDetails?.requested_by}
            </Typography>
          </Grid>
        </Grid>
      </div>
      <div style={{ marginTop: 10 }} className={classes.infoWrapper}>
        <Typography className={classes.heading} >
          Data
        </Typography>

        {isUpdation?
          <ReactDiffViewer
            oldValue={JSON.stringify(oldJson, null, 2)}
            newValue={JSON.stringify(newJson, null, 2)}
            styles={diffStyle}
            useDarkTheme={true}
            splitView={true} />
          :
          <pre>
            {JSON.stringify(logDetails?.metadata, null, 2)}
          </pre>
        }
      </div>
      <DialogActions>
        <Grid item xs={12}>
          {/* to do */}
        </Grid>
      </DialogActions>
    </Modal>
  )
}

export default ViewAuditLog