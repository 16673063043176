import React, { useEffect, useState } from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";

import { Grid, Typography, TablePagination } from "@material-ui/core";
import Pagination from "material-ui-flat-pagination";
import DataTable, { IDataTableProps } from "../DataTable";
import { SortingOrder } from "../DataTable/types";
import useFirstRenderDetector from "../../../hooks/useFirstRenderDetector";
import { ROWS_PER_PAGE } from "../../../utils/constant";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paginateView: {
      color: "#fff",
    },
    tblpaginate: {
      color: "#fff",
    },
    paginateUser: {
      color: "#fff",
    },
    tblpaginatenumber: {
      color: "#fff",
    },
  })
);

interface IPaginatedDataTableProps
  extends Omit<IDataTableProps, "refreshGrid"> {
  dataFetcher?: (args: IPaginationDetails) => void;
  totalRecords: number;
  observeForRefresh: (string | number)[];
}

interface SortingInfo {
  column: string;
  order: SortingOrder;
}
export interface IPaginationDetails {
  pageNumber: number;
  rowsPerPage: number;
  sortByField?: string;
  sortOrder?: SortingOrder;
}

const PaginatedDataTable: React.FC<IPaginatedDataTableProps> = ({
  totalRecords,
  dataFetcher,
  sortingInfo: defaultSortingInfo,
  observeForRefresh,
  onSort = () => true,
  ...dataTableProps
}) => {
  const [rowsPerPage, setRowsPerPage] = useState(ROWS_PER_PAGE[0]);
  const [currentPageNumber, setCurrentPageNumber] = useState(0);
  const [sortingInfo, setSortingInfo] = useState<SortingInfo>();
  const isFirstRender = useFirstRenderDetector();
  const classes = useStyles();
  const observableHash = JSON.stringify(observeForRefresh);

  const fetchGridData = () => {
    //+1 as api is not using zero based pageIndex
    dataFetcher &&
      dataFetcher({
        pageNumber: currentPageNumber + 1,
        rowsPerPage,
        sortByField: sortingInfo?.column,
        sortOrder: sortingInfo?.order,
      });
  };

  useEffect(() => {
    /*calling dataFetcher on first render will trigger one extra unnecessary api call.*/
    if (!isFirstRender && dataFetcher) {
      fetchGridData();
    }
  }, [currentPageNumber, sortingInfo?.column, sortingInfo?.order]);

  useEffect(() => {
    if (!isFirstRender && currentPageNumber === 0) {
      fetchGridData();
    } else {
      setCurrentPageNumber(0); //this will trigger first useEffect to refresh the grid
    }
  }, [rowsPerPage]);

  useEffect(() => {
    if (currentPageNumber !== 0) {
      setCurrentPageNumber(0); //reset the page so that the first useEffect refreshes the grid
    } else {
      !isFirstRender && fetchGridData();
    }
  }, [observableHash]);

  return (
    <>
      <DataTable
        key={currentPageNumber}
        {...dataTableProps}
        sortingInfo={sortingInfo}
        onSort={(columnId: string, sortingOrder: SortingOrder) => {
          setSortingInfo({ column: columnId, order: sortingOrder });
          onSort(columnId, sortingOrder);
        }}
        refreshGrid={() => {
          if (sortingInfo?.column || currentPageNumber === 0) {
            fetchGridData();
          } else {
            setCurrentPageNumber(0); //Modified device will be on top of the list
          }
        }}
      />
      <Grid
        container
        xs={12}
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        style={{ padding: "2em" }}
      >
        <Grid xs={4} item container direction="row" alignItems="center">
          <Typography className={classes.paginateView}>View: </Typography>
          <TablePagination
            rowsPerPageOptions={ROWS_PER_PAGE}
            component="div"
            count={totalRecords}
            rowsPerPage={rowsPerPage}
            page={1}
            labelRowsPerPage={"Devices per page :"} //TODO: make it configurable
            onPageChange={(arg) => console.log("this is not needed", arg)}
            onRowsPerPageChange={(event: any) =>
              setRowsPerPage(event.target.value)
            }
            className={classes.tblpaginate}
          />
          <Typography className={classes.paginateUser}>
            Records per page
          </Typography>
        </Grid>

        <Pagination
          limit={rowsPerPage}
          offset={currentPageNumber * rowsPerPage}
          onClick={(e, offset) => setCurrentPageNumber(offset / rowsPerPage)}
          total={totalRecords}
          className={classes.tblpaginatenumber}
          currentPageColor="secondary"
          otherPageColor="primary"
        />
      </Grid>
    </>
  );
};

export default PaginatedDataTable;
