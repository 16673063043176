import React, { useState } from "react";
import { Grid } from "@material-ui/core";
import { useSnackbar } from "notistack";
import { TAG_TYPE } from "../../../../utils/constant";
import readError from "../../../../utils/readError";

import useStyles from "./AddNewConfiguration.styles";
import { InputContextProvider } from "../../../../contexts/InputContext";
import { validateAddedTags } from "../sharedFormParts/AddNewTag/utils";
import {
  HeaderNavigation,
  BasicConfigFields,
  Tags,
  AddNewTag,
  TabularDropdowns,
  AddNewDropdownSpecifications,
  Properties,
  Submit,
  AddNewProperty,
} from "../sharedFormParts";
import { CONFIG_PAGE } from "../types";
import { validateAddedProperties } from "../sharedFormParts/AddNewProperties/utils";

interface IAddNewConfigurationProps {
  onAddConfig: any;
}

const AddNewConfiguration: React.FC<IAddNewConfigurationProps> = ({
  onAddConfig,
}) => {
  const [basicFields, setBasicFields] = useState({
    application_id: "",
    auth_type: "",
    config_desc: "",
    config_name: "",
    device_type: "",
    edge_type: "",
  });

  const [tags, setTags] = useState<any>({});
  const [properties, setProperties] = useState<any>({});
  const [dropdown, setDropdown] = useState<any>({});
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const safelyRemoveTag = (removedTagName: string) => {
    const { [removedTagName]: removedTagDetails, ...persistingTags } = tags;
    if (
      removedTagDetails.type === TAG_TYPE.Tabular &&
      dropdown[removedTagName] //user needs to remove the dropdown spec first
    ) {
      throw new Error(
        `Please remove all dropdown specification for the tag '${removedTagName}'`
      );
    }
    setTags(persistingTags);
  };

  return (
    <InputContextProvider
      applicationId={+basicFields.application_id}
      entity="configs"
    >
      <HeaderNavigation page={CONFIG_PAGE.ADD_CONFIG} onClick={onAddConfig} />

      <div className={classes.wrapper}>
        <Grid container>
          <BasicConfigFields
            page={CONFIG_PAGE.ADD_CONFIG}
            basicFields={basicFields}
            onFieldChange={(fieldName: string, value: string) =>
              setBasicFields({ ...basicFields, [fieldName]: value })
            }
          />
        </Grid>
        <Grid container>
          <Tags
            page={CONFIG_PAGE.ADD_CONFIG}
            tags={tags}
            onTagChange={(tagName: any, tagData: any) => {
              setTags({ ...tags, [tagName]: tagData });
            }}
            onTagDelete={(removedTagName: any) => {
              try {
                safelyRemoveTag(removedTagName);
              } catch (error) {
                const errorMessage = readError(error);
                enqueueSnackbar(errorMessage, { variant: "error" });
              }
            }}
          />
          <AddNewTag
            onTagAdd={(newTags: any) => {
              //Note: Errors are handled inside component
              validateAddedTags(tags, newTags);
              setTags({ ...tags, ...newTags });
            }}
          />

          <TabularDropdowns
            dropdowns={dropdown}
            newlyAddedTabularTags={Object.keys(tags).filter(
              (t) => tags[t]._isNew
            )}
            onDropdownUpdate={(
              tableName: string,
              columnName: string,
              value: string
            ) => {
              const updatedTableOptions = {
                ...dropdown[tableName],
                [columnName]: value,
              };
              setDropdown({ ...dropdown, [tableName]: updatedTableOptions });
            }}
            onDropdownRemove={(
              tableName: string,
              removedColumnName: string
            ) => {
              const { [removedColumnName]: _, ...persistingTableOptions } =
                dropdown[tableName];

              //if all column specs are removed for the tag, then remove the tag from dropdown
              if (Object.keys(persistingTableOptions).length > 0) {
                setDropdown({
                  ...dropdown,
                  [tableName]: persistingTableOptions,
                });
              } else {
                const { [tableName]: _, ...persitingTableMappings } = dropdown;
                setDropdown(persitingTableMappings);
              }
            }}
          />
          <AddNewDropdownSpecifications
            tags={tags}
            page={CONFIG_PAGE.ADD_CONFIG}
            onDropdownSpecificationAdd={(
              tabularTagName: string,
              selectedColumn: string
            ) => {
              if (dropdown[tabularTagName]?.[selectedColumn] !== undefined) {
                throw new Error(`Dropdown specification already exists!`);
              }
              const updatedTableOptions = {
                ...dropdown[tabularTagName],
                [selectedColumn]: "",
              };
              setDropdown({
                ...dropdown,
                [tabularTagName]: updatedTableOptions,
              });
            }}
          />

          <Properties
            properties={properties}
            onPropertyChange={(propertyName: string, propertyValue: string) => {
              setProperties({ ...properties, [propertyName]: propertyValue });
            }}
            onPropertyDelete={(propertyName: string) => {
              const { [propertyName]: _, ...preservedProperties } = properties;
              setProperties(preservedProperties);
            }}
          />
          <AddNewProperty
            onPropertyAdd={(addedProperties: any) => {
              validateAddedProperties(properties, addedProperties);
              setProperties({ ...properties, ...addedProperties });
            }}
          />

          <Submit
            configData={{
              ...basicFields,
              add_to_iot_hub: "y",
              tags,
              properties,
              dropdown,
            }}
            page={CONFIG_PAGE.ADD_CONFIG}
            onDone={onAddConfig}
          />
        </Grid>
      </div>
    </InputContextProvider>
  );
};

export default AddNewConfiguration;
