import axios from "axios";

const TOKEN_STORAGE_KEY = "sso_token";

const attemptTokenRefresh = async () => {
  const REACT_APP_SSO_HOST = window.SERVER_DATA.REACT_APP_SSO_HOST;
  const ssoTokens = JSON.parse(sessionStorage.getItem(TOKEN_STORAGE_KEY) || "");

  try {
    const response = await axios.post(
      `${REACT_APP_SSO_HOST}/as/token.oauth2?refresh_token=${ssoTokens.refresh_token}&grant_type=refresh_token`,
      {
        headers: {
          "content-type": "application/x-www-form-urlencoded",
          authorization: `Bearer ${ssoTokens.access_token}`,
        },
      }
    );

    if (response.status === 200) {
      sessionStorage.setItem(TOKEN_STORAGE_KEY, JSON.stringify(response.data));
      return true;
    }
  } catch {
    return false;
  }
};

export default attemptTokenRefresh;
