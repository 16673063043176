import React from "react";
import TimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en";

TimeAgo.addDefaultLocale(en);

const pad = (v: string | number) => `0${v}`.slice(-2);

const getDateTimeString = (dateTimeString: string) => {
  const dateTime = new Date(dateTimeString);
  return `${dateTime.getUTCFullYear()}-${pad(dateTime.getUTCMonth() + 1)}-${pad(
    dateTime.getUTCDate()
  )} ${pad(dateTime.getUTCHours())}:${pad(dateTime.getUTCMinutes())}`;
};

const TimeAgoRenderer: React.FC<any> = ({ dateTime }) => {
  const timeAgo = new TimeAgo("en-US");
  if (!dateTime) {
    return null;
  }
  try {
    let formattedTime = null;
    formattedTime = timeAgo.format(new Date(dateTime));
    return (
      <>{`${getDateTimeString(dateTime)} ${
        formattedTime ? `(${formattedTime})` : ""
      } `}</>
    );
  } catch {
    return null;
  }
};

export default TimeAgoRenderer;
