import {
  withStyles,
  createStyles,
  makeStyles,
} from "@material-ui/core/styles";
import "../Users/Pagination.css";
import TableCell from "@material-ui/core/TableCell";
import { TextField, TableRow, TableSortLabel } from "@material-ui/core";
import MuiDialogContent from "@material-ui/core/DialogContent";

export const StyledTableCell = withStyles((theme) =>
  createStyles({
    root: {
      borderBottom: "none",
    },
    head: {
      backgroundColor: "#464659",
      color: "#FFFFFF",
      borderBottom: "none",
      padding: "8px 16px",
      fontWeight: "bold",
    },
    body: {
      fontSize: 14,
      color: "#FFFFFF",
      backgroundColor: "none",
      padding: "12px",
    },
  })
)(TableCell);

export const StyledTableRow = withStyles((theme) =>
  createStyles({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: "#2B2B36",
      },
    },
  })
)(TableRow);

export const StyledTableSort = withStyles({
  root: {
    color: '#ffffff',
  },
  icon: {
    color: '#ffffff !important',
    fontSize: "26px"
  },
})(TableSortLabel)
const constColor = "1px solid #464659"
export const CssTextField = withStyles({
  root: {
    "& input": {
      padding: "10px",
      color: "#ffffff",
      backgroundColor: "#1D1D25 ",
      border: `${constColor}`,
      borderRadius: "2px",
    },
    "& .MuiInput-multiline": {
      padding: "10px",
      color: "#ffffff",
      backgroundColor: "#1D1D25 ",
      border: `${constColor}`,
      borderRadius: "2px",
      marginTop: "32px",
    },
    "& .MuiInput-underline": {
      "&&:before": {
        borderBottom: "none",
      },
      "&&:after": {
        borderBottom: "none",
      },
    },
    "& label.Mui-focused": {
      color: "#ffffff",
    },
  },
})(TextField);

export const DialogTitleStyles = (theme) =>
  createStyles({
    root: {
      margin: "20px",
      padding: 0,
      color: "#FFFFFF",
    },
    title: {
      fontSize: "21px",
      lineHeight: "25px",
      fontWeight: "bold",
    },
    closeButton: {
      position: "absolute",
      right: "2px",
      top: "15px",
      color: "#FFFFFF",
      fontSize: "14px",
    },
  });

export const DialogContent = withStyles((theme) => ({
  root: {
    margin: "0px 15px",
    padding: 0,
    color: "#FFFFFF",
  },
}))(MuiDialogContent);



export const useStyles = makeStyles((theme) =>
  createStyles({
    hidden: {
      display: "none",
    },
    typography: {
      padding: theme.spacing(2),
    },
    input: {
      marginLeft: theme.spacing(1),
      flex: 1,
      color: "#FFFFFF",
    },
    iconButton: {
      padding: 10,
      color: "#FFFFFF",
    },
    formControl: {
      margin: "0 25px",
    },
    gridRoot: {
      flexGrow: 1,
      padding: "0px 24px",
      backgroundColor: "#2B2B36",
      margin: "0px 20px 0px 20px",
      borderBottom: "solid thin #33333F",
    },
    root: {
      width: "100%",
      maxWidth: 360,
      backgroundColor: theme.palette.background.paper,
    },
    flexContainer: {
      display: "flex",
      flexDirection: "row",
    },
    gridRootApp: {
      flexGrow: 1,
      padding: "0",
      margin: "0 20px 20px",
    },
    listButtonWrap: {
      marginLeft: "20px",
    },
    listItem: {
      width: "auto",
      padding: "0px",
      marginRight: "20px",
      fontSize: "16px",
      color: "#FFFFFF",
      lineHeight: "19px",
    },
    colorWhite: {
      color: "#FFFFFF",
    },
    listItemText: {
      fontSize: "16px",
      lineHeight: "19px",
    },
    tableWrapper: {
      padding: "0 20px",
      marginTop: "15px",
      boxShadow: "none",
    },
    table: {
      minWidth: "100%",
      maxWidth: "100%",
    },
    tableLevels: {
      minWidth: "100%",
      maxWidth: "100%",
      background: "#2b2b36",
      paddingRight:"15px"
    },
    listButton: {
      padding: "0px",
      textTransform: "none",
      fontSize: "14px",
      whiteSpace: "nowrap",
    },
    rightSec: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-end",
      whiteSpace: "nowrap",
      padding: "5px 0px",
      fontSize: "12px",
      lineHeight: "14px",
      "& p": {
        fontSize: "12px",
        padding: "11px 0px",
        marginRight: "30px",
      },
      "& button": {
        background: "#0089FF",
        borderRadius: "2px",
        fontSize: "16px",
        color: "#FCFCFC",
        padding: "6px 20px",
        marginRight: "10px",
        "&:hover": {
          background: "#0089FF",
        },
      },
    },
    menuButtonRoot: {
      padding: "0px",
    },
    viewDetails: {
      "&:hover": {
        color: "#0089FF !important",
      },
    },
    icon: {
      color: "#FFFFFF",
    },
    options: {
      color: "#FCFCFC",
      fontSize: "12px",
      padding: "10px",
      cursor: "pointer",
      /* marginBottom: '5px'   */
    },
    fieldRoot: {
      marginTop: "16px",
    },
    spaceRight: {
      paddingRight: "10px",
    },
    fieldWrapper: {
      marginTop: "20px",
    },
    field: {
      color: "#FCFCFC",
      fontSize: "12px",
      padding: "10px",
    },
    textfield: {
      color: "#ffffff",
      backgroundColor: "#1D1D25",
      border: `${constColor}`,
      borderRadius: "2px",
      boxSizing: "border-box",
    },
    menuRoot: {
      backgroundColor: "#1D1D25",
      border: "1px solid #33333F",
      boxShadow: "0px 4px 4px rgba(0,0,0,0.25)",
      borderRadius: "2px",
      /* padding : "8px 8px" */
    },
    menuitem: {
      color: "white",
      fontSize: "14px",
      paddingRight: "40px",
      "& svg": {
        marginRight: "8px",
      },
    },
    button: {
      marginRight: theme.spacing(1),
    },
    instructions: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: "#fff",
    },
    dialogRoot: {
      boxShadow: "0px 30px 100px #000000",
      borderRadius: "3px",
      maxWidth: "729px",
      minWidth: "609px",
      "& .dcTitle.dcHeader": {
        marginTop: "0px",
      },
      "& .dcTitle": {
        fontSize: "16px",
        lineHeight: "19px",
        color: "#FFFFFF",
        fontWeight: "bold",
        marginTop: "1px",
      },
      "& .dcOuter": {
        background: "#2B2B36",
        borderRadius: "4px",
        padding: "15px",
      },
      "& .dcFieldValue": {
        fontSize: "14px",
        lineHeight: "16px",
        marginTop: "10px",
        color: "#FFFFFF",
        overflowWrap: "anywhere",
        paddingRight: ".5em"
      },
      "& .dcSep": {
        marginTop: "20px",
      },
      "& .dcBtDelete": {
        borderRadius: "2px",
        border: "1px solid rgba(235,235,245,0.35)",
        color: "#0089FF",
        background: "none",
      },
      "& .dcBtEdit": {
        backgroundColor: "#0089ff",
        borderRadius: "4px",
        border: "none",
        color: "#FFFFFF",
      },
    },
    underline: {
      "& :before": {
        borderBottom: "none !important",
      },
    },
    dialogRootSmall: {
      boxShadow: "0px 30px 100px #000000",
      borderRadius: "3px",
      maxWidth: "800px",
      position: "absolute",
      top: "10%",
      minWidth: "416px",
      "& .dcTitle.dcHeader": {
        marginTop: "0px",
      },
      "& .dcTitle": {
        fontSize: "16px",
        lineHeight: "19px",
        color: "#FFFFFF",
        fontWeight: "bold",
        marginTop: "25px",
      },
      "& .dcOuter": {
        background: "#2B2B36",
        borderRadius: "4px",
        padding: "15px",
      },
      "& .dcFieldValue": {
        fontSize: "14px",
        lineHeight: "16px",
        marginTop: "10px",
        color: "#FFFFFF",
        overflowWrap: "anywhere",
        paddingRight: ".5em"
      },
      "& .dcSep": {
        marginTop: "20px",
      },
      "& .dcBtDelete": {
        borderRadius: "2px",
        border: "1px solid rgba(235,235,245,0.35)",
        color: "#0089FF",
        background: "none",
      },
      "& .dcBtEdit": {
        backgroundColor: "#0089ff",
        borderRadius: "4px",
        border: "none",
        color: "#FFFFFF",
      },
    },
    dividerColor: {
      background: "#464659",
      margin: "1em",
    },
    tblpaginate: {
      color: "#fff",
    },
    tblfooter: {
      position: "absolute",
      display: "inline-block"
    },
    tblfooter1: {
      position: "sticky",
      padding: "10px 0",
      display: "flex",
      alignItems: "center",
    },
    tblfooter2: {
      position: "sticky",
      right: "0",
      padding: "20px 25px",
      display: "flex",
      marginLeft: "87.4rem",
      marginTop: "-58px"
    },
    tblpaginatenumber: {
      color: "#fff",
    },
    dropdown: {
      width: "207px",
      height: "40px",
      background: "transparent",
      padding: "10px",
      color: "#FFFFFF",
      border: "1px solid #4CA5FF",
      font: "400 13.3333px 'Avenir Next'",
      borderRadius: "2px",
      marginRight: "30px",
      "& option": {
        background: "#1D1D25",
      },
    },
    label: {
      fontSize: "18px",
      lineHeight: "21px",
      marginRight: "18px",
      color: "#FFF",
    },
    alignCentre: {
      alignItems: "center",
      marginTop: "0.5rem",
    },

    paginateUser: {
      color: "#fff",
    },
    paginateView: {
      color: "#fff",
    },
    btnTableCell: {
      color: "#FFFFFF",
      padding: "8px 0",
      fontWeight: "bold",
      borderBottom: "none",
      backgroundColor: "#464659",
      textTransform: "capitalize",
      fontSize: "0.875rem",
    },
    lblFilterBox: {
      color: "#FFFFFF",
    },
    popoverInputSearch: {
      margin: "15px",
      border: "1px solid white",
    },
    space2Top: {
      marginTop: "5px",
    },
    bottomSection: {
      justifyContent: "space-between",
      display: "flex",
      borderTop: "1px solid #33333F",
      paddingTop: "7px",
      paddingBottom: "7px",
    },
    btnCancel: {
      color: "gray",
      fontSize: "15px",
      textTransform: "capitalize",
    },
    btnAddNUser: {
      color: "#0089ff",
      fontSize: "15px",
      textTransform: "capitalize",
    },
    formControlOvrflw: {
      overflowY: "scroll",
      margin: "0 25px",
      width: "90%",
      maxHeight: "250px",
    },
    margin: {
      margin: theme.spacing(1),
    },
    textField: {
      width: "16ch",
      color: "#fff",
      padding: "3px 10px",
      border: "1px solid gray",
    },
    labels: {
      color: "#fcfcfc",
      lineHeight: "16px",
      fontSize: "14px",
      transform: "scale(1)",
    },
  })
);


