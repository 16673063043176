import React from "react";
import Grid from "@material-ui/core/Grid";

import { DropDown } from "../../../../UiComponents";
import { useInputContext } from "../../../../../contexts/InputContext";

const DeviceConfigurationDropDown: React.FC<any> = ({ value, onChange }) => {
  const { device_configs = [] } = useInputContext();

  return (
    <Grid item xs={6}>
      <DropDown
        label="Device Configuration *"
        options={(device_configs || []).map(
          ({ device_config_id: value, config_name: label }: any) => ({
            value,
            label,
          })
        )}
        value={value}
        onChange={(value: string) => onChange(value)}
      />
    </Grid>
  );
};

export default DeviceConfigurationDropDown;
