import React, { useEffect, useState } from "react";
import { format } from "date-fns";

import columns from "./columns";
import {
  ApplicationContextProvider,
  useApplicationContext,
} from "../../contexts/ApplicationContext";
import ApplicationSelector from "../Devices/DeviceInstallation/ApplicationSelector";
import {
  CustomButton,
  DropDown,
  Header,
  MultifilteredDataTable,
  DateTimeDisplay,
} from "../UiComponents";
import { IFilter } from "../UiComponents/MultiFilter/types";
import { PREDEFINED_DATE_RANGE, generateDateRange } from "./utils";
import ExportToCSV from "../UiComponents/ExportToCSV";
import CustomDateRangeSelector from "./CustomDateRangeSelector";
import preDefinedDateRange from "./utils/preDefinedDateRange";
import formatDateTime from "./utils/formatDateTime";
import FormatNumber from "../UiComponents/FormatNumber";

//synthentic columns will be used to load additional template columns
//TODO: If needed this can be injected as a prop. Its ok for now as we have only 'action' column as synthetic.
const addSyntheticColumns = (data: any = []) => {
  if (!data.dlm_msgs || data.dlm_msgs.length === 0) {
    return { ...data, dlm_msgs: [] };
  }
  const updatedDeviceData = data.dlm_msgs.map((record: any) => ({
    ...record,
    action: "",
  }));
  return { ...data, dlm_msgs: updatedDeviceData };
};

const GridController: React.FC<any> = ({
  columns,
  dateRangeSelected,
  customDateRange,
  setCustomDateRange,
  data,
  activeFilters,
  sortingSequence,
  externalFilters,
  gridControls,
  onDateRangeChange,
}) => {
  if (!data?.summary) {
    return null;
  }
  return (
    <>
      <Header
        route="Events"
        headerText={
          <>
            {`Total Events: `}
            <strong>
              <FormatNumber number={data ? data.summary.total_msgs : 0} />
            </strong>
          </>
        }
      >
        <ExportToCSV
          columns={columns.filter((column: any) => column.exportable)}
          filter={[...externalFilters, ...activeFilters].map(
            ({ filterType, filterValue }: IFilter) => ({
              name: filterType,
              value: filterValue,
            })
          )}
          pageName="Events"
          dataLookUp="dlm_msgs"
          endpoint="dlm-msgs-export"
          sortingInfo={
            sortingSequence.length > 0
              ? {
                  sort_by_field: sortingSequence[0].name,
                  sort_order: sortingSequence[0].order,
                }
              : {}
          }
          expectedRecordCount={data.summary.total_msgs}
        />

        <DropDown
          style={{
            margin: "0",
            position: "absolute",
            right: "5px",
            top: "42px",
          }}
          options={[
            { label: "Last 1 Hour", value: PREDEFINED_DATE_RANGE.LAST_1_HOUR },
            {
              label: "Last 3 Hours",
              value: PREDEFINED_DATE_RANGE.LAST_3_HOURS,
            },
            {
              label: "Last 5 Hours",
              value: PREDEFINED_DATE_RANGE.LAST_5_HOURS,
            },
            { label: "Custom", value: PREDEFINED_DATE_RANGE.CUSTOM },
          ]}
          value={dateRangeSelected}
          onChange={(dateRange: any) => {
            onDateRangeChange(dateRange);
          }}
        />
        {gridControls}
      </Header>
      {customDateRange.from !== null && (
        <div style={{ color: "white", marginLeft: "24px" }}>
          Showing Events from{" "}
          <strong>
            <DateTimeDisplay dateTime={customDateRange.from} />
          </strong>{" "}
          to{" "}
          <strong>
            <DateTimeDisplay dateTime={customDateRange.to} />
          </strong>
          <CustomButton
            style={{ marginLeft: "8px", marginTop: "-4px" }}
            onClick={() => setCustomDateRange({ from: null, to: null })}
            variant="outlined-white"
          >
            Change
          </CustomButton>
        </div>
      )}
    </>
  );
};

const getDateRangeFilter = (
  dateRangeSelected: string,
  customDateRange: any
) => {
  if (dateRangeSelected === PREDEFINED_DATE_RANGE.CUSTOM) {
    return customDateRange.from !== null
      ? [
          {
            filterType: "created_on",
            filterValue: {
              from: formatDateTime(customDateRange.from),
              to: formatDateTime(customDateRange.to),
            },
          },
        ]
      : [];
  } else {
    return [
      {
        filterType: "created_on",
        filterValue: generateDateRange(dateRangeSelected),
      },
    ];
  }
};

const Events: React.FC<any> = ({}) => {
  const { selectedApplication } = useApplicationContext();
  const [dateRangeSelected, setDateRangeSelected] = useState(
    PREDEFINED_DATE_RANGE.LAST_1_HOUR
  );
  const [customDateRange, setCustomDateRange] = useState({
    from: null,
    to: null,
  });

  useEffect(() => {
    if (
      dateRangeSelected !== preDefinedDateRange.CUSTOM &&
      customDateRange.from !== null
    ) {
      setCustomDateRange({ from: null, to: null });
    }
  }, [dateRangeSelected]);

  const supplyFilters = getDateRangeFilter(dateRangeSelected, customDateRange);

  return (
    <>
      <ApplicationSelector style={{ marginLeft: "1.8em" }} />
      {dateRangeSelected === PREDEFINED_DATE_RANGE.CUSTOM &&
        customDateRange.from === null && (
          <CustomDateRangeSelector
            onDone={(dateRange: any) => setCustomDateRange(dateRange)}
            onClose={() =>
              setDateRangeSelected(PREDEFINED_DATE_RANGE.LAST_1_HOUR)
            }
          />
        )}

      <MultifilteredDataTable
        endPoint="dlm-msgs"
        dataLookupKey="dlm_msgs"
        totalRecordsLookupKey="total_msgs"
        gridController={(props: any) => (
          <GridController
            columns={columns}
            dateRangeSelected={dateRangeSelected}
            onDateRangeChange={(dateRange: any) =>
              setDateRangeSelected(dateRange)
            }
            customDateRange={customDateRange}
            setCustomDateRange={setCustomDateRange}
            {...props}
          />
        )}
        columns={columns}
        selectedApplication={selectedApplication}
        externalFilters={supplyFilters}
        disabled={supplyFilters.length < 1}
        disableMultiSort
        defaultSortingSequence={[{ name: "created_on", order: "asc" }]}
      />
    </>
  );
};

const EventsWrapper = () => {
  const { selectedApplication } = useApplicationContext();
  return <Events key={selectedApplication.application_id} />;
};

export default EventsWrapper;
