import React, { CSSProperties } from "react";
import {
  createStyles,
  Theme,
  makeStyles,
  withStyles,
  WithStyles,
} from "@material-ui/core/styles";

import Typography from "@material-ui/core/Typography";

import IconButton from "@material-ui/core/IconButton";

import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import CloseIcon from "@material-ui/icons/Close";

const DialogTitleStyles = (theme: Theme) =>
  createStyles({
    root: {
      margin: "20px",
      padding: 0,
      color: "#FFFFFF",
    },
    title: {
      fontSize: "21px",
      lineHeight: "25px",
      maxWidth: "96%",
      fontWeight: "bold",
      minHeight:'1em'
    },
    closeButton: {
      position: "absolute",
      right: "5px",
      top: "12px",
      color: "#FFFFFF",
      fontSize: "20px",
    },
  });

const DialogContent = withStyles((theme: Theme) => ({
  root: {
    padding: "0px 15px",
    color: "#FFFFFF",
    borderTop: "1px solid #33333F",
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme: Theme) => ({
  root: {
    margin: "20px 15px",
    justifyContent: "space-between",
    padding: 0,
    "& button": {
      fontSize: "16px",
      lineHeight: "19px",
      textTransform: "none",
      padding: "9px 20px",
    },
  },
}))(MuiDialogActions);

export interface DialogTitleProps extends WithStyles<typeof DialogTitleStyles> {
  id: string;
  children: React.ReactNode;
  onClose?: () => void;
}

const DialogTitle = withStyles(DialogTitleStyles)((props: DialogTitleProps) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6" className={classes.title}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon fontSize="inherit" />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

interface IModalProps {
  title: string;
  open: boolean;
  onClose?: () => void;
  contentWrapperStyle?: CSSProperties;
  paperStyle?: any;
  disableBackdropClick?: boolean;
}

const shadow_style = "0px 30px 100px #000000";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialogRoot: {
      boxShadow: shadow_style,
      borderRadius: "3px",
      maxWidth: "850px",
    },
    dialogTitle:{
      minHeight:20
    }
  })
);

const Modal: React.FC<IModalProps> = ({
  title,
  open,
  onClose,
  children,
  paperStyle,
  contentWrapperStyle = { padding: "0px 15px" },
  disableBackdropClick,
}) => {
  const classes = useStyles();
  const wrapperStyle = paperStyle || classes.dialogRoot
  return (
    <Dialog
      classes={{ paper: wrapperStyle }}
      onClose={(_, reason) => {
        if (reason === "backdropClick" && !disableBackdropClick) {
          onClose && onClose();
        }
      }}
      aria-labelledby="customized-dialog-title"
      open={open}
    >
      <DialogTitle id="dialog-title" onClose={onClose}>
        <div className={classes.dialogTitle}>
        {title}
        </div>
      </DialogTitle>
      <DialogContent style={contentWrapperStyle}>{children}</DialogContent>
    </Dialog>
  );
};

export default Modal;
