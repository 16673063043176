import React from "react";
import { useApplicationContext } from "../../../../../contexts/ApplicationContext";
import CertificateHandler from "./CertificateHandler";
import {
  getPmSessionId,
  getPmSessionToken,
} from "../../../../../hooks/useApi/useApi";

/*
TODO: DownloadCertificate is moved from older ShowDevices component as is and renamed to CertificateHandler.
Refactoring is not done at any level and CertificateHandlerWrapper acts as an adapter.
*/

const CertificateHandlerWrapper: React.FC<any> = ({
  record,
  popupType,
  onDone,
}) => {
  const { selectedApplication } = useApplicationContext();
  return (
    <CertificateHandler
      open={true}
      popupType={popupType}
      appId={selectedApplication.application_id}
      device={record}
      pmSessionToken={getPmSessionToken()}
      pmSessionId={getPmSessionId()}
      close={() => {
        onDone();
      }}
    />
  );
};

const DownloadCertificate: React.FC<any> = (props) => {
  return <CertificateHandlerWrapper popupType="single_download" {...props} />;
};

const RevokeCertificate: React.FC<any> = (props) => {
  return <CertificateHandlerWrapper popupType="revoke" {...props} />;
};

const RenewCertificate: React.FC<any> = (props) => {
  return <CertificateHandlerWrapper popupType="renew" {...props} />;
};

export { DownloadCertificate, RevokeCertificate, RenewCertificate };
