import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      width: "600px",
      display: "flex",
      flexFlow: "column",
      alignItems: "start",
      padding: "1em 0em",
    },
    deviceId: {
      margin: "0 0 30px 0",
      fontSize: "1.2em",
    },
    portDetails: {
      width: "100%",
      display: "flex",
      alignItems: "end",
      marginBottom: "1em",
    },
    textArea: {
      width: "100%",
      height: "100px",
      background: "#1d1d25",
      padding: "0.5em",
      color: "white",
    },
    buttonHolder: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      borderTop: "1px solid #6b6868",
      paddingTop: "0.5em",
      margin: "15px 0px",
      width: "100%",
    },
  })
);

export default useStyles;
