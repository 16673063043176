import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import currentTIme from "../../../utils/currentTime";
import FormatNumber from "../../UiComponents/FormatNumber";
import ApplicationSelector from "../../Devices/DeviceInstallation/ApplicationSelector";

const useStyles = makeStyles({
  root: {
    gridArea: "header",
    width: "1186px",
    color: "#FCFCFC",
  },
  updated: {
    textAlign: "right",
    marginBottom: "10px",
    marginRight: "5px",
  },
  dropdown: {
    width: "207px",
    height: "40px",
    background: "transparent",
    padding: "10px",
    color: "#FFFFFF",
    border: "1px solid #4CA5FF",
    font: "400 13.3333px 'Avenir Next'",
    borderRadius: "2px",
    marginRight: "30px",
    "& option": {
      background: "#1D1D25",
    },
  },
  controlWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    overflow: "hidden",
  },
  label: {
    fontSize: "18px",
    lineHeight: "21px",
    marginRight: "18px",
  },
  statusBar: {
    display: "inline-flex",
    flexWrap: "nowrap",
    justifyContent: "space-evenly",
    verticalAlign: "bottom",
    alignItems: "center",
    minWidth: "822px",
    height: "40px",
    background: "#2B2B36",
    borderRadius: "3px",
    fontSize: "16px",
  },
});

const statusBarItems = [
  { label: "Total Devices", key: "total_devices" },
  { label: "Things", key: "things" },
  { label: "Gateways", key: "gateways" },
  { label: "Standalones", key: "standalones" },
];
interface DashboardHeaderInterface {
  data: any;
  applications: any;
  select: (value: any) => void;
}

export default function DashBoardHeader({ data }: DashboardHeaderInterface) {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.updated}>Last Updated {currentTIme()} GMT</div>
      <div className={classes.controlWrapper}>
        <ApplicationSelector />

        <div className={classes.statusBar}>
          {statusBarItems.map(({ label, key }) => (
            <span key={key}>
              {`${label} : `}
              <strong>
                <FormatNumber number={data[key]} />
              </strong>
            </span>
          ))}
        </div>
      </div>
    </div>
  );
}
