import React from "react";

import {
  Header,
  LoadingIndicator,
  MultifilteredDataTable,
} from "../UiComponents";

import { columns } from "./columnConfigs";
import ApplicationSelector from "../Devices/DeviceInstallation/ApplicationSelector";
// import mockApi from "./mockApi";
import { useApplicationContext } from "../../contexts/ApplicationContext";
import FormatNumber from "../UiComponents/FormatNumber";
import useApi from "../../hooks/useApi";
import { MemoryRouter, Redirect, Route, Switch } from "react-router";
import ManageApplication from "./ManageApplication";
import AddNewUser from "./AddNewUser";
import { NavLink } from "react-router-dom";
import { Button } from "@material-ui/core";
import useStyles from "./Users.styles";
import AddIcon from "@material-ui/icons/Add";
import ExportToCSV from "./ExportToCSV";
import clientApi from "./clientApi";

const AddNewUserButton = () => {
  const classes = useStyles();

  return (
    <div className={classes.rightSec}>
      <NavLink to="/add-new-user" style={{ textDecoration: "none" }}>
        <Button
          variant="contained"
          color="inherit"
          className={classes.listButton}
          endIcon={<AddIcon />}
          size="large"
        >
          Add New User
        </Button>
      </NavLink>
    </div>
  );
};

const Tuple: React.FC<any> = ({ label, value }) => {
  return (
    <div style={{ marginRight: "1em" }}>
      {label}{" "}
      <strong>
        <FormatNumber number={value} />
      </strong>
    </div>
  );
};

const GridController: React.FC<any> = ({ data, gridControls }) => {
  const classes = useStyles();
  return (
    <Header
      route="users"
      headerText={
        <div style={{ display: "flex" }}>
          <Tuple label="Total: " value={data ? data.summary.total_users : 0} />
          <Tuple
            label="Active: "
            value={data ? data.summary.active_users : 0}
          />
          <Tuple
            label="Inactive: "
            value={data ? data.summary.inactive_users : 0}
          />
        </div>
      }
    >
      <ExportToCSV columns={columns} data={data?.exportableUserData || []} />
      {gridControls}
      <div className={classes.headerRHS}>
        <AddNewUserButton />
      </div>
      <div></div>
    </Header>
  );
};

const UsersGrid = () => {
  const { selectedApplication } = useApplicationContext();
  const { data, status } = useApi(
    `/applications/${selectedApplication.application_id}/users`,
    { method: "GET" }
  );

  if (status.pending) {
    return <LoadingIndicator />;
  }

  return (
    <>
      <ApplicationSelector style={{ marginLeft: "1.8em" }} />
      {data && (
        <MultifilteredDataTable
          endPoint="NA"
          filterPreserveKey={`users${selectedApplication.application_id}`}
          gridController={GridController}
          columns={columns}
          selectedApplication={selectedApplication}
          dataLookupKey="users"
          totalRecordsLookupKey="total_users"
          clientApi={(gridArgs: any) => clientApi(data, gridArgs)}
        />
      )}
    </>
  );
};

const Users = () => {
  return (
    <MemoryRouter>
      <Switch>
        <Route path="/" exact>
          <UsersGrid />
        </Route>
        <Route path="/add-new-user">
          <AddNewUser />
        </Route>
        <Route path="/edit-user">
          <ManageApplication />
        </Route>
        {/*This is the simplest way to refresh grid without complex overrides */}
        <Route path="/remount">
          <Redirect to="/" />
        </Route>
      </Switch>
    </MemoryRouter>
  );
};

export default Users;
