import React from "react";
import { Backdrop, LinearProgress, Typography } from "@material-ui/core";

import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: "#fff",
      display: "flex",
      flexDirection: "column",
    },
  })
);
const LinearProgressBar: React.FC<any> = ({ show }) => {
  const classes = useStyles();
  return (
    <Backdrop className={classes.backdrop} open={show}>
      <LinearProgress color="primary" style={{ width: "80%" }} />
      <Typography style={{ marginTop: "12px" }}>
        Please wait. Request is in progress.
      </Typography>
    </Backdrop>
  );
};

export default LinearProgressBar;
