import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    gpsCard: {
      display: "flex",
      flexFlow: "column",
      border: "1px solid white",
      margin: "1em",
      width: "20em",
      padding: "1em",
    },
  })
);

export default useStyles;
