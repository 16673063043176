import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    expireBlock: {
      display: "flex",
      zIndex: 99,
      flexDirection: "row",
      position: "fixed",
      top: "70px",
      right: "20px",
      padding: "0px 10px",
      background: "#464659",
      borderRadius: "2px",
      color: "#fff",
      borderLeft: "4px solid #FDB840",
      boxShadow: " 0px 0px 2px rgb(0 0 0 / 10%), 0px 2px 5px rgb(0 0 0 / 10%)",
      alignItems: "center",
    },
    expireBtn: {
      width: "63px",
      height: "32px",
      margin: "0px 20px",
      background: "#0089FF",
      border: "1px solid rgba(0, 137, 255, 0.15)",
      borderRadius: "2px",
    },
    headerRHS: {
      flex: 1,
      display: "flex",
      justifyContent: "end",
    },
    rightSec: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-end",
      whiteSpace: "nowrap",
      padding: "5px 0px",
      fontSize: "12px",
      lineHeight: "14px",
      "& p": {
        fontSize: "12px",
        padding: "11px 0px",
        marginRight: "30px",
      },
      "& button": {
        background: "#0089FF",
        borderRadius: "2px",
        fontSize: "16px",
        color: "#FCFCFC",
        padding: "6px 20px",
        marginRight: "10px",
        "&:hover": {
          background: "#0089FF",
        },
      },
    },
    listButton: {
      padding: "0px",
      textTransform: "none",
      fontSize: "14px",
      whiteSpace: "nowrap",
    },
  })
);

export default useStyles;
