import React, { useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { SnackbarProvider } from 'notistack';
import loginBg from '../assets/loginBackground.jpg';
import shellLogo from '../assets/logo.svg';
import { IAppStore } from "../user/AppStore";
import { useStore } from 'mobx-store-provider';

const useStyles = makeStyles((theme) => ({
  body: {
    background: `#1D1D25 url(${loginBg}) 0 0`,
    height: "100vh",
    width: "100vw",
    position: 'relative',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    "&&:before": {
      content: "''",
      position: "absolute",
      left: "0px",
      top: "0px",
      background: "rgb(29 29 37 / 0.9)",
      width: "100%",
      height: "100%",
    }
  },

  innerWrapper: {
    padding: "0px",
    maxWidth: "402px",
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)"
  },
  logo: {
    width: '285px',
    height: '56px',
    margin: '0 auto',
    display: 'inherit'
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'left',
    padding: '32px 37px',
    backgroundColor: '#2B2B36',
    color: '#FFFFFF',
    boxShadow: '0px 0px 2px rgba(29, 29, 37, 0.07), 0px 2px 2px rgba(29, 29, 37, 0.06), 0px 1px 3px rgba(29, 29, 37, 0.1)',
    borderRadius: '4px',
    marginTop: '35px'
  },
  htitle: {
    fontSize: "20px",
    fontWeight: 'bold',
    lineHeight: "23px"
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%',
    marginTop: '23px',
  },
  passWrapper: {
    marginTop: '29px'
  },
  submit: {
    backgroundColor: '#0089FF',
    color: '#FFFFFF',
    textTransform: "none",
    fontSize: "16px",
    fontWeight: 'bold',
    padding: '10px',
    "&:hover": {
      backgroundColor: "#0089FF"
    },
    marginTop: '-3px',
    boxShadow: 'none',
    borderRadius: '3px'
  },
  forgotPasswordWrapper: {
    textAlign: 'center',
    marginTop: '21px'
  },
  forgotPassword: {
    color: '#0089FF',
    fontSize: "16px"
  },
  labels: {
    color: '#fcfcfc',
    lineHeight: "19px",
    fontSize: "16px",
    transform: 'scale(1)',
    fontWeight: 'bold'
  },
  shellLogo: {
    height: '48px',
    width: '48px',
    marginRight: '25px'
  },
  header1: {
    fontFamily: 'Avenir Next',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '20px',
    lineHeight: '27px',
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    color: '#FFFFFF'
  },
  header2: {
    fontFamily: 'Avenir Next',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '26px',
    lineHeight: '36px',
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    color: '#FFFFFF'
  }
}));


function LoginApp() {
  const classes = useStyles();
  const { user }: IAppStore = useStore();
  const authentication = "authentication"
  useEffect(() => {
    const PMSId = sessionStorage.getItem("pm_s_id");
    const PMStoken = sessionStorage.getItem("pm_s_token");
    if (PMSId && PMStoken) {
      user.triggerLogout();
    }
  })
  return (
    <div className={classes.body}>
      <div className={classes.innerWrapper}>

        <Grid container direction="row" justify="center" alignItems='center'>
          <Grid item>
            <img className={classes.shellLogo} src={shellLogo} alt="logo" />
          </Grid>
          <Grid item>
            <Grid container direction="column" alignItems='center'>
              <Grid item>
                <Typography className={classes.header2}>
                  IoT@Shell DLM
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <div className={classes.paper}>
          <form
            className={classes.form}
            noValidate
            method="post"
            onSubmit={(e) => {
              e.preventDefault();
            }}
          >
            <Button
              type="submit"
              fullWidth
              variant="contained"
              size="large"
              className={classes.submit}
              onClick={user.triggerLogin}
            >
              Log in
            </Button>
            <Grid container>
              <Grid item xs className={classes.forgotPasswordWrapper}>
                {sessionStorage.getItem(authentication) === "false"
                  ? "Unable to login. Please contact administrator."
                  : ""}
              </Grid>
            </Grid>
          </form>
        </div>
      </div>
    </div>
  );
}

export default function Login() {

  return (
    <SnackbarProvider maxSnack={3}
      anchorOrigin={{
        horizontal: 'right',
        vertical: 'top'
      }}

    >
      <LoginApp />
    </SnackbarProvider>
  );
}

