import React from "react";
import { Button, Dialog, Grid, TableBody, Typography, TableContainer, TableHead, TableRow, Table, Paper } from "@material-ui/core";
import { DialogContent, StyledTableRow, StyledTableCell } from './applicationStyles';

interface ViewApplicationDetailsProps {
    DialogTitle: any;
    classes: { [key: string]: string };
    DialogActions: any;
    dialogHandleCloseDelete: () => void;
    viewForm: boolean;
    viewItem: any;
    viewItemLevels: any;
    dialogHandleDelete: (e: any) => void;
    handleEdit: (e: any) => void;
}
export default function ViewApplicationDetails({
    DialogTitle,
    classes,
    DialogActions,
    dialogHandleCloseDelete,
    viewForm,
    viewItem,
    viewItemLevels,
    dialogHandleDelete,
    handleEdit
}: ViewApplicationDetailsProps) {
    return (
        <Dialog
            classes={{ paper: classes.dialogRoot }}
            onClose={dialogHandleCloseDelete}
            aria-labelledby="customized-dialog-title"
            open={viewForm}
        >
            <DialogTitle
                id="customized-dialog-title"
                onClose={dialogHandleCloseDelete}
            >
                View Application Details
          </DialogTitle>
            <DialogContent>
                <Grid container spacing={0} className="dcOuter">
                    <Grid item xs={4}>
                        <Typography className="dcTitle">Application Name</Typography>
                        <Typography className="dcFieldValue">
                            {viewItem && viewItem.application_name}
                        </Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography className="dcTitle">Service Now ID</Typography>
                        <Typography className="dcFieldValue">
                            {viewItem && viewItem.snow_req_id}
                        </Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography className="dcTitle">Apex ID</Typography>
                        <Typography className="dcFieldValue">
                            {viewItem && viewItem.apex_id}
                        </Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography className="dcTitle">Line of Business</Typography>
                        <Typography className="dcFieldValue">
                            {viewItem && viewItem.metadata && viewItem.metadata.lob}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={12} container>
                    <Grid
                        item
                        xs={4}
                        sm={4}
                        className={classes.spaceRight}
                    >
                        <TableContainer
                            component={Paper}
                            className={classes.tableWrapper}
                            style={{ padding: "0px" }}
                        >
                            <Table
                                className={classes.tableLevels}
                                aria-label="customized table"
                            >
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell>Levels</StyledTableCell>
                                    </TableRow>
                                </TableHead>

                                <TableHead>
                                    <TableBody>
                                        {viewItemLevels.map((level: any, index: number) => {
                                            return (
                                                <StyledTableRow>
                                                    <StyledTableCell>{level.level_name}</StyledTableCell>
                                                </StyledTableRow>
                                            );
                                        })}
                                    </TableBody>
                                </TableHead>

                            </Table>
                        </TableContainer>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button
                    variant="outlined"
                    className="dcBtDelete"
                    onClick={() =>
                        dialogHandleDelete(viewItem && viewItem.application_id)
                    }
                >
                    Delete
            </Button>
                <Button
                    variant="contained"
                    className="dcBtEdit"
                    onClick={() => handleEdit(viewItem)}
                >
                    Edit
            </Button>
            </DialogActions>
        </Dialog>

    )
}
