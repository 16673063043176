import React from "react";

import { installationStatusDropDownOptions } from "../../../../utils/constant";
import {
  IColumn,
  ITemplateColumnProps,
} from "../../../UiComponents/DataTable/types";
import DeviceIntallationActionColumn from "./DeviceInstallationActionColumn";
import DeviceInstallationStatusColumn from "./DeviceInstallationStatusColumn";

/*
SAMPLE RESPONSE
===============
"application_level_id": 1058, 
"application_level_name": "IRMS|JurongIsland", 
"device_dlm_id": 1395, 
"device_id": "123", 
"device_install_status": null, 
"device_name": "123", 
"device_status": "ACTIVE", 
"device_type": "THING", 
"last_updated_on": "2022-02-04 04:37:23",
"device_tags": {
    "location_equipment": null
  }, 

*/
const columns: IColumn[] = [
  {
    id: "application_level_name",
    label: "Application Level",
    searchable: true,
    sortable: true,
  },
  { id: "device_name", label: "Device Name", searchable: true, sortable: true },
  { id: "device_id", label: "Device Id", searchable: true, sortable: true },
  {
    id: "location_equipment",
    label: "Location Equipment",
    searchable: true,
    sortable: true,
    template: (props: ITemplateColumnProps) => {
      return props.record.device_tags.location_equipment?.value || "";
    },
  },
  {
    id: "device_install_status",
    label: "Installation Status",
    searchable: true,
    sortable: true,
    template: (props: ITemplateColumnProps) => {
      return <DeviceInstallationStatusColumn {...props} />;
    },
    input: {
      type: "dropDown",
      dropDownOptions: installationStatusDropDownOptions,
    },
  },
  {
    id: "action",
    label: " ",
    searchable: false,
    sortable: false,
    template: (props: ITemplateColumnProps) => {
      return <DeviceIntallationActionColumn {...props} />;
    },
  },
];

const uniqueRowKey = "device_id";

export { columns, uniqueRowKey };
