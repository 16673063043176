import React from "react";
import { Grid } from "@material-ui/core";

const FormSection: React.FC<any> = ({ header, children }) => {
  return (
    <>
      <div
        style={{
          width: "100%",
          paddingLeft: "1em",
          fontSize: "18px",
          marginTop: "1em",
        }}
      >
        {header}
      </div>
      <Grid
        container
        style={{
          background: "#2B2B36",
          margin: "1em",
          padding: "30px 0 30px 0",
          borderRadius: "4px",
          width: "98%",
        }}
      >
        {children}
      </Grid>
    </>
  );
};

export default FormSection;
