import { add } from "date-fns";
import PREDEFINED_DATE_RANGE from "./preDefinedDateRange";
import formatDateTime from "./formatDateTime";

const generateDateRange = (dateRangeSelected: string) => {
  const hoursToReduce = {
    [PREDEFINED_DATE_RANGE.LAST_1_HOUR]: -1,
    [PREDEFINED_DATE_RANGE.LAST_3_HOURS]: -3,
    [PREDEFINED_DATE_RANGE.LAST_5_HOURS]: -5,
  }[dateRangeSelected];
  const toDate = new Date();
  const fromDate = add(toDate, { hours: hoursToReduce });
  return {
    from: formatDateTime(fromDate),
    to: formatDateTime(toDate),
  };
};

export default generateDateRange;
