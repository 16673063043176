import React from "react";
import { createStyles, Theme, withStyles } from "@material-ui/core/styles";

import {
  TableCell,
  TableHead,
  TableRow,
  Typography,
  TableSortLabel,
} from "@material-ui/core";

import { ArrowDropDown } from "@material-ui/icons";
import { IColumn, SortingOrder } from "./types";

const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    root: {
      borderBottom: "none",
    },
    head: {
      backgroundColor: "#464659",
      color: "#FFFFFF",
      borderBottom: "none",
      padding: "8px 16px",
      fontWeight: "bold",
    },
    body: {
      fontSize: 14,
      color: "#FFFFFF",
      backgroundColor: "none",
      padding: "12px",
    },
  })
)(TableCell);

const StyledTableSort = withStyles({
  root: {
    color: "#ffffff",
  },
  icon: {
    color: "#ffffff !important",
    fontSize: "26px",
  },
})(TableSortLabel);

interface IDataTableHeader {
  columns: Array<IColumn>;
  onSort?: (columnId: string, sortingOrder: SortingOrder) => void;
  sortedColumn?: string;
  sortingOrder?: SortingOrder;
}

const getSortingOrder = (
  isActive: boolean,
  currentSortingOrder: SortingOrder = "asc"
): SortingOrder => {
  if (isActive) {
    return currentSortingOrder === "asc" ? "desc" : "asc";
  }
  return "asc";
};

const DataTableHeader: React.FC<IDataTableHeader> = ({
  columns,
  sortedColumn,
  sortingOrder,
  onSort,
}) => {
  return (
    <TableHead>
      <TableRow>
        {columns.map((column: any) => {
          const isSortedColumn = sortedColumn === column.id;
          return (
            <StyledTableCell key={column.id}>
              <StyledTableSort
                active={isSortedColumn} //shows sort arrow
                direction={isSortedColumn ? sortingOrder : "desc"}
                onClick={() =>
                  onSort &&
                  onSort(
                    column.id,
                    getSortingOrder(isSortedColumn, sortingOrder)
                  )
                }
                IconComponent={ArrowDropDown}
                style={{ color: "white" }}
                disabled={!column.sortable}
              >
                {column.headerTemplate ? (
                  column.headerTemplate()
                ) : (
                  <Typography style={{ fontWeight: "bold" }}>
                    {column.label}
                  </Typography>
                )}
              </StyledTableSort>
            </StyledTableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
};

export default DataTableHeader;
