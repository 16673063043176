import React from "react";
import { CustomButton } from "../../../../UiComponents";

const downloadSampleCSV = () => {
  const link = document.createElement("a");
  link.download = `Bulk_Update.csv`;
  link.href = "./Bulk_Update.csv";
  link.click();
};

const SampleCSVDownloader: React.FC = () => {
  return (
    <CustomButton onClick={downloadSampleCSV} variant="outlined-white">
      Download Sample CSV
    </CustomButton>
  );
};

export default SampleCSVDownloader;
