import React from "react";
import HightlightCard from "../../DashboardCards/HighlightCard/HighlightCard";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            gridArea: "first-row",
            display: "grid",
            gridTemplateColumns: "min-content min-content min-content min-content ",
            gridColumnGap: "32px",
        },
    })
);

export default function HighlightCards({data}: any) {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <HightlightCard value={data.device_battery.count} text={data.device_battery.desc}/>
            <HightlightCard value={data.errors.count} text={data.errors.desc}/>
        </div>
    );
}
