import React from "react";
import Dialog from "@material-ui/core/Dialog";

import ViewDetails from "./ViewDetails";
import { useStyles } from "./ViewDetails.styles";

const ViewDetailsDialog = (props: any) => {
  const classes = useStyles();
  return (
    <Dialog
      classes={{ paper: classes.dialogRoot }}
      onClose={props.onDone}
      aria-labelledby="customized-dialog-title"
      open={true}
    >
      <ViewDetails {...props} />
    </Dialog>
  );
};

export default ViewDetailsDialog;
