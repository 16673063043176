import { IFilter } from "../../../UiComponents/MultiFilter/types";

const translateFilter = (columns: any[], filter: IFilter): IFilter => {
  const { filterType, filterValue } = filter;
  const { translator = (value: string) => value } = columns.find(
    ({ id }: any) => id === filterType
  );
  return { filterType, filterValue: translator(filterValue) };
};

export default translateFilter;
