import { useRef, useEffect } from 'react';

/*
Often we need to do something on sideEffect but skip that
in first render. This hook comes handy in such scenarios.
*/
const useFirstRenderDetector = () => {
    const isMountRef = useRef(true);
    useEffect(() => {
        isMountRef.current = false;
    }, []);
    return isMountRef.current;
};

export default useFirstRenderDetector;