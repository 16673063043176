import { TableCell, TableRow, TextField } from "@material-ui/core";
import { createStyles, withStyles } from "@material-ui/styles";

export const CustomTextField = withStyles({
  root: {
    "& input": {
      padding: "10px",
      color: "#ffffff",
      backgroundColor: "#1D1D25",
      border: "1px solid #464659",
      borderRadius: "2px",
      marginTop: "16px",
    },
    "& .MuiInput-multiline": {
      padding: "10px",
      color: "#ffffff",
      backgroundColor: "#1D1D25",
      border: "1px solid #464659",
      borderRadius: "2px",
      marginTop: "32px",
    },
    "& .MuiInput-underline": {
      "&&:before": {
        borderBottom: "none",
      },
      "&&:after": {
        borderBottom: "none",
      },
    },
    "& label": {
      color: "#fff !important",
      fontSize: 18
    },
  },
})(TextField);

export const StyledTableRow = withStyles(() =>
  createStyles({
    root: {
      "&:nth-of-type(odd)": {
      },
    },
  })
)(TableRow);

export const StyledTableCell = withStyles(() =>
  createStyles({
    root: {
      borderBottom: "none",
    },
    head: {
      backgroundColor: "#1D1D25",
      color: "#FFFFFF",
      borderBottom: "none",
      padding: "8px",
    },
    body: {
      fontSize: 14,
      color: "#FFFFFF",
      backgroundColor: "none",
      padding: "3px 0",
      background: "#1D1D25",
    },
  })
)(TableCell);