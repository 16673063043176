import React from "react";
import { Typography } from "@material-ui/core";
import { format } from "date-fns";
import ImageViewer from "../../../../UiComponents/AddRemoveImage/ImageViewer";
import { PAGES } from "../../../../../utils/constant";
import useStyles from "./DeviceHistory.styles";

const DeviceHistoryCard: React.FC<any> = ({
  deviceHistoryEntry,
  applicationId,
}) => {
  const classes = useStyles();
  const {
    device_id,
    device_dlm_id,
    valid_to,
    device_image = [],
  } = deviceHistoryEntry;

  return (
    <div className={classes.deviceHistoryCard}>
      <Typography style={{ padding: ".5em", fontSize: "1.1em" }}>
        Device Id: {device_id}
      </Typography>
      <div className={classes.replacedOn}>
        Replaced on: <strong>{format(new Date(valid_to), "y-M-d H:mm")}</strong>
      </div>
      <div className={classes.imageHolder}>
        {device_image.length === 0 && (
          <Typography style={{ margin: "1em 0.5em" }}>
            No Image Available For This Device
          </Typography>
        )}
        {device_image.map((image: any) => {
          return (
            <div key={image.image_id}>
              <ImageViewer
                imageAccessBaseUrl={`/applications/${applicationId}/device-installation/${device_dlm_id}/device-img`}
                image={image}
                requestPage={PAGES.DEVICES}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default DeviceHistoryCard;
