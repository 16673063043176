import React from "react";
import { Redirect } from "react-router";
import {
  Edit as EditIcon,
  Visibility as VisibilityIcon,
  PhotoLibrary as PhotoLibraryIcon,
  DeleteOutlined as DeleteOutlinedIcon,
  FindReplaceOutlined as ReplaceIcon,
  SystemUpdateAlt as SystemUpdateAltIcon,
  Comment as CommentIcon,
  Email as EmailIcon,
  History as HistoryIcon,
} from "@material-ui/icons";

import RevokeImg from "../../../../../assets/revoke.png";
import RenewImg from "../../../../../assets/renew_cert.png";
import { ITemplateColumnProps } from "../../../../UiComponents/DataTable/types";
import { GridContextMenuColumn } from "../../../../UiComponents";
import { IMenuItemProps } from "../../../../UiComponents/DataTable/types";
import { useApplicationContext } from "../../../../../contexts/ApplicationContext";
import { AUTH_TYPES, CONFIG_TYPES, PAGES } from "../../../../../utils/constant";
import AddRemoveImageWrapper from "../AddRemoveImageWrapper";
import DeleteDevice from "../DeleteDevice";
import ViewDetailsDialog from "../ViewDetails";
import {
  DownloadCertificate,
  RenewCertificate,
  RevokeCertificate,
} from "../CertificateManager";
import Comments from "../../../../UiComponents/Comments/Comments";
import DownlinkMessaging from "../../DownlinkMessaging";
import DeviceHistory from "../DeviceHistory";

interface IRedirectToProps extends IMenuItemProps {
  path: string;
}

const RedirectTo: React.FC<IRedirectToProps> = ({ path, record }) => {
  return <Redirect to={`/${path}/${record.device_dlm_id}`} />;
};

const DeviceHistoryWrapper = ({ record, onDone }: any) => {
  const { selectedApplication } = useApplicationContext();
  return (
    <DeviceHistory
      applicationId={selectedApplication.application_id}
      deviceDlmId={record.device_dlm_id}
      onDone={onDone}
    />
  );
};

const getCertificateMenuItems = (record: any) => {
  let menuItems: any[] = [];
  if (record.is_download_allowed) {
    menuItems = [
      ...menuItems,
      {
        id: "downloadCertificate",
        icon: SystemUpdateAltIcon,
        label: "Download Certificate",
        component: <DownloadCertificate />,
      },
    ];
  }
  if (record.is_revoke_allowed) {
    menuItems = [
      ...menuItems,
      {
        id: "revokeCertificate",
        icon: () => (
          <img style={{ maxWidth: 20, marginRight: 6 }} src={RevokeImg} />
        ),
        label: "Revoke Certificate",
        component: <RevokeCertificate />,
      },
    ];
  }
  if (record.is_renew_allowed) {
    menuItems = [
      ...menuItems,
      {
        id: "renewCertificate",
        icon: () => (
          <img style={{ maxWidth: 20, marginRight: 6 }} src={RenewImg} />
        ),
        label: "Renew Certificate",
        component: <RenewCertificate />,
      },
    ];
  }
  return menuItems;
};

const getActionMenuItems = (props: ITemplateColumnProps) => {
  return (
    <GridContextMenuColumn
      menuItems={[
        {
          id: "viewDetails",
          icon: VisibilityIcon,
          label: "View Details",
          component: <ViewDetailsDialog />,
        },
        {
          id: "addRemoveImage",
          icon: PhotoLibraryIcon,
          label: "Add/Remove images",
          component: <AddRemoveImageWrapper />,
        },
        {
          id: "editDevice",
          icon: EditIcon,
          label: "Edit",
          component: <RedirectTo path="edit-device" />,
        },

        {
          id: "deleteDevice",
          icon: DeleteOutlinedIcon,
          label: "Delete",
          component: <DeleteDevice />,
        },
        {
          id: "comments",
          icon: CommentIcon,
          label: "Comments",
          component: <Comments requestPage={PAGES.DEVICES} />,
        },
        ...(props.record.config_type === CONFIG_TYPES.LORIOT
          ? [
              {
                id: "downlinkMessaging",
                icon: EmailIcon,
                label: "Downlink Messaging",
                component: <DownlinkMessaging />,
              },
            ]
          : []),
        ...(props.record.auth_type === AUTH_TYPES.X509
          ? getCertificateMenuItems(props.record)
          : [
              {
                id: "replaceDevice",
                icon: ReplaceIcon,
                label: "Replace",
                component: <RedirectTo path="replace-device" />,
              },
              {
                id: "deviceHistory",
                icon: HistoryIcon,
                label: "Device History",
                component: <DeviceHistoryWrapper />,
              },
            ]),
      ]}
      {...props}
    />
  );
};

export default getActionMenuItems;
