const addOrUpdate = (array: any[], item: any, key: string) => {
  const itemIndex = array.findIndex((i) => i[key] === item[key]);
  if (itemIndex === -1) {
    return [...array, item];
  }
  return array.map((i, index) => (index === itemIndex ? item : i));
};

const addOrRemove = (array: any[], item: any, key?: string) => {
  const itemIndex = array.findIndex((i) =>
    key ? i[key] === item[key] : i === item
  );
  if (itemIndex === -1) {
    return [...array, item];
  }
  return array.filter((_, index) => itemIndex !== index);
};

//data -> the entire Array which needs to sort
//typOfSorting -> asc, dec, strAsc, strDec
//field -> if the data is an array of Object then needs to specify the field name so that we can sort based on that field
//optionalField -> if we need to check two field value then we can use the secondary field in optionalField
type TypeOfSorting = 'asc' | 'dec' | 'strAsc' | 'strDec';
type ArrayData = any[];
type FuncTypeNumber = (arg0: number, arg1: number) => number;
// type FuncTypeString = (arg0: string, arg1: string) => number;
interface SortType {
  asc: FuncTypeNumber;
  dec: FuncTypeNumber;
  strAsc: any;
  strDec: any;
}
const sortAsc = (a: number, b: number):number => a - b;
const sortDec = (a: number, b: number):number => b - a;
const stringSortAsc = (a: string, b: string):number => a.localeCompare(b);
const stringSortDec = (a: string, b: string):number => b.localeCompare(a);
const sortFunction = (data: ArrayData, typeOfSorting: TypeOfSorting, field?: string, optionalField?: string) => {
  const sortedData: ArrayData = [...data];
  const sortTypes: SortType = { asc: sortAsc, dec: sortDec, strAsc: stringSortAsc, strDec: stringSortDec }
  const compareFun = sortTypes[typeOfSorting];
  sortedData.sort((a: any, b: any): number => {
    if (field && optionalField) {
      const value1 = a[field] || a[optionalField];
      const value2 = b[field] || b[optionalField];
      return compareFun(value1, value2);
    }
    else if (field) { return compareFun(a[field], b[field]) }
    else { return compareFun(a, b); }
  });
  return sortedData;
}

export { addOrUpdate, addOrRemove, sortFunction };
