import React from "react";
import { Grid } from "@material-ui/core";

import TextField from "./TextField";
import { ICustomTextFieldProps } from "./TextField/types";

interface IGriddedTextField extends ICustomTextFieldProps {
  fullWidth?: boolean;
}

const GriddedTextField: React.FC<IGriddedTextField> = ({
  fullWidth,
  ...props
}) => {
  return (
    <Grid item xs={fullWidth ? 12 : 6}>
      <TextField {...props} />
    </Grid>
  );
};

export default GriddedTextField;
