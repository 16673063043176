import React from "react";
import useStyles from "./GpsCard.styles";

const GpsCard: React.FC<any> = ({ title, gpsCrd }) => {
  const classes = useStyles();
  return (
    <div className={classes.gpsCard}>
      <strong style={{ marginBottom: ".5em" }}>{title}</strong>

      <table>
        <tr>
          <td>Latitude</td>
          <td>: {gpsCrd.latitude}</td>
        </tr>
        <tr>
          <td>Longitude</td>
          <td>: {gpsCrd.longitude}</td>
        </tr>
        <tr>
          <td>Accuracy</td>
          <td>: {`${Math.round(gpsCrd.accuracy)} Meters`}</td>
        </tr>
        {gpsCrd.location_updated_on && (
          <tr>
            <td>Updated on</td>
            <td>
              : {new Date(`${gpsCrd.location_updated_on}z`).toLocaleString()}
            </td>
          </tr>
        )}
      </table>
    </div>
  );
};

export default GpsCard;
