import React from "react";

import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import InputLabel from "@material-ui/core/InputLabel";
import { withStyles } from "@material-ui/core/styles";

const CssTextField = withStyles({
  root: {
    "& input": {
      padding: "10px",
      color: "#ffffff",
      backgroundColor: "#1D1D25 ",
      border: "1px solid #464659",
      borderRadius: "2px",
      marginTop: "16px",
    },
    "& .MuiInput-underline": {
      "&&:before": {
        borderBottom: "none",
      },
      "&&:after": {
        borderBottom: "none",
      },
    },
    "& label.Mui-focused": {
      color: "#ffffff",
    },
  },
})(TextField);

type TypeAddNewUserStepOneProp = {
  classes: { [key: string]: string };
  onChangeApplication: (e: any) => void;
  onChangeHelperText: (e: any) => void;
  helperText: string;
  applications: any;
  currentApp: string;
  userEmail: string;
};

export default function AddNewUserStepOne({
  classes,
  helperText,
  onChangeHelperText,
  applications,
  onChangeApplication,
  currentApp,
  userEmail,
}: TypeAddNewUserStepOneProp) {
  return (
    <Grid container>
      <Grid item xs={6} className={classes.spaceRight}>
        <CssTextField
          required
          fullWidth
          name="email"
          label="Email ID "
          type="text"
          id="email"
          value={userEmail}
          className={classes.fieldWrapper}
          InputProps={{
            disableUnderline: true,
          }}
          InputLabelProps={{
            shrink: true,
            className: classes.labels,
          }}
          helperText={helperText}
          onChange={onChangeHelperText}
          FormHelperTextProps={{
            className: classes.helperText,
          }}
        />
      </Grid>
      <Grid item xs={6} className={classes.spaceLeft}>
        <div className={classes.fieldWrapper}>
          <InputLabel
            classes={{ root: classes.labels }}
            htmlFor="selectApplication"
          >
            Application *
          </InputLabel>
          <TextField
            className={classes.textfield}
            fullWidth
            select
            variant="outlined"
            classes={{ root: classes.fieldRoot }}
            inputProps={{ className: classes.field }}
            value={currentApp}
            id="selectApplication"
            SelectProps={{
              classes: {
                iconOutlined: classes.icon,
              },
            }}
            onChange={onChangeApplication}
          >
            {applications?applications.map((option: any) => (
              <option
                className={classes.options}
                key={option.application_id}
                value={option.application_id}
              >
                {option.application_name}
              </option>
            )):<option></option>}
          </TextField>
        </div>
      </Grid>
    </Grid>
  );
}
