import React, { useState } from "react";
import { Button } from "@material-ui/core";
import { useSnackbar } from "notistack";

import exportLogo from "../../../assets/Export.png";
import { exportToExcel } from "../../../utils/helper";
import { useApplicationContext } from "../../../contexts/ApplicationContext";
import ExportColumnSelectionDialog from "../../Events/ExportEventsToCSV/ExportColumnSelectionDialog";
import useStyles from "../../Events/ExportEventsToCSV/ExportEventsToCSV.styles";

const MAXIMUM_EXPORTABLE_RECORD_COUNT = 20000;
/* TODO: Implement re-usable component for export once all pages are aligned with common filter mechanism*/
/* TODO:We can use useApi hook here to keep things simple and reusable. Will work on it later*/

function exportData(
  data: any[],
  selectedCols: any[],
  selectedApplication: any
) {
  const csvData = (data || []).map((userRecord: any) => {
    const csvRow = selectedCols?.reduce(
      (acc: any, { id, label, resolver }: any) => {
        return {
          ...acc,
          [label]: resolver ? resolver(userRecord) : userRecord[id],
        };
      },
      {}
    );
    return csvRow;
  });
  const { application_name } = selectedApplication;
  exportToExcel(csvData, "Users", 9, application_name); //TODO: Why 9?
}

const ExportToCSV: React.FC<any> = ({ columns, data }) => {
  const [showExportDialog, setShowExportDialog] = useState(false);

  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { selectedApplication } = useApplicationContext();

  return (
    <>
      <Button
        variant="text"
        color="inherit"
        className={classes.listButton}
        startIcon={<img alt="Export Logo" src={exportLogo}></img>}
        onClick={() => {
          if (data.length > MAXIMUM_EXPORTABLE_RECORD_COUNT) {
            enqueueSnackbar(
              `Export limit is set to ${MAXIMUM_EXPORTABLE_RECORD_COUNT} records. Please narrow your search window to reduce record count.`,
              { variant: "info" }
            );
            return;
          }
          setShowExportDialog(true);
        }}
      >
        Export
      </Button>
      {showExportDialog && (
        <ExportColumnSelectionDialog
          columns={columns}
          onClose={() => setShowExportDialog(false)}
          onSelect={(selectedColumns: any[]) => {
            setShowExportDialog(false);
            exportData(data, selectedColumns, selectedApplication);
            enqueueSnackbar("Export Initiated", { variant: "success" });
          }}
        />
      )}
    </>
  );
};

export default ExportToCSV;
