import { createMuiTheme } from '@material-ui/core/styles';


const headerColor = '#1D1D25';


const theme = createMuiTheme({
    palette: {
        background: {
            paper: `${headerColor}`,
            default: `${headerColor}`
        },
        
        primary: {
            main: `${headerColor}`,
        },
        secondary: {
            main: '#ffffff',
        },
        
    },
    typography: {
        fontFamily: "'Avenir Next', Arial",
      h3:{
            fontSize: '20px',
            fontStyle: 'normal',
            fontWeight: 'bold',
            lineHeight: 1.167
        },
        
      },
    overrides: {
        
    }
        
    
});

export default theme;