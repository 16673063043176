import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import StatusCard from "../../DashboardCards/StatusCard/StatusCard";
import battery from "../../../assets/DashboardIcons/battery.svg";
import device from "../../../assets/DashboardIcons/device.svg";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      gridArea: "second-row",
      display: "grid",
      gridTemplateColumns: "min-content min-content min-content min-content",
      gridColumnGap: "32px",
    },
  })
);

export default function DonutCards({ data }: any) {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <StatusCard statusData={data.device_status} toggleFunction={() => console.log("button pressed")} image={device} />
      <StatusCard statusData={data.battery_status} toggleFunction={() => console.log("button pressed")} image={battery} />
    </div>
  );
}
