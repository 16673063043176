import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";

import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";

import Dialog from "@material-ui/core/Dialog";
import CloseIcon from "@material-ui/icons/Close";
import { Toolbar, AppBar } from "@material-ui/core";

import useApi from "../../../../hooks/useApi";
import { LoadingIndicator } from "../../../UiComponents";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      position: "relative",
    },
    headerStyle: {
      fontStyle: "normal",
      fontFamily: "Avenir Next",
      fontWeight: "bold",
      fontSize: "20px",
      display: "flex",
      lineHeight: "27px",
      color: "#FFFFFF",
    },
  })
);

interface IFullScreenViewProps {
  image: any;
  requestPage: string;
  onClose: () => void;
  imageAccessBaseUrl: string;
}

const createDataUrl = (data: any) =>
  window.URL.createObjectURL(new Blob([data]));

//https://qa-pm.northeurope.cloudapp.azure.com/api/v1d/applications/1002/devices/55072/device-img/1366?pm_s_token=985741853b6a7d87173e53dae3facd6285bcd683e617f706bbb8d0d968c2f8a4&pm_s_id=31750
const FullScreenView: React.FC<IFullScreenViewProps> = ({
  imageAccessBaseUrl,
  image,
  requestPage,
  onClose,
}) => {
  const classes = useStyles();
  const { status, data } = useApi(`${imageAccessBaseUrl}/${image.image_id}`, {
    method: "GET",
    data: { request_page: requestPage },
    responseDataFormatter: createDataUrl,
    responseType: "blob",
  });

  return (
    <Dialog fullScreen open={true} onClose={onClose}>
      <AppBar className={classes.appBar}>
        <Toolbar>
          <Grid
            container
            direction="row"
            alignItems="center"
            justify="space-between"
          >
            <IconButton
              edge="start"
              color="inherit"
              onClick={onClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography className={classes.headerStyle}>
              {image.image_name}
            </Typography>
            <Button
              autoFocus
              color="inherit"
              onClick={() => {
                const link = document.createElement("a");
                link.href = data;
                link.setAttribute("download", image.image_name);
                document.body.appendChild(link);
                link.click();
              }}
            >
              <b>DOWNLOAD</b>
            </Button>
          </Grid>
        </Toolbar>
      </AppBar>
      {status.pending && <LoadingIndicator />}
      {data && (
        <img
          style={{ display: "block", margin: "auto" }}
          src={data}
          width={640}
        />
      )}
    </Dialog>
  );
};

export default FullScreenView;
