import React, { useState } from "react";
import ConfirmationDialog from "./ConfirmationDialog";
import { IConfirmationDialogProps } from "./ConfirmationDialog/ConfirmationDialog.types";
import SubmitButton from "./SubmitButton";

interface ISubmitWithConfirmationProps
  extends Omit<IConfirmationDialogProps, "onClose"> {
  buttonLabel: string;
  disabled?: boolean;
  preValidator?: () => boolean;
}

const SubmitWithConfirmation: React.FC<ISubmitWithConfirmationProps> = ({
  buttonLabel,
  title,
  subTitle,
  onYes,
  onNo,
  disabled,
  preValidator = () => true,
}) => {
  const [showConfirmation, setShowConfirmation] = useState(false);
  return (
    <>
      <SubmitButton
        disabled={disabled}
        onClick={() => {
          preValidator() && setShowConfirmation(true);
        }}
      >
        {buttonLabel}
      </SubmitButton>
      {showConfirmation && (
        <ConfirmationDialog
          title={title}
          subTitle={subTitle}
          onClose={() => setShowConfirmation(false)}
          onYes={onYes}
          onNo={onNo}
        />
      )}
    </>
  );
};

export default SubmitWithConfirmation;
