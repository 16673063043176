import React from "react";
import { Grid } from "@material-ui/core";

import { GriddedTextField, DropDown } from "../../../../UiComponents";
import { useInputContext } from "../../../../../contexts/InputContext";

const BasicDeviceFields: React.FC<any> = ({ deviceDetails, onFieldChange }) => {
  const inputs = useInputContext();

  const installationStatusOptions = inputs.install_status
    ? inputs.install_status.map(
        ({
          device_install_status_id: value,
          device_installation_status: label,
        }: any) => ({ value, label })
      )
    : null;

  return (
    <>
      <GriddedTextField
        readOnly
        label="Application"
        value={deviceDetails.application_name}
      />

      <GriddedTextField
        readOnly
        name="device_configuration"
        label="Device Configuration"
        value={deviceDetails.device_config.config_name}
      />

      <GriddedTextField
        readOnly
        label="Old Device Id"
        value={deviceDetails.device_id}
      />

      <GriddedTextField
        name="device_id_new"
        label="New Device Id *"
        value={deviceDetails.device_id_new}
        onChange={(value: string) => onFieldChange("device_id_new", value)}
      />
      <GriddedTextField
        name="device_name"
        label="Device Name"
        value={deviceDetails.device_name}
        onChange={(value: string) => onFieldChange("device_name", value)}
      />
      <GriddedTextField
        name="serial_num"
        label="Serial Number"
        value={deviceDetails.serial_num}
        onChange={(value: string) => onFieldChange("serial_num", value)}
      />
      <GriddedTextField
        readOnly
        label="IoT Hub"
        value={deviceDetails.cloud_gateway}
      />
      <GriddedTextField
        readOnly
        label="Application Level"
        value={deviceDetails.application_level_name}
      />
      {installationStatusOptions && (
        <Grid item xs={6}>
          <DropDown
            label="Installation Status"
            options={installationStatusOptions}
            value={deviceDetails.device_install_status_id}
            onChange={(value: string) =>
              onFieldChange("device_install_status_id", value)
            }
          />
        </Grid>
      )}
    </>
  );
};

export default BasicDeviceFields;
