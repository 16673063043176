import React from "react";
import { Button, Checkbox, Dialog, Grid, Divider, FormGroup, FormControl, FormControlLabel } from "@material-ui/core";
import { useStyles, DialogContent,  } from './applicationStyles';


interface ExportApplicationProps{
    dialogHandleCloseDelete: () => void;
    exportpopup: boolean;
    DialogTitle: any;
    exportRadioBtn:any
    handleChange: (e: any) => void;
    DialogActions: any;
    handleExport: () => void;
}

export default function ExportApplication({
    dialogHandleCloseDelete,
    exportpopup,
    DialogTitle,
    exportRadioBtn,
    handleChange,
    DialogActions,
    handleExport

}: ExportApplicationProps) {
    const classes = useStyles();

    return (
        <Dialog
            classes={{ paper: classes.dialogRoot }}
            onClose={dialogHandleCloseDelete}
            aria-labelledby="customized-dialog-title"
            open={exportpopup}
        >
            <Grid container direction="row" alignItems="center">
                <DialogTitle
                    id="customized-dialog-title"
                    onClose={dialogHandleCloseDelete}
                >
                    Select Columns to Export
            </DialogTitle>
            </Grid>
            <DialogContent>
                <FormControl component="fieldset" className={classes.formControl}>
                    <FormGroup>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    style={{ color: "#0089ff" }}
                                    color="primary"
                                    checked={exportRadioBtn.application_name}
                                    onChange={handleChange}
                                    name="application_name"
                                />
                            }
                            label="Application Name"
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    style={{ color: "#0089ff" }}
                                    color="primary"
                                    checked={exportRadioBtn.snow_req_id}
                                    onChange={handleChange}
                                    name="snow_req_id"
                                />
                            }
                            label="Service Now ID"
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    style={{ color: "#0089ff" }}
                                    color="primary"
                                    checked={exportRadioBtn.apex_id}
                                    onChange={handleChange}
                                    name="apex_id"
                                />
                            }
                            label="Apex ID"
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    style={{ color: "#0089ff" }}
                                    color="primary"
                                    checked={exportRadioBtn.lob}
                                    onChange={handleChange}
                                    name="lob"
                                />
                            }
                            label="LOB"
                        />
                    </FormGroup>
                </FormControl>
            </DialogContent>
            <Divider
                variant="middle"
                classes={{ middle: classes.dividerColor }}
            />

            <DialogActions>
                <Button
                    onClick={dialogHandleCloseDelete}
                    variant="outlined"
                    className="dcBtDelete"
                >
                    Cancel
            </Button>
                <Button
                    onClick={handleExport}
                    variant="contained"
                    className="dcBtEdit"
                >
                    Export
            </Button>
            </DialogActions>
        </Dialog>


    )
}
