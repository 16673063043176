/*
Resolvers are used in export function. Resolvers inteprets the value and returns a string
*/

import { COLUMN_IDS } from "./columns";

const COLUMN_RESOLVERS = {
  [COLUMN_IDS.is_healthy]: (record: any) => {
    if (record.is_healthy) {
      return "Healthy";
    }
    if (record.is_healthy === false) {
      return "Unhealthy";
    }
    return "Unknown";
  },

  [COLUMN_IDS.battery_level]: (record: any) =>
    +record.battery_level === -1 ? "Invalid Data" : record.battery_level,
  [COLUMN_IDS.battery_unit]: (record: any) => {
    if (+record.battery_level === -1) {
      return "";
    }
    const loweredBatteryUnit = (record.battery_unit || "").toLowerCase();
    if (loweredBatteryUnit === "per") {
      return "%";
    } else if (loweredBatteryUnit === "v") {
      return "V";
    }
    return record.battery_unit;
  },
  [COLUMN_IDS.pi_measurement]: (record: any) =>
    +record.pi_measurement === -1 ? "Invalid Data" : record.pi_measurement,
  [COLUMN_IDS.pi_timestamp]: (record: any) =>
    +record.pi_timestamp === -1 ? "Invalid Data" : record.pi_timestamp,
  [COLUMN_IDS.device_twin]: (record: any) =>
    record.device_twin ? JSON.stringify(record.device_twin) : "",
  [COLUMN_IDS.gps_location]: (record: any) => {
    if (record.loc_latitude === null) {
      return "";
    }
    return JSON.stringify({
      latitude: record.loc_latitude || "",
      longitude: record.loc_longitude || "",
      accuracy: record.loc_accuracy || "",
    });
  },
};

const getResolver = (columnId: string) => {
  return COLUMN_RESOLVERS[columnId];
};

export default getResolver;
