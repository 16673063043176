import React from "react";
import { GriddedTextField } from "../..";
import TabularFieldRenderer from "./TabularFieldRenderer";

import DropDownFieldRenderer from "./DropDownFieldRenderer";

const DynamicFieldRenderer: React.FC<any> = ({ tagData, onChange }) => {
  const { name } = tagData;
  return {
    text: ({ tagData: { value, data_type } }: any) => (
      <GriddedTextField
        label={name + (data_type === "number" ? " (numeric)" : "")}
        value={value}
        onChange={(v: string) => onChange(name, { value: v })}
      />
    ),
    dropdown: (props: any) => (
      <DropDownFieldRenderer name={name} onChange={onChange} {...props} />
    ),
    tabular: ({ tagData: { table_values, value } }: any) => {
      return (
        <TabularFieldRenderer
          label={name}
          columnValues={table_values}
          columnHeaders={value}
          onChange={(v: any) => {
            console.log({ v });
            onChange(name, { table_values: v });
          }}
        />
      );
    },
    unknown: ({ tagData: { value } }: any) => {
      return (
        <GriddedTextField
          label={name}
          value={value}
          onChange={(v: string) => onChange(name, { value: v })}
        />
      );
    },
  }[tagData.type.toLowerCase()]({ tagData });
};

export default DynamicFieldRenderer;
