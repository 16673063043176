import {
  TAG_DATA_TYPE,
  TAG_TYPE,
  letterNumberRegXWithOutSpace,
} from "../../../../../utils/constant";
import readError from "../../../../../utils/readError";

//TODO: Candidate for common array utility function
const replace = (array: any[], index: number, item: any) => {
  return Object.assign([], array, { [index]: item });
};

//TODO: Candidate for common array utility function
const remove = (array: any[], index: number) => {
  return [...array.slice(0, index), ...array.slice(index + 1)];
};

const getTagSpecificFields = (tag: any) => {
  if (tag.type === "Dropdown") {
    return { options: "" };
  }
  return {};
};

const convertToObject = (tags: any[]) => {
  let tagObj = {};
  tags.forEach((tag) => {
    const { name, ...rest } = tag;
    tagObj = { ...tagObj, [name]: { ...rest, ...getTagSpecificFields(tag) } };
  });
  return tagObj;
};

const initializeSingleTag = () => {
  return {
    name: "",
    data_type: "",
    device_metadata_options_id: "",
    is_visible_as_column: false,
    type: "",
    value: "",
    _isNew: true,
  };
};

const toTagArray = (tags: any) => {
  return Object.keys(tags).map((name: string) => ({
    name,
    ...tags[name],
  }));
};

const hasDuplicateTags = (
  existingTagNames: string[],
  tagNameToCompare: string
) => {
  return existingTagNames
    .map((s) => s.trim().toLocaleLowerCase())
    .includes(tagNameToCompare.trim().toLowerCase());
};

const validateAddedTags = (existingTags: any, newTags: any) => {
  const existingTagNames = Object.keys(existingTags);
  const newTagNames = Object.keys(newTags);

  for (let i = 0; i < newTagNames.length; i++) {
    const name = newTagNames[i];
    const tag = { name, ...newTags[name] };

    try {
      if (!name) {
        throw new Error(`Please enter tag name`);
      }
      if (!letterNumberRegXWithOutSpace.test(name)) {
        throw new Error(
          `Invalid tag name - '${name}' (only 0-9,A-Z,a-z,_,- allowed with no spaces)`
        );
      }
      if (hasDuplicateTags(existingTagNames, name)) {
        throw new Error(`Tag name '${name}' already exists`);
      }
      if (!tag.type) {
        throw new Error(`Please select field type`);
      }
      if (!tag.data_type) {
        throw new Error(`Please select data type`);
      }
      if (tag.type === TAG_TYPE.Tabular) {
        if (tag.data_type === TAG_DATA_TYPE.number) {
          throw new Error("Please select 'text' as data type for Tabular tags");
        }
        if (tag.is_visible_as_column) {
          throw new Error("Tabular tag cannot be shown as column");
        }
      }
    } catch (error) {
      const errorMessage = readError(error);
      throw new Error(
        `${errorMessage} ${newTagNames.length > 1 ? `(row ${i + 1})` : ""}`
      );
    }
  }
};

const hasDuplicateTagEntries = (tags: any[]) => {
  const loweredTagNames = tags
    .map(({ name }) => name.toLowerCase())
    .filter((s) => !!s);

  if (new Set(loweredTagNames).size !== loweredTagNames.length) {
    throw new Error(
      `Same tag entered multiple times. Please remove duplicates`
    );
  }
};

export {
  replace,
  remove,
  convertToObject,
  initializeSingleTag,
  validateAddedTags,
  hasDuplicateTags,
  hasDuplicateTagEntries,
};
