import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";


const useStyles = makeStyles({
  root: {
    height: "140px",
    width: "577px",
    color: "#FCFCFC",
    background: "#2B2B36",
  },
  content: {
    padding: "10px 25px 21px 18px",
  },
  title: {
    fontSize: "40px",
    lineHeight: "47px",
    paddingBottom: "12px",
  },
  text: {
    paddingTop: "12px",
    fontSize: "16px",
    lineHeight: "19px",
  },
  arrow: {
    float: "right",
    color: "#0089FF",
    paddingRight: "0px"
  },
});

interface HighlightCardInterface {
  value: number;
  text: string;
}
export default function HighlightCard({
  value,
  text,
}: HighlightCardInterface) {
  const classes = useStyles();
  return (
    <Card className={classes.root} variant="outlined">
      <CardContent className={classes.content}>
        <Typography className={classes.title}>
          {value}
        </Typography>
        <Typography className={classes.text} variant="body2">{text}</Typography>
      </CardContent>
    </Card>
  );
}
