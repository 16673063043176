import React, { useState } from "react";
import {
  CustomButton,
  Modal,
  ButtonText,
  SubmitButton,
} from "../../../../UiComponents";
import {
  replace,
  remove,
  convertToObject,
  initializeSingleTag,
  hasDuplicateTagEntries,
} from "./utils";
import TagForm from "./TagForm";
import useStyles from "./AddNewTag.styles";
import { useSnackbar } from "notistack";
import readError from "../../../../../utils/readError";
import { TAG_DATA_TYPE, TAG_TYPE } from "../../../../../utils/constant";

const getUpdatedTagData = (
  tag: any,
  updatedAttribute: string,
  updatedValue: string
) => {
  let updatedTagData: any = { [updatedAttribute]: updatedValue };

  //if Tabular tag, we will assign defaults - as TagForm locks these inputs
  if (updatedAttribute === "type" && updatedValue === TAG_TYPE.Tabular) {
    updatedTagData = {
      ...updatedTagData,
      data_type: TAG_DATA_TYPE.text,
      is_visible_as_column: false,
    };
  }

  return updatedTagData;
};

const AddNewTagDialog: React.FC<any> = ({ onTagAdd, onClose }) => {
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const [tags, setTags] = useState([initializeSingleTag()]);

  return (
    <Modal
      open={true}
      title="Add New Tag"
      onClose={onClose}
      disableBackdropClick
    >
      {tags.map((tag: any, index: number) => {
        return (
          <TagForm
            key={index}
            {...tag}
            onChange={(changedAttribute: string, value: string) => {
              const updatedTagData = getUpdatedTagData(
                tag,
                changedAttribute,
                value
              );
              const updatedTag = { ...tag, ...updatedTagData };
              setTags(replace(tags, index, updatedTag));
            }}
            onRemove={() => setTags(remove(tags, index))}
            disableDelete={index === 0 && tags.length === 1}
          />
        );
      })}
      <ButtonText
        style={{ margin: "-1.2em 0 0 1em" }}
        label="Add New"
        onClick={() => setTags([...tags, initializeSingleTag()])}
      />

      <div className={classes.buttonHolder}>
        <CustomButton variant="outlined-white" onClick={onClose}>
          Cancel
        </CustomButton>
        <SubmitButton
          onClick={() => {
            try {
              hasDuplicateTagEntries(tags);
              onTagAdd(convertToObject(tags));
              onClose();
            } catch (error) {
              const errorMessage = readError(error);
              enqueueSnackbar(errorMessage, { variant: "error" });
            }
          }}
        >
          Save
        </SubmitButton>
      </div>
    </Modal>
  );
};

const AddNewTag: React.FC<any> = ({ onTagAdd }) => {
  const [showAddModal, setShowAddModal] = useState(false);
  return (
    <>
      <ButtonText
        style={{ margin: "-1.2em 0 0 1em" }}
        label="Add New Tag"
        onClick={() => setShowAddModal(true)}
      />
      {showAddModal && (
        <AddNewTagDialog
          onTagAdd={onTagAdd}
          onClose={() => setShowAddModal(false)}
        />
      )}
    </>
  );
};

export default AddNewTag;
