import React from "react";
import { createStyles, Theme, withStyles } from "@material-ui/core/styles";

import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";

import { IColumn, ITemplateColumnProps } from "./types";

const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    root: {
      borderBottom: "none",
    },
    head: {
      backgroundColor: "#464659",
      color: "#FFFFFF",
      borderBottom: "none",
      padding: "8px 16px",
      fontWeight: "bold",
    },
    body: {
      fontSize: 14,
      color: "#FFFFFF",
      backgroundColor: "none",
      padding: "12px",
    },
  })
)(TableCell);

const StyledTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: "#2B2B36",
      },
    },
  })
)(TableRow);

interface IDataTableBody {
  columns: Array<IColumn>;
  values: Array<any>;
  refreshGrid: () => void;
}

const DataTableBody = ({ columns, values, refreshGrid }: IDataTableBody) => {
  return (
    <TableBody>
      {values.map((record, index) => {
        //assuming first column will be the uniqueId of record
        //UPDATE: This cannot be guaranteed. But index will make the key unique
        const uniqueRowId = `${record[columns[0].id]}--rowid--${index}`;
        return (
          <StyledTableRow key={uniqueRowId}>
            {columns.map((column: any, columnIndex) => {
              const value = record[column.id] || "";

              if (column.template) {
                return (
                  <StyledTableCell key={`ac--${columnIndex}`}>
                    {column.template({ record, refreshGrid, index })}
                  </StyledTableCell>
                );
              }
              return (
                <StyledTableCell key={`tc--${value}--${columnIndex}`}>
                  {value}
                </StyledTableCell>
              );
            })}
          </StyledTableRow>
        );
      })}
    </TableBody>
  );
};

export default DataTableBody;
