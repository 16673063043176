import React from "react";
import FilterListIcon from "@material-ui/icons/FilterList";
import { Button } from "@material-ui/core";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";

import { useMultiFilterContext } from "./MultiFilterContextProvider";
import MultiFilterModal from "./MultiFilterModal";
import { addOrUpdate } from "./utils";
import { IFilter } from "./types";
//TODO: Add proper types

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      padding: "0px",
      textTransform: "none",
      fontSize: "14px",
      whiteSpace: "nowrap",
    },
  })
);
const MultiFilterButton: React.FC<any> = () => {
  const { activeFilters, setActiveFilters, openedFilter, setOpenedFilter } =
    useMultiFilterContext();
  const classes = useStyles();
  return (
    <>
      <Button
        variant="text"
        color="inherit"
        className={classes.button}
        startIcon={
          <FilterListIcon color="primary" style={{ color: "#7C7C94" }} />
        }
        onClick={() => setOpenedFilter("-")}
      >
        <span style={{ color: "white" }}>Filter</span>
      </Button>

      {openedFilter && (
        <MultiFilterModal
          onClose={() => setOpenedFilter("")}
          onSelect={(filter: IFilter) => {
            const updatedActiveFilters = addOrUpdate(
              activeFilters,
              filter,
              "filterType"
            );
            setActiveFilters(updatedActiveFilters);
          }}
        />
      )}
    </>
  );
};

export { MultiFilterButton };
