import React, { useState, useEffect } from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import { useSnackbar } from "notistack";

import { CustomButton, LoadingIndicator } from "../../../UiComponents";
import useApi from "../../../../hooks/useApi";
import FullScreenView from "./FullScreenView";
import ThumbnailView from "./ThumbnailView";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    ImageViewer: {
      margin: "1em",
      display: "flex",
      flexFlow: "column",
      width: "15em",
      alignItems: "center",
    },
    preview: {
      width: "210px",
      height: "118px",
      background: "rgba(0, 0, 0, 0.11)",
      border: "1px dashed rgba(255, 255, 255, 0.2)",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    buttonHolder: {
      display: "flex",
      marginTop: ".5em",
      width: "10em",
      justifyContent: "space-between",
    },
    fileName: {
      //TODO: duplicated in ImageViewer
      fontSize: "0.8em",
      maxWidth: "18em",
      textOverflow: "ellipsis",
      overflow: "hidden",
      whiteSpace: "nowrap",
      marginBottom: "0.5em",
    },
  })
);

interface IImageViewerProps {
  image: any;
  imageAccessBaseUrl: string;
  requestPage: string;
  onDelete?: () => void;
}

const ImageViewer: React.FC<IImageViewerProps> = ({
  imageAccessBaseUrl,
  image,
  requestPage,
  onDelete,
}) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [viewFullScreen, setViewFullScreen] = useState(false);
  const {
    status,
    data: deleteSuccessful,
    trigger: deleteImage,
  } = useApi(`${imageAccessBaseUrl}/${image.image_id}`, {
    method: "DELETE",
    deferred: true,
    data: { request_page: requestPage },
    responseDataFormatter: (responseData: any) =>
      responseData?.message === "Success",
  });

  useEffect(() => {
    deleteSuccessful && onDelete && onDelete();
    if (!status.pending && status.error) {
      enqueueSnackbar("You are not authorized to perform this action", {
        variant: "error",
      });
    }
  }, [deleteSuccessful, status.pending, status.error]);

  const showFooterButtons = !!onDelete;
  return (
    <div className={classes.ImageViewer}>
      <span className={classes.fileName}>{image.image_name}</span>
      {status.pending && <LoadingIndicator />}
      <div className={classes.preview}>
        {image && (
          <ThumbnailView
            image={image}
            imageAccessBaseUrl={imageAccessBaseUrl}
            requestPage={requestPage}
            onClick={() => setViewFullScreen(true)}
          />
        )}
      </div>
      {showFooterButtons && (
        <div className={classes.buttonHolder}>
          <CustomButton
            variant="outlined-green"
            onClick={() => setViewFullScreen(true)}
            style={{ fontWeight: "bolder" }}
          >
            View
          </CustomButton>

          <CustomButton
            variant="outlined-red"
            style={{ fontWeight: "bolder" }}
            onClick={() => deleteImage()}
          >
            Remove
          </CustomButton>
        </div>
      )}
      {viewFullScreen && (
        <FullScreenView
          image={image}
          requestPage={requestPage}
          imageAccessBaseUrl={imageAccessBaseUrl}
          onClose={() => setViewFullScreen(false)}
        />
      )}
    </div>
  );
};

export default ImageViewer;
