import { Button } from "@material-ui/core";
import React, { useState } from "react";

import { useSnackbar } from "notistack";
import axios from "axios";

import exportLogo from "../../../../assets/Export.png";
import {
  arrayOfErrorsRefresh,
  arrayOfErrorsLogout,
  exportToExcel,
} from "../../../../utils/helper";

import { IFilter } from "../../../UiComponents/MultiFilter/types";
import callXhrRequest from "../../../../utils/xhrRequestHandler";
import { useStore } from "mobx-store-provider";
import { API_URL } from "../../../../api/property";
import { useApplicationContext } from "../../../../contexts/ApplicationContext";
import ExportColumnSelectionDialog from "./ExportColumnSelectionDialog";
import translateFilter from "./translateFilter"; //TODO: Move Translator to component and remove file
import useStyles from "./ExportToCSV.styles";
import { COLUMN_IDS } from "../columnConfigs/buildColumnSchema";

const getDeviceMetaDataOptionsId = (columns: any, columnName: string) => {
  const { device_metadata_options_id } = columns.find(
    ({ id }: any) => id === columnName
  );
  return device_metadata_options_id;
};

const getExportableColumns = (columns: any[]) => {
  const exportableColumns = columns.filter(({ exportable }: any) => exportable);
  return exportableColumns;
};

const getOptionalColumnsSelected = (selectedColumns: any[]) => {
  const selectedColumnIds = selectedColumns.map(({ id }: any) => id);
  let optionalColumns: any[] = [];
  if (selectedColumnIds.includes(COLUMN_IDS.comment)) {
    optionalColumns = [...optionalColumns, { name: COLUMN_IDS.comment }];
  }
  if (selectedColumnIds.includes(COLUMN_IDS.gps_location)) {
    optionalColumns = [...optionalColumns, { name: COLUMN_IDS.gps_location }];
  }
  return optionalColumns;
};

/* TODO:We can use useApi hook here to keep things simple and reusable. Will work on it later*/
const ExportToCSV: React.FC<any> = ({
  columns,
  activeFilters,
  sortingSequence,
}) => {
  const { selectedApplication } = useApplicationContext();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [showExportColumnSelectionDialog, setShowExportColumnSelectionDialog] =
    useState(false);
  const { user } = useStore();

  const exportData = async (selectedColumns: any[]) => {
    const accessToken = JSON.parse(
      sessionStorage.getItem("sso_token") as string
    );
    const DEVICES_API = `${API_URL}/api/v1d/applications/${selectedApplication.application_id}/devices-export`;
    const requestOptions = {
      headers: {
        Authorization: `Bearer ${accessToken.access_token} `,
        "Ocp-Apim-Subscription-Key": window.SERVER_DATA.REACT_APP_PM_API_KEY,
        "Ocp-Apim-Trace": `true`,
      },
      params: {
        pm_s_token: sessionStorage.getItem("pm_s_token"),
        pm_s_id: sessionStorage.getItem("pm_s_id"),
        filter: activeFilters.map((filter: IFilter) => {
          const translatedFilter = translateFilter(columns, filter);
          return {
            name: translatedFilter.filterType,
            value: translatedFilter.filterValue,
            device_metadata_options_id: getDeviceMetaDataOptionsId(
              columns,
              filter.filterType
            ),
          };
        }),
        sort: sortingSequence
          .filter((sq: any) => columns.find(({ id }: any) => id === sq.name)) //remove invalid columns
          .map((sq: any) => {
            return {
              device_metadata_options_id: getDeviceMetaDataOptionsId(
                columns,
                sq.name
              ),
              ...sq,
            };
          }),
        optional_column: getOptionalColumnsSelected(selectedColumns),
      },
    };

    try {
      const { data: response }: any = await axios.get(
        DEVICES_API,
        requestOptions
      );

      const csvData = (response.devices || []).map((deviceData: any) => {
        const csvRow = selectedColumns?.reduce(
          (acc, { id, label, resolver }: any) => {
            return {
              ...acc,
              [label]: resolver ? resolver(deviceData) : deviceData[id],
            };
          },
          {}
        );
        return csvRow;
      });
      const {application_name} = selectedApplication;
      exportToExcel(csvData, 'Devices', 9, application_name); //TODO: Why 9?
    } catch (ex) {
      const errorResponse = ex.response && ex.response.data;
      if (
        errorResponse &&
        errorResponse.status === 401 &&
        ex &&
        arrayOfErrorsRefresh.includes(errorResponse.message.toLowerCase())
      ) {
        try {
          const tokenKey = "sso_token";

          callXhrRequest()
            .then(function (data) {
              sessionStorage.setItem(tokenKey, data);

              exportData(selectedColumns);
            })
            .catch(function (error) {
              user.triggerLogout();
              console.log("error", error);
            });
        } catch (error) {
          console.log(error);
        }
        return;
      } else if (
        errorResponse &&
        errorResponse.status === 401 &&
        errorResponse &&
        arrayOfErrorsLogout.includes(errorResponse.message.toLowerCase())
      ) {
        try {
          user.triggerLogout();
        } catch (error) {
          console.log(error);
        }
        return;
      } else {
        enqueueSnackbar("Could not fetch device details.", {
          variant: "error",
        });
      }
    }
  };

  return (
    <>
      <Button
        variant="text"
        color="inherit"
        className={classes.listButton}
        startIcon={<img alt="Export Logo" src={exportLogo}></img>}
        onClick={() => setShowExportColumnSelectionDialog(true)}
      >
        Export
      </Button>
      {showExportColumnSelectionDialog && (
        <ExportColumnSelectionDialog
          columns={getExportableColumns(columns)}
          onClose={() => setShowExportColumnSelectionDialog(false)}
          onSelect={(selectedColumns: any[]) => {
            exportData(selectedColumns);
            setShowExportColumnSelectionDialog(false);
            enqueueSnackbar("Export Initiated", { variant: "success" });
          }}
        />
      )}
    </>
  );
};

export default ExportToCSV;
