import axios from 'axios'
import { types, flow } from 'mobx-state-tree'
import JWTDecode from 'jwt-decode'
import {
  generateRandomString,
  pkceChallengeFromVerifier,
  getCurrentTime,
} from './pkce'

import SSOConfig from './SSOConfig'

/**
 * PKCE authorization logic originally based on/adapted from:
 * https://github.com/aaronpk/pkce-vanilla-js/blob/master/index.html
 * https://auth0.com/docs/flows/add-login-using-the-authorization-code-flow-with-pkce
 */

const tokenKey = 'sso_token'
const pkceStateKey = 'sso_pkce_state'
const pkceCodeVerifierKey = 'sso_pkce_code_verifier'
const loginLandingPageKey = 'sso_login_landing_page'
const tokenExpireBuffer = 60 * 60 * 2 // add 2 hour to the expiration
const hostMatcher = new RegExp(`${process.env.SSO_IGNORE_DOMAIN}$`)
const localLoginExpiration = 999999999999999999999
const localLoginUser = 'user@shell.com'
const localSSOKey = 'local_sso_expiration'
declare global {
  interface Window {
    SERVER_DATA: any
  }
}
const REACT_APP_SSO_HOST = window.SERVER_DATA.REACT_APP_SSO_HOST
const REACT_APP_SSO_CLIENT_ID = window.SERVER_DATA.REACT_APP_SSO_CLIENT_ID
const REACT_APP_SSO_REDIRECT_URI = 'dashboard'
const REACT_APP_SSO_SCOPE = 'openid'
const REACT_APP_PM_API_HOST = window.SERVER_DATA.REACT_APP_PM_API_HOST
const User = types
  .model('User', {
    sso: types.optional(SSOConfig, {
      host: REACT_APP_SSO_HOST as string,
      client_id: REACT_APP_SSO_CLIENT_ID as string,
      login_redirect: REACT_APP_SSO_REDIRECT_URI || 'login_redirect',
      scope: REACT_APP_SSO_SCOPE || 'openid',
    }),
    token_expiration: 0,
    isLoggingIn: false,
    email: 'user@shell.com',
    redirectError: types.maybeNull(types.string),
    currentTime: types.optional(types.number, getCurrentTime()),
  })
  .views((self) => ({
    get authorizationEndpoint() {
      return `${REACT_APP_SSO_HOST}/as/authorization.oauth2`
    },
    get tokenEndpoint() {
      return `${REACT_APP_SSO_HOST}/as/token.oauth2`
    },
    get sessionEndPoint() {
      return `${REACT_APP_PM_API_HOST}/api/v1d/sessions`
    },
    get redirectURI() {
      return `${window.location.origin}/${self.sso.login_redirect}`
    },
    get isLoggedIn() {
      return Boolean(
        self.token_expiration &&
          self.currentTime < self.token_expiration + tokenExpireBuffer,
      )
    },
  }))
  .actions((self) => ({
    updateCurrentTime() {
      self.currentTime = getCurrentTime()
    },
    processIdToken(token: any) {
      if (token) {
        // Decode the token to get the users email and token expiration details
        try {
          const { exp, sub: email, mail } = JWTDecode(
            token.id_token || token.access_token,
          )
          self.token_expiration = exp
          self.email = email || mail
        } catch (e) {
          console.log('Token error', e)
        }
      }

      if (window.location.hostname.match(hostMatcher)) {
        self.token_expiration = JSON.parse(
          sessionStorage.getItem(localSSOKey) || '0',
        )
        self.email = localLoginUser
      }
    },
  }))
  .actions((self) => {
    let updateCurrentTimeInterval: null | ReturnType<typeof setTimeout> = null
    return {
      afterCreate() {
        updateCurrentTimeInterval = setInterval(self.updateCurrentTime, 5000)
        if (typeof window !== 'undefined') {
          self.processIdToken(
            JSON.parse(sessionStorage.getItem(tokenKey) as string),
          )
        }
      },
      beforeDestroy() {
        clearInterval(Number(updateCurrentTimeInterval))
      },
      triggerLogin: flow(function* () {
        if (!self.isLoggingIn) {
          if (window.location.hostname.match(hostMatcher)) {
            window.sessionStorage.setItem(
              localSSOKey,
              JSON.stringify(localLoginExpiration),
            )
            self.token_expiration = localLoginExpiration
            self.email = localLoginUser
          } else {
            self.isLoggingIn = true

            // Record the current page the user landed on
            window.sessionStorage.setItem(
              loginLandingPageKey,
              window.location.pathname,
            )

            // Generate PKCE verifier and code challenge
            const state = generateRandomString()
            const code_verifier = generateRandomString()
            const code_challenge = yield pkceChallengeFromVerifier(
              code_verifier,
            )
            sessionStorage.setItem(pkceStateKey, state)
            sessionStorage.setItem(pkceCodeVerifierKey, code_verifier)

            // Redirect the user to the PKCE authorization/login page
            window.location.href =
              self.authorizationEndpoint +
              '?response_type=code' +
              '&client_id=' +
              encodeURIComponent(self.sso.client_id) +
              '&state=' +
              encodeURIComponent(state) +
              '&scope=' +
              encodeURIComponent(self.sso.scope) +
              '&redirect_uri=' +
              encodeURIComponent(self.redirectURI) +
              '&code_challenge=' +
              encodeURIComponent(code_challenge) +
              '&code_challenge_method=S256'
          }
        }
      }),
      handleLoginRedirect: flow(function* () {
        if (!self.isLoggingIn) {
          self.isLoggingIn = true
          self.redirectError = null

          const queryString = new URLSearchParams(window.location.search)
          if (queryString.has('error')) {
            self.redirectError = queryString.get('error')
          } else if (queryString.has('code') && queryString.has('state')) {
            const pkceState = sessionStorage.getItem(pkceStateKey)
            const queryState = queryString.get('state')
            if (pkceState !== queryState) {
              self.redirectError = 'Invalid redirect state'
            } else {
              try {
                const ssoParams = new URLSearchParams()
                const tokenInfo: any = {
                  grant_type: 'authorization_code',
                  code: queryString.get('code'),
                  client_id: self.sso.client_id,
                  redirect_uri: self.redirectURI,
                  code_verifier: sessionStorage.getItem(pkceCodeVerifierKey),
                }
                Object.keys(tokenInfo).forEach((key) =>
                  ssoParams.append(key, tokenInfo[key]),
                )
                const { data: token } = yield axios.post(
                  self.tokenEndpoint,
                  ssoParams.toString(),
                  {
                    headers: {
                      'Content-Type': 'application/x-www-form-urlencoded',
                    },
                  },
                )

                // Success, code was exchanged for a token - lets save it
                sessionStorage.setItem(tokenKey, JSON.stringify(token))

                // Process the enclosed id_token (and log the user in)
                self.processIdToken(token)

                //Need to add session API call

                // Redirect the user to their original landing page
                const landingPageUrl = window.sessionStorage.getItem(loginLandingPageKey) || '/dashboard';
                window.location.href = landingPageUrl === "/" ? "/dashboard" : landingPageUrl;
                
                // Clear out items no longer needed from sessionStorage
                sessionStorage.removeItem(pkceStateKey)
                sessionStorage.removeItem(pkceCodeVerifierKey)
                sessionStorage.removeItem(loginLandingPageKey)
              } catch (error) {
                self.redirectError = error.message
              }
            }
          } else {
            self.redirectError = 'No code or state present in query string'
          }

          self.isLoggingIn = false
        }
      }),
      triggerLogout() {
        self.email = ''
        self.token_expiration = 0
        window.sessionStorage.clear()
        sessionStorage.removeItem(tokenKey)
        window.location.href = '/'
      },
    }
  })

export default User
