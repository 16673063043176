import React, { useState } from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import { useSnackbar } from "notistack";
import useApi from "../../../hooks/useApi";
import { LoadingIndicator, Modal, CustomButton } from "../../UiComponents";

import ImageViewer from "./ImageViewer";
import ImageUploader from "./ImageUploader";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      width: "815px",
      display: "flex",
      flexFlow: "column",
    },
    imageSection: {
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "center",
    },
    buttonHolder: {
      display: "flex",
      justifyContent: "space-between",
      borderTop: "1px solid #6b6868",
      paddingTop: "0.5em",
      margin: "15px 0px",
    },
  })
);

const MAX_IMAGE_COUNT = 5;

const markDelete = (slots: Array<any>, deleteId: number) => {
  return slots.map((slot) =>
    slot.id === deleteId ? { ...slot, deleted: true } : slot
  );
};

interface IAddRemoveImageProps {
  deviceDataFetchEndPoint: string;
  imageEndPoint: string;
  deviceId: string;
  deviceName:string;
  requestPage: string;
  imageReader: (data: any) => any[];
  onDone: () => void;
}

const AddRemoveImage = ({
  deviceDataFetchEndPoint,
  imageEndPoint,
  deviceId,
  requestPage,
  imageReader,
  onDone,
}:IAddRemoveImageProps) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  //we need to maintain the component keys to preserve the selected images
  const [imageUploaderSlots, setImageUploaderSlots] = useState([
    { id: 1, deleted: false },
  ]);

  // const { selectedApplication } = useApplicationContext();
  const {
    data,
    status,
    trigger: refetchImages,
  } = useApi(deviceDataFetchEndPoint, {
    method: "GET",
    data: { request_page: requestPage },
  });

  // const deviceImages = data ? data.device.device_install_img || [] : [];
  const deviceImages = data ? imageReader(data) : [];

  return (
    <>
      <Modal open={true} onClose={onDone} title={`Device Id: ${deviceId}`}>
        {status.pending && <LoadingIndicator />}
        <div className={classes.wrapper}>
          <div className={classes.imageSection}>
            {deviceImages &&
              deviceImages.map((image: any) => (
                <ImageViewer
                  key={image.image_id}
                  image={image}
                  requestPage={requestPage}
                  imageAccessBaseUrl={imageEndPoint}
                  onDelete={() => {
                    refetchImages();
                  }}
                />
              ))}
            {deviceImages.length < MAX_IMAGE_COUNT &&
              imageUploaderSlots
                .filter(({ deleted }) => !deleted)
                .map(({ id: uploaderId }) => (
                  <ImageUploader
                    key={uploaderId}
                    imageUploadEndPoint={imageEndPoint}
                    requestPage={requestPage}
                    onRemove={() => {
                      setImageUploaderSlots(
                        markDelete(imageUploaderSlots, uploaderId)
                      );
                    }}
                    onDone={() => {
                      setImageUploaderSlots(
                        markDelete(imageUploaderSlots, uploaderId)
                      );
                      refetchImages();
                    }}
                  />
                ))}
          </div>
          <div className={classes.buttonHolder}>
            <CustomButton
              variant="outlined-white"
              onClick={() => onDone && onDone()}
            >
              Cancel
            </CustomButton>

            <CustomButton
              variant="outlined-white"
              onClick={() => {
                const totalSlots = imageUploaderSlots.filter(
                  ({ deleted }) => !deleted
                ).length;
                const totalImage = totalSlots + 1 + deviceImages.length;

                if (totalImage > MAX_IMAGE_COUNT) {
                  enqueueSnackbar(
                    `Maximum of ${MAX_IMAGE_COUNT} images can be uploaded.`,
                    {
                      variant: "error",
                    }
                  );
                } else {
                  setImageUploaderSlots([
                    ...imageUploaderSlots,
                    { id: imageUploaderSlots.length + 1, deleted: false },
                  ]);
                }
              }}
            >
              Add More Images
            </CustomButton>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default AddRemoveImage;
