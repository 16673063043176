import React, { useContext, useState } from "react";
import { IFilter, IFilterSchema } from "./types";

interface IMultiFilterContext {
  availableFilters: IFilterSchema[];
  activeFilters: IFilter[];
  openedFilter: string;
  setActiveFilters: (activeFilters: IFilter[]) => void;
  setOpenedFilter: (filterType: string) => void;
}

const MultiFilterContext = React.createContext<IMultiFilterContext>({
  availableFilters: [],
  activeFilters: [],
  openedFilter: "",
  setActiveFilters: () => true,
  setOpenedFilter: () => true,
});

interface IMultiFilterContextProviderProps {
  availableFilters: IFilterSchema[];
  onFilterChange: (activeFilters: IFilter[]) => void;
  activeFilters: IFilter[];
  children: React.ReactNode;
}

const MultiFilterContextProvider: React.FC<
  IMultiFilterContextProviderProps
> = ({ availableFilters, onFilterChange, activeFilters, children }) => {
  const [openedFilter, setOpenedFilter] = useState<string>("");

  return (
    <MultiFilterContext.Provider
      value={{
        availableFilters,
        activeFilters,
        setActiveFilters: (activeFilters: IFilter[]) => {
          onFilterChange(activeFilters);
        },
        openedFilter,
        setOpenedFilter,
      }}
    >
      {children}
    </MultiFilterContext.Provider>
  );
};

const useMultiFilterContext = () => useContext(MultiFilterContext);

export { MultiFilterContextProvider, useMultiFilterContext };
