export enum COLUMN_IDS {
  action = "action",
  cloud_gateway = "cloud_gateway",
  device_twin = "device_twin",
  comment = "comment",
  gps_location = "gps_location",
  certificate_expiring = "certificate_expiring",
  is_healthy = "is_healthy",
  status = "status",
  battery_level = "battery_level",
  battery_unit = "battery_unit",
  pi_measurement = "pi_measurement",
  pi_timestamp = "pi_timestamp",
  installation_status = "installation_status",
}
