import React from "react";
import HealthImg from "../../../../../assets/HealthIcon.svg";
import { COLUMN_IDS } from "./columns";

const HEADER_COLUMN_TEMPLATES = {
  [COLUMN_IDS.is_healthy]: () => (
    <img alt="Health Icon" src={HealthImg} style={{ width: "1.5em" }} />
  ),
  [COLUMN_IDS.certificate_expiring]: () => "",
};

const getHeaderTemplate = (columnId: string) => {
  return HEADER_COLUMN_TEMPLATES[columnId];
};

export default getHeaderTemplate;
