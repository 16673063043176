import React from "react";
import { Grid, IconButton } from "@material-ui/core";
import { Delete as DeleteIcon } from "@material-ui/icons";
import { CustomTextField, FormSection } from "../../../UiComponents";
import useStyles from "../EditConfiguration/EditConfiguration.styles";

const Properties: React.FC<any> = ({
  properties,
  onPropertyChange,
  onPropertyDelete,
}) => {
  const classes = useStyles();

  return (
    <FormSection header="Properties">
      {Object.keys(properties).map((property: any) => (
        <Grid item xs={6}>
          <div className={classes.propertyWrapper}>
            <div className={classes.propertyHeaderWrapper}>
              <div
                style={{
                  fontSize: "14px",
                  fontWeight: "bold",
                  marginBottom: "2px",
                }}
              >
                {property}
              </div>
              {onPropertyDelete && (
                <IconButton onClick={() => onPropertyDelete(property)}>
                  <DeleteIcon color="secondary" />
                </IconButton>
              )}
            </div>
            <CustomTextField
              key={property}
              value={properties[property]}
              onChange={(value: string) => onPropertyChange(property, value)}
            />
          </div>
        </Grid>
      ))}
    </FormSection>
  );
};

export default Properties;
